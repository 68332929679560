import { ApiQuery } from 'core/model/interface';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { PaymentModeInput } from 'company/screens/ClinicManagement/components/PaymentModes/CompanyPaymentModeModel';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'payment-modes';

export const getCompanyPaymentModes = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getSinglePaymentMode = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getSinglePaymentModeByName = (name: string) => {
  return axios.get(`${baseURL}/${path}/name/${name}`);
};

export const createNewPaymentMode = (data: PaymentModeInput) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const handleDeletePaymentMode = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const updatePaymentMode = (id: number, data: PaymentModeInput) => {
  return axios.patch(`${baseURL}/${path}/${id}`, data);
};

import * as yup from 'yup';

import { DROPDOWN_FIELD, FieldInput, SelectOption } from 'core/model/interface';
import { calculateAge, formatArray, formatDateTime } from 'core/utils';

import { CompanyTagsModel } from 'company/model/Entities';
import { EntityFields } from '../../../utils';
import ImageUpload from 'company/screens/ClinicManagement/components/ImageUpload';
import { Typography } from '@mui/material';

export const PATIENT_ENTITY_NAME = 'Patient';

export const createPatientSchema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .test('required-if-mobile-2', 'Required when secondary is provided', function (value, context) {
      const hasSecondaryMobile = !!context.parent.mobile_number_2;
      if (hasSecondaryMobile && !value) {
        return false;
      }
      return true;
    }),
  mobile_number_2: yup.string().matches(/^(09|639)\d{9}$/, {
    message: 'Mobile number is invalid.',
    excludeEmptyString: false,
  }),
  landline_number: yup.string().matches(/^[0-9-]+$/, {
    message: 'Landline number is invalid.',
    excludeEmptyString: true,
  }),
  // .required('Mobile number is required.'),
  birthday: yup
    .date()
    .typeError('Invalid birthday')
    .max(new Date(), 'Invalid birthday')
    .nullable()
    .transform((curr, orig) => (!orig ? null : curr)),
  // .required('Birthday is required.'),
  age: yup.number().max(130, 'Maximum age is 130 years old').nullable(),
  sex: yup.string().required('Sex is required.'),
  occupation: yup
    .string()
    .matches(/^[a-zA-Z\s]*$/, 'Occupation must contain only letters')
    .max(100, 'Occupation must be 100 characters or less'),
  department_name: yup
    .string()
    .matches(/^[a-zA-Z\s]*$/, 'Department must contain only letters')
    .max(100, 'Department must be 50 characters or less'),
  section_name: yup
    .string()
    .matches(/^[a-zA-Z\s]*$/, 'Section must contain only letters')
    .max(100, 'Section must be 50 characters or less'),
  pwd_id: yup
    .string()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    // .typeError('PWD ID must be a number')
    // .positive('PWD ID must be a positive number')
    .test('len', 'Must be 20 characters or less', (val) => val == null || val.toString().length <= 20)
    .nullable(),
  hmo_id: yup
    .string()
    .test('len', 'Must be 30 characters or less', (val) => !val || val.length <= 30)
    .nullable(),
  hmo_account_number: yup
    .string()
    // .matches(/^[0-9-]*$/, 'Only numbers and dashes are allowed')
    .test('len', 'Must be 30 characters or less', (val) => !val || val.length <= 30)
    .nullable(),
  hmo_card_number: yup
    .string()
    // .matches(/^[0-9-]*$/, 'Only numbers and dashes are allowed')
    .test('len', 'Must be 30 characters or less', (val) => !val || val.length <= 30)
    .nullable(),
  case_number: yup
    .string()
    .test('len', 'Must be 30 characters or less', (val) => !val || val.length <= 30)
    .nullable(),
  senior_citizen_id: yup
    .string()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    // .typeError('Senior Citizen ID must be a number')
    // .positive('Senior Citizen ID must be a positive number')
    .test('len', 'Must be 20 characters or less', (val) => val == null || val.toString().length <= 20)
    .nullable(),
  diagnosis: yup.string().max(500, 'Diagnosis must be 500 characters or less').nullable(),
});

export interface PatientModel {
  id: number;
  company_id: number;
  facility_id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  full_name: string;
  address: string;
  province_city: string;
  birthday?: string;
  age: string;
  occupation: string;

  pwd_id: string;
  senior_citizen_id: string;

  email: string;
  encrypted_mobile_number: string; // not used
  mobile_number?: string;
  mobile_number_2?: string;
  landline_number?: string;
  patient_id: number;
  sex: string;
  is_active: boolean;
  tags: CompanyTagsModel[];
  corporate_client_id: number;
  created_at: string;
  package_id: number;
  company_name: string;
  employee_id: string;

  // department_id: number;
  department_name: string;
  section_name: string;
  case_number: string;

  diagnosis: string;
  hmo_id: number;
  hmo_name: string;
  hmo_account_name: string;
  hmo_account_number: string;
  hmo_card_number: string;
  profile_photo: string;
  civil_status: string;

  region?: string;
  province?: string;
  municipality?: string;
}

export interface PatientInput {
  first_name: string;
  middle_name: string;
  last_name: string;
  mobile_number?: string;
  mobile_number_2?: string;
  landline_number?: string;
  sex: string;
  birthday?: string;
  age: string;
  occupation: string;
  department_name: string;
  section_name: string;
  case_number?: string;

  pwd_id: string;
  senior_citizen_id: string;

  province_city: string;
  address: string;
  email: string;
  tags: SelectOption[];
  corporate_client_id?: string;
  // department_id?: number;
  diagnosis: string;

  hmo_id?: number;
  hmo_account_number: string;
  hmo_card_number: string;
  hmo_account_name: string;
}

export interface PatientFieldInput extends FieldInput {
  field_name?: keyof PatientInput;
}

export const patientInitialValues: PatientInput = {
  first_name: '',
  middle_name: '',
  last_name: '',
  mobile_number: '',
  mobile_number_2: '',
  landline_number: '',

  sex: '',
  birthday: '',
  age: '',
  occupation: '',
  department_name: '',
  section_name: '',

  pwd_id: '',
  senior_citizen_id: '',

  province_city: '',
  address: '',
  email: '',
  tags: [],
  corporate_client_id: '',
  diagnosis: '',
  hmo_id: 0,
  hmo_account_name: '',
  hmo_account_number: '',
  hmo_card_number: '',
};

// fieldName should be unique
export const patientFields: EntityFields[] = [
  { fieldName: 'first_name', displayName: 'First Name' },
  { fieldName: 'middle_name', displayName: 'Middle Name', optional: true },
  { fieldName: 'last_name', displayName: 'Last Name' },
  {
    fieldName: 'full_name',
    displayName: 'Name',
    generateLink: (data) => `/company/patient-management?patient_id=${data.patient_id}`,
    flex: 1.5,
  },
  { fieldName: 'mobile_number', displayName: 'Mobile Number (Primary)', optional: true },
  { fieldName: 'mobile_number_2', displayName: 'Mobile Number (Secondary)', optional: true },
  { fieldName: 'landline_number', displayName: 'Landline Number', optional: true },

  { fieldName: 'email', displayName: 'Email', type: 'email', optional: true },
  {
    fieldName: 'sex',
    displayName: 'Sex Assigned At Birth',
    type: 'select',
    options: [
      { key: 'Male', value: 'Male' },
      { key: 'Female', value: 'Female' },
    ],
  },
  { fieldName: 'birthday', displayName: 'Birthday', type: 'date', editable: true, optional: true },
  {
    fieldName: 'age',
    displayName: 'Age',
    type: 'number',
    flex: 0.5,
    optional: true,
    // renderCell: (params) => <Typography>{params.row.birthday ? calculateAge(params.row.birthday) : ''}</Typography>,
    hiddenBasedOnOtherField: (data) => !!data.birthday,
  },
  { fieldName: 'occupation', displayName: 'Occupation', optional: true },
  { fieldName: 'department_name', displayName: 'Department Name', optional: true },
  { fieldName: 'section_name', displayName: 'Section Name', optional: true },
  {
    fieldName: 'case_number',
    displayName: 'Case No.',
    optional: true,
  },
  {
    fieldName: 'civil_status',
    displayName: 'Civil Status',
    type: 'select',
    options: [
      { key: 'Single', value: 'Single' },
      { key: 'Married', value: 'Married' },
      { key: 'Widowed', value: 'Widowed' },
      { key: 'Legally Separated', value: 'Legally Separated' },
    ],
    optional: true,
  },
  { fieldName: 'pwd_id', displayName: 'PWD ID', optional: true },
  { fieldName: 'senior_citizen_id', displayName: 'Senior Citizen ID', optional: true },

  {
    fieldName: 'address',
    displayName: 'Address',
    flex: 2,
    span: 4,
    rows: 2,
    multiline: true,
    optional: true,
    renderCell: (params) => {
      const { address, province_city } = params.row;
      return <Typography>{formatArray([address, province_city], ', ')}</Typography>;
    },
  },
  { fieldName: 'province_city', displayName: 'Province / City', optional: true },
  {
    fieldName: 'tags',
    displayName: 'Patient Group',
    type: DROPDOWN_FIELD.PATIENT_GROUP,
    multiple: true,
    optional: true,
  },
  {
    fieldName: 'corporate_client_id',
    displayName: 'Corporate Client',
    type: DROPDOWN_FIELD.CORPORATE_CLIENT,
    optional: true,
    onChange: (value, setFieldValue, setCustomDisplayName, setFieldData) => {
      setFieldValue && setFieldValue('department_id', '');
      if (setFieldData) {
        if (value) {
          setFieldData((prev: any) => ({ ...prev, department_id: { corporate_client_id: value.value } }));
        } else {
          setFieldData((prev: any) => ({ ...prev, department_id: undefined }));
        }
      }
    },
  },
  {
    fieldName: 'diagnosis',
    displayName: 'Diagnosis',
    type: 'text',
    multiline: true,
    rows: 3,
    optional: true,
    span: 4,
  },
  {
    fieldName: 'hmo_id',
    displayName: 'HMO',
    type: DROPDOWN_FIELD.HMO,
    optional: true,
  },

  {
    fieldName: 'hmo_account_name',
    displayName: 'HMO Account Name',
    type: 'string',
    hiddenBasedOnOtherField: (data) => data.hmo_id < 1,
    optional: true,
  },

  {
    fieldName: 'hmo_account_number',
    displayName: 'HMO Account Number',
    type: 'string',
    hiddenBasedOnOtherField: (data) => data.hmo_id < 1,
    optional: true,
  },
  {
    fieldName: 'hmo_card_number',
    displayName: 'HMO Card Number',
    type: 'string',
    hiddenBasedOnOtherField: (data) => data.hmo_id < 1,
    optional: true,
  },

  {
    fieldName: 'created_at',
    displayName: 'Date Registered',
    flex: 2,
    // type: 'dateTime',
    // valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY hh:mm A'),
    renderCell: (params) => <Typography>{formatDateTime(params.row.created_at)}</Typography>,
  },

  { fieldName: 'divider_1', type: 'divider' },
  {
    fieldName: 'personal_info_subheader',
    type: 'subsection_header',
    subsection_header: 'Basic Information',
  },
  {
    fieldName: 'component_contact_details',
    type: 'subsection_header',
    subsection_header: 'Other Information',
  },
  {
    fieldName: 'employee_id',
    displayName: 'Employee ID',
    optional: true,
  },

  {
    fieldName: 'component_hmo',
    type: 'subsection_header',
    subsection_header: 'HMO Information',
  },

  // {
  //   fieldName: 'department_id',
  //   displayName: 'Department',
  //   type: DROPDOWN_FIELD.CORPORATE_CLIENT_DEPARTMENT,
  //   hiddenBasedOnOtherField: (data) => {
  //     return !data.corporate_client_id;
  //   },
  // },
];

// Table
export const patientTableColumns: string[] = ['full_name', 'mobile_number', 'birthday', 'age', 'sex', 'created_at'];

// Forms
export const patientFormFields2: string[] = [
  'personal_info_subheader',
  'first_name',
  'middle_name',
  'last_name',
  'sex',
  'birthday',
  'age',

  'occupation',
  'department_name',
  'section_name',
  'case_number',

  'civil_status',
  'mobile_number',
  'mobile_number_2',
  'landline_number',
  'email',
  'address',
  'province_city',

  'component_contact_details',

  'pwd_id',
  'senior_citizen_id',
  // 'diagnosis',
  'hmo_id',
  'hmo_account_name',
  'hmo_account_number',
  'hmo_card_number',
  'corporate_client_id',

  // 'divider_1',
  // 'department_id',
  'employee_id',

  'tags',
  'divider_1',
];

export const patientUpdateFormFields: string[] = [
  'personal_info_subheader',
  'first_name',
  'middle_name',
  'last_name',
  'sex',
  'birthday',
  'age',
  'case_number',
  'civil_status',
  'occupation',
  'tags',

  'email',
  'landline_number',
  'mobile_number',
  'mobile_number_2',
  'province_city',
  'address',

  'component_hmo',
  'hmo_id',
  'hmo_account_name',
  'hmo_account_number',
  'hmo_card_number',

  'component_contact_details',
  'pwd_id',
  'senior_citizen_id',
  'corporate_client_id',
  'employee_id',
  'department_name',
  'section_name',
  'diagnosis',
];

export const patientFormFields: string[] = patientUpdateFormFields;

export const patientModelSample: PatientModel = {
  id: 4,
  company_id: 4,
  facility_id: 5,
  first_name: 'Tom',
  middle_name: '', // Middle name is a string, so it should not be null.
  last_name: 'Aspinall',
  full_name: 'Aspinall, Tom',
  address: '',
  province_city: '',
  birthday: '1999-12-22',
  age: '',
  occupation: '',
  pwd_id: '',
  senior_citizen_id: '',
  email: '',
  encrypted_mobile_number: '', // Not used but required in the model.
  mobile_number: '09101010101',
  mobile_number_2: undefined,
  landline_number: undefined,
  patient_id: 4,
  sex: 'Male',
  is_active: true,
  tags: [], // Assuming no tags are present.
  corporate_client_id: 0, // Defaulting to 0 if not provided.
  created_at: '2024-07-10T09:42:43.000000Z',
  package_id: 0, // Defaulting to 0 if not provided.
  company_name: '',
  employee_id: '',
  department_name: '',
  section_name: '',
  case_number: '',
  diagnosis: '',
  hmo_id: 0, // Defaulting to 0 if not provided.
  hmo_name: '',
  hmo_account_name: '',
  hmo_account_number: '',
  hmo_card_number: '',
  profile_photo: '',
  civil_status: '',
};

export const patientSampleForCareGo: PatientModel = {
  id: 4,
  company_id: 100,
  facility_id: 2,
  first_name: 'Sample',
  middle_name: '',
  last_name: 'Patient',
  full_name: 'Sample Patient',
  address: 'Manila, Philippines',
  province_city: '',
  birthday: '1999-12-22',
  age: '', // Null value replaced with an empty string since age is required
  occupation: '', // Null value replaced with an empty string

  pwd_id: '',
  senior_citizen_id: '',

  email: '',
  encrypted_mobile_number: '',
  mobile_number: '09101010101',
  mobile_number_2: '',
  landline_number: '',
  patient_id: 4, // Assuming patient_id is the same as id unless specified otherwise
  sex: 'Male',
  is_active: true, // Converted 1 to boolean true
  tags: [], // Assuming no tags were provided
  corporate_client_id: 1,
  created_at: '2024-07-10 17:42:43',
  package_id: 0, // Assuming package_id is not provided
  company_name: '', // No company name provided
  employee_id: '',

  department_name: '',
  section_name: '',
  case_number: '0000412',

  diagnosis: '',
  hmo_id: 0, // Assuming hmo_id is null, setting it to 0
  hmo_name: '',
  hmo_account_name: '',
  hmo_account_number: '',
  hmo_card_number: '',
  profile_photo: '',
  civil_status: '',

  region: '',
  province: '',
  municipality: '',
};

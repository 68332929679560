import { Box, Typography, useTheme } from '@mui/material';
import {
  ConfirmationDialog,
  CustomModal,
  HideOrShowComponent,
  PrimaryButton,
  RegularButton,
  RowAction,
} from 'core/components';
import { deletePrescriptionMedicine, getPrescriptionMedicines } from 'company/api/prescription-medicines';
import { useContext, useEffect, useRef, useState } from 'react';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { ContainerRow } from 'core/components/containers';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DispenseHistory from './PrescriptionLineItem/DispenseHistory';
import DispenseMedicineForm from './PrescriptionLineItem/DispenseMedicineForm';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { Link } from 'react-router-dom';
import MedicationLiquidOutlinedIcon from '@mui/icons-material/MedicationLiquidOutlined';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from '../Patient/PatientModel';
import PrescriptionLineItemForm from './PrescriptionLineItem/PrescriptionLineItemForm';
import { PrescriptionLineItemModel } from './PrescriptionLineItem/PrescriptionLineItemModel';
import { PrescriptionModel } from './PrescriptionModel';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import PrintablePrescription from 'company/screens/RegistrationBoard/components/PrintablePrescription';
import { SIZES } from 'theme/constants';
import SendReminders from './PrescriptionLineItem/Components/SendReminder';
import { SmsScheduleModal } from 'company/components';
import { UserContext } from 'core/context/user.context';
import { cancelMedicationReminder } from 'company/api/medication-reminder';
import { formatNumberMaxDecimal } from 'core/utils';
import { getDispenseHistory } from 'company/api/prescription-dispense';
import { getDoctor } from 'company/api/doctors';
import { getPatientPrescriptions } from 'patient/api/records';
import { getPrintSettings } from 'company/api/print-settings';
import { tokens } from 'theme/theme';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';

type Props = {
  prescription: PrescriptionModel;
  patient: PatientModel;
  source?: string;
};

const PrescriptionContainer: React.FC<Props> = ({ prescription, patient, source }) => {
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const printablePrescriptionRef = useRef(null);

  const [lineItems, setLineItems] = useState<PrescriptionLineItemModel[]>();
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selectedMedicine, setSelectedMedicine] = useState<PrescriptionLineItemModel>();
  const [openSendReminder, setOpenSendReminder] = useState(false);
  const [openConfirmCancelation, setOpenConfirmCancelation] = useState(false);
  const [openSmsSchedules, setOpenSmsSchedules] = useState(false);
  const [doctor, setDoctor] = useState<any>();
  const [prescriptionPrintSettings, setPrescriptionPrintSettings] = useState({
    font: '',
    orientation: 'portrait',
    margin: '10mm',
    page: 'A5',
  });
  const [openDispenseForm, setOpenDispenseForm] = useState<boolean>(false);
  const [openDispenseHistory, setOpenDispenseHistory] = useState<boolean>(false);
  const [dispenseHistory, setDispenseHistory] = useState([]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility, prescription.id, user]);

  useEffect(() => {
    async function getPrescriptionPrintSettings() {
      try {
        const res = await getPrintSettings();
        const settings = res.data.data;

        const {
          prescription_font_size: font,
          prescription_orientation: orientation,
          prescription_margin: margin,
          prescription_page_size: page,
        } = settings;
        if (!!font && !!margin && page) {
          const settingsCombined = { font, orientation, margin, page };
          setPrescriptionPrintSettings(settingsCombined);
        } else throw new Error('Unable to fetch prescription print settings. Please modify on clinic settings.');
      } catch (err) {
        if (err instanceof Error) {
          enqueueSnackbar(`${err.message}`, { variant: 'warning', autoHideDuration: 10000 });
        } else {
          console.error('Unknown error', err);
        }
      }
    }
    getPrescriptionPrintSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFontSizes = (size: string) => {
    switch (size) {
      case 'xl':
        return { header: '15px', body: '14px' };
      case 'l':
        return { header: '14px', body: '13px' };
      case 's':
        return { header: '12px', body: '11px' };
      case 'm':
      default:
        return { header: '13px', body: '12px' };
    }
  };

  const fontSizes = getFontSizes(prescriptionPrintSettings.font);

  const handlePrintPrescription = useReactToPrint({
    content: () => printablePrescriptionRef.current,
    pageStyle: `
      @page {
        size: ${prescriptionPrintSettings.page} ${prescriptionPrintSettings.orientation};
        margin: ${prescriptionPrintSettings.margin};
      }
      body {
        margin: 0;
      }
  
    `,
  });

  const fetchData = async () => {
    try {
      if (facility && prescription) {
        const response = await getPrescriptionMedicines(facility.id, prescription.id, { length: 100 });
        setLineItems(response.data.data);

        const doctor_res = await getDoctor(prescription.doctor_id);
        setDoctor(doctor_res.data);
      }

      if (user?.is_patient_account && prescription) {
        const response = await getPatientPrescriptions(prescription.id);
        setLineItems(response.data.data);
        // const doctor_res = await getDoctor(prescription.doctor_id);
        // setDoctor(doctor_res.data);
      }
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

  const handleDeleteAction = (lineItem: PrescriptionLineItemModel) => {
    setDeleteModal(true);
    setSelectedMedicine(lineItem);
  };

  const handleUpdateAction = (lineItem: PrescriptionLineItemModel) => {
    try {
      setSelectedMedicine(lineItem);
      setOpenForm(true);
    } catch (error) {
      console.error('Error updating prescription:', error);
    }
  };

  const handleDeleteConfirm = async (lineItem: PrescriptionLineItemModel) => {
    try {
      await deletePrescriptionMedicine(facility.id, lineItem.id);
      enqueueSnackbar(`Medicine successfully deleted!`, { variant: 'success' });
      fetchData();
    } catch (error) {
      enqueueSnackbar(`Error occured! Failed to delete medicine!`, { variant: 'error' });
    }
  };

  const fetchDispenseHistory = async (lineItem: PrescriptionLineItemModel) => {
    try {
      const response = await getDispenseHistory(facility.id, lineItem.id);
      setDispenseHistory(response.data.data);
      setOpenDispenseHistory(true);
    } catch (error) {
      enqueueSnackbar('Failed to fetch dispense history', { variant: 'error' });
    }
  };

  return (
    <>
      <Box>
        <Box display="flex-column" gap={SIZES.padding}>
          <ContainerRow sx={{ mb: SIZES.padding }}>
            <ContainerRow sx={{ justifyContent: 'flex-start', width: undefined, gap: SIZES.paddingS }}>
              <HideOrShowComponent
                hidden={source === 'patient' || Number(prescription.facility_id) !== Number(facility.id)}
              >
                <PrimaryButton
                  label="Add Medicine"
                  onClick={() => {
                    setSelectedMedicine(undefined);
                    setOpenForm(true);
                  }}
                  disabled={user.remaining_storage <= 0}
                />
              </HideOrShowComponent>
              <RegularButton
                startIcon={<PrintOutlinedIcon />}
                variant="outlined"
                onClick={() => {
                  document.title = `Prescription - ${patient.full_name}`;
                  setTimeout(() => (document.title = `CareGo Health Suite`), 3000);
                  handlePrintPrescription();
                }}
                label="Print Prescription"
              />
            </ContainerRow>
            {/* <RegularButton
              startIcon={<SmsOutlinedIcon />}
              variant="outlined"
              onClick={() => setOpenSmsSchedules(true)}
              label="View SMS Schedules"
            /> */}
          </ContainerRow>
          {lineItems &&
            lineItems.map((data, index) => (
              <Box
                key={index}
                display="flex"
                gap="10px"
                borderRadius={'10px'}
                border="2px solid"
                borderColor={colors.border}
                padding="15px"
                marginBottom={'20px'}
              >
                <AccessTimeFilledIcon sx={{ color: colors.accent }} />

                <Box width="100%">
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h5" fontWeight={'bold'}>
                      {data.generic_name} - Medication
                    </Typography>
                    {source !== 'patient' && parseInt(prescription.facility_id + '') === facility.id && (
                      <RowAction
                        data={data}
                        actions={[
                          {
                            label: 'Send Reminders',
                            action: () => {
                              setSelectedMedicine(data);
                              setOpenSendReminder(true);
                            },
                            icon: <NotificationsActiveOutlinedIcon />,
                            hideCallback: (data) => !!data.with_reminders,
                            hidden: true,
                          },
                          {
                            label: 'Cancel Reminders',
                            action: () => {
                              setSelectedMedicine(data);
                              setOpenConfirmCancelation(true);
                            },
                            icon: <NotInterestedIcon />,
                            hideCallback: (data) => !data.with_reminders,
                          },
                          {
                            label: 'Update',
                            action: () => handleUpdateAction(data),
                            icon: <EditOutlinedIcon />,
                          },
                          // {
                          //   label: 'Dispense Medicine',
                          //   action: () => {
                          //     setSelectedMedicine(data);
                          //     setOpenDispenseForm(true);
                          //   },
                          //   icon: <MedicationLiquidOutlinedIcon />,
                          // },
                          {
                            label: 'Delete',
                            action: () => handleDeleteAction(data),
                            icon: <DeleteOutlineIcon sx={{ color: 'red' }} />,
                            color: 'error',
                          },
                        ]}
                      />
                    )}
                  </Box>
                  <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" columnGap="20px" rowGap="10px">
                    <PatientField
                      label="Generic Name"
                      valueIsComponent
                      value={
                        data.variant_id ? (
                          <Link
                            style={{ color: colors.text }}
                            to={`/company/inventory/products/variant?id=${data.variant_id}`}
                          >
                            <Typography>{data.generic_name}</Typography>
                          </Link>
                        ) : (
                          <Typography>{data.generic_name}</Typography>
                        )
                      }
                    />
                    <PatientField label="Brand Name" value={data.brand_name} />
                    <PatientField label="Dosage" value={data.dosage} />
                    <PatientField label="Quantity" value={data.quantity} />

                    {/* <PatientField
                      label="Dispensed Quantity"
                      valueIsComponent
                      value={
                        <Box
                          onClick={() => {
                            setSelectedMedicine(data);
                            fetchDispenseHistory(data);
                          }}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              color: colors.primary,
                            },
                          }}
                        >
                          <Typography>{formatNumberMaxDecimal(data.dispensed_quantity)}</Typography>
                        </Box>
                      }
                      sx={{ gridColumn: 'span 1' }}
                    /> */}
                    <PatientField label="Remarks" value={data.remarks} />
                    <PatientField label="Instructions" value={data.instruction} sx={{ gridColumn: 'span 3' }} />
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>

      <Box display="none">
        <PrintablePrescription
          ref={printablePrescriptionRef}
          prescription={{ schedule: prescription.schedule, created_at: new Date() }}
          medicines={lineItems ?? []}
          patient={patient}
          doctor={doctor}
          fontSizes={fontSizes}
        />
      </Box>

      <CustomModal header="Dispense Medicine" open={openDispenseForm} setOpen={setOpenDispenseForm}>
        {selectedMedicine && (
          <DispenseMedicineForm
            lineItem={{
              ...selectedMedicine,
              uom_id: selectedMedicine.dispensed_uom_id,
              dispensed_quantity: selectedMedicine.quantity - (selectedMedicine.dispensed_quantity || 0),
            }}
            facility_id={facility.id}
            callbackAfterSubmit={() => {
              fetchData();
              setOpenDispenseForm(false);
            }}
          />
        )}
      </CustomModal>

      <CustomModal header="Dispense History" open={openDispenseHistory} setOpen={setOpenDispenseHistory} width={800}>
        <DispenseHistory history={dispenseHistory} />
      </CustomModal>

      <CustomModal
        header={selectedMedicine ? 'Update Instruction' : 'Add Medicine'}
        open={openForm}
        setOpen={setOpenForm}
      >
        <PrescriptionLineItemForm
          prescriptionLineItem={selectedMedicine}
          callbackAfterSubmit={() => {
            fetchData();
            setOpenForm(false);
            setSelectedMedicine(undefined);
          }}
          prescriptionId={prescription.id}
        />
      </CustomModal>

      <CustomModal header="Send Reminder" open={openSendReminder} setOpen={setOpenSendReminder}>
        <SendReminders
          prescription={selectedMedicine}
          patientDetails={patient}
          callbackAfterSubmit={() => {
            enqueueSnackbar('Successfully set reminder!', { variant: 'success' });
            setOpenSendReminder(false);
            fetchData();
          }}
        />
      </CustomModal>

      <ConfirmationDialog
        title="Medication Reminder"
        content="Are you sure you want to cancel the reminder?"
        open={openConfirmCancelation}
        setOpen={setOpenConfirmCancelation}
        onConfirm={() => {
          if (selectedMedicine)
            cancelMedicationReminder(selectedMedicine.id).then((response) => {
              enqueueSnackbar('Successfully cancelled reminders!', { variant: 'success' });
              fetchData();
            });
        }}
      />

      <SmsScheduleModal
        open={openSmsSchedules}
        setOpen={setOpenSmsSchedules}
        programCode="appointments"
        patientID={patient.patient_id}
        fromPatientManagement
        prescriptionID={prescription.id}
      />

      {selectedMedicine && (
        <ConfirmationDialog
          onConfirm={() => handleDeleteConfirm(selectedMedicine)}
          open={deleteModal}
          subContent="Are you sure you want to delete this medicine?"
          setOpen={setDeleteModal}
        />
      )}
    </>
  );
};

export default PrescriptionContainer;

import * as Yup from 'yup';

import { Box, Typography } from '@mui/material';
import { CustomForm, CustomIconButton, CustomTextField, Dropdown, RegularButton } from 'core/components';
import { useEffect, useState } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DraggableList from 'core/components/DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

type HealthInfoFieldFormProps = {
  onSubmit: (formData: any, options: string[]) => void;
  onCancel: () => void;
  initialValue?: any;
};

const HealthInfoFieldForm: React.FC<HealthInfoFieldFormProps> = ({ onSubmit, onCancel, initialValue }) => {
  const validationSchema = Yup.object().shape({
    display_name: Yup.string().required('Display Name is required'),
    field_type: Yup.string().required('Field Type is required'),
  });

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [optionList, setOptionList] = useState<any[]>([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedOption, setEditedOption] = useState('');

  const handleSaveField = async (formData: any) => {
    setButtonLoading(true);
    try {
      await onSubmit(
        formData,
        optionList.map((option) => option.value)
      );
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    if (initialValue?.field_options) {
      const field_options = initialValue.field_options;
      setOptionList(
        (Array.isArray(field_options) ? field_options : JSON.parse(field_options + ''))?.map((option: string) => ({
          key: option,
          value: option,
        }))
      );
    }
  }, [initialValue]);

  const handleRadioOptionsDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedOptions = Array.from(optionList);
    const [movedItem] = reorderedOptions.splice(result.source.index, 1);
    reorderedOptions.splice(result.destination.index, 0, movedItem);
    setOptionList(reorderedOptions);
  };

  const handleSaveOption = (index: number) => {
    setEditingIndex(-1);
    setEditedOption('');
    handleSaveEditedOption(index, editedOption);
  };
  const handleEditOption = (index: number, currentKey: string) => {
    setEditingIndex(index);
    setEditedOption(currentKey);
  };

  const handleSaveEditedOption = (index: number, updatedKey: string) => {
    const updatedOptions = optionList.map((option, i) =>
      i === index ? { ...option, value: updatedKey, key: updatedKey } : option
    );
    const newList = [...updatedOptions];
    setOptionList(newList);
  };

  const handleDeleteOption = (indexToDelete: number) => {
    const updatedOptions = optionList.filter((_, index) => index !== indexToDelete);
    const newList = [...updatedOptions];
    setOptionList(newList);
  };

  const handleAddNewOption = () => {
    const newOption = {
      key: `Option ${optionList.length + 1}`,
      value: `Option ${optionList.length + 1}`,
    };

    const newList = [...optionList, newOption];
    setOptionList(newList);
  };

  return (
    <Box width="100%">
      <CustomForm
        schema={validationSchema}
        loading={buttonLoading}
        onSubmit={(data) => handleSaveField(data)}
        onCancel={onCancel}
        submitButtonText="Save"
        showCancelButton
        fields={[
          {
            field_name: 'display_name',
            display_name: 'Display Name',
            type: 'text',
            span: 4,
          },
          {
            field_name: 'field_type',
            span: 2,
            display_name: 'Field Type',
            hidden:
              initialValue && initialValue?.field_type !== 'long_text' && initialValue?.field_type !== 'short_text',
            type: 'select',
            onChange: (value) => {
              if (value === 'radiogroup' || value === 'select') {
                setOptionList([
                  { key: 'Option 1', value: 'option1' },
                  { key: 'Option 2', value: 'option2' },
                ]);
              }
            },
            options: initialValue
              ? [
                  { key: 'Long Text', value: 'long_text' },
                  { key: 'Short Text', value: 'short_text' },
                ]
              : [
                  { key: 'Long Text', value: 'long_text' },
                  { key: 'Short Text', value: 'short_text' },
                  { key: 'Yes/No', value: 'yes_no' },
                  { key: 'Number', value: 'number' },
                  { key: 'Radio Group', value: 'radiogroup' },
                  { key: 'Single Select', value: 'select' },
                  { key: 'Group Header', value: 'group_header' },
                  { key: 'Date', value: 'date' },
                ],
          },
          {
            field_name: 'shown_in_self_reg_form',
            display_name: 'Display in Self Registration Form',
            type: 'checkbox',
            span: 4,
          },
          {
            field_name: 'field_unit',
            display_name: 'Field Unit',
            type: 'text',
            span: 2,
            hiddenBasedOnOtherField: (values) => values.field_type !== 'number',
          },
          {
            type: 'component',
            hiddenBasedOnOtherField: (values) => values.field_type !== 'radiogroup' && values.field_type !== 'select',
            component: (
              <Box>
                <Typography fontWeight="bold">Options</Typography>
                <DraggableList
                  droppableId="radioOptions"
                  fromModal
                  handleDragEnd={handleRadioOptionsDragEnd}
                  list={optionList}
                  displayItem={(item, index) => (
                    <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                      {editingIndex === index ? (
                        <Box gap="10px" display="flex" width="100%" alignItems="center">
                          <CustomTextField
                            fieldName="edit_option"
                            label="Edit Option"
                            value={editedOption}
                            handleChangeCallback={(value) => setEditedOption(value)}
                          />
                          <CustomIconButton
                            icon={<SaveOutlinedIcon color="primary" />}
                            onClick={() => handleSaveOption(index)}
                          />
                        </Box>
                      ) : (
                        <>
                          <Typography>{item.key}</Typography>
                          <Dropdown
                            isIcon
                            icon={<MoreVertOutlinedIcon />}
                            optionList={[
                              {
                                label: 'Edit',
                                icon: <EditOutlinedIcon />,
                                action: () => handleEditOption(index, item.key),
                              },
                              {
                                label: 'Delete',
                                icon: <DeleteOutlinedIcon style={{ color: 'red' }} />,
                                action: () => handleDeleteOption(index),
                              },
                            ]}
                          />
                        </>
                      )}
                    </Box>
                  )}
                />
                <RegularButton
                  styles={{ marginTop: '10px' }}
                  variant="outlined"
                  size="small"
                  label="Add option"
                  onClick={handleAddNewOption}
                />
              </Box>
            ),
          },
        ]}
        initialValues={
          initialValue ?? {
            field_type: '',
            display_name: '',
            field_unit: '',
          }
        }
      />
    </Box>
  );
};

export default HealthInfoFieldForm;

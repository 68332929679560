import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getLot, getLotsForVariant } from 'company/api/inventories';
import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import WaitForFacility from '../WaitForFacility';
import { format } from 'date-fns';
import { useContext } from 'react';

interface LotSearchProps extends TemplateDropdownProps {
  variant_id: number;
}

const LotSearchDropdown: React.FC<LotSearchProps> = (props: LotSearchProps) => {
  const { facility } = useContext(FacilityContext);

  return (
    <WaitForFacility facility={facility}>
      <TemplateDropdown
        {...props}
        hideAddOption={true}
        entity={'Lot'}
        getData={(query: ApiQuery) => getLotsForVariant(props.variant_id, facility.id, query)}
        getById={(id: number) => getLot(id)}
        processOption={(lot) => ({
          key: `${lot.lot_number} (Exp: ${format(new Date(lot.expiry_date), 'MMM dd, yyyy')}) - Stock: ${
            lot.quantity_in_stock
          }`,
          value: lot.id,
          data: lot,
        })}
        processNewRecord={(record) => record}
      />
    </WaitForFacility>
  );
};

export default LotSearchDropdown;

import { Box, Divider, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { calculateAge, formatDate } from 'core/utils';

import PatientField from '../PatientManagement/components/PatientField';
import { PatientNoteModel } from 'company/model/Entities';
import { QRCode } from 'react-qrcode-logo';
import { SIZES } from 'theme/constants';
import { tokens } from 'theme/theme';

type HealthOneHeaderProps = {
  isPrinting?: boolean;
  isMobilePhone: boolean;
  user: any;
  note: PatientNoteModel;
  patient: any;
  templateName: string;
};

export default function HealthOneNoteHeader({
  isPrinting,
  isMobilePhone,
  user,
  note,
  patient,
  templateName,
}: HealthOneHeaderProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const hideDOHLicense = useMemo(() => {
    const _templateName = templateName.toLowerCase();
    return _templateName.includes('radiologic') || _templateName.includes('ecg');
  }, [templateName]);

  const Field = ({ label, value }: { label: string; value: any }) => {
    return (
      <PatientField
        label={label}
        direction="row"
        value={value}
        sx={{ gap: '5px' }}
        fieldStyles={{ fontWeight: '600', color: undefined }}
      />
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          gap: '20px',
        }}
      >
        <Box flex={1}>
          <img
            alt={'HealthOne Diagnostic'}
            src={`/assets/Landing2/p4/healthone.png`}
            width={'100%'}
            height={'auto'}
            style={{ minWidth: '150px' }}
          />
        </Box>

        <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center">
          <Box display="flex" flexDirection="column" gap="5px" textAlign={'center'}>
            <Typography textAlign="center" fontSize="10px">
              2<sup>nd</sup> floor ETG Business Center A. Mabini St.
              <br></br>
              San Pedro, Laguna, 4023
              <br></br>
              Contact No. 0917-1061-932/ (02) 836-202-85
              <br></br>
              Email: healthoneclinic@gmail.com
            </Typography>
            {!hideDOHLicense && (
              <Typography fontSize="11px" fontWeight="600">
                DOH License No.-4A-0590-23-CL-2
              </Typography>
            )}
          </Box>
          {(!isMobilePhone || isPrinting) && !!user.company?.with_patient_result_sending && (
            <QRCode
              qrStyle="squares"
              ecLevel="L"
              fgColor={colors.primary}
              value={`${process.env.REACT_APP_BASE_URL}/verify/${note.facility_id}/${note.note_code}`}
              size={100}
              // style={{ height: 'auto', maxWidth: '100%' }}
            />
          )}
        </Box>
      </Box>

      <Divider sx={{ mb: SIZES.paddingS }} />
      <Box display="grid" gridTemplateColumns="2fr 1fr 1fr" rowGap={'5px'} alignItems="flex-end">
        <Box display="flex" flexDirection="column" gap="5px">
          {templateName.toLowerCase().includes('radiologic') && (
            <Field label="Patient No." value={patient.case_number} />
          )}
          <Field label="Patient's Name" value={patient.full_name} />
        </Box>

        <Field label="Age" value={calculateAge(patient.birthday)} />
        <Field label="Gender" value={patient.sex} />
        <Field label="Company" value={patient.company_name} />
        <Field label="Date" value={formatDate(note.date_recorded)} />
        <Field label="Date of Birth" value={formatDate(patient.birthday)} />

        {/* <Typography>Patient's Name:</Typography>
        <Typography>Jhon Christian Garcia</Typography>
        <Typography>Age:</Typography>
        <Typography>23</Typography>
        <Typography>Gender:</Typography>
        <Typography>Male</Typography> */}
      </Box>
    </Box>
  );
}

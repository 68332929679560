import * as yup from 'yup';

import { EntityFields } from '../../../utils';

export const PNST_ENTITY_NAME = 'Patient Note Summary Template';

export const createPatientNoteSummaryTemplate = yup.object().shape({
  template_name: yup
    .string()
    .required('Template Name is required.')
    .max(100, 'Template Name must be less than or equal to 100 characters'),
});

export interface PatientNoteSummaryTemplateModel {
  id: number;
  template_name: string;
  template_description: string;
  is_active: boolean;
  template_file_url: string;
  template_file_start_row: number;
  file_name: string;
}

export interface PatientNoteSummaryTemplateInput {
  template_name: string;
  template_description: string;
}

export const patientNoteSummaryTemplateInitialValues: PatientNoteSummaryTemplateInput = {
  template_name: '',
  template_description: '',
};

export const patientNoteSummaryTemplateFields: EntityFields[] = [
  { fieldName: 'template_name', displayName: 'Template Name', span: 4 },
  { fieldName: 'template_description', displayName: 'Template Description', multiline: true, rows: 3, span: 4 },
];

// // Table
export const patientNoteSummaryTemplateTableColumns: string[] = ['template_name', 'template_description'];

// // Forms
export const patientNoteSummaryTemplateFormFields: string[] = ['template_name', 'template_description'];

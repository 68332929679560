import { isDevMode } from 'core/components/HideOrShowComponent';
import { useState } from 'react';

export const LoginGuideModal = () => {
  const [open, setOpen] = useState(false);

  if (isDevMode()) return <></>;

  return (
    <div>
      <button onClick={() => setOpen(true)}>Need Help? View Guide</button>

      {open && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '500px',
            background: 'white',
            border: '1px solid #ccc',
            boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
            padding: '20px',
            zIndex: 1000,
          }}
        >
          <button onClick={() => setOpen(false)} style={{ float: 'right' }}>
            ✖ Close
          </button>
          <iframe
            title="Guide"
            src="https://app.tango.us/app/workflow/Logging-into-CareGo-Health-Suite-1d5aefa6288946baa104cdef3d7e8ece"
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

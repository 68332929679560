import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import { Ref, forwardRef, useContext, useEffect, useState } from 'react';
import { formatArray, formatCurrency, formatDate, formatDateTime, getDiscountAmount } from 'core/utils';

import { FacilityContext } from 'core/context/facility.context';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import { OrderProps } from 'company/screens/InventorySystem/POS';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { SIZES } from 'theme/constants';
import { UserContext } from 'core/context/user.context';
import { getInvoiceItems } from 'company/api/invoice-items';
import { getPatient } from 'company/api/patient';
import { tokens } from 'theme/theme';

type Props = {
  invoice: any;
  smallPrinter?: boolean;
  landscape?: boolean;
  fontSizes?: {
    header: string;
    body: string;
  };
};

interface ServiceItem {
  id: number;
  service_name: string;
  price: number;
}

const PrintableInvoice = forwardRef(
  ({ invoice, smallPrinter, landscape, fontSizes }: Props, ref: Ref<HTMLDivElement>) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { facility } = useContext(FacilityContext);
    const { user } = useContext(UserContext);

    const [patient, setPatient] = useState<PatientModel>();
    const [items, setItems] = useState<OrderProps[]>([]);

    useEffect(() => {
      if (invoice && facility) {
        if (invoice.patient_id) {
          getPatient(invoice.facility_id, invoice.patient_id).then((patient) => {
            setPatient(patient.data);
          });
        }
        getInvoiceItems(facility.id, 'all', invoice.id, { length: 1000 }).then((items) => {
          setItems(
            items.data.data.filter(
              (item: any) => !(item.variant_id !== null && !item.show_in_invoice && parseFloat(item.total_price) === 0)
            )
          );
        });
      }
    }, [facility, invoice]);

    const formatIncludedServices = (included: ServiceItem[] | string | undefined) => {
      if (!included) return null;
      if (typeof included === 'string') {
        return included;
      }
      if (Array.isArray(included)) {
        return included.map((service) => service.service_name).join(', ');
      }
      return null;
    };

    if (smallPrinter) {
      const getFontSize = (size: number) => {
        const adjustment = 1;
        return size + adjustment + 'px';
      };
      return (
        <Box>
          <style>{styles}</style>
          <Box ref={ref} sx={{ '& p': { fontWeight: '600 !important' } }}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              rowGap={'1px'}
              alignItems={'center'}
              sx={{ '& p': { textAlign: 'center' } }}
            >
              {user.company?.company_logo && (
                <img alt="clinic-logo" width={'40px'} height="auto" id="logo" src={user.company?.company_logo} />
              )}
              <Typography fontSize={getFontSize(10)} fontWeight={'bold'}>
                {facility.facility_name}
              </Typography>
              <Typography fontSize={getFontSize(7)}>
                {formatArray([facility.globe_mobile_number, facility.smart_mobile_number], ' | ')}
              </Typography>
              <Typography fontSize={getFontSize(7)}>
                {formatArray([facility.address, facility.municipality_name, facility.province_name])}
              </Typography>
            </Box>

            <Box my="10px">
              <Typography fontSize={'8px'} textAlign="center" fontWeight={'bold'}>
                INVOICE # {invoice.invoice_number}
              </Typography>
              <Typography fontSize={'7px'} textAlign="center">
                {formatDate(invoice.issue_date)}
              </Typography>
            </Box>

            <Box sx={{ '& p': { fontSize: getFontSize(7) + ' !important', color: 'black !important' } }}>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                  <PersonPinOutlinedIcon sx={{ fontSize: '12px' }} />
                  <Typography sx={{ fontSize: getFontSize(10) + ' !important', fontWeight: '600' }}>
                    Patient Information
                  </Typography>
                </Box>
                <Box display="grid" gridTemplateColumns="1fr" gap="2px" mt="2px" paddingLeft="15px">
                  <PatientField
                    label="Full Name"
                    value={patient?.full_name}
                    fontSizes={fontSizes}
                    sx={{ flexDirection: 'row', gap: '5px' }}
                  />
                  <PatientField
                    label="HMO"
                    value={invoice?.hmo_name ?? 'None'}
                    fontSizes={fontSizes}
                    sx={{ flexDirection: 'row', gap: '5px' }}
                  />
                  <PatientField
                    label="HMO Card Number"
                    value={invoice?.hmo_card_number ?? 'None'}
                    fontSizes={fontSizes}
                    sx={{ flexDirection: 'row', gap: '5px' }}
                  />
                  {invoice?.company_name && (
                    <PatientField
                      label="Corporate Client"
                      value={invoice?.company_name ?? 'None'}
                      fontSizes={fontSizes}
                      sx={{ flexDirection: 'row', gap: '5px' }}
                    />
                  )}
                </Box>
              </Box>
            </Box>

            <Box mt="10px" sx={{ '& p': { fontSize: getFontSize(7) + ' !important', color: 'black !important' } }}>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                  <MedicalInformationIcon sx={{ fontSize: '12px' }} />
                  <Typography sx={{ fontSize: getFontSize(10) + ' !important', fontWeight: '600' }}>
                    Service Information
                  </Typography>
                </Box>
                <Box display="grid" gridTemplateColumns="1fr" gap="2px" mt="2px" paddingLeft="15px">
                  <PatientField
                    label="Provider Name"
                    value={invoice.service_provider_names ?? 'None'}
                    fontSizes={fontSizes}
                    sx={{ flexDirection: 'row', gap: '5px' }}
                  />
                  <Grid container spacing={0}>
                    {invoice.approval_code && (
                      <Grid item xs={12}>
                        <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="5px">
                          <Typography color={colors.primary}>Approval Code: </Typography>
                          <Typography color={colors.primary}>Approved By:</Typography>
                        </Box>
                      </Grid>
                    )}
                    {[1, 2, 3, 4, 5].map((num) => {
                      if (num === 1 && !invoice.approval_code) return <></>;
                      if (num > 1 && !invoice['approval_code_' + num]) return <></>;
                      return (
                        <Grid item xs={12}>
                          <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="5px">
                            <Typography>
                              {num}. {num === 1 ? invoice.approval_code : invoice['approval_code_' + num]}
                            </Typography>
                            <Typography>
                              {num}. {num === 1 ? invoice.approved_by ?? '-' : invoice['approval_code_' + num] ?? '-'}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            </Box>

            {invoice.diagnosis && (
              <Box mt="10px" sx={{ '& p': { fontSize: getFontSize(7) + ' !important', color: 'black !important' } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                  <LocalHospitalIcon sx={{ fontSize: '12px' }} />
                  <Typography sx={{ fontSize: getFontSize(10) + ' !important', fontWeight: '600' }}>
                    Diagnosis
                  </Typography>
                </Box>
                <Box width="100%" paddingLeft="15px">
                  <Typography fontSize={fontSizes?.body}>{invoice.diagnosis}</Typography>
                </Box>
              </Box>
            )}

            <Box mt="10px" sx={{ '& p': { fontSize: getFontSize(7) + ' !important' } }}>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                  <ReceiptIcon sx={{ fontSize: '12px' }} />
                  <Typography sx={{ fontSize: getFontSize(10) + ' !important', fontWeight: '600' }}>
                    Invoice Details
                  </Typography>
                </Box>
                <Box display="grid" gridTemplateColumns="2fr 1fr" gap="2px" mt="2px" paddingRight="10px">
                  <Typography fontWeight="600">Items</Typography>
                  <Typography fontWeight="600" textAlign="right">
                    Amount
                  </Typography>
                  <Divider style={{ width: '100%', gridColumn: 'span 2' }} />
                  {items.map((invoiceItem, index) => (
                    <>
                      <Typography>
                        {invoiceItem.quantity} × {invoiceItem.item_name}
                      </Typography>
                      <Typography textAlign="right">{formatCurrency(invoiceItem.total_price)}</Typography>
                    </>
                  ))}
                  <Divider style={{ width: '100%', gridColumn: 'span 2' }} />
                  <Box>
                    <Typography>Subtotal:</Typography>
                    <Typography>Total VAT (12%):</Typography>
                    <Typography>VAT Exempt:</Typography>
                    <Typography>Total Discount:</Typography>
                    <Typography fontWeight="600">Total:</Typography>
                  </Box>
                  <Box sx={{ '& p': { textAlign: 'right' } }}>
                    <Typography>{formatCurrency(invoice.original_amount)}</Typography>
                    <Typography>{formatCurrency(invoice.total_tax)}</Typography>
                    <Typography>{formatCurrency(invoice.total_vat_exempt)}</Typography>
                    <Typography>{formatCurrency(invoice.total_discount)}</Typography>
                    <Typography fontWeight="600">{formatCurrency(invoice.total_amount)}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }

    const SectionHeader = ({ Icon, header }: { Icon: any; header: string }) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Icon color="primary" sx={{ fontSize: fontSizes?.header }} />
          <Typography
            variant="h5"
            sx={{
              fontWeight: '600',
              color: colors.dark_grey_text,
              fontSize: fontSizes?.header,
            }}
          >
            {header}
          </Typography>
        </Box>
      );
    };

    const InvoiceInfo = () => {
      return (
        <Box>
          <SectionHeader Icon={ReceiptOutlinedIcon} header="INVOICE" />
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={SIZES.paddingS} mt="5px">
            <PatientField
              label="Invoice Date"
              value={formatDate(invoice.issue_date)}
              fontSizes={fontSizes}
              fieldFontSize={fontSizes?.body}
            />
            <PatientField
              label="Invoice Number"
              value={invoice.invoice_number}
              fontSizes={fontSizes}
              fieldFontSize={fontSizes?.body}
            />
            {invoice.reference_number && (
              <PatientField
                label="Reference Number"
                value={invoice.reference_number}
                fontSizes={fontSizes}
                fieldFontSize={fontSizes?.body}
              />
            )}
          </Box>
        </Box>
      );
    };

    const PatientInfo = () => {
      return (
        <Box>
          <SectionHeader Icon={PersonPinOutlinedIcon} header="Patient Information" />
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={SIZES.paddingS} mt="5px">
            <PatientField
              label="Full Name"
              value={patient?.full_name}
              fontSizes={fontSizes}
              fieldFontSize={fontSizes?.body}
            />
            <PatientField
              label="HMO"
              value={formatArray([invoice?.hmo_name, invoice?.hmo_card_number])}
              fontSizes={fontSizes}
              fieldFontSize={fontSizes?.body}
            />
            {invoice?.company_name && (
              <PatientField
                label="Corporate Client"
                value={invoice?.company_name}
                fontSizes={fontSizes}
                fieldFontSize={fontSizes?.body}
              />
            )}
          </Box>
        </Box>
      );
    };

    const ServiceInfo = () => {
      if (!invoice.service_provider_names && !invoice.approval_code) return <></>;
      return (
        <Box>
          <SectionHeader Icon={MedicalInformationIcon} header="Service Information" />
          <Box mt="5px" display="grid" gridTemplateColumns="repeat(4, 1fr)">
            <PatientField
              label="Provider Name"
              value={invoice.service_provider_names ?? 'None'}
              fontSizes={fontSizes}
              fieldFontSize={fontSizes?.body}
            />
            {invoice.diagnosis && (
              <PatientField
                label="Diagnosis"
                value={invoice.diagnosis ?? 'None'}
                fontSizes={fontSizes}
                fieldFontSize={fontSizes?.body}
              />
            )}
            {invoice.approval_code && (
              <Box
                display="grid"
                gridTemplateColumns="repeat(2, 1fr)"
                gap="5px"
                gridColumn="span 2"
                paddingBottom={SIZES.paddingS}
                width="100%"
                sx={{ '& p': { fontSize: fontSizes?.body } }}
              >
                <Typography color={colors.primary}>Approval Code: </Typography>
                <Typography color={colors.primary}>Approved By:</Typography>
                <>
                  {[1, 2, 3, 4, 5].map((num) => {
                    if (num === 1 && !invoice.approval_code) return null;
                    if (num > 1 && !invoice[`approval_code_${num}`]) return null;
                    return (
                      <>
                        <Typography>
                          {num}. {num === 1 ? invoice.approval_code : invoice[`approval_code_${num}`]}
                        </Typography>
                        <Typography>
                          {num}. {num === 1 ? invoice.approved_by ?? '-' : invoice[`approved_by_${num}`] ?? '-'}
                        </Typography>
                      </>
                    );
                  })}
                </>
              </Box>
            )}
          </Box>
        </Box>
      );
    };

    const InvoiceItems = () => {
      return (
        <Box>
          <SectionHeader Icon={ReceiptIcon} header="Invoice Details" />
          <Box>
            <table>
              <thead>
                <tr>
                  <td width="5%">
                    <Typography fontWeight="600" sx={{ fontSize: fontSizes?.body }}>
                      #
                    </Typography>
                  </td>
                  <td width="35%">
                    <Typography fontWeight="600" sx={{ fontSize: fontSizes?.body }}>
                      Services/Products
                    </Typography>
                  </td>
                  <td width="5%" align="right">
                    <Typography fontWeight="600" sx={{ fontSize: fontSizes?.body }}>
                      Qty
                    </Typography>
                  </td>
                  <td width="15%" align="right">
                    <Typography fontWeight="600" sx={{ fontSize: fontSizes?.body }}>
                      Unit Price
                    </Typography>
                  </td>
                  <td width="20%" align="right">
                    <Typography fontWeight="600" sx={{ fontSize: fontSizes?.body }}>
                      Discount
                    </Typography>
                  </td>
                  <td width="20%" align="right">
                    <Typography fontWeight="600" sx={{ fontSize: fontSizes?.body }}>
                      Amount
                    </Typography>
                  </td>
                </tr>
              </thead>
              <tbody>
                {items.map((invoiceItem, index) => (
                  <tr key={index}>
                    <td>
                      <Typography sx={{ fontSize: fontSizes?.body }}>{index + 1}</Typography>
                    </td>
                    <td>
                      <Typography sx={{ fontSize: fontSizes?.body }}>{invoiceItem.item_name}</Typography>
                      {invoiceItem.included_services && (
                        <Typography fontSize={'10px'} sx={{ fontSize: fontSizes?.body }}>
                          Includes: {formatIncludedServices(invoiceItem.included_services)}
                        </Typography>
                      )}
                    </td>
                    <td align="right">
                      <Typography sx={{ fontSize: fontSizes?.body }}>{invoiceItem.quantity}</Typography>
                    </td>
                    <td align="right">
                      <Typography sx={{ fontSize: fontSizes?.body }}>
                        {formatCurrency(invoiceItem.original_price)}
                      </Typography>
                    </td>
                    <td align="right">
                      <Typography sx={{ fontSize: fontSizes?.body }}>{getDiscountAmount(invoiceItem)}</Typography>
                      <Box display="flex" gap="5px" alignItems="center" width="100%" justifyContent="flex-end">
                        {(!!invoiceItem.discount_type || !!invoiceItem.sc_pwd_discounted) && (
                          <LocalMallIcon sx={{ fontSize: '12px', mt: '-4px', ml: '10px' }} />
                        )}
                        {!!invoiceItem.discount_type && (
                          <Typography sx={{ fontSize: fontSizes?.body }}>{invoiceItem.discount_reason}</Typography>
                        )}
                        {!!invoiceItem.discount_type && !!invoiceItem.sc_pwd_discounted && (
                          <Typography sx={{ fontSize: fontSizes?.body }}>&</Typography>
                        )}
                        {!!invoiceItem.sc_pwd_discounted && (
                          <Typography sx={{ fontSize: fontSizes?.body }}>SC/PWD</Typography>
                        )}
                      </Box>
                    </td>
                    <td align="right">
                      <Typography sx={{ fontSize: fontSizes?.body }}>
                        {formatCurrency(invoiceItem.total_price)}
                      </Typography>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2} align="right" style={{ backgroundColor: '#f3f9ff' }}>
                    <Typography sx={{ fontSize: fontSizes?.body }}>Total VAT (12%):</Typography>
                    <Typography sx={{ fontSize: fontSizes?.body }}>VAT Exempt:</Typography>
                    <Typography sx={{ fontSize: fontSizes?.body, color: 'transparent' }}>.</Typography>
                  </td>
                  <td colSpan={2} align="right" style={{ backgroundColor: '#f3f9ff' }}>
                    <Typography sx={{ fontSize: fontSizes?.body }}>{formatCurrency(invoice.total_tax)}</Typography>
                    <Typography sx={{ fontSize: fontSizes?.body }}>
                      {formatCurrency(invoice.total_vat_exempt)}
                    </Typography>
                    <Typography sx={{ fontSize: fontSizes?.body, color: 'transparent' }}>.</Typography>
                  </td>
                  <td colSpan={1} align="right" style={{ backgroundColor: '#f3f9ff' }}>
                    <Typography sx={{ fontSize: fontSizes?.body }}>Subtotal:</Typography>

                    <Typography sx={{ fontSize: fontSizes?.body }}>Total Discount:</Typography>
                    <Typography fontWeight="600" sx={{ fontSize: fontSizes?.body }}>
                      Total:
                    </Typography>
                  </td>
                  <td align="right" style={{ backgroundColor: '#f3f9ff' }}>
                    <Typography sx={{ fontSize: fontSizes?.body }}>
                      {formatCurrency(invoice.original_amount)}
                    </Typography>

                    <Typography sx={{ fontSize: fontSizes?.body }}>{formatCurrency(invoice.total_discount)}</Typography>
                    <Typography fontWeight="600" sx={{ fontSize: fontSizes?.body }}>
                      {formatCurrency(invoice.total_amount)}
                    </Typography>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4}>
                    <Box width="100%">
                      <Typography sx={{ height: '30px' }}></Typography>
                      <Box position="fixed" bottom="0" width="100%">
                        {/* <hr
                          style={{
                            border: 'none',
                            borderTop: '1px solid #0192D5',
                            width: '100%',
                            transform: 'scaleY(0.5)',
                            margin: 0,
                          }}
                        /> */}
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                            <img
                              alt="cphi-logo"
                              width="auto"
                              height="20px"
                              id="logo"
                              src={process.env.PUBLIC_URL + `/assets/carego_health_suite_logo.png`}
                            />
                            <Typography sx={{ fontSize: '6px', color: colors.accent }}>
                              Document generated using CareGo Health Suite on {formatDateTime(new Date())}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </td>
                  <td colSpan={2}>
                    {/* <Box position="fixed" bottom="0px" width="100%">
                      <Box display="flex" gap="10px" sx={{ borderBottom: '1px solid #0192D5' }}>
                        <Typography sx={{ fontSize: fontSizes?.body, fontWeight: 500 }}>Px Signature:</Typography>
                        <Typography sx={{ fontSize: fontSizes?.body, color: 'transparent' }}>.</Typography>
                      </Box>
                    </Box> */}
                  </td>
                </tr>
              </tfoot>
            </table>
          </Box>
        </Box>
      );
    };

    return (
      <Box>
        <style>{styles}</style>
        <Box ref={ref} display="flex" flexDirection="column" gap="15px">
          <Box display="flex" justifyContent="space-between">
            <Box flex={2} display="flex" width="100%" flexDirection="column" gap="15px">
              <InvoiceInfo />
              <PatientInfo />
            </Box>
            <Box flex={1}>
              <Box flex={2} display={'flex'} flexDirection={'column'} rowGap={'3px'} alignItems={'end'}>
                {user.company?.company_logo && (
                  <img alt="clinic-logo" width="auto" height="65px" id="logo" src={user.company?.company_logo} />
                )}
                <Typography
                  variant="h3"
                  textAlign="right"
                  color={colors.primary}
                  fontWeight={'bold'}
                  sx={{ fontSize: fontSizes?.header }}
                >
                  {facility.facility_name}
                </Typography>
                <Typography variant="h5" textAlign="right" sx={{ fontSize: fontSizes?.body }}>
                  {formatArray([facility.address, facility.municipality_name, facility.province_name])}
                </Typography>
                <Typography variant="h5" textAlign="right" sx={{ fontSize: fontSizes?.body }}>
                  {formatArray([facility.globe_mobile_number, facility.smart_mobile_number], ' | ')}
                </Typography>
              </Box>
            </Box>
          </Box>
          <ServiceInfo />
          <InvoiceItems />
        </Box>
      </Box>
    );
  }
);

const styles = `
  table {
    border-collapse: collapse;
    width: 100%;
  }

  thead td, tbody td {
    padding: 3px 5px;
    border-bottom: 1px solid #E0E0E0;
  }
`;

export default PrintableInvoice;

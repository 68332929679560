import * as yup from 'yup';

import { Box, Tooltip, Typography } from '@mui/material';
import { DISCOUNT_TYPE, DROPDOWN_FIELD } from 'core/model/interface';

import { EntityFields } from '../../../../utils';
import { SIZES } from 'theme/constants';
import { formatCurrency } from 'core/utils';
import { shortenEventName } from 'company/components/ServiceProviderCalendar/WeekView';

export const INVOICE_ITEM_ENTITY_NAME = 'Item';

export const invoiceItemSchema = yup.object().shape({
  patient_id: yup.number().required('Patient is required.'),
  issue_date: yup
    .date()
    .default(() => new Date())
    .required('Date is required.')
    .typeError('Invalid Date'),
});

export interface InvoiceItemModel {
  id: number;
  invoice_id: number;
  service_id?: number;
  variant_id?: number;
  quantity: number;
  unit_price: number;
  total_price: number;
  date_availed: number;
  included_services?: string;
}

// fieldName should be unique
export const invoiceItemFields: EntityFields[] = [
  {
    fieldName: 'item_name',
    displayName: 'Items',
    flex: 2,
    renderCell: ({ value, row }) => (
      <Box display="flex" flexDirection="column">
        <Typography>{row.item_name}</Typography>
        {row.included_services && (
          <Typography fontSize="12px" color="text.secondary" sx={{}}>
            Includes: {row.included_services}
          </Typography>
        )}
        {!row.show_in_invoice && !!row.variant_id && parseFloat(row.total_price) === 0 && (
          <Typography fontSize="11px" color="text.secondary" sx={{}}>
            Hidden in printed invoice
          </Typography>
        )}
      </Box>
    ),
  },
  { fieldName: 'quantity', displayName: 'Quantity', type: 'number' },
  {
    fieldName: 'unit_price',
    displayName: 'Unit Price × Quantity',
    flex: 2,
    headerAlign: 'right',
    align: 'right',
    renderCell: (params) => {
      const DiscountBadge = ({ discount, reason }: { discount: string; reason?: string }) => {
        return (
          <Box display="flex" flexDirection="column" gap="5px" justifyContent="center">
            <Typography
              fontWeight="500"
              fontSize="11px"
              sx={{
                backgroundColor: 'grey',
                color: 'white',
                paddingInline: SIZES.paddingS,
                marginLeft: SIZES.paddingS,
                borderRadius: '10px',
                textAlign: 'left',
              }}
            >
              {`${discount} OFF`}
            </Typography>

            {reason && (
              <Tooltip title={reason}>
                <Typography fontSize="11px" fontWeight="500" sx={{ cursor: 'pointer' }}>
                  {shortenEventName(reason, 25)}
                </Typography>
              </Tooltip>
            )}
          </Box>
        );
      };
      return (
        <Box display="flex" gap="5px" justifyContent="flex-end" alignItems="center">
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Box display="flex" gap={SIZES.padding}>
              <Typography>{formatCurrency(params.row.original_price ?? params.row.unit_price)}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="flex-end" gap="5px">
              {params.row.discount_type === DISCOUNT_TYPE.NEW_UNIT_PRICE && (
                <DiscountBadge
                  discount={formatCurrency(params.row.original_price - params.row.unit_price)}
                  reason={params.row.discount_reason}
                />
              )}
              {params.row.discount_type === DISCOUNT_TYPE.PERCENTAGE && (
                <DiscountBadge discount={`${params.row.discount_percentage}%`} reason={params.row.discount_reason} />
              )}
              {!!params.row.sc_pwd_discounted && <DiscountBadge discount={`20%`} reason="SC/PWD" />}
            </Box>
          </Box>
          <Typography>× {params.row.quantity}</Typography>
        </Box>
      );
    },
  },
  {
    fieldName: 'total_price',
    displayName: 'Total',
    type: 'currency',
  },
  {
    fieldName: 'service_id',
    displayName: 'Service',
    type: DROPDOWN_FIELD.SERVICE,
  },
  {
    fieldName: 'product_id',
    displayName: 'Product',
    type: DROPDOWN_FIELD.PRODUCT_VARIANT,
  },
  {
    type: 'table',
    fieldName: 'services',
    displayName: 'Services',
    columns: [
      {
        fieldName: 'service',
        displayName: 'Service',
        type: DROPDOWN_FIELD.SERVICE,
        onCellChange: (value, row, handleRowEdit) => {
          handleRowEdit(row, 'unit_price', value ? value.unit_price ?? value.data.price : '');
          handleRowEdit(row, 'quantity', value ? '1' : '');
        },
      },
      { fieldName: 'quantity', displayName: 'Quantity' },
      { fieldName: 'unit_price', displayName: 'Unit Price' },

      // {
      //   fieldName: 'discount_type',
      //   displayName: 'Discount Type',
      //   type: 'select',
      //   options: [
      //     { key: 'None', value: 'none' },
      //     { key: 'Discount Amount', value: DISCOUNT_TYPE.NEW_UNIT_PRICE },
      //     { key: 'Discount Percentage', value: DISCOUNT_TYPE.PERCENTAGE },
      //   ],
      // },
      // {
      //   fieldName: 'unit_price',
      //   displayName: 'Discount Amount',
      //   hiddenBasedOnOtherField: (data) => data.discount_type !== DISCOUNT_TYPE.NEW_UNIT_PRICE,
      // },
      // {
      //   fieldName: 'percentage',
      //   displayName: 'Discount Percentage',
      //   hiddenBasedOnOtherField: (data) => data.discount_type !== DISCOUNT_TYPE.PERCENTAGE,
      // },
    ],
  },
  {
    type: 'table',
    fieldName: 'products',
    displayName: 'Products',
    columns: [
      {
        fieldName: 'product',
        displayName: 'Product',
        type: DROPDOWN_FIELD.PRODUCT_VARIANT,
        onCellChange: (value, row, handleRowEdit) => {
          handleRowEdit(row, 'unit_price', value ? value.unit_price ?? value.data.price : '');
          handleRowEdit(row, 'quantity', value ? '1' : '');
        },
      },
      { fieldName: 'quantity', displayName: 'Quantity' },
      { fieldName: 'unit_price', displayName: 'Unit Price' },
    ],
  },
];

// Table
export const invoiceItemTableColumns: string[] = ['item_name', 'unit_price', 'total_price'];

//Form
export const invoiceItemFormFields: string[] = ['services', 'products'];

export const updateInvoiceItemFormFields: string[] = ['unit_price', 'quantity'];

export const updateInvoiceItemInitialValues = {
  unit_price: 0.0,
  quantity: 1,
};

export const invoiceItemInitialValues = {
  services: [
    {
      service: '',
      unit_price: 0.0,
      quantity: 1,
    },
  ],
  products: [
    {
      product: '',
      unit_price: 0.0,
      quantity: 1,
    },
  ],
};

import React, { useEffect, useMemo, useState } from 'react';
import {
  SELF_REGISTRATION_ENTITY_NAME,
  selfRegistrationFields,
  selfRegistrationFormFields,
  selfRegistrationInitialValues,
  selfRegistrationSchema,
} from './SelfRegistrationModel';

import TemplateForm from '../../../Template/TemplateForm';
import { createSelfRegistration } from 'company/api/self-registration';
import { FieldInput } from 'core/model/interface';
import { getSelfRegHealthInfoFields } from 'company/api/public-self-reg-fields';

type Props = {
  facilityId: number;
  callbackAfterSubmit?: (data?: any) => void;
  company_id?: number;
};

const SelfRegistrationForm: React.FC<Props> = ({ facilityId, callbackAfterSubmit, company_id }) => {
  const [healthInformationFields, setHealthInformationFields] = useState<any[]>([]);
  const handleSubmit = async (data: any) => {
    const healthInfo = healthInformationFields.map((info) => {
      return { field_id: info.field_id, value: data[info.fieldName] };
    });

    data.health_info = healthInfo;
    data.mobile_number = data.mobile_number.replace(/\s+/g, '');
    const result = await createSelfRegistration(facilityId, data);
    if (callbackAfterSubmit) {
      callbackAfterSubmit(result);
    }
    return result;
  };
  const processFields = (fields: any[]): any[] => {
    return fields.map(
      (field: {
        field_type: any;
        id: any;
        field_name: string;
        display_name: string;
        field_options?: string[];
        field_unit?: string;
      }) =>
        ({
          field_id: field.id,
          fieldName: field.field_name,
          field_name: field.field_name,
          displayName: (field.display_name ?? field.field_name) + (field.field_unit ? ` (${field.field_unit})` : ''),
          type: field.field_type === 'yes_no' ? 'radiogroup' : field.field_type,
          span: ['long_text', 'group_header'].includes(field.field_type) ? 4 : 2,
          multiline: field.field_type === 'long_text',
          rows: field.field_type === 'long_text' ? 3 : undefined,
          options:
            field.field_type === 'yes_no' || (field.field_options && field.field_options.length)
              ? field.field_type === 'yes_no'
                ? [
                    { key: 'Yes', value: 'yes' },
                    { key: 'No', value: 'no' },
                  ]
                : (Array.isArray(field!.field_options)
                    ? field!.field_options
                    : JSON.parse(field!.field_options + '')
                  )?.map((option: string) => ({ key: option, value: option }))
              : undefined,
        } as FieldInput)
    );
  };
  // const healthInformationFormFields = healthInformationFields.map((field: any) => ({
  //   ...field,
  //   fieldName: field.field_name,
  //   displayName: field.field_name,
  //   field_unit: field.field_unit,
  //   type: field.field_type,
  // }));

  useEffect(() => {
    async function getHealthInformationFields() {
      if (!company_id) return;
      const res = await getSelfRegHealthInfoFields(company_id);
      const { data } = res.data;
      if (!data) return;
      const processedFields = processFields(data);
      setHealthInformationFields(processedFields);
    }

    getHealthInformationFields();
  }, [company_id]);

  const visibleFields = useMemo(() => {
    if (!healthInformationFields.length) {
      return selfRegistrationFormFields.filter((field: any) => field !== 'health_information_subheader');
    }
    return selfRegistrationFormFields;
  }, [healthInformationFields]);

  return (
    <TemplateForm
      entityName={SELF_REGISTRATION_ENTITY_NAME}
      fields={[...selfRegistrationFields, ...healthInformationFields]}
      visibleFields={[...visibleFields, ...healthInformationFields?.map((item: any) => item.field_name)]}
      initialValues={selfRegistrationInitialValues}
      schema={selfRegistrationSchema}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
      submitButtonText="Submit Registration"
      useInitialValue={true}
      resetOnSubmit={true}
    />
  );
};

export default SelfRegistrationForm;

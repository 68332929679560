import TransactionDetails, { TransactionProps } from './TransactionDetails';
import TransactionHeader, { INVENTORY_TRANSACTION } from './TransactionHeader';
import { capitalizeWord, formatNumberMaxDecimal, formatProductVariantName } from 'core/utils';

import InventoryTransactionContainer from './InventoryTransactionContainer';
import { Typography } from '@mui/material';

const ReversalTransaction: React.FC<TransactionProps> = (props) => {
  return (
    <InventoryTransactionContainer viewAll={props.viewAll}>
      <TransactionHeader transaction={props.transaction} transaction_type={INVENTORY_TRANSACTION.REVERSAL} />
      <TransactionDetails
        {...props}
        fields={[
          {
            display_name: 'Product',
            field_name: 'product_name',
            renderValue: () => <Typography>{`${formatProductVariantName(props.transaction)}`}</Typography>,
          },
          { display_name: 'Staff', field_name: 'full_name' },
          // { display_name: 'Variant', field_name: 'variant_name' },
          {
            display_name: 'Quantity',
            field_name: 'quantity',
            renderValue: () => (
              <Typography>
                {formatNumberMaxDecimal(props.transaction.quantity, 6)} {props.transaction.uom_symbol}
              </Typography>
            ),
          },
          {
            display_name: 'Reason',
            field_name: 'reversal_reason',
            renderValue: () => <Typography>{capitalizeWord(props.transaction.reversal_reason)}</Typography>,
          },
          { display_name: 'Invoice #', field_name: 'invoice_number' },
        ]}
      />
    </InventoryTransactionContainer>
  );
};

export default ReversalTransaction;

import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material';

import { Dropdown } from 'core/components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PhoneAndroid } from '@mui/icons-material';
import React from 'react';
import { tokens } from 'theme/theme';

interface CustomDashboardWidgetProps {
  icon?: React.ComponentType<any>;
  label: string;
  count: string;
  sideLabel?: string;
  sideCount?: string;
  sx?: SxProps<Theme>;
}

const InventoryWidget: React.FC<CustomDashboardWidgetProps> = ({
  icon: IconComponent,
  label,
  count,

  sideLabel,
  sideCount,
  sx,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '16px 20px',
        color: 'white',
        borderRadius: '8px',
        backgroundColor: colors.accent,
        ...sx,
      }}
    >
      {IconComponent && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.secondary,
            height: '35px',
            width: '40px',
            borderRadius: '10px',
            marginRight: '20px',
          }}
        >
          {IconComponent && <IconComponent sx={{ fontSize: '24px' }} />}
        </Box>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            marginBottom: sideLabel && sideCount ? '10px' : '0',
            width: '300px',
          }}
        >
          <Typography variant="h3" fontWeight="bold" mr={1}>
            {count}
          </Typography>
          <Typography variant="h5">{label}</Typography>
        </Box>

        {sideLabel && sideCount && (
          <Box sx={{ display: 'flex', alignItems: 'baseline', width: '100%' }}>
            <Typography variant="h3" fontWeight="bold" mr={1}>
              {sideCount}
            </Typography>
            <Typography variant="h5">{sideLabel}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InventoryWidget;

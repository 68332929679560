import { Box, Typography, useTheme } from '@mui/material';

import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import { SIZES } from 'theme/constants';
import { formatCurrency } from 'core/utils';
import { tokens } from 'theme/theme';

export type TransactionDetailsProps = {
  display_name: string;
  field_name: string;
  renderValue?: () => ReactNode;
  type?: 'currency' | 'text';
  hidden?: boolean;
};

export type TransactionProps = {
  transaction: any;
  fields?: TransactionDetailsProps[];
  viewAll?: boolean;
};

const TransactionDetails: React.FC<TransactionProps> = ({ transaction, fields, viewAll }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box sx={{ padding: viewAll ? '10px 15px' : '0px 15px 10px', width: '100%' }}>
      <Box
        display="grid"
        gridTemplateColumns={viewAll ? 'repeat(6, 1fr)' : 'repeat(1, 1fr)'}
        columnGap="10px"
        rowGap="5px"
        sx={{ '& p': { wordBreak: 'break-word', fontSize: '11px' } }}
      >
        {fields?.map((field, index) => {
          if (field.hidden) return <></>;
          return (
            <Box
              gap={viewAll ? '5px' : SIZES.paddingS}
              key={field.field_name}
              sx={{
                display: 'flex',
                flexDirection: viewAll ? 'column' : 'row',
                justifyContent: 'flex-start',
                // gridColumn: field.field_name === 'product_name' ? 'span 2' : undefined,
              }}
            >
              <Typography color={colors.accent} fontSize={'12px'} width={viewAll ? undefined : '35%'}>
                {field.display_name}
              </Typography>
              {field.field_name === 'product_name' && field.renderValue ? (
                <Link
                  to={`/company/inventory/products/variant?id=${transaction.variant_id}`}
                  style={{ color: colors.text, flex: 1 }}
                >
                  <Box flex={1}>{field.renderValue()}</Box>
                </Link>
              ) : field.renderValue ? (
                <Box flex={1}>{field.renderValue()}</Box>
              ) : (
                <Typography flex={1}>
                  {field.type === 'currency'
                    ? formatCurrency(transaction[field.field_name])
                    : transaction[field.field_name]}
                </Typography>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default TransactionDetails;

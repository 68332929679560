import { Box, Card, CardContent, List, ListItem, Typography, styled, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';

import { CustomModal } from 'core/components';
import TopUpForm from 'company/screens/CompanyDashboard/components/TopUpForm';
import { UserContext } from 'core/context/user.context';
import { tokens } from 'theme/theme';

interface Module {
  id: number;
  name: string;
  description: string;
  price: number;
  features: string[];
}

const mockModules: Module[] = [
  {
    id: 1,
    name: 'Health Assessments Module',
    description: 'Comprehensive patient health assessment tracking',
    price: 12499,
    features: [
      'Automated APE and PEME form generation for hundreds to thousands of patients',
      'Dedicated customer success representative',
      'Bulk patient report creation',
    ],
  },
  {
    id: 2,
    name: 'Inventory Module',
    description: 'Real-time tracking of medical stocks in every Patient Transaction',
    price: 1499,
    features: ['Comprehensive inventory management access', 'View stock transfers', 'Inspect product logs'],
  },
  {
    id: 3,
    name: 'POS and Transactions Module',
    description: 'Complete tracking for your clinics finances',
    price: 1499,
    features: ['Invoice generation', 'Payment and sales tracking', 'Receipt printing'],
  },
  {
    id: 4,
    name: 'Patient Booking Module',
    description:
      'Allow clinics to accept, reject, or waitlist patients requesting for an appointment through a dedicated clinic booking page.',
    price: 999,
    features: [
      'Remote booking management',
      'Distributable self-booking features for patients',
      'Customizable available services',
    ],
  },
];

const ModuleCard: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);

  const availableModules = mockModules.filter((module) => {
    if (module.name === 'Health Assessments Module' && user?.company?.with_health_assessment) {
      return false;
    }
    if (module.name === 'POS and Transactions Module' && user?.company?.with_pos_and_patient_transactions) {
      return false;
    }
    if (module.name === 'Inventory Module' && user?.company?.with_inventory) {
      return false;
    }
    if (module.name === 'Patient Booking Module' && user?.company?.with_booking_platform) {
      return false;
    }
    return true;
  });

  const [selectedModule, setSelectedModule] = useState<Module | undefined>(
    availableModules.length > 0 ? availableModules[0] : undefined
  );

  const [openDemoModal, setOpenDemoModal] = useState(false);
  const visibleFields: string[] = [];

  const StyledModuleCard = styled(Card)(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(2),
    height: '100%',
    transition: 'transform 0.2s, box-shadow 0.2s',
    // '&:hover': {
    //   transform: 'translateY(-2px)',
    //   boxShadow: '0 10px 20px rgba(0, 0, 0, 0.12)',
    // },
    borderRadius: '15px',
    backgroundColor: 'white',
  }));

  const ModuleListItem = styled(Box)<{ selected?: boolean }>(({ theme, selected }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    backgroundColor: selected ? colors.light_blue_background_2 : colors.light_blue_background,
    '&:hover': {
      backgroundColor: selected ? colors.light_blue_background_2 : colors.light_blue_background,
    },
    transition: 'background-color 0.2s',
  }));

  const ScrollableList = styled(Box)({
    overflowY: 'auto',
    maxHeight: '400px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  });

  const handleTopUp = (values: any): void => {};

  if (availableModules.length === 0) {
    return (
      <StyledModuleCard>
        <CardContent sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="h5" color={colors.primary}>
            All modules are already activated for your clinic
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
            You have access to all available modules. If you need any assistance, please contact
            customercare@cphealthinnovations.com.
          </Typography>
        </CardContent>
      </StyledModuleCard>
    );
  }

  if (!selectedModule) {
    return null;
  }

  return (
    <StyledModuleCard>
      <CardContent sx={{ height: '100%', p: 3 }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 3,
            height: '100%',
          }}
        >
          {/* Left side - Module details */}
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 2 }}>
            <Box>
              <Typography variant="h4" fontWeight="bold" gutterBottom color={colors.primary}>
                {selectedModule.name}
              </Typography>
              <Typography color="textSecondary" paragraph>
                {selectedModule.description}
              </Typography>
              <Typography variant="caption" color="textSecondary" fontStyle="italic">
                All modules are inclusive of 5GB storage, and two accounts with custom permissions
              </Typography>

              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Features:
                </Typography>
                <List>
                  {selectedModule.features.map((feature, index) => (
                    <ListItem key={index} sx={{ py: 0.5 }}>
                      <Typography color={colors.primary}>• {feature}</Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>

              {/* <Box sx={{ mt: 2 }}>
                <AnimatedRegularButton
                  onClick={() => {
                    setOpenDemoModal(true);
                  }}
                  label="Module Demo"
                />
              </Box> */}

              <Box sx={{ mt: 4 }}>
                <Typography variant="h1" color="primary" fontWeight="bold">
                  ₱{selectedModule.price.toLocaleString()}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  Billed Monthly
                </Typography>
              </Box>
            </Box>

            <Box width={'100%'}>
              <TopUpForm
                requestType="module"
                visibleFields={visibleFields}
                unit={selectedModule.name}
                pricePerUnit={selectedModule.price}
                callbackAfterSubmit={handleTopUp}
              />
            </Box>
          </Box>

          {/* Right side - Module list */}
          <Box sx={{ borderLeft: 1, borderColor: 'divider', p: 3, pl: 6 }}>
            <Typography variant="h5" fontWeight="bold" gutterBottom color={colors.primary} sx={{ mb: 2 }}>
              Available Modules
            </Typography>

            <ScrollableList>
              {availableModules.map((module) => (
                <ModuleListItem
                  key={module.id}
                  selected={selectedModule.id === module.id}
                  onClick={() => setSelectedModule(module)}
                >
                  <Box>
                    <Typography variant="h6" fontWeight="bold">
                      {module.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {module.description}
                    </Typography>
                    <Typography variant="subtitle1" color="primary" fontWeight="bold" sx={{ mt: 1 }}>
                      ₱{module.price.toLocaleString()}
                    </Typography>
                  </Box>
                </ModuleListItem>
              ))}
            </ScrollableList>
          </Box>
        </Box>
      </CardContent>

      <CustomModal open={openDemoModal} setOpen={setOpenDemoModal} header={'Module Demo'} width={1000}>
        <Box height={'60vh'}></Box>
      </CustomModal>
    </StyledModuleCard>
  );
};

export default ModuleCard;

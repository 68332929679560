import { Box } from '@mui/material';
import TemplateTable from 'company/entities/Template/TemplateTable';
import { CareGoPage } from 'core/PageBuilder';
import React, { useRef, useState } from 'react';
import { MOP_ENTITY_NAME, mopFields, PaymentModeModel, paymentModesVisibleColumns } from './CompanyPaymentModeModel';
import { ApiQuery } from 'core/model/interface';
import { getCompanyPaymentModes, handleDeletePaymentMode } from 'company/api/payment-modes';
import CompanyPaymentModeForm from './CompanyPaymentModeForm';

export default function CompanyPaymentModes() {
  const [selectedRow, setSelectedRow] = useState<PaymentModeModel>();
  const getData = (query: ApiQuery) => {
    return getCompanyPaymentModes(query);
  };
  const mainRef: any = useRef();
  function callBackAfterSubmit() {
    mainRef.current?.closeFormModal();
    mainRef.current?.refreshTable();
  }

  async function handleDeleteMOP() {
    if (!selectedRow) return;
    const res = await handleDeletePaymentMode(selectedRow.id);
  }

  return (
    <Box sx={{ padding: '0 10px' }}>
      <CareGoPage header="Manage Payment Modes">
        <TemplateTable
          handleDelete={handleDeleteMOP}
          ref={mainRef}
          entityName={MOP_ENTITY_NAME}
          fields={mopFields}
          getData={getData}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          visibleColumns={paymentModesVisibleColumns}
          templateForm={<CompanyPaymentModeForm callbackAfterSubmit={callBackAfterSubmit} payment_mode={selectedRow} />}
        ></TemplateTable>
      </CareGoPage>
    </Box>
  );
}

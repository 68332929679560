import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'appointment-billing';

export const getPrescriptions = (facility_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const getPrescription = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const getPrescriptionDetails = (facility_id: number, prescription_id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/prescriptions/${prescription_id}`);
};

export const getAppointmentPrescriptions = (facility_id: number, appointment_id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/appointment/${appointment_id}`);
};

export const getAllAppointmentPrescriptions = (facility_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/appointments`, { params: query });
};

export const deletePrescription = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

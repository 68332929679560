import {
  PNPT_ENTITY_NAME,
  PatientNotePrintTemplateModel,
  createPatientNotePrintTemplate,
  patientNotePrintTemplateFields,
  patientNotePrintTemplateFormFields,
  patientNotePrintTemplateInitialValues,
} from './PatientNotePrintTemplateModel';
import { createNotePrintTemplate, updateNotePrintTemplate } from 'company/api/patient-note-print-template';

import TemplateForm from 'company/entities/Template/TemplateForm';

type Props = {
  readonly?: boolean;
  healthAssessment?: PatientNotePrintTemplateModel;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
};

const PatientNotePrintTemplateForm = (props: Props) => {
  const { healthAssessment, callbackAfterSubmit } = props;
  async function handleSubmit(data: any) {
    if (!healthAssessment) return createNotePrintTemplate(data);
    return updateNotePrintTemplate(healthAssessment.id, data);
  }

  return (
    <TemplateForm
      entity={healthAssessment}
      schema={createPatientNotePrintTemplate}
      callbackAfterSubmit={callbackAfterSubmit}
      entityName={PNPT_ENTITY_NAME}
      fields={patientNotePrintTemplateFields}
      initialValues={!!healthAssessment ? healthAssessment : patientNotePrintTemplateInitialValues}
      visibleFields={patientNotePrintTemplateFormFields}
      formSubmitApiFunction={handleSubmit}
    />
  );
};

export default PatientNotePrintTemplateForm;

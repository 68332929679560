import { changeNullToBlank, formatProductVariantName } from 'core/utils';
import { useContext, useEffect, useState } from 'react';

import { ClinicModel } from '../../ClinicManagement/Clinic/ClinicModel';
import { CustomModal } from 'core/components';
import { FacilityContext } from 'core/context/facility.context';
import ProductVariantForm from '../Product/ProductVariant/ProductVariantForm';

type Props = {
  variant: any;
  product?: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  facilityDetails?: ClinicModel;
  callbackAfterSubmit?: (data?: any) => void;
  exclusiveSupplier?: number;
};

const UpdateDetails: React.FC<Props> = ({
  variant,
  product,
  open,
  setOpen,
  facilityDetails,
  callbackAfterSubmit,
  exclusiveSupplier,
}) => {
  const { facility } = useContext(FacilityContext);
  const [variantAttributes, setVariantAttributes] = useState<any[]>([]);

  useEffect(() => {
    setVariantAttributes([]);
    if (variant && variant.attributes && variant.attributes) {
      const attributes = JSON.parse(variant.attributes);
      const combined = attributes.reduce((result: any, attribute: any) => {
        if (result[attribute.attribute_id]) {
          result[attribute.attribute_id].push(attribute);
        } else {
          result[attribute.attribute_id] = [attribute];
        }
        return result;
      }, {});
      setVariantAttributes(combined);
    }
  }, [variant]);

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      header={`Update Details - ${formatProductVariantName(variant)}`}
      subHeader={facilityDetails ? facilityDetails.facility_name : facility?.facility_name}
    >
      <ProductVariantForm
        product={product}
        productVariant={changeNullToBlank(variant)}
        callbackAfterSubmit={callbackAfterSubmit}
        // variantAttributes={variantAttributes}
      />
    </CustomModal>
  );
};

export default UpdateDetails;

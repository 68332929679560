import { useContext, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { CareGoPage } from 'core/PageBuilder';
import Cart from './components/Cart';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { CustomTabs } from 'core/components';
import ItemList from './components/ItemList';
import { LoadingScreen } from 'core/screens';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import PrescriptionList from './components/PrescriptionList';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { TabProps } from 'core/components/CustomTabs';
import TakeoutDiningOutlinedIcon from '@mui/icons-material/TakeoutDiningOutlined';
import { UserContext } from 'core/context/user.context';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import { formatProductVariantName } from 'core/utils';
import { useNavigate } from 'react-router-dom';

export interface OrderProps {
  item_type: 'product' | 'service' | 'service-package';
  item_id: number;
  unit_price: number;
  original_price: string;
  total_price?: number;
  quantity: number;
  item_name: string;
  attributes: string;
  made_to_order: boolean;
  quantity_in_stock: number;
  initial_quantity_in_stock?: number;

  product_id?: number;
  isEdit?: boolean;
  sc_pwd_discounted?: boolean;
  tax: string;
  discount?: {
    discount_type: string;
    percentage?: number;
  };
  included_services?: ServiceItem[];
  package_name?: string;
  id?: number;
  service_id?: number;
  variant_id?: number;
  service_name?: string;
  discount_type?: string;
  discount_percentage?: number;
  patientId?: number;
  invoice_id?: number;
  service_tax?: string;
  variant_tax?: string;
  auto_added?: boolean;
  show_in_invoice?: boolean;

  uom_id?: number;
  uom_symbol?: string;
  base_uom_symbol?: string;
  discount_reason?: string;
}

export interface ServiceItem {
  id: number;
  service_name: string;
  price: number;
}

export interface POSProps {
  fromRunningInvoice?: boolean;
  fromCreateInvoice?: boolean;
  fromUpdateInvoiceItems?: boolean;
  appointment?: CompanyAppointmentModel;
  initialOrders?: OrderProps[];
  afterPayment?: (orders: OrderProps[], invoiceId: number) => void;
  setOpenInvoiceItemModal?: any;
  refreshInvoiceDetails?: () => void;
  fromAppointmentCreateInvoice?: boolean;
  autoAdded?: boolean;
}

const POS = ({
  fromRunningInvoice,
  fromCreateInvoice,
  fromUpdateInvoiceItems,
  appointment,
  initialOrders,
  afterPayment,
  setOpenInvoiceItemModal,
  refreshInvoiceDetails,
  fromAppointmentCreateInvoice,
  autoAdded,
}: POSProps) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [selected, setSelected] = useState<number>(0);
  const [orders, setOrders] = useState<OrderProps[]>([]);
  const productListRef: any = useRef<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPatient, setSelectedPatient] = useState<number>(0);
  const [filterResetCounter, setFilterResetCounter] = useState(0);
  const [localAppointmentId, setLocalAppointmentId] = useState<number | undefined>();
  const [refreshAppointmentDetails, setRefreshAppointmentDetails] = useState<boolean | undefined>();

  const handleAfterPayment = (orders: OrderProps[], invoiceId: number) => {
    productListRef.current?.refreshItems(orders);
    afterPayment && afterPayment(orders, invoiceId);
    setRefreshAppointmentDetails(true);
    localStorage.removeItem('orders');
    localStorage.removeItem('selectedPatient');
  };

  useEffect(() => {
    if (initialOrders) {
      const processedOrders = initialOrders
        .map((order) => {
          let processedOrder = { ...order };
          processedOrder.auto_added = true;
          const currentStock = order.quantity_in_stock;
          processedOrder.initial_quantity_in_stock = currentStock;
          processedOrder.quantity_in_stock = currentStock - order.quantity;

          if (order.service_id) {
            if (!processedOrder.item_name) processedOrder.item_name = order.service_name!;
            processedOrder.item_type = 'service';
            processedOrder.item_id = order.service_id!;
            processedOrder.tax = order.service_tax!;
          } else {
            processedOrder.item_type = 'product';
            processedOrder.item_id = order.variant_id!;
            processedOrder.tax = order.variant_tax!;
            // processedOrder.base_uom_symbol = order.variant_tax!;
          }

          if (order.discount_type) {
            processedOrder.discount = {
              discount_type: order.discount_type,
            };
            if (order.discount_type === 'percentage') {
              processedOrder.discount.percentage = order.discount_percentage;
            }
          }

          return processedOrder;
        })
        .filter((order) => {
          if (order.item_type === 'service' || !order.auto_added) {
            return true;
          }
          const initialStock = order.initial_quantity_in_stock ?? order.quantity_in_stock;
          return !(order.auto_added && !order.made_to_order && order.quantity > initialStock);
        });

      setOrders(processedOrders);
    } else {
      const localStorageOrders = JSON.parse(localStorage.getItem('orders') || '[]');
      setOrders(localStorageOrders);
    }
  }, [initialOrders]);

  useEffect(() => {
    if (!initialOrders) localStorage.setItem('orders', JSON.stringify(orders));
  }, [orders, initialOrders]);

  const addToCart = (item: any) => {
    let newQuantityInStock = 0;
    if (item.type === 'product' && !item.made_to_order) {
      const existingOrders = orders.filter((order) => order.item_id === item.id && order.item_type === 'product');
      newQuantityInStock = item.quantity_in_stock;
      if (existingOrders.length) {
        newQuantityInStock = existingOrders[0].quantity_in_stock;
        existingOrders.forEach((order) => (order.quantity_in_stock = newQuantityInStock - 1));
      }
    }

    const newItem: OrderProps = {
      item_type: item.type,
      item_id: item.id,
      product_id: item.product_id ?? 0,
      unit_price: item.unit_price,
      original_price: item.unit_price,
      quantity: item.quantity ?? 1,
      item_name:
        item.type === 'service-package'
          ? item.package_name
          : item.type === 'product'
          ? formatProductVariantName(item)
          : item.service_name,
      attributes: item.attributes,
      made_to_order: item.type === 'product' ? item.made_to_order : '',
      show_in_invoice: item.type === 'product' ? item.show_in_invoice : '',
      tax: item.tax,
      quantity_in_stock: item.type === 'product' ? newQuantityInStock - 1 : 0,
      initial_quantity_in_stock: item.type === 'product' ? item.quantity_in_stock : 0,
      uom_id: item.uom_id,
      included_services:
        item.type === 'service-package'
          ? typeof item.included_services === 'string'
            ? JSON.parse(item.included_services)
            : item.included_services
          : undefined,
    };

    if (!initialOrders) localStorage.setItem('orders', JSON.stringify([...orders, newItem]));
    setOrders((prev) => [...prev, newItem]);
  };

  const removeFromCart = (itemToRemove: OrderProps) => {
    setOrders((prevOrders) =>
      prevOrders.filter(
        (order) => !(order.item_id === itemToRemove.item_id && order.item_type === itemToRemove.item_type)
      )
    );
  };
  const tabs: TabProps[] = [
    {
      label: 'Appointment Billing',
      icon: <MedicationOutlinedIcon />,
      content: (
        <PrescriptionList
          addToCart={addToCart}
          orders={orders}
          appointmentId={appointment?.id}
          setOrders={setOrders}
          selectedPatient={selectedPatient}
          setSelectedPatient={setSelectedPatient}
          localAppointmentId={localAppointmentId}
          setLocalAppointmentId={setLocalAppointmentId}
          resetDetails={refreshAppointmentDetails}
        />
      ),
    },
    {
      label: 'Services ',
      icon: <VaccinesIcon />,
      content: <ItemList addToCart={addToCart} type="service" orders={orders} fromCreateInvoice={fromCreateInvoice} />,
    },
    {
      label: 'Products ',
      icon: <SellOutlinedIcon />,
      content: (
        <ItemList
          addToCart={addToCart}
          type="product"
          orders={orders}
          ref={productListRef}
          fromCreateInvoice={fromCreateInvoice}
        />
      ),
    },
    {
      label: 'Service Packages ',
      icon: <TakeoutDiningOutlinedIcon />,
      content: (
        <ItemList
          addToCart={addToCart}
          type="service-package"
          orders={orders}
          ref={productListRef}
          fromCreateInvoice={fromCreateInvoice}
        />
      ),
    },
  ];

  useEffect(() => {
    if (user) {
      if (!user?.company?.with_pos_and_patient_transactions) {
        navigate('/company/top-up');
      }
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (refreshAppointmentDetails) {
      setTimeout(() => {
        setRefreshAppointmentDetails(false);
      }, 300);
    }
  }, [refreshAppointmentDetails]);

  return (
    <LoadingScreen loading={isLoading}>
      <CareGoPage
        header={fromCreateInvoice || appointment ? undefined : 'POS'}
        breadcrumb={fromCreateInvoice || appointment ? undefined : [{ label: 'POS' }]}
      >
        <Box
          sx={{
            display: 'grid',
            columnGap: '20px',
            gridTemplateColumns: {
              md: 'minmax(0, 2fr) minmax(360px, 1.3fr)',
            },
          }}
        >
          <CustomTabs
            tabs={tabs}
            setSelected={setSelected}
            selected={selected}
            stopParamChange={fromAppointmentCreateInvoice ?? false}
          />
          <Cart
            orders={orders}
            setOrders={setOrders}
            initialOrders={initialOrders}
            afterPayment={handleAfterPayment}
            fromRunningInvoice={fromRunningInvoice}
            fromCreateInvoice={fromCreateInvoice}
            fromAppointmentCreateInvoice={fromAppointmentCreateInvoice}
            removeFromCart={removeFromCart}
            appointment={appointment}
            fromUpdateInvoiceItems={fromUpdateInvoiceItems}
            setOpenInvoiceItemModal={setOpenInvoiceItemModal}
            refreshInvoiceDetails={refreshInvoiceDetails}
            selectedPatientfromAppointment={selectedPatient}
            localAppointmentId={localAppointmentId}
            setRefreshAppointmentDetails={setRefreshAppointmentDetails}
          />
        </Box>
      </CareGoPage>
    </LoadingScreen>
  );
};

export default POS;

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { formatArray, formatDateTime } from 'core/utils';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Navbar from 'LandingPage/Navbar';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import styled from 'styled-components';
import { tokens } from 'theme/theme';
import { useParams } from 'react-router-dom';
import { verifyPatientNote } from 'company/api/verify-notes';

export default function DocumentVerification() {
  const { facility_id, patient_note_code } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [documentData, setDocumentData] = useState<any>(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const status = documentData?.status || null;
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  async function handleVerifyNote() {
    if (!facility_id || !patient_note_code) return;
    setLoading(true);
    const { data } = await verifyPatientNote(Number(facility_id), patient_note_code);
    setDocumentData(data);
    setLoading(false);
  }

  useEffect(() => {
    handleVerifyNote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility_id, patient_note_code]);

  const DocumentCard = styled(Box)`
    display: grid;
    grid-template-columns: ${isMobilePhone ? '1fr' : '1fr 4fr'};
    width: 100%;
    max-width: 800px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    gap: 20px;
  `;

  return (
    <PageWrapper sx={{ width: '100%' }}>
      <Navbar />

      <ContentWrapper sx={{ mt: '100px' }}>
        <PageTitle>
          <Typography variant="h3" fontWeight={600} color="primary" textAlign="center">
            Document Verification
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" textAlign="center">
            Verify the details of the patient document below
          </Typography>
        </PageTitle>

        {loading && (
          <Typography variant="h6" color="text.secondary" textAlign="center">
            Verifying Patient Note...
          </Typography>
        )}
        {!loading && !!documentData && status === 'success' && (
          <DocumentCard>
            {/* <LogoSection>
              <img
                src="/assets/carego_health_suite_logo.png"
                alt="CPHI Logo"
                style={{ width: '6rem', height: '6rem' }}
              />
            </LogoSection> */}

            <StatusSection>
              <TaskOutlinedIcon fontSize="large" htmlColor={colors.greenAccent} />
              <Typography variant="h2" color={colors.greenAccent} fontWeight={600}>
                Verified
              </Typography>
            </StatusSection>

            <DetailsSection>
              <Typography variant="h4" fontWeight={500} color={colors.primary}>
                Document Details
              </Typography>
              <Box
                display="grid"
                gridTemplateColumns={isMobilePhone ? '1fr' : '150px 1fr'}
                columnGap="10px"
                rowGap="5px"
              >
                <Typography fontWeight={500}>Name:</Typography>
                <Typography>{documentData.data.patient_name}</Typography>

                <Typography fontWeight={500} mt={isMobilePhone ? '10px' : ''}>
                  Clinic:
                </Typography>
                <Typography>{documentData.data.facility_name}</Typography>

                <Typography fontWeight={500} mt={isMobilePhone ? '10px' : ''}>
                  Clinic Address:
                </Typography>
                <Typography>
                  {formatArray([
                    documentData.data.address,
                    documentData.data.municipality_name,
                    documentData.data.province_name,
                  ])}
                </Typography>

                <Typography fontWeight={500} mt={isMobilePhone ? '10px' : ''}>
                  Document Created:
                </Typography>
                <Typography>{formatDateTime(documentData.data.created_at)}</Typography>
              </Box>
            </DetailsSection>
          </DocumentCard>
        )}

        {!loading && documentData && status === 'failed' && (
          <FailedCard>
            <Box sx={{ textAlign: 'center' }}>
              <ErrorOutlineOutlinedIcon fontSize="large" htmlColor={colors.error} />
              <Typography variant="h4" color={colors.error} mb={2}>
                Verification Failed
              </Typography>
              <Typography variant="body1" color="text.secondary" mb={1}>
                The patient note could not be verified. Please check the details and try again.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                If the issue persists, contact support for assistance.
              </Typography>
            </Box>
          </FailedCard>
        )}
        {status === 'success' && (
          <Box sx={{ mt: '2rem', textAlign: 'center' }}>
            <Typography variant="subtitle1" color="text.secondary">
              If you need further clarification or have any questions about this document, please contact the clinic at
            </Typography>
            {!!documentData?.data?.email ? (
              <Typography variant="subtitle1" color="text.secondary">
                <a
                  href={`mailto:${documentData?.data?.email}`}
                  style={{ color: colors.primary, fontFamily: 'Poppins' }}
                >
                  {documentData?.data?.email}
                </a>{' '}
                {documentData?.data.smart_mobile_number || documentData?.data.globe_mobile_number
                  ? `or call them at ${formatArray(
                      [documentData.data.smart_mobile_number, documentData.data.globe_mobile_number],
                      ' | '
                    )}.`
                  : ''}
              </Typography>
            ) : (
              <Typography variant="subtitle1" color="text.secondary">
                (no email provided)
              </Typography>
            )}
          </Box>
        )}
      </ContentWrapper>

      <Footer>
        <Box>CP Health Innovations (c) 2024. All rights reserved.</Box>
        <Box>Terms and Conditions | Privacy Policy</Box>
      </Footer>
    </PageWrapper>
  );
}
const FailedCard = styled(Box)`
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border: 1px solid #f44336; /* Red border for failure indication */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(244, 67, 54, 0.1);
  background-color: #fff5f5; /* Light red background */
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled(Box)`
  flex: 1;
  padding: 2% 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const PageTitle = styled(Box)`
  text-align: center;
`;

const LogoSection = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DetailsSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StatusSection = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  color: white;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 70px;
  height: 50px;
  background: linear-gradient(to right, #239bd7, #0463ae);
  @media (max-width: 768px) {
    font-size: 12px;
    padding: 20px 10px;
    flex-direction: column;
    height: auto;
    gap: 10px;
  }
`;

import { Box, Typography } from '@mui/material';
import { ConfirmationDialog, CustomModal, PrimaryButton, RegularButton } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getAllGroups, removeServiceGroupFromCategory } from 'company/api/service-groups';

import AddServiceAndPackageTable from './AddServiceAndPackageTable';
import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import { PageBuilderTableType } from 'core/PageBuilder';
import RemoveIcon from '@mui/icons-material/Remove';
import { formatNumber } from 'core/utils';
import { getServicesAssigned } from 'company/api/services';
import { removeServiceFromCategory } from 'company/api/service-categories';
import { useSnackbar } from 'notistack';

export interface ServiceCategorySearchProp extends ApiQuery {
  service_category_id?: number;
  is_package?: boolean;
  service_package_category?: string[];
}

interface ServicesPerCategoryTableProps {
  category_id: number;
  entity?: string;
}

export default function ServicesPerCategoryTable({ category_id, entity }: ServicesPerCategoryTableProps) {
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [clearSelected, setClearSelected] = useState<number>(0);
  const { facility } = useContext(FacilityContext);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const serviceCategoryTableRef: any = useRef();
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);

  const forService = entity === 'Services';
  if (!!serviceCategoryTableRef.current) serviceCategoryTableRef.current.refreshTable = refreshTable;
  function refreshTable() {
    setForceRefresh((prev) => prev + 1);
  }
  const handleSelectRows = (selected: any) => {
    setSelectedRows(Array.from(selected));
  };

  useEffect(() => {
    refreshTable();
  }, [category_id]);

  const columns: GridColDef[] = [
    {
      field: 'service_name',
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => (
        <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center">
          {params.row.service_name}
        </Box>
      ),
    },
    {
      field: 'clinic_price',
      headerName: 'Price (Php)',
      flex: 1,
      renderCell: (params) =>
        formatNumber(parseFloat(params.row.clinic_price)) ? formatNumber(parseFloat(params.row.clinic_price)) : '0',
    },
  ];

  const service_package_columns: GridColDef[] = [
    {
      field: 'group_name',
      headerName: 'Service Package Name',
      flex: 2,
      renderCell: ({ row, value }) => {
        return (
          <Box>
            <Typography>{value}</Typography>
            <Typography variant="subtitle1" fontWeight={500} color="#888" lineHeight={1} fontSize={'13px'}>
              {row.services}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const getServiceList = (query: ServiceCategorySearchProp) => {
    if (forService) return getServicesAssigned(facility.id, { ...query, service_category_id: category_id });
    return getAllGroups(facility.id, { ...query, is_package: true, service_category_id: category_id });
  };

  async function handleRemoveServiceFromCurrentCategory(singleId?: number) {
    await removeServiceFromCategory(facility.id, category_id, { service_ids: singleId ? [singleId] : selectedRows });
    refreshTable();
    !singleId && enqueueSnackbar('Successfully removed services', { variant: 'success' });
    setClearSelected((prev) => prev + 1);
  }

  async function handleRemoveServiceGroupFromCurrentCategory(singleId?: number) {
    await removeServiceGroupFromCategory(facility.id, category_id, { group_ids: singleId ? [singleId] : selectedRows });
    refreshTable();
    !singleId && enqueueSnackbar('Successfully removed services', { variant: 'success' });
    setClearSelected((prev) => prev + 1);
  }

  const content = {
    type: BLOCK_TYPE.TABLE,
    entityName: forService ? 'Service' : 'Service Package',
    useColoredTable: true,
    tableAction: {
      includeDeleteAction: true,
      deleteButtonLabel: 'Remove',
      hideDeleteCallback: (data) => true,
      deleteApiFunction: (data) => {
        if (forService) return handleRemoveServiceFromCurrentCategory(data);
        return handleRemoveServiceGroupFromCurrentCategory(data);
      },
    },
    tableComponent: {
      columns: forService ? columns : service_package_columns,
      getData: getServiceList,
      forceRefresh: forceRefresh,
      checkboxSelection: true,
      selectableRow: true,
      clearSelected: clearSelected,
      hideAddSelectedButton: true,
      onSelectRow: handleSelectRows,
      headerComponent: (
        <Box display="flex" gap="10px">
          <PrimaryButton
            onClick={() => {
              setOpenAddModal(true);
            }}
            label={forService ? 'Add Services' : 'Add Service Packages'}
          />

          <RegularButton
            onClick={() => {
              if (selectedRows.length) {
                setOpenRemoveDialog(true);
                setAlertContent(
                  'Are you sure you want to remove ' +
                    selectedRows.length +
                    ' ' +
                    (forService ? 'service(s)' : 'service package(s)')
                );
              } else {
                enqueueSnackbar('Invalid action. Select services first!', { variant: 'error' });
              }
            }}
            label={forService ? 'Remove Services' : 'Remove Service Packages'}
            disabled={!selectedRows.length}
            startIcon={<RemoveIcon />}
            color="error"
          />
        </Box>
      ),
    },
  } as PageBuilderTableType;
  return (
    <Box ref={serviceCategoryTableRef}>
      <PageBuilder content={content} />

      <CustomModal width={900} header={'Add Services'} open={openAddModal} setOpen={setOpenAddModal}>
        <AddServiceAndPackageTable
          entity={forService ? 'Services' : 'Service Package'}
          service_category_id={category_id}
          serviceCategoryTableRef={serviceCategoryTableRef}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openRemoveDialog}
        setOpen={setOpenRemoveDialog}
        content={alertContent}
        onConfirm={forService ? handleRemoveServiceFromCurrentCategory : handleRemoveServiceGroupFromCurrentCategory}
      />
    </Box>
  );
}

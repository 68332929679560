import { Box, Collapse, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { formatCurrency, formatNumber } from 'core/utils';

import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OrderProps } from '..';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import { RegularButton } from 'core/components';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { tokens } from 'theme/theme';

type LinkedProduct = {
  id: number;
  variant_id: number;
  quantity: number;
  price: number;
  show_in_invoice: boolean;
  variant_name: string;
  product_name: string;
  quantity_in_stock: string;
  made_to_order: boolean;
  conversion_factor: string;
};

type ServiceItem = {
  service_id: number;
  name: string;
  unit_price: number;
  quantity: number;
  line_item_id: number;
  item_type: 'service';
  linked_products: LinkedProduct[];
};

type Props = {
  items: ServiceItem[];
  addToCart: (item: any) => void;
  orders: OrderProps[];
};

const PrescriptionServices = ({ items, addToCart, orders }: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [expandedServices, setExpandedServices] = useState<number[]>([]);

  // Calculate items in cart for each variant
  const itemsInCartByVariant = useMemo(() => {
    const cartItems: Record<number, number> = {};
    items.forEach((service) => {
      service.linked_products.forEach((product) => {
        cartItems[product.variant_id] = orders
          .filter((order) => order.item_id === product.variant_id && order.item_type === 'product')
          .reduce((sum, order) => sum + order.quantity, 0);
      });
    });
    return cartItems;
  }, [orders, items]);

  const checkIfServiceInCart = useCallback(
    (serviceId: number) => {
      return orders.findIndex((order) => order.item_type === 'service' && order.item_id === serviceId) >= 0;
    },
    [orders]
  );

  const handleServiceExpand = (serviceId: number) => {
    setExpandedServices((prevExpanded) => {
      if (prevExpanded.includes(serviceId)) {
        return prevExpanded.filter((id) => id !== serviceId);
      }
      return [...prevExpanded, serviceId];
    });
  };

  const handleAddToCart = (item: any, product?: LinkedProduct) => {
    const itemsInCart = product ? itemsInCartByVariant[product.variant_id] || 0 : 0;
    const availableStock = product ? parseFloat(product.quantity_in_stock) - itemsInCart : 0;

    if (product) {
      if (product.made_to_order || availableStock > 0) {
        const quantity = Math.min(item.quantity, availableStock);
        addToCart({
          ...item,
          quantity: product.made_to_order ? item.quantity : quantity,
        });
      }
    } else {
      addToCart(item);
    }
  };

  useEffect(() => {
    const servicesWithProducts =
      items?.filter((service) => service.linked_products?.length > 0).map((service) => service.service_id) || [];
    setExpandedServices(servicesWithProducts);
  }, [items]);

  if (!items?.length) return null;

  return (
    <Box
      sx={{
        backgroundColor: colors.light_blue_background,
        borderRadius: '12px',
        mb: 2,
        overflow: 'hidden',
      }}
    >
      <Box sx={{ padding: '12px 15px' }}>
        <Typography variant="h5" fontWeight="500">
          Appointment Services
        </Typography>

        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {items.map((service) => (
            <Box
              key={service.service_id}
              sx={{
                backgroundColor: 'white',
                borderRadius: '8px',
                border: `1px solid ${colors.border}`,
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: service.linked_products?.length ? 'pointer' : 'default',
                }}
                onClick={() => service.linked_products?.length && handleServiceExpand(service.service_id)}
              >
                <Box flex={1}>
                  <Typography variant="h5" fontWeight="500">
                    {service.name}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" gap={2}>
                  <Typography variant="body2" color={colors.primary} fontWeight="500">
                    {formatCurrency(service.unit_price)}
                  </Typography>
                  <RegularButton
                    startIcon={<ShoppingCartOutlinedIcon />}
                    disabled={checkIfServiceInCart(service.service_id)}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleAddToCart({
                        id: service.service_id,
                        type: 'service',
                        unit_price: service.unit_price,
                        service_name: service.name,
                        quantity: service.quantity,
                        line_item_id: service.line_item_id,
                      });
                    }}
                    size="small"
                  >
                    Add to Cart
                  </RegularButton>
                  {service.linked_products?.length > 0 && (
                    <Box>
                      {expandedServices.includes(service.service_id) ? (
                        <ExpandLessIcon sx={{ color: colors.primary }} />
                      ) : (
                        <ExpandMoreIcon sx={{ color: colors.primary }} />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>

              {service.linked_products?.length > 0 && (
                <Collapse in={expandedServices.includes(service.service_id)}>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      padding: '10px',
                      borderTop: `1px solid ${colors.border}`,
                    }}
                  >
                    <Typography variant="subtitle2" mb={1} color={colors.primary}>
                      Linked Products
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={1}>
                      {service.linked_products.map((product) => {
                        const itemsInCart = itemsInCartByVariant[product.variant_id] || 0;
                        const stock = parseFloat(product.quantity_in_stock);
                        const qtyNeeded = product.conversion_factor
                          ? product.quantity * parseFloat(product.conversion_factor)
                          : product.quantity;
                        const availableStock = stock - itemsInCart;
                        const isOutOfStock = !product.made_to_order && stock < qtyNeeded;
                        const isAdded = itemsInCart === qtyNeeded;
                        return (
                          <Box
                            key={product.id}
                            sx={{
                              backgroundColor: 'white',
                              padding: '8px 10px',
                              borderRadius: '6px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              border: `1px solid ${colors.border}`,
                            }}
                          >
                            <Box>
                              <Typography variant="body2" fontWeight="500">
                                {product.product_name} - {product.variant_name}
                              </Typography>
                              {isOutOfStock && <Typography variant="caption">Qty Needed: {qtyNeeded}</Typography>}

                              <Box display="flex">
                                <Typography
                                  variant="caption"
                                  color={
                                    product.made_to_order
                                      ? colors.greenAccent
                                      : availableStock > 0
                                      ? colors.greenAccent
                                      : colors.error
                                  }
                                >
                                  {product.made_to_order
                                    ? 'Made to Order'
                                    : `Stock: ${formatNumber(availableStock, 0)}`}
                                </Typography>
                              </Box>
                            </Box>
                            <Box display="flex" alignItems="center" gap={2}>
                              <Typography variant="body2" color={colors.primary} fontWeight="500">
                                {formatCurrency(product.price)}
                              </Typography>

                              <RegularButton
                                startIcon={
                                  isOutOfStock ? (
                                    <ProductionQuantityLimitsOutlinedIcon />
                                  ) : isAdded ? (
                                    <AddShoppingCartOutlinedIcon />
                                  ) : (
                                    <ShoppingCartOutlinedIcon />
                                  )
                                }
                                onClick={() =>
                                  handleAddToCart(
                                    {
                                      id: product.variant_id,
                                      type: 'product',
                                      unit_price: product.price,
                                      product_name: `${product.product_name} - ${product.variant_name}`,
                                      quantity: qtyNeeded,
                                      service_product_id: product.id,
                                      show_in_invoice: product.show_in_invoice,
                                      quantity_in_stock: stock,
                                    },
                                    product
                                  )
                                }
                                size="small"
                                disabled={isOutOfStock || isAdded}
                                styles={{ width: '135px' }}
                              >
                                {isOutOfStock ? 'Out of Stock' : isAdded ? 'Added' : 'Add to Cart'}
                              </RegularButton>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Collapse>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PrescriptionServices;

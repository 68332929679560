import { CustomGridCell, CustomModal } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { changeNullToBlank, formatNumber, formatNumberMaxDecimal, formatProductVariantName } from 'core/utils';
import { deleteProduct, getAppointmentProducts, updateProduct } from 'company/api/appointment-products';
import { useContext, useEffect, useState } from 'react';

import AddModal from './AddModal';
import { ApiQuery } from 'core/model/interface';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import { EntityFields } from 'company/entities/utils';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { PageBuilderTableType } from 'core/PageBuilder';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

type AppointmentProductTableProps = {
  appointment: any;
  refreshProducts?: () => void;
};

const AppointmentProductTable: React.FC<AppointmentProductTableProps> = ({ appointment, refreshProducts }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);

  const [openAddProductModal, setOpenAddProductModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [row, setRow] = useState<any>();
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [clearSelected, setClearSelected] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  const columns: GridColDef[] = [
    // {
    //   field: 'created_at',
    //   headerName: 'Date',
    //   flex: 1,
    //   editable: true,
    //   type: 'date',
    //   valueGetter: (params) => new Date(params.row.created_at),
    //   renderCell: (params) => <Typography>{formatDate(params.row.created_at)}</Typography>,
    // },
    {
      field: 'product_name',
      headerName: 'Product Name',
      flex: 2,
      renderCell: ({ value, row }) => (
        <CustomGridCell>
          <Link to={`/company/inventory/products/variant?id=${row.variant_id}`} className="emphasis">
            <Typography>{formatProductVariantName(row)}</Typography>
          </Link>
        </CustomGridCell>
      ),
    },
    {
      field: 'unit_price',
      headerName: 'Unit Price',
      flex: 1,
      renderCell: (params) => <Typography>{formatNumber(parseFloat(params.row.unit_price))}</Typography>,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1,
      editable: true,
      type: 'number',
      renderCell: ({ value, row }) => (
        <Typography>
          {formatNumberMaxDecimal(value)} {row.uom}
        </Typography>
      ),
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 1,
      type: 'number',
      renderCell: (params) => {
        return <Typography>{formatNumber(params.row.quantity * parseFloat(params.row.unit_price))}</Typography>;
      },
    },
  ];

  const getData = async (query: ApiQuery) => {
    return getAppointmentProducts(facility.id, appointment.id, query);
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
    refreshProducts && refreshProducts();
  };

  const handleDeleteProduct = async (id: number) => {
    return deleteProduct(facility.id, appointment.id, id).then((res) => {
      refreshTable();
    });
  };

  const handleSubmit = async (data: any) => {
    if (data.id) {
      return updateProduct(facility.id, appointment.id, data.id, data).then((res) => {
        enqueueSnackbar(`Product successfully updated!`, { variant: 'success' });
        setClearSelected((prev: number) => prev + 1);
      });
    }
  };

  const handleSubmitUpdateItem = async (data: any) => {
    if (data.id) {
      return updateProduct(facility.id, appointment.id, data.id, data).then((res) => {
        enqueueSnackbar(`Appointment product successfully updated!`, { variant: 'success' });
        refreshTable();
        setClearSelected((prev: number) => prev + 1);
        setOpenUpdateModal(false);
      });
    }
  };

  useEffect(() => {
    refreshTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment]);

  const fields: EntityFields[] = [
    {
      displayName: 'Unit Price',
      fieldName: 'unit_price',
      flex: 1,
    },
    {
      displayName: 'Quantity',
      fieldName: 'quantity',
      flex: 1,
    },
  ];

  const content = {
    type: BLOCK_TYPE.TABLE,
    entityName: 'Product',
    doNotWaitForFacility: false,
    useColoredTable: true,
    tableComponent: {
      columns: columns,
      getData: getData,
      forceRefresh: forceRefresh,
      rowId: 'id',
      clearSelected: clearSelected,
      // headerComponent: (
      //   <HideForInactiveCompany>
      //     <Box display="flex" gap="10px">
      //       <PrimaryButton onClick={() => setOpenAddProductModal(true)} label="Add Products" />
      //     </Box>
      //   </HideForInactiveCompany>
      // ),
    },
    tableForm: {
      formSubmitApiFunction: handleSubmit,
    },
    tableAction: {
      // includeAddButton: false,
      includeDeleteAction: true,
      // includeUpdateAction: appointment.invoice_id === null,
      // includeDeleteAction: invoice && !(invoice.is_sales_receipt || invoice.is_posted),
      // includeDeleteAction: appointment.invoice_id === null,
      deleteApiFunction: handleDeleteProduct,
      handleRowActionsClick(selectedRow, action) {
        if (action === 'update') {
          setRow(selectedRow);
          setOpenUpdateModal(true);
        }
      },
    },
  } as PageBuilderTableType;

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? <CustomLoadingIndicator /> : <PageBuilder content={content} />}

      <CustomModal open={openAddProductModal} setOpen={setOpenAddProductModal} header={'Add Product'}>
        <AddModal
          entity={'Product'}
          refreshMainTable={() => {
            refreshTable();
          }}
          isProduct
          appointment_id={appointment.id}
          patient_id={appointment.patient_id}
        />
      </CustomModal>
      {row && (
        <CustomModal
          open={openUpdateModal}
          setOpen={setOpenUpdateModal}
          header={'Update ' + row.product_name + row.item_name}
        >
          <TemplateForm
            entityName="Invoice Product"
            initialValues={changeNullToBlank(row)}
            fields={fields}
            formSubmitApiFunction={handleSubmitUpdateItem}
            visibleFields={['unit_price', 'quantity']}
            hideDefaultSnackbar
          />
        </CustomModal>
      )}
    </>
  );
};

export default AppointmentProductTable;

import { Box, Divider, SvgIconTypeMap, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DISPLAY_ONLY_FIELDS, FORM_MODE, FieldInput, SIGNATORY_MODE } from 'core/model/interface';
import { Fragment, Ref, forwardRef, useContext, useEffect, useState } from 'react';
import { calculateAge, formatArray, formatDate } from 'core/utils';

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import CustomRichTextEditor from 'core/components/CustomRichTextEditor';
import CustomTableForm from 'core/components/forms/CustomTableForm';
import DocumentRichText from './DocumentRichText';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { FacilityModel } from 'core/model/Entities';
import FileUpload from '../../../../core/components/forms/FileUpload';
import HealthOneNoteHeader from '../HealthOneNoteHeader';
import { HideOrShowComponent } from 'core/components';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { PatientNoteModel } from 'company/model/Entities';
import PatientNoteSignatories from './PatientNoteSignatories';
import { QRCode } from 'react-qrcode-logo';
import { SIZES } from 'theme/constants';
import { ServiceProviderModel } from 'company/entities/modules/ClinicManagement/ServiceProvider/ServiceProviderModel';
import { UserContext } from 'core/context/user.context';
import { getDoctor } from 'company/api/doctors';
import { getFacilityDetails } from 'core/api/public';
import { tokens } from 'theme/theme';

type Props = {
  note: PatientNoteModel;
  patient: PatientModel;
  fields?: FieldInput[];
  responses: any;
  appointment?: CompanyAppointmentModel;
  hideEmptyFields?: boolean;
  compact?: boolean;
  formView?: boolean;
  isPrinting?: boolean;
  noteSignatories?: any[];
  fontSizes?: {
    header: string;
    body: string;
  };
};

type FacilityDetailsProps = {
  label: string;
  Icon?: OverridableComponent<SvgIconTypeMap>;
  compact?: boolean;
  fontSizes?: {
    header: string;
    body: string;
  };
};

export const FacilityDetails: React.FC<FacilityDetailsProps> = ({ label, Icon, compact, fontSizes }) => {
  if (label) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
        <Typography textAlign="center" sx={{ fontSize: fontSizes?.body || (compact ? '12px' : '13px') }}>
          {Icon && (
            <Icon
              color="primary"
              sx={{
                fontSize: fontSizes?.header || (compact ? '16px' : '20px'),
                marginBottom: '-2px',
              }}
            />
          )}
          {label}
        </Typography>
      </Box>
    );
  }
  return <></>;
};

const PrintablePatientNote = forwardRef(
  (
    {
      note,
      patient,
      fields,
      responses,
      appointment,
      hideEmptyFields = false,
      compact,
      formView,
      noteSignatories,
      fontSizes,
      isPrinting,
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const span = 12;
    const defaultSpan = 'span ' + span / 3;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobilePhone = useMediaQuery('(max-width:768px)');
    const { user } = useContext(UserContext);
    const { facility } = useContext(FacilityContext);
    const [provider, setProvider] = useState<ServiceProviderModel>();
    const [facilityDetails, setFacilityDetails] = useState<FacilityModel>();

    useEffect(() => {
      if (appointment?.provider_id) {
        getDoctor(appointment.provider_id).then((res) => setProvider(res.data));
      }
    }, [appointment]);

    useEffect(() => {
      if (user) {
        const logo = process.env.PUBLIC_URL + `/assets/carego_health_suite_logo.png`;
        if (user.is_company_account) {
          setFacilityDetails({ ...facility, company_logo: user.company?.company_logo ?? logo });
        } else if (user.is_patient_account) {
          getFacilityDetails(note.facility_id).then((res) => {
            setFacilityDetails({ ...res.data, company_logo: res.data.company_logo ?? logo });
          });
        }
      }
    }, [facility, user, note]);

    return (
      <Box>
        <Box position="relative">
          {!formView && <style>{styles}</style>}

          <Box ref={ref} className="page-break">
            {user.company?.company_name.includes('HealthOne Diagnostic') ? (
              <HealthOneNoteHeader
                isMobilePhone={isMobilePhone}
                isPrinting={isPrinting}
                user={user}
                note={note}
                patient={patient}
                templateName={note.header}
              />
            ) : (
              <>
                <Box>
                  {facilityDetails && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        // alignItems: 'center',
                        position: 'relative',
                      }}
                    >
                      {/* <Box> */}
                      {!isMobilePhone || isPrinting ? (
                        <Box>
                          <img
                            alt="cphi-logo"
                            width={compact ? '70px' : '80px'}
                            height={'auto'}
                            id="logo"
                            src={facilityDetails.company_logo}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}

                      <Box
                        width={isMobilePhone && !isPrinting ? '100%' : compact ? '85%' : '75%'}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={compact ? '1px' : '4px'}
                        margin="0 auto"
                      >
                        <Typography
                          variant={isMobilePhone ? 'h2' : 'h2'}
                          fontWeight="bold"
                          textAlign="center"
                          sx={{
                            color: colors.accent,
                            // fontSize: fontSizes?.header,
                          }}
                        >
                          {facilityDetails.facility_name ?? ''}
                        </Typography>

                        <Box sx={compact ? { display: 'flex', gap: '10px' } : undefined}>
                          <FacilityDetails
                            Icon={CallOutlinedIcon}
                            label={formatArray(
                              [facilityDetails.smart_mobile_number, facilityDetails.globe_mobile_number],
                              ' | '
                            )}
                            compact={compact}
                            fontSizes={fontSizes}
                          />
                          <FacilityDetails
                            Icon={EmailOutlinedIcon}
                            label={facilityDetails.email}
                            compact={compact}
                            fontSizes={fontSizes}
                          />
                        </Box>
                        <FacilityDetails
                          Icon={LocationOnOutlinedIcon}
                          label={formatArray([
                            facilityDetails.address,
                            facilityDetails.municipality_name,
                            facilityDetails.province_name,
                          ])}
                          compact={compact}
                          fontSizes={fontSizes}
                        />
                      </Box>

                      {!isMobilePhone || isPrinting ? (
                        <Box width="100px" display="flex" alignItems="center" flexDirection="column">
                          <Typography fontSize="12px">Patient ID: {patient.id}</Typography>
                          {!!user.company?.with_patient_result_sending && (
                            <QRCode
                              qrStyle="squares"
                              ecLevel="L"
                              fgColor={colors.primary}
                              value={`${process.env.REACT_APP_BASE_URL}/verify/${note.facility_id}/${note.note_code}`}
                              style={{ height: 'auto', width: '100%', minWidth: '90px' }}
                            />
                          )}
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Box>
                  )}
                </Box>
              </>
            )}

            <Box className="container">
              <Box className="note-content">
                <Divider sx={{ marginBlock: SIZES.paddingS }} />
                {!user.company?.company_name.includes('HealthOne Diagnostic') && (
                  <>
                    <Box
                      sx={
                        isMobilePhone && !isPrinting
                          ? { display: 'grid', gridTemplateColumns: '1fr 1fr' }
                          : { display: 'flex' }
                      }
                      gap={SIZES.paddingS}
                      mt={SIZES.paddingS}
                      width="100%"
                    >
                      <PatientField
                        sx={{ flex: 1 }}
                        label="Full Name"
                        value={patient.full_name}
                        fieldFontSize={fontSizes?.body}
                      />
                      <PatientField sx={{ flex: 1 }} label="Sex" value={patient.sex} fieldFontSize={fontSizes?.body} />
                      <PatientField
                        sx={{ flex: 1 }}
                        label="Birthday"
                        value={formatDate(patient.birthday)}
                        fieldFontSize={fontSizes?.body}
                      />
                      <PatientField
                        sx={{ flex: 1 }}
                        label="Age"
                        value={calculateAge(patient.birthday)}
                        fieldFontSize={fontSizes?.body}
                      />
                      {patient.employee_id && (
                        <PatientField
                          sx={{ flex: 1 }}
                          label="Employee ID"
                          value={patient.employee_id}
                          fieldFontSize={fontSizes?.body}
                        />
                      )}
                    </Box>
                    <Divider sx={{ marginBlock: SIZES.paddingS }} />
                  </>
                )}
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    gap: compact ? '0px' : '5px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    m: compact ? '10px 0' : '20px 0',
                  }}
                >
                  <Typography
                    variant={compact ? 'h4' : 'h3'}
                    sx={{
                      fontWeight: '600',
                      // fontSize: fontSizes?.header,
                      color: colors.accent,
                    }}
                  >
                    {note.header}
                  </Typography>
                  {!user.company?.company_name.includes('HealthOne Diagnostic') && (
                    <Typography sx={{ fontSize: fontSizes?.body || (compact ? '11px' : '13px') }}>
                      {formatDate(note.date_recorded)}
                    </Typography>
                  )}
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns={`repeat(${span}, 1fr)`}
                  columnGap={compact ? '6px' : '15px'}
                  rowGap={compact ? '10px' : '15px'}
                  mt={SIZES.padding}
                >
                  {fields?.map((field, index) => {
                    if (field.hide_in_print && !formView) return <Fragment key={index} />;

                    if (DISPLAY_ONLY_FIELDS.includes(field.type as string)) {
                      if (field.type === 'section_header' || field.type === 'subsection_header')
                        return (
                          <Typography
                            key={index}
                            variant={
                              field.type === 'subsection_header'
                                ? 'h5'
                                : field.type === 'section_header'
                                ? 'h4'
                                : undefined
                            }
                            fontWeight={'bold'}
                            sx={{
                              gridColumn: 'span ' + (field.span ?? span),
                              mb: compact ? '0.5em' : '1em',
                              fontSize: fontSizes?.header,
                            }}
                          >
                            {field.subsection_header ?? field.section_header ?? field.body_text}
                          </Typography>
                        );

                      if (field.type === 'table') {
                        return (
                          <Box key={index} sx={{ gridColumn: 'span ' + (field.span ?? span) }}>
                            <CustomTableForm
                              displayName={field.display_name}
                              columns={field.columns!}
                              formMode={FORM_MODE.VIEW}
                              initialValues={JSON.stringify(responses[field.field_name])}
                              fixedRows
                              compact={compact}
                              hideEmptyRows={field.hide_empty_rows}
                              isPrinting={isPrinting}
                              // fontSizes={fontSizes}
                            />
                          </Box>
                        );
                      }

                      if (field.type === 'body_text') {
                        return (
                          <Box key={index} sx={{ gridColumn: 'span ' + (field.span ?? span) }}>
                            <DocumentRichText
                              isBodyText
                              component={{ id: '', type: 'rich_text', content: field.body_text }}
                              mode={FORM_MODE.VIEW}
                              patient={patient}
                              appointment={appointment}
                              provider={provider}
                            />
                          </Box>
                        );
                      }

                      if (field.type === 'file_upload' && !isPrinting) {
                        return (
                          <Box sx={{ gridColumn: 'span ' + (field.span ?? span) }}>
                            <FileUpload
                              source={'forms'}
                              maxFilesonDrop={field.maxFilesonDrop}
                              maxFilesAllowed={field.maxFilesAllowed}
                              maxSize={field.maxSize}
                              fileTypes={field.fileTypes}
                              span={field.span ?? span}
                              formMode={FORM_MODE.VIEW}
                              appointmentId={appointment?.id}
                              patientId={patient?.patient_id}
                              label={field.display_name!}
                              value={responses[field.field_name]}
                              fieldName={field.field_name}
                              // handleChange={(values) => updateField(field, values)}
                              patientNoteId={note.id}
                            />
                          </Box>
                        );
                      }

                      if (field.type === 'divider') {
                        return (
                          <Box key={index} sx={{ gridColumn: 'span ' + (field.span ?? defaultSpan) }}>
                            <Divider />
                          </Box>
                        );
                      }

                      return <Fragment key={index} />;
                    }

                    if (field.type === 'rich_text') {
                      return (
                        <PatientField
                          key={index}
                          label={field.display_name}
                          valueIsComponent
                          value={
                            <CustomRichTextEditor
                              label=""
                              fieldName={field.field_name}
                              value={responses[field.field_name]}
                              readOnly
                              // fontSizes={fontSizes}
                            />
                          }
                          sx={{ gridColumn: 'span ' + (field.span ?? span) }}
                          fieldFontSize={fontSizes?.body}
                        />
                      );
                    }

                    return (
                      <HideOrShowComponent
                        key={index}
                        hidden={hideEmptyFields && !responses[field.field_name] && !formView}
                      >
                        <PatientField
                          label={field.display_name + (field.hide_in_print ? ' (hidden in print)' : '')}
                          value={
                            field.type === 'checkbox'
                              ? responses[field.field_name]
                                ? 'Yes'
                                : 'No'
                              : field.type === 'date'
                              ? formatDate(responses[field.field_name])
                              : responses[field.field_name]
                          }
                          sx={{ gridColumn: 'span ' + (field.span ?? defaultSpan) }}
                          fieldFontSize={fontSizes?.body}
                        />
                      </HideOrShowComponent>
                    );
                  })}
                </Box>
              </Box>
            </Box>

            {noteSignatories && (
              <Box className="footer">
                <PatientNoteSignatories
                  mode={SIGNATORY_MODE.VIEW}
                  noteSignatories={noteSignatories}
                  isPrinting={isPrinting}
                  // fontSizes={fontSizes}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);

const styles = `
 .page-break:not(:first-child) {
    page-break-before: always;
  }

  .page-break {
    display: block;
    page-break-inside: avoid;    
  }
   @media print {
    .container {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 350px);
      page-break-after: auto;
    }

    .note-content {
      flex: 1; /* Allows content to grow dynamically */
    }

    .footer {
      display: block;
      position: relative; /* Ensures footer appears at the bottom of the last page */
      text-align: center;
      margin-top: auto; /* Pushes footer to the bottom */
    }
  }

`;

export default PrintablePatientNote;

import { Box, Typography, useTheme } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import RemoveIcon from '@mui/icons-material/Remove';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { formatDateTime } from 'core/utils';
import { tokens } from 'theme/theme';
import { useMemo } from 'react';

export enum INVENTORY_TRANSACTION {
  PRODUCT_IN = 'Product In',
  PRODUCT_OUT = 'Product Out',
  PRODUCT_TRANSFER = 'Product Transfer',
  STOCK_ADJUSTMENT = 'Stock Adjustment',
  REVERSAL = 'Reversal',
}

type Props = {
  transaction: any;
  transaction_type: INVENTORY_TRANSACTION;
};

const TransactionHeader: React.FC<Props> = ({ transaction, transaction_type }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const transactionColor = useMemo(() => {
    switch (transaction_type) {
      case INVENTORY_TRANSACTION.PRODUCT_IN:
        return '#5f79e5';

      case INVENTORY_TRANSACTION.PRODUCT_OUT:
        return '#cc60e5';

      case INVENTORY_TRANSACTION.PRODUCT_TRANSFER:
        return '#23b662';

      case INVENTORY_TRANSACTION.STOCK_ADJUSTMENT:
        return '#ff7d5a';

      case INVENTORY_TRANSACTION.REVERSAL:
        return '#888888';
    }
    return undefined;
  }, [transaction_type]);

  const transactionIcon = useMemo(() => {
    switch (transaction_type) {
      case INVENTORY_TRANSACTION.PRODUCT_IN:
        return <AddIcon sx={{ color: 'white' }} />;

      case INVENTORY_TRANSACTION.PRODUCT_OUT:
        return <RemoveIcon sx={{ color: 'white' }} />;

      case INVENTORY_TRANSACTION.PRODUCT_TRANSFER:
        return <SyncAltIcon sx={{ color: 'white' }} />;

      case INVENTORY_TRANSACTION.STOCK_ADJUSTMENT:
        return <TuneOutlinedIcon sx={{ color: 'white' }} />;

      case INVENTORY_TRANSACTION.REVERSAL:
        return <ManageHistoryIcon sx={{ color: 'white' }} />;
    }
    return undefined;
  }, [transaction_type]);

  return (
    <Box
      width="280px"
      sx={{
        backgroundColor: colors.light_blue_background,
        borderRadius: '10px',
        padding: '15px 20px',
        display: 'flex',
        gap: '10px',
      }}
    >
      <Box display="flex" width="100%" gap="15px" alignItems={'center'}>
        <Box
          sx={{
            borderRadius: '50%',
            backgroundColor: transactionColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '40px',
            height: '40px',
          }}
        >
          {transactionIcon}
        </Box>
        <Box>
          <Typography color={transactionColor} fontWeight={'bold'}>
            {formatDateTime(transaction.transaction_date)}
          </Typography>
          <Typography fontSize={'10px'}>{transaction_type}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionHeader;

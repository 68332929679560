import { Box, Button, CircularProgress, SxProps, Theme } from '@mui/material';

import { CheckCircleOutline } from '@mui/icons-material';

type SubmitButtonProps = {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  width?: string;
  isSuccess?: boolean;
  id?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  submitButtonStyles?: SxProps<Theme>;
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: () => void;
};

const SubmitButton: React.FC<SubmitButtonProps> = ({
  label,
  loading,
  disabled,
  width,
  isSuccess,
  id,
  startIcon,
  endIcon,
  size,
  submitButtonStyles,
  variant = 'contained',
  onClick,
}) => {
  return isSuccess ? (
    <Box display="flex" alignItems="center" justifyContent="center">
      <CheckCircleOutline fontSize="large" sx={{ color: '#02ad2e' }} />
    </Box>
  ) : (
    <Button
      type="submit"
      variant={variant}
      disabled={disabled || loading || isSuccess}
      onClick={onClick}
      sx={{
        textTransform: 'none',
        borderRadius: 2,
        padding: size === 'small' ? '4px 25px' : '7px 25px',
        width: width,
        '@media screen and (max-width: 768px)': { width: '140px', height: '40px', padding: '0px' },
        ...submitButtonStyles,
      }}
      id={id}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
    >
      {!loading ? label || 'Submit' : <CircularProgress color="inherit" size="1.3rem" />}
    </Button>
  );
};

export default SubmitButton;

// import { Button, CircularProgress, useTheme } from '@mui/material';

// import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material'; // Import the check mark icon
// import React from 'react';
// import { tokens } from 'theme';

// type SubmitButtonProps = {
//   label?: string;
//   loading?: boolean;
//   disabled?: boolean;
//   width?: string;
//   isSuccess?: boolean;
//   onClick?: () => void;
// };

// const SubmitButton: React.FC<SubmitButtonProps> = ({
//   label,
//   loading,
//   disabled,
//   width,
//   isSuccess,
//   onClick,
// }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   return (
//     <Button
//       type="submit"
//       variant="contained"
//       disabled={disabled || loading || isSuccess}
//       sx={{
//         backgroundColor: isSuccess ? 'none' : colors.primary,
//         textTransform: 'none',
//         width: width ? width : '50px',
//         display: 'flex',
//         alignItems: 'center', // Center the icon and label vertically
//         justifyContent: 'center', // Center the icon and label horizontally
//         padding: '8px', // Adjust padding as needed
//       }}
//       onClick={onClick} // Pass the onClick handler
//     >
//       {loading ? (
//           <CircularProgress color="inherit" size={'1.3rem'} />
//       ) : isSuccess ? (
//       ) : (
//         label || 'Submit'
//       )}
//     </Button>
//   );
// };

// export default SubmitButton;

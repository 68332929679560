import { Box, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BackToTopButton from 'core/components/BackToTopButton';
import { CompanyFacilityModel } from 'company/model/Entities';
import Navbar from 'LandingPage/Navbar';
import SelfRegistrationForm from 'company/entities/modules/ClinicManagement/SelfRegistration/SelfRegistrationForm';
import SuccessModal from 'core/components/modals/SuccessModal';
import { getFacilityDetails } from 'core/api/public';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import styled from 'styled-components';

const SelfRegistrationPage = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [rawClinic, setRawClinic] = useState<CompanyFacilityModel>();
  const [visibleBackToTopButton, setVisibleBackToTopButton] = useState<boolean>(false);

  const isMobile = useMediaQuery('(max-width:768px)');

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const parentRef = useRef();
  const parentElement = parentRef.current as any;

  const handleAfterSubmit = (data: any) => {
    setOpenModal(true);
  };

  const handleBack = () => {
    setOpenModal(false);
    navigate(0);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (parentElement.scrollTop > 100) {
        setVisibleBackToTopButton(true);
      } else {
        setVisibleBackToTopButton(false);
      }
    };

    if (parentElement) {
      parentElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (parentElement) parentElement.removeEventListener('scroll', handleScroll);
    };
  }, [parentElement]);

  const messageComponent = (
    <>
      <MainLine> Thank You for Registering! </MainLine>
      <br />
      <SecondaryLine>
        Your registration has been successfully submitted. We are reviewing your information, and you will receive a
        confirmation soon.
        <br />
        <br />
        {rawClinic?.email && rawClinic?.facility_name ? (
          <>
            If you have any urgent inquiries, please feel free to reach out to us at {rawClinic.email}. <br /> <br />
            Thank you for choosing {rawClinic.facility_name}. We look forward to seeing you!
          </>
        ) : (
          <>
            If you have any urgent inquiries, please contact the facility directly using the contact information
            provided on their website. <br /> <br />
            Thank you for your registration. We look forward to serving you soon!
          </>
        )}
      </SecondaryLine>
    </>
  );

  useEffect(() => {
    getFacilityDetails(Number(id))
      .then((response) => {
        setRawClinic(response.data);
      })
      .catch((error) => console.error('Error fetching facility data:', error));
  }, [id]);

  return (
    <Box pt={8}>
      {isMobile ? (
        <Box width={'100vw'}>
          <Box ref={parentRef} sx={{ width: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Navbar />
            <MobileContainer>
              <MobileWelcomeRow>
                <Typography variant="h3" fontWeight="600" color={'#ffffff'} mb={'8px'}>
                  Welcome to Self Registration
                </Typography>
                <Typography variant="h5" color={'#ffffff'}>
                  Please fill out the form to get started.
                </Typography>
              </MobileWelcomeRow>
              <MobileFormRow>
                <MobileFormContainer>
                  <SelfRegistrationForm
                    facilityId={Number(id)}
                    callbackAfterSubmit={handleAfterSubmit}
                    company_id={rawClinic?.company_id}
                  />
                </MobileFormContainer>
              </MobileFormRow>
            </MobileContainer>
            <SuccessModal
              openModal={openModal}
              setOpenModal={setOpenModal}
              successMessageComponent={messageComponent}
              handleOkayCallback={handleBack}
            />
          </Box>
          {/* not working backtotop */}
          <BackToTopButton
            parentRef={parentRef}
            visible={visibleBackToTopButton}
            position={{ bottom: 100, right: 45 }}
          />
        </Box>
      ) : (
        <FullHeightBox>
          <Navbar />
          <FullContainer>
            <BackgroundSection>
              <IllustrationWrapper>
                <TextContent>
                  <Typography variant="h1" fontWeight="600">
                    Patient Self-Registration
                  </Typography>
                  <Box>
                    <Typography variant="h3" fontWeight="600" color={'#ffffff'} mb={'4px'}>
                      {rawClinic?.facility_name}
                    </Typography>
                    {rawClinic?.address && rawClinic?.municipality_name && rawClinic?.province_name && (
                      <Typography
                        variant="h6"
                        fontWeight="400"
                        color={'#ffffff'}
                        mb={'4px'}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: '4px' }}
                      >
                        <HomeIcon sx={{ fontSize: '16px' }} />
                        {`${rawClinic?.address}, ${rawClinic?.municipality_name}, ${rawClinic?.province_name}`}
                      </Typography>
                    )}
                    {rawClinic?.email && (
                      <Typography
                        variant="h6"
                        fontWeight="400"
                        color={'#ffffff'}
                        mb={'4px'}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: '4px' }}
                      >
                        <EmailIcon sx={{ fontSize: '16px' }} /> {`${rawClinic?.email}`}
                      </Typography>
                    )}
                    <Box>
                      {rawClinic?.globe_mobile_number && (
                        <Typography
                          variant="h6"
                          fontWeight="400"
                          color={'#ffffff'}
                          mb={'4px'}
                          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: '4px' }}
                        >
                          <PhoneIcon sx={{ fontSize: '16px' }} />
                          {`${rawClinic?.globe_mobile_number}`}
                        </Typography>
                      )}
                      {rawClinic?.smart_mobile_number && (
                        <Typography
                          variant="h6"
                          fontWeight="400"
                          color={'#ffffff'}
                          mb={'4px'}
                          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: '4px' }}
                        >
                          {' '}
                          <PhoneIcon sx={{ fontSize: '16px' }} />
                          {`${rawClinic?.smart_mobile_number}`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Typography>Please fill out the form to get started</Typography>

                  <img src="/assets/Signup/ILLUSTRATION.png" alt="" style={{ width: '375px' }} />
                </TextContent>
              </IllustrationWrapper>
            </BackgroundSection>

            <FormSection>
              <FormWrapper>
                <SelfRegistrationForm
                  facilityId={Number(id)}
                  callbackAfterSubmit={handleAfterSubmit}
                  company_id={rawClinic?.company_id}
                />
              </FormWrapper>
            </FormSection>
          </FullContainer>

          <SuccessModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            successMessageComponent={messageComponent}
            handleOkayCallback={handleBack}
          />
        </FullHeightBox>
      )}
    </Box>
  );
};

export default SelfRegistrationPage;

const MobileBoxVertical = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MobileContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MobileWelcomeRow = styled.div`
  padding: 1rem;
  margin: 1rem 1rem 0 1rem;

  text-align: center;
  background-image: url('/assets/Signup/BACKGROUND.png');
  background-size: cover;
`;

const MobileFormRow = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1rem 1rem 1rem;
  overflow-y: auto;
`;

const MobileFormContainer = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: white;
  // border-radius: 0.5rem;
  // // border-top-left-radius: 0;
  // // border-top-right-radius: 0;
  border: 2px solid #0666b0;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-image: linear-gradient(to right, #3699cd 0%, #0464af 100%) 1;
  border-width: 0px 2px 2px 2px;
  border-style: solid;
`;

const MainLine = styled(Typography)`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`;

const SecondaryLine = styled(Typography)`
  font-weight: regular;
  font-size: 14px;
  display: inline;
  text-align: center;
`;

const FullHeightBox = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const FullContainer = styled.div`
  width: 100vw;
  padding: 0px 32px 24px 32px;
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const BackgroundSection = styled.div`
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IllustrationWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/assets/Signup/BACKGROUND.png');
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 2.5rem;
  gap: 2rem; /* Increased gap between main sections */
  width: 100%;
  max-width: 500px;
`;

const ClinicInfoCard = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  border-radius: 16px;
  padding: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
`;

const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
`;

const FormSection = styled.div`
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: white;
  border-radius: 0.5rem;
  border: 2px solid #93c5fd;
  overflow-y: auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

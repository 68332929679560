import * as yup from 'yup';

import { EntityFields } from '../../../utils';

export const PNPT_ENTITY_NAME = 'Patient Note Print Template';

export const createPatientNotePrintTemplate = yup.object().shape({
  template_name: yup
    .string()
    .required('Template Name is required.')
    .max(100, 'Template Name must be less than or equal to 100 characters'),
  template_code: yup
    .string()
    .required('Template Code is required.')
    .max(100, 'Template Code must be less than or equal to 100 characters'),
  description: yup.string().max(500, 'Description must be less than or equal to 500 characters'),
});

export interface PatientNotePrintTemplateModel {
  id: number;
  template_name: string;
  template_code: string;
  description: string;
  file_name: string;
}

export interface PatientNotePrintTemplateInput {
  template_name: string;
  template_code: string;
  description: string;
}

export const patientNotePrintTemplateInitialValues: PatientNotePrintTemplateInput = {
  template_name: '',
  template_code: '',
  description: '',
};

export const patientNotePrintTemplateFields: EntityFields[] = [
  { fieldName: 'template_name', displayName: 'Template Name', span: 4 },
  { fieldName: 'template_code', displayName: 'Template Code', span: 4 },
  { fieldName: 'description', displayName: 'Description', multiline: true, rows: 3, span: 4, optional: true },
];

// // Table
export const patientNotePrintTemplateTableColumns: string[] = ['template_name', 'template_code', 'description'];

// // Forms
export const patientNotePrintTemplateFormFields: string[] = ['template_name', 'template_code', 'description'];

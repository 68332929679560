import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { PatientNoteModel } from 'company/model/Entities';

export const medical_history = [
  'Tuberculosis',
  'Diabetes Mellitus',
  'Cancer or Tumor',
  'Asthma',
  'Thyroid Disorders',
  'Gastritis /Ulcer',
  'Pnuemonia',
  'Eye and Ear Disorders',
  'Kidney & Liver Disease',
  'Hypertension',
  'Nose and Throat Disorders',
  'Seizure Disorders',
  'Heart Disease',
  'Skin Disease',
];
export const medical_history2 = [
  'Present Symptoms',
  'Other Past Medical History',
  'Family Medical History',
  'Operations and Accidents',
  'Allergies',
  'OB & Menstrual History',
  'OB Score',
  'Smoking History',
  'If "Smoker" is selected, indicate duration',
  'Alcohol Consumption',
  'Other Present Symptoms',
];
export const vital_signs = [
  "Height (ft' in)",
  'Height (m)',
  'Weight (lbs)',
  'BMI',
  'BMI Class',
  'Blood Pressure (mmHg)',
  'Pulse Rate (per min)',
  'Respiratory Rate (per min)',
  'Temperature (°C)',
  'Blood Pressure Class',
  'Weight (kg)',
];
export const eye_test = ['Eye Test', 'OD', 'OS', 'OU', 'Color Vision (Ishihara)'];
export const dental_exam = [
  'For Extraction',
  'For Restoration',
  'For Oral Prophylaxis',
  'Missing',
  'Dentures',
  'Others',
  'Dental Caries',
  'Severe Tooth Decay',
  'Non Restorable Tooth',
];
export const physical_exam = [
  'Head/Scalp',
  'Eyes',
  'Ears',
  'Nose/Sinuses',
  'Mouth/Throat',
  'Neck/Thyroid',
  'Chest/Breast',
  'Lungs',
  'Heart',
  'Abdomen',
  'Muscoloskeletal',
  'Anus',
  'Genitals',
  'Extremities',
  'Skin',
];
export const malvar_physical_exam = ['Anus/Rectum'];

export const hematology = [
  'Hemoglobin',
  'Hematocrit',
  'RBC Count',
  'WBC Count',
  'Platelet Count',
  // 'Segmenters',
  // 'Lymphocytes',
  // 'Monocytes',
  // 'Eosinophils',
  // 'Basophils',
];

export const clinical_chemistry = [
  'Blood Uric Acid (BUA)',
  'Creatinine',
  'SGOT / AST',
  'SGPT / ALT',
  'Cholesterol',
  'Triglycerides',
  'Low Density Lipoprotein (LDL)',
  'Fasting Blood Sugar (FBS)',
];

export const differential_count = ['Segmenters', 'Lymphocytes', 'Monocytes', 'Eosinophils', 'Basophils'];

export const hematology_normal_values = [
  '(M) 133 - 180 g|L\n(F) 123 - 157 g|L',
  '(M) 0.40 - 0.54%\n(F) 0.37 - 0.47%',
  '3.8 - 5.8 x 10^12/L',
  '5 - 10 x 10^12/L',
  '150 - 450 / cumm',
  // '0.35 - 0.65',
  // '0.25 - 0.45',
  // '0.0 - 0.10',
  // '0.0 - 0.03',
  // '0.0 - 0.01',
];

export const differential_count_normal_values = [
  '0.35 - 0.65',
  '0.25 - 0.45',
  '0.0 - 0.08',
  '0.0 - 0.03',
  '0.0 - 0.01',
];

export const urinalysis = [
  'Color',
  'Transparency',
  'pH',
  'Specific Gravity',
  'Protein',
  'Sugar',
  'WBC',
  'RBC',
  'Epithelial Cells',
  'Mucus Threads',
  'Bacteria',
  'Crystals',
];

export const fecalysis = ['Color', 'Consistency', 'WBC', 'RBC', 'Parasites', 'Parasite Seen'];
export const xray = ['RESULT', 'IMPRESSION', 'X-RAY VIEW', 'SIGNIFICANT FINDINGS', 'RECOMMENDATION'];
export const other_tests = [
  'Drug Test',
  'Blood Chemistry',
  'ECG Findings',
  'Audiometry Findings',
  'Other ECG Findings',
  'Pregnancy Test',
];

export const medical_history_index = [1, 6, 11, 2, 7, 12, 3, 8, 13, 4, 9, 14, 5, 10, 15];
export const medical_history_mapping = medical_history.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Medical History',
    source_type: 'table',
    table_name: '',
    table_column_name: 'Past Medical History',
    table_column_name_answer: 'Yes or No',
    more_info: '',
  };
  return acc;
}, {});

export const medical_history_mapping2 = medical_history2.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Medical History',
  };
  return acc;
}, {});

export const vital_signs_mapping = vital_signs.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Vital Signs',
  };
  return acc;
}, {});

export const eye_test_mapping = eye_test.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Eye Test',
  };
  return acc;
}, {});

export const dental_exam_mapping = dental_exam.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Dental Exam',
  };
  return acc;
}, {});

export const fecalysis_mapping = fecalysis.reduce((acc: any, condition) => {
  acc['F_' + condition] = {
    template_name: 'Fecalysis',
  };
  acc['Remarks'] = {
    template_name: 'Fecalysis',
  };
  return acc;
}, {});

export const physical_exam_mapping = physical_exam.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Physical Exam',
    source_type: 'table',
    table_name: '',
    table_column_name: 'Physical Exam',
    table_column_name_answer: 'Findings',
  };
  return acc;
}, {});

export const malvar_physical_exam_mapping = malvar_physical_exam.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Physical Exam',
    source_type: 'table',
    table_name: '',
    table_column_name: 'Physical Exam',
    table_column_name_answer: 'Findings',
  };
  return acc;
}, {});

export const hematology_mapping = hematology.reduce(
  (acc: any, condition) => {
    acc[condition] = {
      template_name: 'Hematology',
      source_type: 'table',
      table_name: '',
      table_column_name: 'Test',
      table_column_name_answer: 'Result',
    };
    return acc;
  },
  {
    'Blood Typing': {
      template_name: 'Hematology',
    },
    'RH Typing': {
      template_name: 'Hematology',
    },
  }
);

export const clinical_chemistry_mapping = clinical_chemistry.reduce(
  (acc: any, condition) => {
    acc[condition] = {
      template_name: 'Clinical Chemistry',
      source_type: 'table',
      table_name: '',
      table_column_name: 'Test',
      table_column_name_answer: 'Level',
    };
    return acc;
  },
  {
    CC_Remarks: {
      template_name: 'Clinical Chemistry',
    },
    'CC_Remarks (Significant Findings based on Result)': {
      template_name: 'Clinical Chemistry',
    },
  }
);

export const differential_count_mapping = differential_count.reduce((acc: any, condition) => {
  acc['DC_' + condition] = {
    template_name: 'Hematology',
    source_type: 'table',
    table_name: 'Differential Count',
    table_column_name: 'Test',
    table_column_name_answer: 'Result',
  };
  return acc;
}, {});

export const urinalysis_mapping = urinalysis.reduce(
  (acc: any, condition) => {
    acc['U_' + condition] = {
      template_name: 'Urinalysis',
    };
    return acc;
  },
  {
    'Remarks (Significant Findings based on Result)': {
      template_name: 'Urinalysis',
    },
    'With Menstruation': {
      template_name: 'Urinalysis',
    },
  }
);

export const other_tests_mapping = other_tests.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Other Tests Findings',
  };
  return acc;
}, {});

export const xray_mapping = xray.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Radiologic Findings',
  };
  return acc;
}, {});

export const summary_mapping = {
  ...medical_history_mapping,
  ...medical_history_mapping2,
  ...vital_signs_mapping,
  ...eye_test_mapping,
  ...dental_exam_mapping,
  ...physical_exam_mapping,
  ...malvar_physical_exam_mapping,
  ...hematology_mapping,
  ...differential_count_mapping,
  ...urinalysis_mapping,
  ...fecalysis_mapping,
  ...other_tests_mapping,
  ...xray_mapping,
  ...clinical_chemistry_mapping,

  'Hepatitis B Result': {
    template_name: 'Hepatitis B Screening (HBsAg)',
  },
  'Hepatitis A Result': {
    template_name: 'Hepatitis A Screening (HAV IgM)',
  },
};

export const getAnswer = (
  field: string,
  notes: PatientNoteModel[],
  defaultValue?: string,
  occurence?: number,
  prefix?: string,
  columns?: string[]
): string => {
  const defValue = defaultValue ?? '-';
  const defOccurence = occurence ?? 1;
  const source_mapping = summary_mapping as any;
  const fieldKey = prefix ? prefix + field : field;
  const mapping = source_mapping[fieldKey];

  if (mapping) {
    const note = notes.find(
      (note) =>
        note.header.toLowerCase().includes(mapping.template_name.toLowerCase()) ||
        (mapping.template_id && note.template_id === mapping.template_id)
    );

    if (note) {
      const body = note.template_body[0];
      const responses = note.responses;
      if (body) {
        if (mapping.source_type === 'table') {
          const tableField = body.fields.find(
            (field: any) =>
              (mapping.table_name && field.display_name === mapping.table_name) ||
              (!mapping.table_name && field.type === 'table')
          );

          if (tableField) {
            const questionColumn = tableField.columns.find(
              (column: any) => column.displayName === mapping.table_column_name
            );

            const rowResponses = responses[tableField.field_name];
            if (rowResponses) {
              const response = rowResponses.find((response: any) => {
                return response[questionColumn.fieldName] === field;
              });

              if (response) {
                if (columns && columns.length > 0) {
                  // Format columns into a string for easy display
                  return columns
                    .map((columnName) => {
                      const column = tableField.columns.find((col: any) => col.displayName === columnName);
                      return response[column?.fieldName] ? `${columnName}: ${response[column?.fieldName]}` : defValue;
                    })
                    .join(', ');
                } else {
                  // Default behavior: return only the main answer column
                  const answerColumn = tableField.columns.find(
                    (column: any) => column.displayName === mapping.table_column_name_answer
                  );
                  return response[answerColumn?.fieldName] || defValue;
                }
              }
            }
          }
        } else {
          let count = 0;
          const formField = body.fields.find((item: any) => {
            if (item.display_name?.trim() === field?.trim() && !item.section_header) {
              count++;
              return defOccurence === count;
            }
            return false;
          });

          return responses[formField?.field_name] || defValue;
        }
      }
    }
  }

  return defValue;
};

export enum APE_FIELD {
  BMI = 'BMI', // Body Mass Index
  BP_CLASS = 'BP_CLASS', // Blood Pressure Classification
  SMOKING_HISTORY = 'SMOKING_HISTORY',
  PRESENT_SYMPTOMS = 'PRESENT_SYMPTOMS',

  // Physical Exam (PE)
  PE_HEAD = 'PE_HEAD',
  PE_EYES = 'PE_EYES',
  PE_EARS = 'PE_EARS',
  PE_NOSE = 'PE_NOSE',
  PE_MOUTH = 'PE_MOUTH',
  PE_NECK = 'PE_NECK',
  PE_BREAST = 'PE_BREAST',
  PE_LUNGS = 'PE_LUNGS',
  PE_HEART = 'PE_HEART',
  PE_ABDOMEN = 'PE_ABDOMEN',
  PE_MUSCOLOSKELETAL = 'PE_MUSCOLOSKELETAL',
  PE_ANUS = 'PE_ANUS',
  PE_GENITALS = 'PE_GENITALS',
  PE_EXTREMITIES = 'PE_EXTREMITIES',
  PE_SKIN = 'PE_SKIN',

  // Visual and Hearing Tests
  VISUAL_ACUITY = 'VISUAL_ACUITY',
  ISHIHARA = 'ISHIHARA',
  AUDIOMETRY = 'AUDIOMETRY',

  // Laboratory Tests
  URINALYSIS_SUGAR = 'URINALYSIS_SUGAR',
  URINALYSIS_PROTEIN = 'URINALYSIS_PROTEIN',
  URINALYSIS_PROTEIN_RBC = 'URINALYSIS_PROTEIN_RBC',
  URINALYSIS_WBC = 'URINALYSIS_WBC',
  URINALYSIS_RBC = 'URINALYSIS_RBC',
  CBC = 'CBC', // Complete Blood Count

  // Imaging and Diagnostic Tests
  CHEST_XRAY = 'CHEST_XRAY',
  ECG = 'ECG',

  // Clinical Chemistry (CC) Tests
  CC_BUA = 'CC_BUA', // Blood Uric Acid
  CC_CREATININE = 'CC_CREATININE',
  CC_SGOT = 'CC_SGOT',
  CC_SGPT = 'CC_SGPT',
  CC_CHOLESTEROL = 'CC_CHOLESTEROL',
  CC_TRIGLYCERIDE = 'CC_TRIGLYCERIDE',
  CC_LDL = 'CC_LDL', // Low-Density Lipoprotein
  CC_FBS = 'CC_FBS', // Fasting Blood Sugar

  // DENTAL
  DENTAL_EXTRACTION = 'For Extraction',
  DENTAL_RESTORATION = 'For Restoration',
  DENTAL_ORAL_PROPHYLAXIS = 'For Oral Prophylaxis',
  DENTAL_CARIES = 'Dental Caries',
  DENTAL_SEVERE_TOOTH_DECAY = 'Severe Tooth Decay',
  DENTAL_NON_RESTORABLE_TOOTH = 'Non Restorable Tooth',
  DENTAL_DENTURES = 'Dentures',

  // Others
  OTHERS = 'OTHERS',
  PREGNANT = 'PREGNANT',
  CLASS = 'CLASS',
  AGE = 'AGE',
  SEX = 'SEX',
}

type AutomatedFindingsField = {
  field: APE_FIELD;
  result: string;
  recommendation?: string;
  extra?: any;
};

const ecg_results_cardiology_evaluation = [
  'Ventricular Hypertrophy',
  'Ischemia',
  'Infarct',
  'Atrial enlargement',
  'Atrial fibrillation',
  'Atrial rhythm',
];

const ecg_results_yearly_monitoring = [
  'Sinus Arrythmia',
  'Complete right bundle branch block',
  'Incomplete left bundle branch block',
  'Poor R wave',
  'Normal ST/T wave changes',
  'Left axis deviation',
  'Sinus rhythm',
  'Tachycardia',
  'Bradycardia',
  'Incomplete right bundle branch block',
];

export const getFindingsAndRecommendations = (fields: AutomatedFindingsField[], currentFindings: string): string[] => {
  const concatString = (currentValue: string, newValue: string, currentValue2: string, newValue2: string) => {
    if (!currentValue) return [newValue, newValue2];
    if (currentValue.toLowerCase().includes(newValue.toLocaleLowerCase())) return [currentValue, currentValue2];
    if (currentFindings.toLowerCase().includes(newValue.toLowerCase().trim())) return [currentValue, currentValue2];

    const value1 = currentValue + '\n' + newValue;
    const value2 = currentValue2 + '\n' + newValue2;
    return [value1, value2];
  };

  const defaultValue = ['', ''];
  if (!fields.length) return defaultValue;

  return fields.reduce((result: any, field) => {
    if (!field.result) return result;
    if (currentFindings.includes(field.result)) return result;
    const [findings, recommendations] = getFindings(field);

    return findings ? concatString(result[0], findings, result[1], recommendations) : result;
  }, defaultValue);
};

export const getFindings = (field: AutomatedFindingsField, actualResult?: boolean): [string, string, boolean] => {
  let findings = '';
  let recommendations = '';
  let noData = !field.result;

  switch (field.field) {
    case APE_FIELD.BMI:
      if (actualResult) {
        findings = field.result;
      } else if (field.result !== 'Normal') {
        findings = field.result;
        recommendations = getRecommendations(field);
      }
      break;

    case APE_FIELD.BP_CLASS:
      if (field.result.includes('HPN') || field.result === 'Hypotension') {
        findings = field.result;
        recommendations = getRecommendations(field);
      }
      break;

    case APE_FIELD.VISUAL_ACUITY:
      if (actualResult) {
        if (field.extra.with_corrective_lenses) findings = 'With corrective lenses';
        else findings = field.result;
      } else if (field.result && field.result !== 'Normal') {
        if (
          field.result === 'Error of refraction' &&
          field.extra.with_corrective_lenses &&
          field.extra.company_name?.toLowerCase().includes('epson')
        ) {
          findings = 'With corrective lenses';
          recommendations = 'N/A';
        } else {
          findings = field.result;
          recommendations = field.extra.with_corrective_lenses
            ? 'With corrective lenses'
            : 'Suggest ophthalmology consult';
        }
      }
      break;

    case APE_FIELD.ISHIHARA:
      if (field.result === 'Inadequate') {
        findings = 'Defective color perception';
        recommendations = 'Suggest ophthalmology consult';
      }
      break;

    case APE_FIELD.URINALYSIS_SUGAR:
      if (field.result !== 'Negative') {
        findings = 'Tc Diabetes Mellitus';
        recommendations = 'Suggest to undergo FBS and HBa1C';
      } else if (actualResult && field.result) {
        findings = 'Normal';
      }
      break;

    case APE_FIELD.URINALYSIS_PROTEIN_RBC:
      if (field.result) {
        findings = field.result;
        recommendations = 'Suggest to undergo BUN, creatinine, and nephorology consult';
      }
      break;

    case APE_FIELD.URINALYSIS_PROTEIN:
      if (field.result !== 'Negative' && field.extra.ckd !== 'Tc CKD') {
        findings = 'Proteinuria';
        recommendations = getRecommendations(field);
      } else if (actualResult && field.result) {
        findings = 'Normal';
      }
      break;

    case APE_FIELD.URINALYSIS_WBC:
      if (field.result) {
        const tooMany = isTooMany(field.result);
        let num1 = 0;
        let num2 = 0;
        const match = field.result.trim().match(/(\d+)\s*-\s*(\d+)/);
        if (match) {
          num1 = parseInt(match[1]);
          num2 = parseInt(match[2]);
        }

        if (num1 >= 12 || num2 >= 12 || tooMany) {
          findings = 'Pyuria';
          recommendations = 'Suggest increase oral fluid intake and repeat urinalysis';
        }
      }
      break;

    case APE_FIELD.URINALYSIS_RBC:
      if (field.result && field.extra.ckd !== 'Tc CKD') {
        const tooMany = isTooMany(field.result);
        let num1 = 0;
        const match = field.result.trim().match(/(\d+)\s*-\s*(\d+)/);
        if (match) {
          num1 = parseInt(match[1]);
        }

        if (num1 > 0 || tooMany) {
          if (field.extra.sex === 'Male') {
            findings = 'Hematuria';
            recommendations = 'Suggest KUB Ultrasound';
          } else {
            if (num1 >= 5 || tooMany) {
              findings = 'Hematuria';
              recommendations = 'Suggest increase oral fluid intake';
              if (field.extra.with_menstruation)
                recommendations = recommendations + ' and repeat urinalysis after menstruation';
            } else if (actualResult) {
              findings = 'Normal';
            }
          }
        } else if (actualResult) {
          findings = 'Normal';
        }
      }
      break;

    case APE_FIELD.CBC:
      if (field.extra) {
        const { hemoglobinLevel, hematocritLevel } = field.extra;
        if (hemoglobinLevel === 'Level: Low' && hematocritLevel === 'Level: Low') {
          findings = 'Tc Anemia';
          recommendations = 'Suggest iron supplementation and repeat CBC after a month';
        } else if (actualResult && hemoglobinLevel && hematocritLevel) {
          findings = 'Normal';
        }
        noData = !hemoglobinLevel && !hematocritLevel;
      }

      break;

    case APE_FIELD.PE_EARS:
      if (field.result) {
        if (field.result.toLowerCase().includes('perforated tm')) {
          findings = 'Perforated TM';
        } else if (field.result.toLowerCase().includes('impacted cerumen')) {
          findings = 'Impacted Cerumen';
        } else if (field.result.toLowerCase().includes('retained cerumen')) {
          findings = 'Retained Cerumen';
        } else if (field.result.toLowerCase().includes('mass')) {
          findings = 'Mass';
        } else if (field.result.toLowerCase().includes('pierce')) {
          findings = field.result;
        }
        recommendations = getRecommendations(field, findings);
      }
      if (actualResult && !findings) {
        findings = field.result || 'Normal';
      }
      break;

    case APE_FIELD.PE_BREAST:
      if (
        field.result &&
        (field.result.toLowerCase().includes('breast mass') || field.result.toLowerCase().includes('breast cyst'))
      ) {
        findings = 'Breast mass';
        recommendations = getRecommendations(field);
      }
      if (actualResult && !findings) {
        findings = field.result || 'Normal';
      }
      break;

    case APE_FIELD.PE_NECK:
      if (field.result && field.result.toLowerCase().includes('enlarged of anterior neck')) {
        findings = 'Tc Goiter';
        recommendations = 'Suggest ultrasound of anterior the neck, FT3, FT4, TSH and EENT consult';
      }
      if (actualResult && !findings) {
        findings = field.result || 'Normal';
      }
      break;

    case APE_FIELD.PE_ANUS:
      if (field.result) {
        if (field.result.toLowerCase().includes('skin tag') || field.result.toLowerCase().includes('anal tag')) {
          findings = 'Anal skin tag';
        } else if (field.result.toLowerCase().includes('hemorrhoid')) {
          findings = field.result;
        } else if (field.result.toLowerCase().includes('mass')) {
          findings = 'Mass';
        }
        recommendations = getRecommendations(field, findings);
      }
      if (actualResult && !findings) {
        findings = field.result || 'Normal';
      }
      break;

    case APE_FIELD.PE_LUNGS:
      if (field.result) {
        if (
          field.result.toLowerCase().includes('shortness of breath') ||
          field.result.toLowerCase().includes('decreased breath sound') ||
          field.result.toLowerCase().includes('wheezing')
        ) {
          findings = 'Upper Respiratory Tract infection';
        } else if (field.result.toLowerCase().includes('mass')) {
          findings = 'Mass';
        }
        recommendations = getRecommendations(field, findings);
      }
      if (actualResult && !findings) {
        findings = field.result || 'Normal';
      }
      break;

    case APE_FIELD.PE_HEART:
      if (field.result) {
        if (field.result.toLowerCase().includes('heart murmur')) {
          findings = 'Tc valvular heart disease ';
          recommendations = 'Suggest cardiology evaluation';
        }
      }
      if (actualResult && !findings) {
        findings = field.result || 'Normal';
      }
      break;

    case APE_FIELD.AUDIOMETRY:
      if (field.result && field.result.toLowerCase().includes('hearing loss')) {
        findings = 'Hearing loss';
        recommendations = 'Suggest EENT consult';
      }
      break;

    case APE_FIELD.CHEST_XRAY:
      if (field.result && field.result !== 'Normal' && field.result !== 'No X-ray') {
        findings = field.result;
        recommendations =
          field.recommendation && field.recommendation !== 'No recommendations' ? field.recommendation : '';
      } else if (actualResult && field.result === 'Normal') {
        findings = 'Normal';
      }
      break;

    case APE_FIELD.PRESENT_SYMPTOMS:
      if (
        field.result &&
        (field.result.toLowerCase().includes('cough') || field.result.toLowerCase().includes('colds'))
      ) {
        findings = 'Upper Respiratory Tract infection';
        recommendations = 'Suggest medical consult';
      }
      break;

    case APE_FIELD.ECG:
      if (actualResult) findings = field.result === 'N/A' ? 'Normal' : field.result;
      else if (ecg_results_cardiology_evaluation.includes(field.result.trim())) {
        findings = 'With ECG findings';
        recommendations = 'Suggest cardiology evaluation';
      } else if (ecg_results_yearly_monitoring.includes(field.result.trim())) {
        findings = 'With ECG findings';
        recommendations = 'Suggest yearly monitoring';
      }
      break;

    case APE_FIELD.CC_BUA:
      if (field.result === 'High') {
        findings = 'Hyperuricemia';
        recommendations = 'Suggest low purine diet and repeat BUN after 3 months';
      } else if (actualResult && field.result) {
        findings = 'Normal';
      }
      break;

    case APE_FIELD.CC_CREATININE:
      if (field.result === 'High') {
        findings = 'Tc kidney pathology';
        recommendations = 'Suggest low protein diet and repeat BUN and Creatinine after 2 weeks';
      } else if (actualResult && field.result) {
        findings = 'Normal';
      }
      break;

    case APE_FIELD.CC_SGOT:
    case APE_FIELD.CC_SGPT:
      if (field.result === 'High') {
        findings = 'Tc fatty liver';
        recommendations = 'Suggest whole abdomen ultrasound';
      } else if (actualResult && field.result) {
        findings = 'Normal';
      }
      break;

    case APE_FIELD.CC_CHOLESTEROL:
    case APE_FIELD.CC_TRIGLYCERIDE:
    case APE_FIELD.CC_LDL:
      if (field.result === 'High') {
        findings = 'Dyslipidemia';
        recommendations = 'Suggest diet modification and repeat blood chemistry after 3 months';
      } else if (actualResult && field.result) {
        findings = 'Normal';
      }
      break;

    case APE_FIELD.CC_FBS:
      if (field.result === 'High') {
        findings = 'Tc Diabetes Mellitus';
        recommendations = 'Suggest to undergo HBa1cf';
      } else if (actualResult && field.result) {
        findings = 'Normal';
      }
      break;

    case APE_FIELD.SMOKING_HISTORY:
      if (field.result === 'Smoker') {
        findings = field.result;
        recommendations = 'Suggest lifestyle modification';
      }
      break;

    case APE_FIELD.PE_SKIN:
      if (actualResult) {
        findings = field.result || 'Normal';
      } else if (field.result.toLowerCase().includes('tattoo')) {
        findings = field.result;
        recommendations = 'N/A';
      } else if (field.result.toLowerCase().includes('acne')) {
        findings = field.result;
        recommendations = 'Suggest dermatology consult';
      } else if (field.result.toLowerCase().includes('skin tag')) {
        findings = 'Skin tag';
        recommendations = 'Suggest high fiber diet';
      }
      break;

    case APE_FIELD.PE_HEAD:
    case APE_FIELD.PE_EYES:
    case APE_FIELD.PE_NOSE:
    case APE_FIELD.PE_MOUTH:
    case APE_FIELD.PE_ABDOMEN:
    case APE_FIELD.PE_MUSCOLOSKELETAL:
    case APE_FIELD.PE_GENITALS:
    case APE_FIELD.PE_EXTREMITIES:
      if (actualResult) {
        findings = field.result || 'Normal';
      }
      break;

    case APE_FIELD.DENTAL_CARIES:
    case APE_FIELD.DENTAL_DENTURES:
    case APE_FIELD.DENTAL_EXTRACTION:
    case APE_FIELD.DENTAL_NON_RESTORABLE_TOOTH:
    case APE_FIELD.DENTAL_ORAL_PROPHYLAXIS:
    case APE_FIELD.DENTAL_RESTORATION:
    case APE_FIELD.DENTAL_SEVERE_TOOTH_DECAY:
      if (actualResult) {
        findings = field.result === 'Yes' ? field.field : 'Normal';
      }
      break;
  }

  return [findings, recommendations, noData];
};

export const getRecommendations = (field: AutomatedFindingsField, findings?: string) => {
  switch (field.field) {
    case APE_FIELD.BMI:
      if (field.result.includes('Obese')) return 'Suggest diet and weight management';
      if (field.result === 'Overweight') return 'Suggest diet modification';
      if (field.result.includes('Underweight')) return 'Suggest increase caloric intake';
      return '';

    case APE_FIELD.BP_CLASS:
      if (field.result.includes('HPN')) {
        if (field.result === 'Pre-HPN') return 'Suggest BP Monitoring';
        else return 'Suggest BP Monitoring and cardiology evaluation';
      }
      if (field.result === 'Hypotension') return 'Suggest regular exercise and BP Monitoring';
      return '';

    case APE_FIELD.URINALYSIS_PROTEIN:
      if (field.result === 'Trace') return 'Suggest increase oral fluid intake and repeat Urinalysis';
      if (field.result) return 'Suggest to undergo BUN and creatinine';
      return '';

    case APE_FIELD.PE_EARS:
      if (findings === 'Perforated TM' || findings === 'Impacted Cerumen') return 'Suggest EENT consult';
      if (findings === 'Retained Cerumen') return 'Suggest proper hygiene';
      if (findings === 'Mass') return 'Suggest surgery consult';
      if (findings?.toLowerCase().includes('pierce')) return 'N/A';
      return '';

    case APE_FIELD.PE_BREAST:
      if (field.result.toLowerCase().includes('breast mass')) return 'Suggest breast ultrasound';
      if (field.result.toLowerCase().includes('breast cyst')) return 'Suggest Mammogram';
      return '';

    case APE_FIELD.PE_ANUS:
      if (findings === 'Anal skin tag') return 'Suggest high fiber diet';
      if (field.result.toLowerCase().includes('hemorrhoid')) {
        if (field.result.toLowerCase().includes('bleed') || field.result.toLowerCase().includes('blood'))
          return 'Suggest surgery consult';
        return 'Suggest high fiber diet';
      }
      if (findings === 'Mass') return 'Suggest surgery consult';
      return '';

    case APE_FIELD.PE_LUNGS:
      if (findings === 'Mass') return 'Suggest surgery consult';
      if (findings) return 'Suggest medical consult';
      return '';

    default:
      return '';
  }
};

export const isTooMany = (result: string) => {
  return result.toLowerCase().replaceAll(' ', '') === 'tntc' || result.replaceAll(' ', '').includes('>100');
};

export const getNoteResponses = (source: PatientNoteModel[], patient?: PatientModel, batchId?: number) => {
  const uncorrectedODStr = getAnswer(eye_test[1], source, '');
  const uncorrectedOSStr = getAnswer(eye_test[2], source, '');
  const correctedODStr = getAnswer(eye_test[1], source, '', 2);
  const correctedOSStr = getAnswer(eye_test[2], source, '', 2);
  const uncorrectedOD = parseInt(getAnswer(eye_test[1], source, '').replace('20/', ''));
  const correctedOD = parseInt(getAnswer(eye_test[1], source, '', 2).replace('20/', ''));
  const uncorrectedOS = parseInt(getAnswer(eye_test[2], source, '').replace('20/', ''));
  const correctedOS = parseInt(getAnswer(eye_test[2], source, '', 2).replace('20/', ''));
  const uncorrectedNV = parseInt(getAnswer(eye_test[3], source, '').replace('J', ''));
  const correctedNV = parseInt(getAnswer(eye_test[3], source, '', 2).replace('J', ''));
  let visualAcuity = '';
  const isErrorOfRefraction = (value1: number, value2: number, threshold: number) =>
    (!isNaN(value1) && value1 >= threshold) || (!isNaN(value2) && value2 >= threshold);

  if (uncorrectedODStr || uncorrectedOSStr || correctedODStr || correctedOSStr) {
    visualAcuity = 'Normal';
  }
  if (uncorrectedODStr.toLowerCase().includes('blind')) {
    visualAcuity = uncorrectedODStr;
  } else if (uncorrectedOSStr.toLowerCase().includes('blind')) {
    visualAcuity = uncorrectedODStr;
  } else if (
    batchId !== 86 &&
    (correctedOD ||
      correctedOS ||
      isErrorOfRefraction(correctedNV, uncorrectedNV, 5) ||
      (isNaN(correctedOD) && isNaN(correctedOS) && isErrorOfRefraction(uncorrectedOD, uncorrectedOS, 21)))
  ) {
    visualAcuity = 'Error of refraction';
  }
  const protein = getAnswer(urinalysis[4], source, '', 1, 'U_');
  const rbc = getAnswer(urinalysis[7], source, '', 1, 'U_');
  let ckd = '';
  if (patient?.sex === 'Male') {
    const tooMany = isTooMany(rbc);
    let num1 = 0;
    const match = rbc.trim().match(/(\d+)\s*-\s*(\d+)/);
    if (match) {
      num1 = match?.length ? parseInt(match[1]) : 0;
    }
    if (protein && protein !== 'Negative' && (num1 > 0 || tooMany)) {
      ckd = 'Tc CKD';
    }
  }

  return [
    { field: APE_FIELD.BMI, result: getAnswer(vital_signs[4], source, '') },
    { field: APE_FIELD.BP_CLASS, result: getAnswer(vital_signs[9], source, '') },
    {
      field: APE_FIELD.VISUAL_ACUITY,
      result: visualAcuity,
      extra: {
        with_corrective_lenses: !isNaN(correctedOD) && !isNaN(correctedOS) && correctedOD <= 20 && correctedOS <= 20,
        company_name: patient?.company_name,
      },
    },
    { field: APE_FIELD.ISHIHARA, result: getAnswer(eye_test[4], source, '') },
    { field: APE_FIELD.URINALYSIS_SUGAR, result: getAnswer(urinalysis[5], source, '', 1, 'U_') },
    { field: APE_FIELD.URINALYSIS_PROTEIN_RBC, result: ckd },
    { field: APE_FIELD.URINALYSIS_PROTEIN, result: protein, extra: { ckd } },
    {
      field: APE_FIELD.URINALYSIS_RBC,
      result: rbc,
      extra: { ckd, sex: patient?.sex, with_menstruation: getAnswer('With Menstruation', source, '') },
    },
    { field: APE_FIELD.URINALYSIS_WBC, result: getAnswer(urinalysis[6], source, '', 1, 'U_') },
    {
      field: APE_FIELD.CBC,
      result: 'cbc',
      extra: {
        hemoglobinLevel: getAnswer(hematology[0], source, '', 1, '', ['Level']),
        hematocritLevel: getAnswer(hematology[1], source, '', 1, '', ['Level']),
      },
    },
    { field: APE_FIELD.PE_HEAD, result: getAnswer(physical_exam[0], source, '') },
    { field: APE_FIELD.PE_EYES, result: getAnswer(physical_exam[1], source, '') },
    { field: APE_FIELD.PE_EARS, result: getAnswer(physical_exam[2], source, '') },
    { field: APE_FIELD.PE_NOSE, result: getAnswer(physical_exam[3], source, '') },
    { field: APE_FIELD.PE_MOUTH, result: getAnswer(physical_exam[4], source, '') },
    { field: APE_FIELD.PE_NECK, result: getAnswer(physical_exam[5], source, '') },
    { field: APE_FIELD.PE_BREAST, result: getAnswer(physical_exam[6], source, '') },
    { field: APE_FIELD.PE_LUNGS, result: getAnswer(physical_exam[7], source, '') },
    { field: APE_FIELD.PE_HEART, result: getAnswer(physical_exam[8], source, '') },
    { field: APE_FIELD.PE_ABDOMEN, result: getAnswer(physical_exam[9], source, '') },
    { field: APE_FIELD.PE_MUSCOLOSKELETAL, result: getAnswer(physical_exam[10], source, '') },
    {
      field: APE_FIELD.PE_ANUS,
      result: getAnswer(physical_exam[11], source, '') || getAnswer(malvar_physical_exam[0], source, ''),
    },
    { field: APE_FIELD.PE_GENITALS, result: getAnswer(physical_exam[12], source, '') },
    { field: APE_FIELD.PE_EXTREMITIES, result: getAnswer(physical_exam[13], source, '') },
    { field: APE_FIELD.PE_SKIN, result: getAnswer(physical_exam[14], source, '') },
    { field: APE_FIELD.AUDIOMETRY, result: getAnswer(other_tests[3], source, '') },
    {
      field: APE_FIELD.CHEST_XRAY,
      result: getAnswer(xray[3], source, ''),
      recommendation: getAnswer(xray[4], source, ''),
    },
    { field: APE_FIELD.PRESENT_SYMPTOMS, result: getAnswer(medical_history2[0], source, '') },
    { field: APE_FIELD.ECG, result: getAnswer(other_tests[2], source, '') },
    { field: APE_FIELD.CC_BUA, result: getAnswer(clinical_chemistry[0], source, '') },
    { field: APE_FIELD.CC_CREATININE, result: getAnswer(clinical_chemistry[1], source, '') },
    { field: APE_FIELD.CC_SGOT, result: getAnswer(clinical_chemistry[2], source, '') },
    { field: APE_FIELD.CC_SGPT, result: getAnswer(clinical_chemistry[3], source, '') },
    { field: APE_FIELD.CC_CHOLESTEROL, result: getAnswer(clinical_chemistry[4], source, '') },
    { field: APE_FIELD.CC_TRIGLYCERIDE, result: getAnswer(clinical_chemistry[5], source, '') },
    { field: APE_FIELD.CC_LDL, result: getAnswer(clinical_chemistry[6], source, '') },
    { field: APE_FIELD.CC_FBS, result: getAnswer(clinical_chemistry[7], source, '') },
    { field: APE_FIELD.SMOKING_HISTORY, result: getAnswer(medical_history2[7], source, '') },

    { field: APE_FIELD.DENTAL_EXTRACTION, result: getAnswer(dental_exam[0], source, '') },
    { field: APE_FIELD.DENTAL_RESTORATION, result: getAnswer(dental_exam[1], source, '') },
    { field: APE_FIELD.DENTAL_ORAL_PROPHYLAXIS, result: getAnswer(dental_exam[2], source, '') },
    { field: APE_FIELD.DENTAL_DENTURES, result: getAnswer(dental_exam[4], source, '') },
    { field: APE_FIELD.DENTAL_CARIES, result: getAnswer(dental_exam[6], source, '') },
    { field: APE_FIELD.DENTAL_SEVERE_TOOTH_DECAY, result: getAnswer(dental_exam[7], source, '') },
    { field: APE_FIELD.DENTAL_NON_RESTORABLE_TOOTH, result: getAnswer(dental_exam[8], source, '') },
  ];
};

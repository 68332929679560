import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getAllPatientAppointments, getAppointment } from 'company/api/appointments';
import { FacilityContext } from 'core/context/facility.context';
import { useContext } from 'react';
import { ApiQuery } from 'core/model/interface';

interface AppointmentSearchDropdownProps extends Omit<TemplateDropdownProps, 'getData' | 'getById'> {
  patientId: number;
}

const AppointmentSearchDropdown: React.FC<AppointmentSearchDropdownProps> = ({
  patientId,
  handleChangeCallback,
  initialValue,
  ...props
}) => {
  const { facility } = useContext(FacilityContext);

  const formatDateTime = (schedule: string) => {
    const date = new Date(schedule);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <TemplateDropdown
      key={patientId}
      {...props}
      disabled={!patientId}
      entity={'Appointment'}
      getData={(query: ApiQuery) => getAllPatientAppointments(facility.id, patientId, query)}
      getById={(id: number) => (id ? getAppointment(facility.id, id) : Promise.resolve(null))}
      processOption={(record) => ({
        key: `${formatDateTime(record.schedule)} - ${record.service_names}${
          record.invoice_id ? ` (Invoice #${record.invoice_id})` : ''
        }`,
        value: record.id,
        data: record,
      })}
      processNewRecord={(record) => record}
      handleChangeCallback={handleChangeCallback}
      initialValue={initialValue || null}
      displayName="Search Appointment"
      hideAddOption={true}
    />
  );
};

export default AppointmentSearchDropdown;

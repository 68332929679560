import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getCompanyFacilities, getFacility } from 'company/api/facility';
import { useEffect, useState } from 'react';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ClinicDetails from './ClinicDetails';
import ClinicForm from './ClinicForm';
import ClinicHealthPackages from './ClinicHealthPackages';
import ClinicServices from './ClinicServices';
import ClinicUserAccess from './ClinicUserAccess';
import { CompanyFacilityModel } from 'company/model/Entities';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DoctorsAssigned from './DoctorsAssigned';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { DropdownOptionProps } from 'core/components/Dropdown';
import { FacilityModel } from 'core/model/Entities';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import MedicationIcon from '@mui/icons-material/Medication';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import SectionDropdown from 'core/components/SectionDropdown';
import WeeklySchedule from '../WeeklySchedule';
import { getLanguages } from 'company/api/languages';
import { tokens } from 'theme/theme';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import ServiceProviderCalendar from 'company/components/ServiceProviderCalendar';

export enum CLINIC_SECTION {
  USER_ACCESS = 'User Access',
  SERVICES = 'Services',
  SERVICE_PROVIDERS = 'Service Provider',
  HEALTH_PACKAGE = 'Health Packages',
  WEEKLY_SCHED = 'Weekly Schedules',
}

const Clinics = () => {
  const [selectedClinic, setSelectedClinic] = useState<number>();
  const [rawClinic, setRawClinic] = useState<CompanyFacilityModel>();
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [refreshTable, setRefreshTable] = useState<number>(0);
  const [languages, setLanguages] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState<number>(0);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const [clinicSchedule, setClinicSchedule] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const sections = [
    {
      icon: <AdminPanelSettingsIcon />,
      label: CLINIC_SECTION.USER_ACCESS,
      content: rawClinic && <ClinicUserAccess clinic={rawClinic as CompanyFacilityModel} />,
    },
    {
      icon: <HomeRepairServiceIcon />,
      label: CLINIC_SECTION.SERVICES,
      content: rawClinic && <ClinicServices clinic={rawClinic as CompanyFacilityModel} />,
    },
    {
      icon: <MedicationIcon />,
      label: CLINIC_SECTION.SERVICE_PROVIDERS,
      content: rawClinic && <DoctorsAssigned clinic={rawClinic as CompanyFacilityModel} />,
    },
    {
      icon: <MedicalInformationIcon />,
      label: CLINIC_SECTION.HEALTH_PACKAGE,
      content: rawClinic && <ClinicHealthPackages clinic={rawClinic as CompanyFacilityModel} />,
    },
    {
      icon: <MonitorHeartIcon />,
      label: CLINIC_SECTION.WEEKLY_SCHED,
      content: rawClinic && <WeeklySchedule schedule={rawClinic.schedule} id={rawClinic.id} type="clinic" />,
    },
  ];

  const actions: DropdownOptionProps[] = [
    {
      icon: <DriveFileRenameOutlineIcon />,
      label: 'Update',
      action: (data: any) => {
        setOpenUpdateForm(true);
      },
    },
    // {
    //   icon: <DeleteOutlineIcon />,
    //   label: 'delete',
    //   color: 'error',
    //   action: () => {
    //   },
    // },
  ];

  const handleRender = function (item: CompanyFacilityModel) {
    return (
      <Box display="flex" flexDirection="column" gap="5px" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          color={item.id === selectedClinic && !isMobilePhone ? 'white' : colors.text}
        >
          <Typography>{item.facility_name}</Typography>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    selectedClinic &&
      getFacility(selectedClinic).then((response) => {
        setRawClinic(response.data);
      });
  }, [selectedClinic, refreshTable]);

  useEffect(() => {
    getLanguages().then((response) => {
      setLanguages(response.data.data);
    });
  }, []);

  return (
    <Box>
      {isMobilePhone && (
        <Typography sx={{ fontSize: '18px', color: colors.primary, fontWeight: '600' }}>List of Clinics</Typography>
      )}
      <InfiniteScrollContainer
        title="Clinics"
        urlKeyId="cid"
        selected={selectedClinic}
        setSelected={setSelectedClinic}
        getData={getCompanyFacilities}
        manualRefresh={refreshList}
        renderItem={handleRender}
        containerHeight="calc(100vh - 170px)"
        displaySelectedTitle={() => (
          <Typography variant="h3" fontWeight={700} color={colors.primary}>
            {!isMobilePhone ? rawClinic?.facility_name : 'Clinic'}
          </Typography>
        )}
        actions={!isMobilePhone ? actions : undefined}
      >
        {rawClinic && !isMobilePhone && rawClinic.banner_image && (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img
            src={rawClinic?.banner_image}
            height="120px"
            width="100%"
            style={{
              objectFit: 'cover',
              borderRadius: '15px',
            }}
          />
        )}
        <Box>
          <Box>
            {isMobilePhone && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '0 0 15px 0',
                }}
              >
                <Typography sx={{ fontSize: '22px', fontWeight: '600', color: colors.primary }}>
                  {rawClinic?.facility_name}
                </Typography>
                {/* <MoreHorizIcon sx={{ fontSize: '32px', color: colors.primary }} onClick={() => setOpenDialog(true)} /> */}
              </Box>
            )}
            <ClinicDetails clinic={rawClinic as CompanyFacilityModel} />
          </Box>
          <Box mt={'10px'}>
            <SectionDropdown optionList={sections} popupContainerStyle={{ padding: '10px' }} urlKeyId="section" />
          </Box>
        </Box>
      </InfiniteScrollContainer>
      <CustomModal header="Clinic Details Update" open={openUpdateForm} setOpen={setOpenUpdateForm}>
        {rawClinic && (
          <ClinicForm
            languages={languages}
            refreshTable={setRefreshTable}
            clinic={rawClinic as unknown as FacilityModel}
            callbackOnSuccess={(data: any) => {
              setOpenUpdateForm(false);
              setRefreshList((c) => c + 1);
            }}
          />
        )}
      </CustomModal>

      {isMobilePhone && (
        <CustomBottomPopover open={openDialog} setOpen={setOpenDialog} title="Actions">
          <RegularButton
            styles={{ marginTop: '20px' }}
            fullWidth
            variant="outlined"
            onClick={() => setOpenUpdateForm(true)}
            label="Update"
            startIcon={<DriveFileRenameOutlineIcon />}
          />
        </CustomBottomPopover>
      )}
    </Box>
  );
};

export default Clinics;

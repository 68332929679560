import { Box, Collapse, Typography, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore, ShoppingCartOutlined } from '@mui/icons-material';
// PrescriptionLineItem.tsx
import React, { useMemo, useState } from 'react';
import { formatCurrency, formatNumber } from 'core/utils';

import { RegularButton } from 'core/components';
import { tokens } from 'theme/theme';

interface Variant {
  variant_id: number;
  variant_name: string;
  variant_price: number;
  made_to_order: boolean;
  quantity_in_stock: number;
  facility_price: number;
  product_name: string;
  product_id: number;
}

interface MedicineItem {
  line_item_id: number;
  quantity: number;
  prescribed_date: Date;
  item_type: 'medicine';
  medicine_id: number;
  frequency: string;
  instruction: string;
  duration: string;
  brand_name: string;
  generic_name: string;
  dosage: string;
  medical_instructions: string;
  prescription_doctor_full_name: string;
  available_variants: Variant[];
}

interface VariantItemProps {
  variant: Variant;
  itemsInCart: number;
  onAddToCart: (variant: Variant) => void;
}

const VariantItem = ({ variant, itemsInCart, onAddToCart }: VariantItemProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const availableStock = variant.quantity_in_stock - itemsInCart;
  const isOutOfStock = !variant.made_to_order && availableStock <= 0;

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: `1px solid ${colors.border}`,
      }}
    >
      <Box flex={1}>
        <Typography variant="h5" fontWeight="500">
          {variant.variant_name || variant.product_name}
        </Typography>
        <Typography variant="caption">
          {variant.made_to_order ? 'Made to Order' : `Stock: ${formatNumber(availableStock, 0)}`}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Typography variant="body2" color="primary" fontWeight="500" sx={{ minWidth: '80px', textAlign: 'right' }}>
          {formatCurrency(variant.facility_price)}
        </Typography>
        <RegularButton
          startIcon={<ShoppingCartOutlined />}
          label={isOutOfStock ? 'Out of Stock' : 'Add to Cart'}
          size="small"
          onClick={() => onAddToCart(variant)}
          disabled={isOutOfStock}
        />
      </Box>
    </Box>
  );
};

const PrescriptionLineItem = ({
  item,
  addToCart,
  orders,
}: {
  item: MedicineItem;
  addToCart: (item: any) => void;
  orders: Array<{ item_id: number; item_type: string; quantity: number }>;
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [expanded, setExpanded] = useState(true);

  const itemsInCartByVariant = useMemo(() => {
    return item.available_variants.reduce(
      (acc, variant) => ({
        ...acc,
        [variant.variant_id]: orders
          .filter((order) => order.item_id === variant.variant_id && order.item_type === 'product')
          .reduce((sum, order) => sum + order.quantity, 0),
      }),
      {} as Record<number, number>
    );
  }, [orders, item.available_variants]);

  const handleAddToCart = (variant: Variant) => {
    const itemsInCart = itemsInCartByVariant[variant.variant_id] || 0;
    const availableStock = variant.quantity_in_stock - itemsInCart;

    if (variant.made_to_order || availableStock > 0) {
      addToCart({
        ...variant,
        type: 'product',
        id: variant.variant_id,
        product_id: variant.product_id,
        unit_price: variant.facility_price,
        product_name: variant.product_name + (variant.variant_name ? ` (${variant.variant_name})` : ''),
        prescription_line_item_id: item.line_item_id,
        quantity: item.quantity > availableStock ? availableStock : item.quantity,
        made_to_order: variant.made_to_order,
        quantity_in_stock: availableStock,
      });
    } else {
      console.error('Cannot add to cart - insufficient stock');
    }
  };

  return (
    <Box sx={{ backgroundColor: colors.light_blue_background, borderRadius: '12px', mb: 2, overflow: 'hidden' }}>
      <Box
        onClick={() => setExpanded(!expanded)}
        sx={{
          padding: '12px 15px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box>
          <Typography variant="h5" fontWeight="500">
            {item.generic_name} - {item.dosage}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
              Dr. {item.prescription_doctor_full_name} • Qty Needed: {item.quantity}
            </Typography>
            {item.instruction && (
              <Typography variant="body2" color="text.secondary">
                Doctor's Instructions: {item.instruction}
              </Typography>
            )}
          </Box>
        </Box>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </Box>

      <Collapse in={expanded}>
        <Box sx={{ borderTop: `1px solid ${colors.border}`, padding: '12px 15px' }}>
          <Typography variant="body2" color="text.secondary" mb={2}>
            Available in Inventory:
          </Typography>
          {item.available_variants.length > 0 ? (
            <Box display="flex" flexDirection="column" gap={1}>
              {item.available_variants.map((variant) => (
                <VariantItem
                  key={variant.variant_id}
                  variant={variant}
                  itemsInCart={itemsInCartByVariant[variant.variant_id] || 0}
                  onAddToCart={handleAddToCart}
                />
              ))}
            </Box>
          ) : (
            <Typography textAlign="start" color="text.secondary">
              No variants found in inventory
            </Typography>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default PrescriptionLineItem;

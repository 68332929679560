import { Box, List, ListItem, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { CustomModal, RegularButton } from 'core/components';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';
import { FacilityContext } from 'core/context/facility.context';
import { importInventories } from 'company/api/inventories';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  callbackAfterImport?: () => void;
};

const ImportProductVariantModal: React.FC<Props> = ({ open, setOpen, callbackAfterImport }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [file, setFile] = useState<File>();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { facility } = useContext(FacilityContext);

  const handleImportProductVariants = async () => {
    setLoading(true);
    if (facility) {
      try {
        await importInventories(file, facility.id);
        enqueueSnackbar(`Product variants successfully imported!`, { variant: 'success' });
        setOpen(false);
        callbackAfterImport && callbackAfterImport();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFile(e.target.files[0]);
  };

  useEffect(() => setFile(undefined), [open]);

  return (
    <>
      <CustomModal header="Import Product Variants" open={open} setOpen={setOpen}>
        <Box>
          <Typography variant="h5"> Instructions: </Typography>

          <List dense>
            <ListItem>
              <Typography>
                1. Download the Excel template
                <a
                  href={process.env.PUBLIC_URL + '/template/inventory_import_template.xlsx'}
                  download="inventory_import_template.xlsx"
                  style={{ color: colors.accent, paddingLeft: '5px' }}
                >
                  here
                </a>
                .
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>2. Required base columns: Product, Price, Stock, Tax, Product Cost, Made To Order</Typography>
            </ListItem>
            <ListItem>
              <Typography>
                3. Optional base columns: Category, SKU, Supplier, Description, Additional Attribute Columns
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                4. After Description column, you can add custom attribute columns: - Column headers should be the
                attribute names (e.g., "Color", "Size") - Cell values will be the attribute values (e.g., "Red",
                "Large") - If an attribute name doesn't exist, it will be created - If an attribute value doesn't exist
                for that attribute, it will be created
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>5. Tax only accepts "vat" or "non-vat"</Typography>
            </ListItem>
            <ListItem>
              <Typography>6. Made To Order accepts "true" or "false"</Typography>
            </ListItem>
          </List>

          <Typography variant="h5" mb="16px">
            Note: You can import up to 500 product variants per Excel file.
          </Typography>
        </Box>
        <Box marginTop={'10px'} display="grid" gridTemplateColumns="1fr 1fr">
          <Box>
            <Typography variant="h5"> Select file here: </Typography>
            <Box margin={'10px'}>
              <input type="file" onChange={handleFileChange} accept=".xlsx" />
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="end">
          <RegularButton label={'Import'} onClick={handleImportProductVariants} loading={loading} />
        </Box>
      </CustomModal>
    </>
  );
};

export default ImportProductVariantModal;

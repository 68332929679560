import { IconButton, Box, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { tokens } from 'theme/theme';

interface NavigationButtonProps {
  direction: 'prev' | 'next';
  onClick: () => void;
}

const NavigationButton = ({ direction, onClick }: NavigationButtonProps) => {
  const [isPressed, setIsPressed] = useState(false);
  const isNext = direction === 'next';
  const Icon = isNext ? ChevronRight : ChevronLeft;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
      <IconButton
        onClick={onClick}
        onMouseDown={() => setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
        onMouseLeave={() => setIsPressed(false)}
        sx={{
          width: 42,
          height: 42,
          border: '2px solid',
          borderColor: colors.primary,
          color: isPressed ? 'white' : colors.primary,
          backgroundColor: isPressed ? colors.primary : 'transparent',
          transition: 'all 0.3s ease',
          '&:hover': {
            backgroundColor: isPressed ? colors.primary : 'rgba(25, 118, 210, 0.04)',
            borderColor: colors.primary,
          },
        }}
        aria-label={`${isNext ? 'Next' : 'Previous'} slide`}
      >
        <Icon sx={{ fontSize: 28 }} />
      </IconButton>
    </motion.div>
  );
};

interface NavigationButtonsProps {
  onPrev: () => void;
  onNext: () => void;
}

const NavigationButtons = ({ onPrev, onNext }: NavigationButtonsProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mt: 3 }}>
      <NavigationButton direction="prev" onClick={onPrev} />
      <NavigationButton direction="next" onClick={onNext} />
    </Box>
  );
};

export default NavigationButtons;

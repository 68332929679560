import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  CompanyProtectedComponent,
  ConfirmationDialog,
  CustomAnimatedDrawer,
  CustomForm,
  CustomModal,
  PrimaryButton,
  RegularButton,
} from 'core/components';
import {
  createBasicHealthInformation,
  getBasicHealthInformationByPatient,
} from 'company/api/patient-basic-health-info';
import {
  createBasicHealthInformationField,
  deleteBasicHealthInformationField,
  getBasicHealthInformationFields,
  updateBasicHealthInformationField,
} from 'company/api/patient-basic-health-info-field';
import { useContext, useEffect, useState } from 'react';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import { DropResult } from 'react-beautiful-dnd';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import HealthInfoFieldForm from './HealthInfoFieldForm';
import HealthInfoFieldList from './HealthInfoFieldList';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PatientField from '../PatientField';
import PatientHeader from 'company/screens/PatientManagement/components/PatientHeader';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { SIZES } from 'theme/constants';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { formatDate } from 'core/utils';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type PatientBasicHealthInformationProps = {
  patient: PatientModel;
  onUpdate: (id: number, data: PatientModel) => void;
  onDelete: (id: number) => void;
};

const PatientBasicHealthInformation: React.FC<PatientBasicHealthInformationProps> = ({
  patient,
  onUpdate,
  onDelete,
}) => {
  const { facility } = useContext(FacilityContext);
  const [fields, setFields] = useState<FieldInput[]>([]);
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [reorderedFields, setReorderedFields] = useState<any[]>([]);
  const [hiddenReorderedFields, setHiddenReorderedFields] = useState<any[]>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [fieldsLoading, setFieldsLoading] = useState<boolean>(false);

  const [companyId, setCompanyId] = useState<number>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddNewFieldOpen, setIsAddNewFieldOpen] = useState(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState(0);
  const [idToDelete, setIdToDelete] = useState('');
  const [deleteHidden, setDeleteHidden] = useState(false);
  const [isFormUpdated, setIsFormUpdated] = useState(false);

  const [openUpdateHealthInfoModal, setOpenUpdateHealthInfoModal] = useState<boolean>(false);
  const [openCustomizeMobileModal, setOpenCustomizeMobileModal] = useState<boolean>(false);
  const [openAction, setOpenAction] = useState<boolean>(false);

  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Inside your component
  const handleSubmit = async (data: any) => {
    const keys = Object.keys(data);
    const patientData = keys.map((key) => ({
      field_id: key,
      patient_id: patient.patient_id,
      value: data[key],
      company_id: companyId,
    }));

    try {
      await createBasicHealthInformation(facility.id, { data: JSON.stringify(patientData) });
      enqueueSnackbar('Health Information successfully saved!', { variant: 'success' });
      setReadOnly(true);
      refreshTable();
      setOpenUpdateHealthInfoModal(false);

      onUpdate(patient.patient_id, data);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error saving Health Information', { variant: 'error' });
    }
  };
  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  useEffect(() => {
    if (patient && facility) {
      getBasicHealthInformationByPatient(facility.id, patient.patient_id).then((fieldData) => {
        const initialData = {} as any;
        // eslint-disable-next-line array-callback-return
        fieldData.data.data.map((data: any) => {
          initialData[data.field_id as string] = data.value;
        });

        setInitialValues(initialData);
      });
      setFieldsLoading(true);
      getBasicHealthInformationFields({ order_by: 'order' }).then((result) => {
        setFields(processFields(result.data.data.filter((item: any) => !item.hidden)));
        setReorderedFields(
          result.data.data
            .filter((item: any) => !item.hidden)
            .map((field: any) => ({
              ...field,
              field_name: field.id.toString(),
              display_name: field.field_name,
              field_unit: field.field_unit,
              shown_in_self_reg_form: field.shown_in_self_reg_form,
            }))
        );

        setHiddenReorderedFields(
          result.data.data
            .filter((item: any) => item.hidden)
            .map((field: any) => ({
              ...field,
              field_name: field.id.toString(),
              display_name: field.field_name,
              field_unit: field.field_unit,
            }))
        );
        setCompanyId(result.data.data[0]?.company_id);
        setFieldsLoading(false);
      });
    }
  }, [refresh, facility, patient]);

  const processFields = (fields: any[]) => {
    return fields.map(
      (field: {
        field_type: any;
        id: any;
        field_name: string;
        display_name: string;
        field_options?: string[];
        field_unit?: string;
      }) =>
        ({
          field_name: field.id.toString(),
          display_name: (field.display_name ?? field.field_name) + (field.field_unit ? ` (${field.field_unit})` : ''),
          type: field.field_type === 'yes_no' ? 'radiogroup' : field.field_type,
          span: ['long_text', 'group_header'].includes(field.field_type) ? 4 : 2,
          multiline: field.field_type === 'long_text',
          rows: field.field_type === 'long_text' ? 3 : undefined,
          options:
            field.field_type === 'yes_no' || (field.field_options && field.field_options.length)
              ? field.field_type === 'yes_no'
                ? [
                    { key: 'Yes', value: 'yes' },
                    { key: 'No', value: 'no' },
                  ]
                : (Array.isArray(field!.field_options)
                    ? field!.field_options
                    : JSON.parse(field!.field_options + '')
                  )?.map((option: string) => ({ key: option, value: option }))
              : undefined,
        } as FieldInput)
    );
  };

  const handleSaveEditedField = (index: number, data: any, options: string[], hidden?: boolean) => {
    const fields = hidden ? [...hiddenReorderedFields] : [...reorderedFields];
    const final = { ...data, field_options: options.length ? JSON.stringify(options) : undefined };
    const updatedFields = fields.map((field, i) => (i === index ? { ...field, ...final } : field));

    const newList = [...updatedFields];
    if (hidden) {
      setHiddenReorderedFields(newList);
    } else {
      setReorderedFields(newList);
    }
    updateBasicHealthInformationField(parseInt(fields[index].field_name), final);
    onUpdate(patient.patient_id, data);
    setIsFormUpdated(true);
  };

  const handleDeleteOption = (indexToDelete: number, id: string, hidden?: boolean) => {
    setConfirmDeleteModalOpen(true);
    setIndexToDelete(indexToDelete);
    setIdToDelete(id);
    hidden === undefined || hidden === null ? setDeleteHidden(false) : setDeleteHidden(hidden);
  };

  const handleDeleteConfirmed = () => {
    const fields = deleteHidden ? [...hiddenReorderedFields] : [...reorderedFields];
    const updatedOptions = fields.filter((_, index) => index !== indexToDelete);
    const newList = [...updatedOptions];
    deleteHidden ? setHiddenReorderedFields(newList) : setReorderedFields(newList);
    deleteBasicHealthInformationField(parseInt(idToDelete));

    setIsFormUpdated(true);
  };

  const handleDragEnd = (result: DropResult, hidden?: boolean) => {
    if (!result.destination) return;
    const fields = hidden ? [...hiddenReorderedFields] : [...reorderedFields];
    const newFields = [...fields];
    const [movedField] = newFields.splice(result.source.index, 1);
    newFields.splice(result.destination.index, 0, movedField);

    if (result.source.index !== result.destination.index) {
      newFields.forEach((field, index) => {
        updateBasicHealthInformationField(parseInt(field.field_name), { position: index + 1 });
      });
    }

    if (hidden) {
      setHiddenReorderedFields(newFields);
    } else {
      setReorderedFields(newFields);
    }

    setIsFormUpdated(true);
  };

  const handleCustomizeClose = () => {
    if (isFormUpdated) {
      setFieldsLoading(true);

      getBasicHealthInformationFields({ order_by: 'order' }).then((result) => {
        setFields(processFields(result.data.data.filter((item: any) => !item.hidden)));
        setFieldsLoading(false);
      });
      setIsFormUpdated(false);
    }
  };

  const handleSaveNewField = async (data: any, options: string[]) => {
    setButtonLoading(true);
    setIsAddNewFieldOpen(false);

    try {
      await createBasicHealthInformationField({
        field_name: data.display_name,
        field_type: data.field_type,
        field_unit: data.field_unit,
        position: reorderedFields.length,
        field_options: options.length ? JSON.stringify(options) : undefined,
        shown_in_self_reg_form: data.shown_in_self_reg_form,
      });
      onUpdate(patient.patient_id, data);
      setIsFormUpdated(true);
    } finally {
      setButtonLoading(false);
    }

    refreshTable();
  };

  const handleAddNewField = () => {
    setIsAddNewFieldOpen(true);
  };

  const handleCancelAddNewField = () => {
    setIsAddNewFieldOpen(false);
  };

  const handleHideShowOption = (index: any, item: any, hidden?: boolean) => {
    const newValue = !hidden;

    if (hidden) {
      const newHiddenFields = hiddenReorderedFields.filter((_, i) => i !== index);
      setHiddenReorderedFields(newHiddenFields);
      const newFields = [...reorderedFields, { ...item, hidden: false }];
      setReorderedFields(newFields);
    } else {
      const newFields = reorderedFields.filter((_, i) => i !== index);
      setReorderedFields(newFields);
      const newHiddenFields = [...hiddenReorderedFields, { ...item, hidden: true }];
      setHiddenReorderedFields(newHiddenFields);
    }
    updateBasicHealthInformationField(parseInt(item.field_name), { hidden: newValue });

    reorderedFields.forEach((field, index) => {
      updateBasicHealthInformationField(parseInt(field.field_name), { position: index + 1 });
    });
    hiddenReorderedFields.forEach((field, index) => {
      updateBasicHealthInformationField(parseInt(field.field_name), { position: index + 1 });
    });
    setIsFormUpdated(true);
  };

  if (fieldsLoading)
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CustomLoadingIndicator />
      </Box>
    );
  if (isMobilePhone)
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '10px',
          }}
        >
          <Box sx={{ display: 'flex', gap: '10px', margin: '10px 0' }}>
            <AssignmentIndIcon sx={{ color: colors.accent }} />
            <Typography sx={{ fontWeight: '600', color: colors.dark_grey_text }}>Health Information</Typography>
          </Box>
          <MoreHorizIcon sx={{ fontSize: '20px', color: colors.accent }} onClick={() => setOpenAction(true)} />
        </Box>

        <Box width="100%">
          <>
            {readOnly ? (
              <>
                <Box gap="10px" style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                  {fields.map((field, index) =>
                    field.type === 'group_header' ? (
                      <Typography key={index} variant="h4" fontWeight={'600'} style={{ gridColumn: 'span 2' }}>
                        {field.display_name}
                      </Typography>
                    ) : (
                      <PatientField
                        key={index}
                        label={field.display_name!}
                        value={initialValues[field.field_name] ?? '-'}
                        sx={{ gridColumn: field.type === 'long_text' ? 'span 2' : undefined }}
                      />
                    )
                  )}
                </Box>
              </>
            ) : (
              <>
                <CustomForm
                  onSubmit={handleSubmit}
                  fields={fields}
                  initialValues={initialValues}
                  loading={false}
                  submitButtonText="Save"
                  showSubmitAtTop
                />
              </>
            )}
          </>
        </Box>

        <CustomBottomPopover open={openAction} title="Actions" setOpen={setOpenAction}>
          <Box
            display="flex"
            gap={SIZES.paddingS}
            alignItems="center"
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1rem 0.5rem',
              paddingTop: '2rem',
            }}
          >
            {readOnly && (
              <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENT_INFO']}>
                <RegularButton
                  size="small"
                  variant="outlined"
                  label="Update Information"
                  onClick={() => setOpenUpdateHealthInfoModal(true)}
                  startIcon={<EditOutlinedIcon />}
                  fullWidth
                  styles={{ border: 'none', backgroundColor: colors.light_blue_background, fontWeight: '400' }}
                />
                <RegularButton
                  size="small"
                  variant="outlined"
                  label="Customize "
                  onClick={() => setOpenCustomizeMobileModal(true)}
                  startIcon={<TuneOutlinedIcon />}
                  fullWidth
                  styles={{ border: 'none', backgroundColor: colors.light_blue_background, fontWeight: '400' }}
                />
              </CompanyProtectedComponent>
            )}
          </Box>
        </CustomBottomPopover>

        <CustomModal
          header="Update Health Information"
          open={openUpdateHealthInfoModal}
          setOpen={setOpenUpdateHealthInfoModal}
        >
          {/* <PatientForm
          facility={facility}
          patient={updateData}
          callbackAfterSubmit={(data) => {
            setOpenUpdateModal(false);
            onUpdate(patient.patient_id, data);
          }}
        /> */}
          <CustomForm
            onSubmit={handleSubmit}
            fields={fields}
            initialValues={initialValues}
            loading={false}
            submitButtonText="Save"
            submitButtonStyles={{ flex: 2 }}
          />
        </CustomModal>

        <CustomModal
          header="Customize Fields"
          open={openCustomizeMobileModal}
          setOpen={setOpenCustomizeMobileModal}
          onClose={handleCustomizeClose}
        >
          {/* <PatientForm
          facility={facility}
          patient={updateData}
          callbackAfterSubmit={(data) => {
            setOpenUpdateModal(false);
            onUpdate(patient.patient_id, data);
          }}
        /> */}
          <Box>
            {reorderedFields && (
              <>
                {(isAddNewFieldOpen || buttonLoading) && (
                  <>
                    <Box marginBottom={'40px'}>
                      <HealthInfoFieldForm onSubmit={handleSaveNewField} onCancel={handleCancelAddNewField} />
                    </Box>
                    <Divider />
                  </>
                )}
                <Box marginLeft={'200px'}>
                  {!isAddNewFieldOpen && !buttonLoading && (
                    <RegularButton label="Add new field" onClick={handleAddNewField} fullWidth startIcon={null} />
                  )}
                </Box>
                <HealthInfoFieldList
                  title={'Fields'}
                  droppableId={'basic-health-fields'}
                  fieldList={reorderedFields}
                  handleDragEnd={(result: DropResult) => handleDragEnd(result)}
                  handleSaveEditedOption={async (index, data, options) =>
                    await handleSaveEditedField(index, data, options)
                  }
                  handleHideShowOption={(index, item) => handleHideShowOption(index, item)}
                  handleDeleteOption={(index, id) => handleDeleteOption(index, id)}
                />

                <ConfirmationDialog
                  open={confirmDeleteModalOpen}
                  setOpen={setConfirmDeleteModalOpen}
                  content={'Are you sure you want to delete this field?'}
                  onConfirm={handleDeleteConfirmed}
                />

                <Divider />
                <HealthInfoFieldList
                  title={'Hidden Fields'}
                  droppableId={'basic-health-hidden-fields'}
                  fieldList={hiddenReorderedFields}
                  handleDragEnd={(result: DropResult) => handleDragEnd(result, true)}
                  handleSaveEditedOption={async (index, data, options) =>
                    await handleSaveEditedField(index, data, options, true)
                  }
                  handleHideShowOption={(index, item) => handleHideShowOption(index, item, true)}
                  handleDeleteOption={(index, id) => handleDeleteOption(index, id, true)}
                />
              </>
            )}
          </Box>
        </CustomModal>
      </Box>
    );
  return (
    <Box>
      <PatientHeader
        onUpdate={onUpdate}
        onDelete={onDelete}
        patient={patient}
        extraAction={
          <>
            {readOnly && (
              <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENT_INFO']}>
                <RegularButton
                  size="small"
                  variant="outlined"
                  onClick={() => setReadOnly(false)}
                  label="Edit"
                  startIcon={<EditOutlinedIcon />}
                />
              </CompanyProtectedComponent>
            )}
            <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENT_INFO_FIELDS']}>
              <RegularButton
                variant="outlined"
                size="small"
                onClick={() => setIsEditModalOpen(true)}
                label="Customize"
                startIcon={<TuneOutlinedIcon />}
              />
            </CompanyProtectedComponent>
          </>
        }
      />
      <Box width="100%" paddingLeft={SIZES.paddingL}>
        <>
          {readOnly ? (
            <>
              <Box gap="20px" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '20px' }}>
                {fields.map((field, index) =>
                  field.type === 'group_header' ? (
                    <Typography key={index} variant="h4" fontWeight={'600'} style={{ gridColumn: 'span 3' }}>
                      {field.display_name}
                    </Typography>
                  ) : (
                    <PatientField
                      key={index}
                      label={field.display_name!}
                      value={
                        field.type === 'date'
                          ? formatDate(initialValues[field.field_name])
                          : initialValues[field.field_name]
                      }
                      sx={{ gridColumn: field.type === 'long_text' ? 'span 3' : undefined }}
                    />
                  )
                )}
              </Box>
            </>
          ) : (
            <>
              <CustomForm
                onSubmit={handleSubmit}
                fields={fields}
                initialValues={initialValues}
                loading={false}
                submitButtonText="Save"
                showSubmitAtTop
              />
            </>
          )}
        </>
      </Box>
      <CustomAnimatedDrawer
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        header="Customize"
        id="editFieldModal"
        onClose={handleCustomizeClose}
        width={500}
      >
        <Box>
          {reorderedFields && (
            <>
              {(isAddNewFieldOpen || buttonLoading) && (
                <>
                  <Box marginBottom={'40px'}>
                    <HealthInfoFieldForm onSubmit={handleSaveNewField} onCancel={handleCancelAddNewField} />
                  </Box>
                  <Divider />
                </>
              )}
              <Box marginLeft={'200px'}>
                {!isAddNewFieldOpen && !buttonLoading && (
                  <PrimaryButton label="Add new field" onClick={handleAddNewField} />
                )}
              </Box>
              <HealthInfoFieldList
                title={'Fields'}
                droppableId={'basic-health-fields'}
                fieldList={reorderedFields}
                handleDragEnd={(result: DropResult) => handleDragEnd(result)}
                handleSaveEditedOption={async (index, data, options) =>
                  await handleSaveEditedField(index, data, options)
                }
                handleHideShowOption={(index, item) => handleHideShowOption(index, item)}
                handleDeleteOption={(index, id) => handleDeleteOption(index, id)}
              />

              <ConfirmationDialog
                open={confirmDeleteModalOpen}
                setOpen={setConfirmDeleteModalOpen}
                content={'Are you sure you want to delete this field?'}
                onConfirm={handleDeleteConfirmed}
              />

              <Divider />
              <HealthInfoFieldList
                title={'Hidden Fields'}
                droppableId={'basic-health-hidden-fields'}
                fieldList={hiddenReorderedFields}
                handleDragEnd={(result: DropResult) => handleDragEnd(result, true)}
                handleSaveEditedOption={async (index, data, options) =>
                  await handleSaveEditedField(index, data, options, true)
                }
                handleHideShowOption={(index, item) => handleHideShowOption(index, item, true)}
                handleDeleteOption={(index, id) => handleDeleteOption(index, id, true)}
              />
            </>
          )}
        </Box>
      </CustomAnimatedDrawer>
    </Box>
  );
};

export default PatientBasicHealthInformation;

import { PatientNoteModel } from 'company/model/Entities';

export const patient_information_fields = ['Name', 'Age', 'Sex', 'Date', 'Address', 'Contact No.', 'Email', 'Company'];

export const chronic_illnesses_history = [
  'Diabetes',
  'Hypertension',
  'Asthma or other respiratory conditions',
  'Heart disease',
  'Thyroid disorders',
  'Epilepsy or neurological conditions',
  'Kidney or liver diseases',
  'Tuberculosis',
  'Psychiatric or Mental Health Conditions',
];

export const vital_signs = [
  { sign: 'Height (cm)', unit: 'cm' },
  { sign: 'Weight (kg)', unit: 'kg' },
  { sign: 'BMI', unit: 'kg/m2' },
  { sign: 'Blood Pressure (mmHg)', unit: 'mmHg' },
  { sign: 'Pulse Rate (per min)', unit: 'bpm' },
  { sign: 'Temperature (°C)', unit: '°C' },
];

export const physicalExamData = [
  { letter: 'A', exam: 'Head/Scalp' },
  { letter: 'H', exam: 'Chest/Breast' },
  { letter: 'B', exam: 'Eyes' },
  { letter: 'I', exam: 'Lungs' },
  { letter: 'C', exam: 'Ears' },
  { letter: 'J', exam: 'Abdomen' },
  { letter: 'D', exam: 'Nose/Sinuses' },
  { letter: 'K', exam: 'Anus' },
  { letter: 'E', exam: 'Neck' },
  { letter: 'L', exam: 'Musculoskeletal' },
  { letter: 'F', exam: 'Throat' },
  { letter: 'M', exam: 'Neurological' },
  { letter: 'G', exam: 'Heart' },
  { letter: 'N', exam: 'Skin' },
];

export const cbc_tests = [
  { test: 'Hemoglobin', normal_value: 'M: 12-18 g/dL\nF: 12-16 g/dL' },
  { test: 'Hematocrit', normal_value: 'M: 0.36-0.52%\nF: 0.35-0.47%' },
  { test: 'RBC Count', normal_value: '4.0-5.4' },
  { test: 'WBC Count', normal_value: '4.5-11.0 x 103/uL' },
  { test: 'Platelet Count', normal_value: '150-400 x 103/uL' },
  { test: 'Differential count', normal_value: '' },
  { test: 'Segmenters', normal_value: '50-70%' },
  { test: 'Lymphocytes', normal_value: '20-40%' },
  { test: 'Monocytes', normal_value: '2-8%' },
  { test: 'Eosinophils', normal_value: '2-5%' },
  { test: 'Basophils', normal_value: '0-1%' },
  { test: 'MCV', normal_value: '80-100 fL' },
  { test: 'MCH', normal_value: '26-32 pg' },
  { test: 'MCHC', normal_value: '32-36 g/dL' },
];

export const urinalysis = [
  'Color',
  'Transparency',
  'pH',
  'Specific Gravity',
  'Protein',
  'Sugar',
  'WBC',
  'RBC',
  'Epithelial cells',
  'Mucus Threads',
  'Bacteria',
  'Crystals',
];

export const fecalysis = ['Color', 'Consistency', 'WBC', 'RBC', 'Parasites'];

export const other_tests = [
  'Drug Test',
  'Blood Chemistry',
  'ECG Findings',
  'Audiometry Findings',
  'Other ECG Findings',
  'Papsmear Findings',
  'Pregnancy Test',
];

export const physical_exam_mapping = physicalExamData
  .map((p) => p.exam)
  .reduce((acc: any, condition) => {
    acc[condition] = {
      template_name: 'Physical Exam',
      source_type: 'table',
      table_name: '',
      table_column_name: 'Physical Exam',
      table_column_name_answer: 'Findings',
    };
    return acc;
  }, {});

export const vital_signs_mapping = vital_signs
  .map((s) => s.sign)
  .reduce((acc: any, condition) => {
    acc[condition] = {
      template_name: 'Vital Signs',
    };
    return acc;
  }, {});

export const medical_history_mapping = chronic_illnesses_history.reduce(
  (acc: any, condition) => {
    acc[condition] = {
      template_name: 'Medical History',
      source_type: 'table',
      table_name: '',
      table_column_name: 'Past Medical History',
      table_column_name_answer: 'Yes or No',
      more_info: '',
    };
    return acc;
  },
  {
    'Past Surgeries or Hospitalizations': {
      template_name: 'Medical History',
    },
    Injuries: {
      template_name: 'Medical History',
    },
    Allergies: {
      template_name: 'Medical History',
    },
    'Family Medical History': {
      template_name: 'Medical History',
    },
    Chemicals: {
      template_name: 'Medical History',
    },
    Noise: {
      template_name: 'Medical History',
    },
    Dust: {
      template_name: 'Medical History',
    },
    'Pregnancy and Obstetric History (date and history)': {
      template_name: 'Medical History',
    },
    Smoking: {
      template_name: 'Medical History',
    },
    'Alcohol consumption': {
      template_name: 'Medical History',
    },
    'Physical Activity': {
      template_name: 'Medical History',
    },
  }
);
export const hematology_mapping = cbc_tests
  .slice(0, 5)
  .map((c) => c.test)
  .reduce(
    (acc: any, condition) => {
      acc[condition] = {
        template_name: 'Hematology',
        source_type: 'table',
        table_name: '',
        table_column_name: 'Test',
        table_column_name_answer: 'Result',
      };
      return acc;
    },
    {
      'Blood Typing': {
        template_name: 'Hematology',
      },
      'RH Typing': {
        template_name: 'Hematology',
      },
    }
  );

export const diff_count_mapping = cbc_tests
  .slice(6)
  .map((c) => c.test)
  .reduce((acc: any, condition) => {
    acc['DC_' + condition] = {
      template_name: 'Hematology',
      source_type: 'table',
      table_name: 'Differential Count',
      table_column_name: 'Test',
      table_column_name_answer: 'Result',
    };
    return acc;
  }, {});

export const urinalysis_mapping = urinalysis.reduce(
  (acc: any, condition) => {
    acc['U_' + condition] = {
      template_name: 'Urinalysis',
    };
    return acc;
  },
  {
    'Remarks (Significant Findings based on Result)': {
      template_name: 'Urinalysis',
    },
    'With Menstruation': {
      template_name: 'Urinalysis',
    },
  },
);

export const fecalysis_mapping = fecalysis.reduce((acc: any, condition) => {
  acc['F_' + condition] = {
    template_name: 'Fecalysis',
  };
  acc['Remarks'] = {
    template_name: 'Fecalysis',
  };
  return acc;
}, {});

export const other_tests_mapping = other_tests.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Other Test Findings',
  };
  return acc;
}, {});

export const summary_mapping = {
  ...physical_exam_mapping,
  ...vital_signs_mapping,
  ...medical_history_mapping,
  ...hematology_mapping,
  ...diff_count_mapping,
  ...urinalysis_mapping,
  ...fecalysis_mapping,
  ...other_tests_mapping,

  // "Hepatitis B Result":{
  //     template_name: "Hepatitis B Ag"
  // },
  'Hepatitis B Result': {
    template_name: 'Hepatitis B Screening (HBsAg)',
  },
  'Hepatitis A Result': {
    template_name: 'Hepatitis A Screening (HAV IgM)',
  },
  IMPRESSION: {
    template_name: 'Radiologic Findings',
  },
  'SIGNIFICANT FINDINGS': {
    template_name: 'Radiologic Findings',
  },
  'Pregnancy Test': {
    template_name: 'Urinalysis',
  },
  'ECG Diagnosis': {
    template_name: 'ECG',
  },
  'Other ECG Diagnosis': {
    template_name: 'ECG',
  },
};

export const getAnswerCareGoPEME = (
  field: string,
  notes: PatientNoteModel[],
  defaultValue?: string,
  occurence?: number,
  prefix?: string,
  columns?: string[]
): string => {
  const defValue = defaultValue ?? '-';
  const defOccurence = occurence ?? 1;
  const source_mapping = summary_mapping as any;
  const fieldKey = prefix ? prefix + field : field;
  const mapping = source_mapping[fieldKey];
  if (mapping) {
    const note = notes.find(
      (note) =>
        note.header.toLowerCase().includes(mapping.template_name.toLowerCase()) ||
        note.template_id === mapping.template_id
    );

    if (note) {
      const body = note.template_body[0];
      const responses = note.responses;

      if (body) {
        if (mapping.source_type === 'table') {
          const tableField = body.fields.find(
            (field: any) =>
              (mapping.table_name && field.display_name === mapping.table_name) ||
              (!mapping.table_name && field.type === 'table')
          );

          if (tableField) {
            const questionColumn = tableField.columns.find(
              (column: any) => column.displayName === mapping.table_column_name
            );

            const rowResponses = responses[tableField.field_name];
            if (rowResponses) {
              const response = rowResponses.find((response: any) => {
                return response[questionColumn.fieldName] === field;
              });

              if (response) {
                if (columns && columns.length > 0) {
                  // Format columns into a string for easy display
                  return columns
                    .map((columnName) => {
                      const column = tableField.columns.find((col: any) => col.displayName === columnName);
                      return response[column?.fieldName] ? `${columnName}: ${response[column?.fieldName]}` : defValue;
                    })
                    .join(', ');
                } else {
                  // Default behavior: return only the main answer column
                  const answerColumn = tableField.columns.find(
                    (column: any) => column.displayName === mapping.table_column_name_answer
                  );

                  return response[answerColumn?.fieldName] || defValue;
                }
              }
            }
          }
        } else {
          let count = 0;
          const formField = body.fields.find((item: any) => {
            if (item.display_name?.trim() === field?.trim() && !item.section_header) {
              count++;
              return defOccurence === count;
            }
            return false;
          });

          return responses[formField?.field_name] || defValue;
        }
      }
    }
  }

  return defValue;
};

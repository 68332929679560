import { Badge, Box, Popover, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CustomCheckbox from './CustomCheckbox';
import FilterListIcon from '@mui/icons-material/FilterList';
import RegularButton from './buttons/RegularButton';
import { tokens } from 'theme/theme';

interface Props {
  optionList: CheckboxOptionProps[];
  buttonLabel?: string;
  buttonSize?: 'small' | 'medium' | 'large';
  customIcon?: React.ReactNode;
  setSelected?: Dispatch<SetStateAction<string[]>>;
  selected?: string[];
  filterLabel?: string;
  selectAllOption?: boolean;
  span?: number;
  noOptionContent?: ReactNode;
  variant?: 'text' | 'outlined' | 'contained';
  iconOnly?: boolean;
}

export type CheckboxOptionProps = {
  label: string;
  id: string;
  data?: any;
  isHeader?: boolean;
  showCheckbox?: boolean;
};

const CustomFilter: React.FC<Props> = ({
  optionList,
  buttonLabel = 'Filters',
  buttonSize,
  customIcon,
  setSelected,
  selected,
  filterLabel,
  selectAllOption = false,
  span = 1,
  noOptionContent,
  variant,
  iconOnly,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openFilterPopover = Boolean(anchorEl);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const parents = useMemo(
    () => optionList.filter((option) => option.isHeader).map((option) => option.id),
    [optionList]
  );

  const getChildren = (parent_id: string): string[] => {
    const startIndex = optionList.findIndex((option) => option.isHeader && option.id === parent_id);
    let lastIndex = optionList.findIndex((option, index) => index > startIndex && option.isHeader && option.isHeader);
    lastIndex = lastIndex === -1 ? optionList.length + 1 : lastIndex;

    return optionList.filter((option, index) => index > startIndex && index < lastIndex).map((option) => option.id);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let children: string[] = [];

    const selectedFilter = event.target.name;
    if (parents.includes(selectedFilter)) {
      children = getChildren(selectedFilter);
      children
        .filter((item) => parents.includes(item))
        .forEach((optionId) => {
          children = [...children, ...getChildren(optionId)];
        });
    }

    if (setSelected) {
      if (event.target.checked) {
        setSelected((prev) => [...prev, selectedFilter, ...children]);
      } else {
        setSelected((prev) => prev.filter((item) => item !== selectedFilter && !children.includes(item)));
      }
    }
  };

  const handleClearSelection = () => {
    if (setSelected) {
      setSelected([]);
    }
  };

  const selectedAll = useMemo(() => selected && selected.length === optionList.length, [optionList, selected]);

  const handleSelectAll = () => {
    if (setSelected) {
      const allOptionsSelected = optionList.map((option) => option.id);
      setSelected(selectedAll ? [] : allOptionsSelected);
    }
  };

  const gridColumn = useMemo(() => 'repeat(' + (isMobile ? 1 : span) + ', minmax(0, 1fr))', [span, isMobile]);

  const hasParentOptionWithCheckbox = useMemo(
    () => optionList.some((option) => option.isHeader && option.showCheckbox),
    [optionList]
  );

  const selectedCount = useMemo(
    () => (selected ? optionList.filter((option) => selected.includes(option.id) && !option.isHeader).length : 0),
    [selected, optionList]
  );

  return (
    <>
      <Badge
        badgeContent={selectedCount ?? undefined}
        color="primary"
        sx={{
          '& .MuiBadge-badge': {
            top: 3,
            right: 5,
            bgcolor: selected && selected.length > 0 ? undefined : 'transparent',
            color: selected && selected.length > 0 ? undefined : 'transparent',
          },
        }}
      >
        <RegularButton
          aria-describedby={'filter-popover'}
          startIcon={customIcon ?? <FilterListIcon />}
          variant={variant ? variant : 'outlined'}
          color="primary"
          onClick={handleClick}
          label={buttonLabel}
          styles={{
            '@media screen and (max-width: 768px)': { width: '100%', height: '40px', padding: '20px' },
          }}
          size={buttonSize}
        />
      </Badge>

      <Popover
        id={'filter-popover'}
        open={openFilterPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiSvgIcon-root': { color: colors.primary },
          '& .MuiPaper-root': { borderRadius: '15px', width: isMobile ? '90%' : 'auto', maxWidth: '90vw' },
        }}
      >
        <Box
          padding={'15px 10px'}
          display={'flex'}
          alignContent={'left'}
          flexDirection={'column'}
          gap={'5px'}
          sx={{ display: 'flex' }}
        >
          {!optionList.length ? (
            noOptionContent
          ) : (
            <>
              <Box display={'flex'} justifyContent={'space-between'} alignItems="center" sx={{ paddingInline: '5px' }}>
                <Typography flex={1} fontWeight="600">
                  {filterLabel ?? buttonLabel}
                </Typography>

                {selectAllOption && (
                  <RegularButton
                    styles={{ flex: 1 }}
                    variant="text"
                    label={selectedAll ? 'Unselect All' : 'Select All'}
                    onClick={handleSelectAll}
                    size="small"
                  />
                )}
              </Box>
              <Box
                display="grid"
                gridTemplateColumns={gridColumn}
                columnGap="7px"
                rowGap="7px"
                sx={{
                  maxHeight: isMobile ? '50vh' : '60vh',
                  overflow: 'auto',
                  alignItems: 'flex-start',
                  padding: hasParentOptionWithCheckbox ? '0px 5px 0px 15px' : '0px 5px',
                  '@media screen and (max-width: 768px)': {
                    gridTemplateColumns: '1fr',
                  },
                }}
              >
                {optionList.map((option, index) => {
                  return option.isHeader ? (
                    <>
                      {option.showCheckbox ? (
                        <CustomCheckbox
                          key={index}
                          sx={{
                            gridColumn: isMobile ? '1' : 'span ' + span,
                            color: colors.primary,
                            padding: '5px 12px',
                            // backgroundColor: 'rgba(21, 129, 196, 0.04)',
                            borderRadius: '10px',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginLeft: hasParentOptionWithCheckbox ? '-20px' : undefined,
                            width: '100%',
                            '& .MuiTypography-root': { fontWeight: 600 },
                          }}
                          label={option.label}
                          handleChange={handleFilterChange}
                          value={selected?.includes(option.id)}
                          fieldName={option.id}
                        />
                      ) : (
                        <Typography
                          key={index}
                          sx={{
                            gridColumn: isMobile ? '1' : 'span ' + span,
                            maxWidth: '100%',
                            textWrap: 'wrap',
                            wordBreak: 'break-word',
                          }}
                          fontWeight={500}
                        >
                          {option.label}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="left"
                      flexDirection="column"
                      sx={{ width: '100%', maxWidth: '200px', textWrap: 'wrap', wordBreak: 'break-word' }}
                    >
                      <CustomCheckbox
                        style={{
                          color: colors.accent,
                          padding: '5px 12px',
                          backgroundColor: 'rgba(21, 129, 196, 0.04)',
                          borderRadius: '10px',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          width: '100%',
                        }}
                        label={option.label}
                        handleChange={handleFilterChange}
                        value={selected?.includes(option.id)}
                        fieldName={option.id}
                      />
                    </Box>
                  );
                })}
              </Box>
            </>
          )}

          <Box display="flex" gap="10px" mt="10px">
            {(selected || []).length > 0 && (
              <RegularButton
                aria-describedby={'filter-popover'}
                startIcon={<CloseOutlinedIcon style={{ fill: '#FFFFFF' }} />}
                variant="contained"
                color="primary"
                onClick={handleClearSelection}
                label="Clear"
                size="small"
                fullWidth
              />
            )}
            <RegularButton
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleClose}
              fullWidth
              label={'Done'}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default CustomFilter;

import { Box, Modal, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';

import BackToTopButton from '../BackToTopButton';
import CloseIcon from '@mui/icons-material/Close';
import CustomIconButton from '../buttons/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { SIZES } from 'theme/constants';
import { tokens } from 'theme/theme';

type CustomModalProps = {
  header?: any;
  subHeader?: string | ReactNode;
  headerComponent?: any;
  children: React.ReactNode;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  width?: number | string;
  onClose?: () => void;
  hideClose?: boolean;
  headerIcon?: any;
  id?: string;
  noHeader?: boolean;
};

const CustomModal: React.FC<CustomModalProps> = ({
  header,
  headerComponent,
  children,
  open,
  setOpen,
  width,
  subHeader,
  onClose,
  hideClose,
  headerIcon,
  id,
  noHeader,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery('(max-width:700px)');
  const parentRef = useRef();

  const [visibleBackToTopButton, setVisibleBackToTopBotton] = useState<boolean>(false);
  const parentElement = parentRef.current as any;

  const style: any = isMobile
    ? {
        width: '100%',
        height: '100%',
        bgcolor: 'background.paper',
        overflowY: 'auto',
        position: 'absolute',
        top: 0,
      }
    : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: width ? width : 700,
        bgColor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
      };

  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (parentElement.scrollTop > 100) {
        setVisibleBackToTopBotton(true);
      } else {
        setVisibleBackToTopBotton(false);
      }
    };

    if (parentElement) {
      parentElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (parentElement) parentElement.removeEventListener('scroll', handleScroll);
    };
  }, [parentElement]);

  return (
    <Modal open={open}>
      <Paper sx={style} elevation={6} id={id}>
        {!noHeader && (
          <Box
            sx={{
              backgroundRepeat: 'no-repeat',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              padding: isMobile ? '16px 32px 4px 12px' : '32px 32px 16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'row-reverse' : 'row',
                justifyContent: isMobile ? 'flex-end' : 'space-between',
                alignItems: 'center',
                gap: SIZES.padding,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignContent: 'left',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Box
                  display={'flex'}
                  gap="0.5rem"
                  justifyContent={'center'}
                  sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
                >
                  <Typography variant="h3" fontWeight="600" color={colors.accent}>
                    {header}
                  </Typography>

                  {headerIcon && headerIcon}
                </Box>
                <Typography variant="h5" component="h4" color={colors.primary} whiteSpace="pre-line">
                  {subHeader}
                </Typography>
                {headerComponent}
              </Box>

              <CustomIconButton
                icon={isMobile ? <KeyboardBackspaceIcon /> : <CloseIcon />}
                onClick={handleClose}
                color="primary"
                sx={{ visibility: hideClose ? 'hidden' : 'visible' }}
              />
            </Box>
          </Box>
        )}
        <Box
          ref={parentRef}
          padding={isMobile ? '20px 30px' : '32px'}
          paddingTop="0"
          sx={{
            maxHeight: isMobile ? 'calc(100vh - 80px)' : 'calc(100vh - 120px)',
            overflowY: 'auto',
          }}
        >
          {children}
          {isMobile && <BackToTopButton parentRef={parentRef} visible={visibleBackToTopButton} />}
        </Box>
      </Paper>
    </Modal>
  );
};

export default CustomModal;

import { Divider, InputBase, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { SIZES } from 'theme/constants';
import SearchIcon from '@mui/icons-material/Search';
import { tokens } from 'theme/theme';
import { debounce } from 'lodash';

type SearchBarProps = {
  handleSearch: (searchKey: string) => void;
  backgroundColor?: string;
  styles?: SxProps<Theme>;
  fullWidth?: boolean;
  contrast?: boolean;
  placeholder?: string;
};

const SearchBar: React.FC<SearchBarProps> = ({
  handleSearch,
  backgroundColor,
  styles,
  fullWidth,
  placeholder,
  contrast = false,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [search, setSearch] = useState<string>('');
  const prevSearchKeyRef = useRef('');
  const isMobile = useMediaQuery('(max-width:768px)');

  // debounced search function
  const debouncedSearch = useRef(
    debounce((searchTerm: string) => {
      if (searchTerm !== prevSearchKeyRef.current) {
        handleSearch(searchTerm);
        prevSearchKeyRef.current = searchTerm;
      }
    }, 300)
  ).current;

  // debounce cancel on unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchClick = () => {
    if (search !== prevSearchKeyRef.current) {
      handleSearch(search);
      prevSearchKeyRef.current = search;
      debouncedSearch.cancel();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  const handleSearchChange = (value: string) => {
    setSearch(value);
    debouncedSearch(value);
  };

  const bgColor = contrast ? '#FFFFFF' : backgroundColor ?? colors.input_background;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minWidth: fullWidth ? '100%' : '300px',
        maxWidth: fullWidth ? 'none' : '300px',
        backgroundColor: bgColor,
        borderRadius: SIZES.borderRadiusS,
        height: '40px',
        paddingLeft: '5px',
        '@media screen and (max-width: 768px)': { width: '140px', height: '40px', padding: '0px' },
        boxShadow: contrast ? '0 1px 2px rgba(0, 0, 0, 0.04)' : 'none',
        border: contrast ? '1px solid rgba(0, 0, 0, 0.02)' : 'none',
        ...styles,
      }}
    >
      <IconButton
        type="button"
        onClick={handleSearchClick}
        sx={{
          color: contrast ? 'rgba(0, 0, 0, 0.6)' : 'inherit',
        }}
      >
        <SearchIcon style={{ fontSize: isMobile ? '20px' : '' }} />
      </IconButton>
      <Divider
        sx={{
          height: 28,
          m: 0.5,
          backgroundColor: contrast ? 'rgba(0, 0, 0, 0.06)' : undefined,
        }}
        orientation="vertical"
      />
      <InputBase
        sx={{
          m: SIZES.paddingS,
          flex: 1,
          fontSize: '12px',
          color: contrast ? 'rgba(0, 0, 0, 0.87)' : undefined,
          '&::placeholder': {
            color: contrast ? 'rgba(0, 0, 0, 0.4)' : undefined,
          },
        }}
        placeholder={placeholder ? placeholder : 'Search'}
        value={search}
        onChange={(e) => handleSearchChange(e.target.value)}
        onKeyUp={handleKeyPress}
      />
    </Box>
  );
};

export default SearchBar;

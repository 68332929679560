import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import {
  getPatientNotesSummaryTemplates,
  getSinglePatientNoteSummary,
} from 'company/api/patient-note-summary-template';
import { FacilityContext } from 'core/context/facility.context';

import { ApiQuery } from 'core/model/interface';
import { useContext } from 'react';

const PatientNoteSummaryTemplateSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  const { facility } = useContext(FacilityContext);
  return (
    <TemplateDropdown
      {...props}
      hideAddOption
      entity={'Note Summary Template'}
      getData={(query: ApiQuery) => getPatientNotesSummaryTemplates(facility.id, query)}
      getById={(id: number) => getSinglePatientNoteSummary(facility.id, id)}
      processOption={(record) => ({ key: record.template_name, value: record.id })}
      //   processNewRecord={(record) => record.hmo}
    />
  );
};

export default PatientNoteSummaryTemplateSearchDropdown;

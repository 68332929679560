import {
  PNPT_ENTITY_NAME,
  PatientNotePrintTemplateModel,
  patientNotePrintTemplateFields,
  patientNotePrintTemplateTableColumns,
} from './PatientNotePrintTemplateModel';
import { deleteNotePrintTemplate, getPatientNotePrintTemplates } from 'company/api/patient-note-print-template';
import { forwardRef, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import PatientNotePrintTemplateForm from './PatientNotePrintTemplateForm';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import TemplateTable from 'company/entities/Template/TemplateTable';
import TuneIcon from '@mui/icons-material/Tune';
import { useNavigate } from 'react-router-dom';

type Props = {
  visibleColumns?: string[];
  tableRef?: any;
  tableAction?: TableActionProps;
};

const PatientNotePrintTemplateTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, tableRef } = props;
  const [selectedRow, setSelectedRow] = useState<PatientNotePrintTemplateModel>();
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const getData = (query: ApiQuery) => {
    return getPatientNotePrintTemplates(query);
  };

  const refreshTable = () => {
    if (mainRef.current) {
      mainRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  function callBackAfterSubmit() {
    refreshTable();
    mainRef.current?.closeFormModal();
  }

  const navigate = useNavigate();
  const update = (data: any) => {
    navigate(`/company/notes-summary/${data.id}`);
  };
  const handleDeleteNoteTemplatePrint = (data: any) => {
    return deleteNotePrintTemplate(data);
  };

  return (
    <TemplateTable
      // hideAddUpdateDeleteAction
      handleDelete={(data) => handleDeleteNoteTemplatePrint(data)}
      // hideUpdateAction
      // hideDeleteAction
      useColoredTable
      ref={mainRef}
      entityName={PNPT_ENTITY_NAME}
      fields={patientNotePrintTemplateFields}
      getData={getData}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      visibleColumns={visibleColumns ?? patientNotePrintTemplateTableColumns}
      addActionDisplayOverwrite={`Create ${PNPT_ENTITY_NAME}`}
      updateActionDisplayOverwrite={!!selectedRow ? `Update ${PNPT_ENTITY_NAME}` : `Create ${PNPT_ENTITY_NAME}`}
      templateForm={
        <PatientNotePrintTemplateForm callbackAfterSubmit={callBackAfterSubmit} healthAssessment={selectedRow} />
      }
    />
  );
});

export default PatientNotePrintTemplateTable;

import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'basic-health-info-field';

export const getBasicHealthInformationFields = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getBasicHealthInformationField = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getFieldsVisibleInSelfReg = () => {
  return axios.get(`${baseURL}/${path}/self-reg-fields`)
}

export const createBasicHealthInformationField = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateBasicHealthInformationField = (id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${id}`, data);
};

export const deleteBasicHealthInformationField = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

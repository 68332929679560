import {
  PNST_ENTITY_NAME,
  PatientNoteSummaryTemplateModel,
  createPatientNoteSummaryTemplate,
  patientNoteSummaryTemplateFields,
  patientNoteSummaryTemplateFormFields,
  patientNoteSummaryTemplateInitialValues,
} from './PatientNoteSummaryTemplateModel';
import { createNewNoteSummaryTemplate, updateNoteSummaryTemplate } from 'company/api/patient-note-summary-template';

import TemplateForm from 'company/entities/Template/TemplateForm';
import { FacilityContext } from 'core/context/facility.context';
import { useContext } from 'react';

type Props = {
  readonly?: boolean;
  healthAssessment?: PatientNoteSummaryTemplateModel;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
};

const PatientNoteSummaryTemplateForm = (props: Props) => {
  const { facility } = useContext(FacilityContext);
  const { healthAssessment, callbackAfterSubmit } = props;
  async function handleSubmit(data: any) {
    if (!healthAssessment) return createNewNoteSummaryTemplate(facility.id, data);
    return updateNoteSummaryTemplate(healthAssessment.id, data);
  }

  return (
    <TemplateForm
      entity={healthAssessment}
      schema={createPatientNoteSummaryTemplate}
      callbackAfterSubmit={callbackAfterSubmit}
      entityName={PNST_ENTITY_NAME}
      fields={patientNoteSummaryTemplateFields}
      initialValues={!!healthAssessment ? healthAssessment : patientNoteSummaryTemplateInitialValues}
      visibleFields={patientNoteSummaryTemplateFormFields}
      formSubmitApiFunction={handleSubmit}
    />
  );
};

export default PatientNoteSummaryTemplateForm;

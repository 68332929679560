import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getPatient, getPatients } from 'company/api/patient';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { UserContext } from 'core/context/user.context';
import WaitForFacility from '../WaitForFacility';
import { useContext } from 'react';

const PatientSearchDropdown: React.FC<TemplateDropdownProps> = ({
  contrast,
  hideAddOption,
  ...props
}: TemplateDropdownProps) => {
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);

  return (
    <WaitForFacility facility={facility}>
      <TemplateDropdown
        {...props}
        hideAddOption={!user?.authorities?.includes('ADD_PATIENT') || hideAddOption}
        entity={'Patient'}
        getData={(query: ApiQuery) => getPatients(facility.id, query)}
        getById={(id: number) => getPatient(facility.id, id)}
        processOption={(record) => ({
          key: record.patient_id + ' - ' + record.full_name,
          value: record.patient_id,
          data: record,
        })}
        processNewRecord={(record) => record.patient}
        contrast={contrast}
      />
    </WaitForFacility>
  );
};

export default PatientSearchDropdown;

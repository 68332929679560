import { Box, Collapse, Divider, Typography, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import { calculateAge, formatDateTime } from 'core/utils';
import { getAppointmentPrescriptions, getPrescriptionDetails, getPrescriptions } from 'company/api/appointment-billing';

import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import { FacilityContext } from 'core/context/facility.context';
import PatientAppointmentFilter from './PatientAppointmentFilter';
import PrescriptionLineItem from './PrescriptionLineItem';
import PrescriptionServices from './PrescriptionServices';
import { tokens } from 'theme/theme';

interface Prescription {
  prescription_id: number;
  patient_id: number;
  schedule: string;
  appointment_doctor_full_name: string;
  patient_full_name: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  age: number;
  birthday: Date;
  sex: string;
  total_medicines: number;
  instructions: string;
  appointment_id: number;
}

interface AppointmentHeaderProps {
  prescription?: Prescription;
  service?: any;
  onPrescriptionClick?: () => void;
  isExpanded?: boolean;
}

const AppointmentHeader = ({
  prescription,
  service,
  onPrescriptionClick,
  isExpanded = true,
}: AppointmentHeaderProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        padding: '15px',
        cursor: prescription ? 'pointer' : 'default',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onClick={onPrescriptionClick}
    >
      <Box>
        <Typography variant="h5" fontWeight="600" color={colors.primary} sx={{ mb: 1 }}>
          {service?.patient_full_name ? `${service.patient_full_name}` : 'Appointment'}
        </Typography>
        {prescription ? (
          <>
            <Typography variant="body2">
              {prescription.age || calculateAge(prescription.birthday)} old, {prescription.sex}
            </Typography>
            <Typography variant="body2">
              Appointment Details:{' '}
              {prescription.appointment_doctor_full_name && `Dr. ${prescription.appointment_doctor_full_name} -`}{' '}
              {formatDateTime(prescription.schedule)}
            </Typography>
          </>
        ) : (
          <Typography variant="body2">Schedule: {formatDateTime(service.schedule)}</Typography>
        )}
      </Box>

      <Box display="flex" alignItems="center" gap={1}>
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </Box>
    </Box>
  );
};

const PrescriptionList = ({
  addToCart,
  orders,
  appointmentId,
  setOrders,
  selectedPatient,
  setSelectedPatient,
  localAppointmentId,
  setLocalAppointmentId,
  resetDetails,
}: {
  addToCart: (item: any) => void;
  orders: any[];
  appointmentId?: number;
  setOrders: React.Dispatch<React.SetStateAction<any[]>>;
  selectedPatient: number;
  setSelectedPatient: React.Dispatch<React.SetStateAction<number>>;
  localAppointmentId?: number;
  setLocalAppointmentId: React.Dispatch<React.SetStateAction<number | undefined>>;
  resetDetails?: boolean;
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);

  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [expandedPrescription, setExpandedPrescription] = useState<number | null>(null);
  const [expandedService, setExpandedService] = useState<{ [key: number]: boolean }>({});
  const [prescriptionDetails, setPrescriptionDetails] = useState<any>({});
  // const [selectedPatient, setSelectedPatient] = useState<number>();
  const [loading, setLoading] = useState(true);

  const autoAddAppointmentItems = (appointmentServices: any[]) => {
    appointmentServices.forEach((service) => {
      const serviceItem = {
        type: 'service',
        id: service.service_id,
        unit_price: service.unit_price || service.price,
        service_name: service.name || service.service_name,
        attributes: '',
        tax: service.tax || '0',
        service_id: service.service_id,
        line_item_id: service.line_item_id,
        patientId: service.patient_id,
      };
      addToCart(serviceItem);

      if (service.linked_products && Array.isArray(service.linked_products)) {
        service.linked_products.forEach((product: any) => {
          const availableStock = parseFloat(product.quantity_in_stock + '');
          const quantityNeed = product.conversion_factor
            ? product.quantity * parseFloat(product.conversion_factor)
            : product.quantity;
          if (availableStock >= quantityNeed) {
            const productItem = {
              ...product,
              type: 'product',
              id: product.variant_id,
              unit_price: product.price,
              patientId: service.patient_id,
              service_product_id: product.id,
              quantity: quantityNeed,
              uom_id: product.product_base_uom,
            };
            addToCart(productItem);
          }
        });
      }
    });
  };

  const loadPrescriptions = async () => {
    try {
      setLoading(true);
      if (facility) {
        const effectiveAppointmentId = appointmentId || localAppointmentId;
        if (effectiveAppointmentId) {
          const response = await getAppointmentPrescriptions(facility.id, effectiveAppointmentId);
          const appointment = response.data.data;
          setPrescriptions(appointment.prescriptions || []);
          setServices(appointment.services || []);

          if (appointment.services?.length > 0 && localAppointmentId) {
            autoAddAppointmentItems(appointment.services);
          }

          if (appointment.prescriptions?.length > 0) {
            const firstPrescriptionId = appointment.prescriptions[0].prescription_id;
            setExpandedPrescription(firstPrescriptionId);
            handlePrescriptionClick(firstPrescriptionId);
          }
        } else {
          setPrescriptions([]);
          setServices([]);
        }
      }
    } catch (error) {
      console.error('Error loading prescriptions:', error);
      setPrescriptions([]);
      setServices([]);
    } finally {
      setLoading(false);
    }
  };

  const handlePrescriptionClick = async (prescriptionId: number) => {
    if (expandedPrescription === prescriptionId) {
      setExpandedPrescription(null);
      return;
    }

    setExpandedPrescription(prescriptionId);
    if (!prescriptionDetails[prescriptionId]) {
      const response = await getPrescriptionDetails(facility.id, prescriptionId);
      setPrescriptionDetails((prev: any) => ({ ...prev, [prescriptionId]: response.data.data }));
    }
  };

  const toggleServiceExpanded = (appointmentId: number) => {
    setExpandedService((prev) => ({
      ...prev,
      [appointmentId]: !prev[appointmentId],
    }));
  };

  const handleFilterChange = (patientId: number, selectedAppointmentId: number) => {
    setOrders([]);
    setLocalAppointmentId(selectedAppointmentId != 0 ? selectedAppointmentId : 0);
    setSelectedPatient(patientId);
    setExpandedPrescription(selectedAppointmentId);
    setExpandedService({});
    setPrescriptionDetails({});
    localStorage.removeItem('orders');
  };

  useEffect(() => {
    if (facility) {
      loadPrescriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility, localAppointmentId]);

  return (
    <Box height="calc(100vh - 200px)" display="flex" flexDirection="column">
      {!appointmentId && (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} columnGap={2}>
          <PatientAppointmentFilter
            facilityId={facility.id}
            onFilterChange={handleFilterChange}
            resetFilter={resetDetails}
          />
        </Box>
      )}

      <Box sx={{ overflowY: 'auto', flex: 1 }} pr={2}>
        {loading ? (
          <CustomLoadingIndicator />
        ) : prescriptions.length > 0 || services.length > 0 ? (
          <>
            {prescriptions.map((prescription) => (
              <Box
                key={prescription.prescription_id}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '12px',
                  mb: 2,
                  overflow: 'hidden',
                  border: `1px solid ${colors.border}`,
                }}
              >
                <AppointmentHeader
                  prescription={prescription}
                  service={services.find((s) => s.appointment_id === prescription.appointment_id)}
                  onPrescriptionClick={() => handlePrescriptionClick(prescription.prescription_id)}
                  isExpanded={expandedPrescription === prescription.prescription_id || true}
                />
                <Collapse in={true}>
                  <Divider />
                  <Box p={2}>
                    {services.length > 0 && (
                      <Box mb={2}>
                        <PrescriptionServices
                          items={services.filter((s) => s.appointment_id === prescription.appointment_id)}
                          addToCart={addToCart}
                          orders={orders}
                        />
                      </Box>
                    )}

                    {prescriptionDetails[prescription.prescription_id]
                      ?.filter((item: any) => item.item_type === 'medicine')
                      .map((prescriptionItem: any) => (
                        <PrescriptionLineItem
                          key={prescriptionItem.line_item_id}
                          item={prescriptionItem}
                          addToCart={addToCart}
                          orders={orders}
                        />
                      ))}
                  </Box>
                </Collapse>
              </Box>
            ))}

            {services
              .filter((service) => !prescriptions.some((p) => p.appointment_id === service.appointment_id))
              .reduce((groups: any[], service) => {
                const existingGroup = groups.find((g) => g.appointment_id === service.appointment_id);
                if (existingGroup) {
                  existingGroup.services.push(service);
                } else {
                  groups.push({
                    appointment_id: service.appointment_id,
                    services: [service],
                  });
                }
                return groups;
              }, [])
              .map((group) => (
                <Box
                  key={`service-${group.appointment_id}`}
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '12px',
                    mb: 2,
                    overflow: 'hidden',
                    border: `1px solid ${colors.border}`,
                  }}
                >
                  <AppointmentHeader
                    service={group.services[0]}
                    onPrescriptionClick={() => toggleServiceExpanded(group.appointment_id)}
                    isExpanded={expandedService[group.appointment_id] || true}
                  />
                  <Collapse in={true}>
                    <Divider />
                    <Box p={2}>
                      <PrescriptionServices items={group.services} addToCart={addToCart} orders={orders} />
                    </Box>
                  </Collapse>
                </Box>
              ))}
          </>
        ) : (
          <Typography textAlign="center" color="text.secondary" mt={4}>
            {localAppointmentId ? 'No appointment billables found' : 'Please select a patient and appointment'}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PrescriptionList;

/* eslint-disable react-hooks/exhaustive-deps */

import 'react-pro-sidebar/dist/css/styles.css';

import CustomSideBar, { APP_MODULE, MenuItemProps } from './CustomSideBar';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { isDevMode } from 'core/components/HideOrShowComponent';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const InventoryAppSidebar = () => {
  const company_menu_items: MenuItemProps[] = [
    {
      section_name: 'Inventory',
      subitems: [
        {
          title: 'Dashboard',
          link: '/company/inventory/dashboard',
          icon: <DashboardOutlinedIcon />,
          hidden: !isDevMode(),
        },
        {
          title: 'Product Directory',
          link: '/company/inventory/products',
          icon: <Inventory2OutlinedIcon />,
        },
        {
          title: 'Inventory Settings',
          link: '/company/inventory/settings',
          icon: <SettingsOutlinedIcon />,
        },
      ],
    },
    {
      section_name: 'Transactions',
      subitems: [
        {
          title: 'POS',
          link: '/company/inventory/pos',
          icon: <PointOfSaleOutlinedIcon />,
        },
        {
          title: 'Patient Transactions',
          link: '/company/inventory/patient-transactions',
          icon: <ReceiptOutlinedIcon />,
          id: 'sidebar-client-billing',
        },

        // {
        //   title: 'General Transactions',
        //   link: '/company/inventory/general-transactions',
        //   icon: <ReceiptLongOutlinedIcon />,
        // },
        // {
        //   title: 'Product Transfers',
        //   link: '/company/inventory/transfers',
        //   icon: <MoveUpOutlinedIcon />,
        // },
        // {
        //   title: 'Stock Adjustments',
        //   link: '/company/inventory/stock-adjustments',
        //   icon: <InventoryOutlinedIcon />,
        // },
      ],
    },
  ];

  return <CustomSideBar menuItems={company_menu_items} module={APP_MODULE.INVENTORY} />;
};

export default InventoryAppSidebar;

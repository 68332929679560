import { Box, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomCheckbox, CustomModal, RegularButton } from 'core/components';
import {
  deleteBatch,
  getAllHealthAssessmentBatches,
  getSingleHealthAssessmentBatch,
} from 'company/api/health-assessment-batches';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AddToQueueOutlinedIcon from '@mui/icons-material/AddToQueueOutlined';
import { ApiQuery } from 'core/model/interface';
import { AppointmentLegendModel } from 'company/entities/modules/ClinicManagement/AppointmentLegend/AppointmentLegendModel';
import AppointmentLegendTable from 'company/entities/modules/ClinicManagement/AppointmentLegend/AppointmentLegendTable';
import { Assessments } from '.';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import { ContainerRow } from 'core/components/containers';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CustomFilter from 'core/components/CustomFilter';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import { FacilityContext } from 'core/context/facility.context';
import HealthAssessmentBatchForm from 'company/entities/modules/ClinicManagement/HealthAssessment/HealthAssessmentBatchForm';
import { HealthAssessmentBatchModel } from 'company/entities/modules/ClinicManagement/HealthAssessment/HealthAssessmentBatchModel';
import HealthAssessmentForm from 'company/entities/modules/ClinicManagement/HealthAssessment/HealthAssessmentForm';
import ImportModal from '../RegistrationBoard/components/ImportModal';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import PatientNoteSummaryTemplateSearchDropdown from 'company/components/dropdown/PatientNoteSummaryTemplateSearchDropdown';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { UserContext } from 'core/context/user.context';
import { WaitForFacility } from 'company/components';
import { exportReportByTemplate } from 'company/api/patient-note-summary-template';
import { getAllBatchTags } from 'company/api/appointment-legends';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

export default function HealthAssessmentBatch() {
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const componentRef: any = useRef();

  const [selectedBatch, setSelectedBatch] = useState<number>();
  const [openBatchForm, setOpenBatchForm] = useState<boolean>(false);
  const [addedBatch, setAddedBatch] = useState<any>();
  const [deletedId, setDeletedId] = useState<number>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [updateForm, setUpdateForm] = useState<boolean>(false);
  const [selectedBatchDetails, setSelectedBatchDetails] = useState<HealthAssessmentBatchModel>();
  const [updatedBatch, setUpdatedBatch] = useState<number | null>(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const [generateReportModalOpen, setGenerateReportModalOpen] = useState<boolean>(false);
  const [selectedSummaryTemplate, setSelectedSummaryTemplate] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [importOpen, setImportOpen] = useState<boolean>(false);
  const [openEnrollPatient, setOpenEnrollPatient] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [generateCompletedAssessments, setGenerateCompletedAssessments] = useState<boolean>(false);
  const [openBatchTagTable, setOpenBatchTagTable] = useState<boolean>(false);
  const [batchTags, setBatchTags] = useState<AppointmentLegendModel[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [refreshBatchList, setRefreshBatchList] = useState<number>(0);

  const displayItem = (item: any) => {
    const isSelected = selectedBatch === item.id;
    return (
      <ContainerRow>
        <Typography
          variant="h5"
          color={isSelected && !isMobilePhone ? colors.background : colors.text}
          fontWeight="600"
        >
          {item.batch_name}
          {item.company_name ? ` - ${item.company_name}` : ''}
        </Typography>
      </ContainerRow>
    );
  };

  const displayTitle = () => {
    if (!selectedBatchDetails) return <></>;

    let displayName = selectedBatchDetails.batch_name || '';
    if (selectedBatchDetails.company_name) {
      displayName += ` - ${selectedBatchDetails.company_name}`;
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ color: colors.primary, fontSize: '20px', fontWeight: '700' }}>{displayName}</Typography>
        {!!selectedBatchDetails?.group_name && (
          <Tooltip title={selectedBatchDetails?.service_names} placement="right-end">
            <Typography sx={{ color: colors.dark_grey_text, fontSize: '14px', fontWeight: '500', cursor: 'pointer' }}>
              {selectedBatchDetails?.group_name}
            </Typography>
          </Tooltip>
        )}
      </Box>
    );
  };

  useEffect(() => {
    async function getBatchDetails() {
      if (!selectedBatch) return;
      const res = await getSingleData(selectedBatch);
      setSelectedBatchDetails(res.data);
    }
    getBatchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBatch]);

  useEffect(() => {
    async function getBatchTagOptions(query?: ApiQuery) {
      const res = await getAllBatchTags(facility.id, query);
      setBatchTags(res.data.data);
    }
    getBatchTagOptions();
  }, [facility.id, refresh]);

  useEffect(() => {
    setRefreshBatchList((prev) => prev + 1);
  }, [selectedTags]);

  const batchTagOptions = useMemo(() => {
    return batchTags.map((tag: any) => ({
      label: tag.tag_name,
      id: tag.id.toString(),
    }));
  }, [batchTags]);

  async function getData(query?: ApiQuery) {
    return getAllHealthAssessmentBatches(facility.id, { ...query, tag: selectedTags });
  }

  function getSingleData(id: number) {
    return getSingleHealthAssessmentBatch(facility.id, id);
  }

  async function handleDeleteBatch() {
    await deleteBatch(facility.id, selectedBatch!);
    setDeletedId(selectedBatch);
  }

  const exportTemplatedReport = async () => {
    let fileName = selectedSummaryTemplate.key;

    if (selectedSummaryTemplate && selectedBatchDetails) {
      fileName = `${selectedSummaryTemplate.key}_${selectedBatchDetails.company_name}_${selectedBatchDetails.batch_name}`;
    }

    setLoading(true);
    try {
      await exportReportByTemplate(
        fileName,
        selectedSummaryTemplate.value,
        selectedBatch!,
        generateCompletedAssessments
      );
      setGenerateReportModalOpen(false);
      setGenerateCompletedAssessments(false);
      enqueueSnackbar('Report successfully generated! Please check your downloads folder.', { variant: 'success' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <WaitForFacility facility={facility}>
      <InfiniteScrollContainer
        manualRefresh={refreshBatchList}
        title="Health Assessment Batch"
        getData={getData}
        getSingleData={getSingleData}
        selected={selectedBatch}
        setSelected={setSelectedBatch}
        deleteId={deletedId}
        updatedItem={updatedBatch}
        addedNewItem={addedBatch}
        urlKeyId="hab"
        renderItem={displayItem}
        displaySelectedTitle={!!selectedBatch ? displayTitle : undefined}
        underSearchComponents={
          <CustomFilter
            buttonLabel="Batch Tag Filter"
            optionList={batchTagOptions}
            selected={selectedTags}
            setSelected={setSelectedTags}
            buttonSize="small"
          />
        }
        containerHeight="calc(100vh - 240px)"
        actions={
          selectedBatchDetails
            ? [
                {
                  icon: <SummarizeOutlinedIcon />,
                  label: 'Generate Report',
                  action: (data) => {
                    setGenerateReportModalOpen(true);
                  },
                },
                { label: 'divider' },
                {
                  icon: <AddIcon />,
                  label: 'Enroll Patient',
                  action: (data) => {
                    setOpenEnrollPatient(true);
                  },
                  hidden: user?.remaining_storage <= 0,
                },

                {
                  icon: <DownloadIcon style={{ transform: 'rotate(180deg)' }} />,
                  label: 'Import Patients',
                  requiredAuth: ['IMPORT_PATIENT_HEALTH_ASSESSMENTS'],
                  hidden: !selectedBatchDetails.service_group_id || user.remaining_storage <= 0,
                  action: (data) => {
                    setImportOpen(true);
                  },
                },
                { label: 'divider' },
                {
                  icon: <CreateOutlinedIcon />,
                  label: 'Update',
                  action: (data) => {
                    if (!selectedBatch) return;
                    setUpdateForm(true);
                    setOpenBatchForm(true);
                  },
                },
                {
                  icon: <DeleteOutlineOutlinedIcon sx={{ color: colors.redAccent }} />,
                  label: 'Delete',
                  action: (data) => {
                    setOpenDeleteDialog(true);
                  },
                  labelStyle: { color: colors.redAccent },
                },
              ]
            : undefined
        }
        titleComponents={
          <RegularButton
            size="small"
            startIcon={<AddIcon />}
            label="Add"
            onClick={() => {
              setUpdateForm(false);
              setOpenBatchForm(true);
            }}
            disabled={user.remaining_storage <= 0}
          />
        }
        headerComponents={
          <RegularButton
            fullWidth
            size="small"
            startIcon={<BookOutlinedIcon />}
            label="Batch Tags"
            variant="outlined"
            onClick={() => {
              // setUpdateForm(false);
              setOpenBatchTagTable(true);
            }}
          />
        }
      >
        {!!selectedBatch ? (
          <Box sx={{ width: '100%', marginTop: '20px' }}>
            <Assessments
              batch_id={selectedBatch}
              batchTemplateCode={selectedBatchDetails?.template_code}
              fromBatch
              ref={componentRef}
              refreshFromBatch={refresh}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '500px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <AddToQueueOutlinedIcon sx={{ fontSize: '32px', color: colors.primary }} />
            <Typography sx={{ fontWeight: '500', fontSize: '16px', color: colors.primary }}>
              No batch selected. Please create new batch
            </Typography>
          </Box>
        )}
      </InfiniteScrollContainer>

      <CustomModal open={openEnrollPatient} setOpen={setOpenEnrollPatient} header="Enroll Patient">
        <HealthAssessmentForm
          initialValues={{ patient_id: '', assessment_date: new Date(), batch_id: selectedBatch }}
          callbackAfterSubmit={(data) => {
            setRefresh((prev) => prev + 1);
            setOpenEnrollPatient(false);
          }}
        />
      </CustomModal>
      <CustomModal
        setOpen={setOpenBatchForm}
        open={openBatchForm}
        header={!updateForm ? `Create New Batch` : 'Update Batch'}
      >
        <HealthAssessmentBatchForm
          batch={updateForm ? selectedBatchDetails : undefined}
          callbackAfterSubmit={(data) => {
            if (updateForm) {
              setUpdatedBatch(data.data);
              setRefresh((prev) => prev + 1);
            } else {
              setAddedBatch(data.data.id);
            }

            setSelectedBatchDetails(data.data);
            setUpdateForm(false);
            setOpenBatchForm(false);
          }}
        />
        {/* <CustomForm
          onCancel={() => setUpdateForm(false)}
          schema={batchSchema}
          fields={batchFields}
          initialValues={!updateForm ? { batch_name: '', corporate_client_id: '' } : selectedBatchDetails}
          onSubmit={!updateForm ? createNewBatch : handleUpdateBatch}
          loading={formLoading}
        /> */}
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onConfirm={handleDeleteBatch}
        content="Are you sure you want to delete this batch?"
      ></ConfirmationDialog>

      <CustomModal
        open={generateReportModalOpen}
        setOpen={setGenerateReportModalOpen}
        header="Generate Report"
        width={600}
        onClose={() => setSelectedSummaryTemplate(undefined)}
        subHeader={
          componentRef.current?.assessments.length < 1 ? (
            <Typography variant="h6" color={colors.redAccent}>
              Cannot generate report - no patients enrolled under this batch
            </Typography>
          ) : (
            ''
          )
        }
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
          <PatientNoteSummaryTemplateSearchDropdown handleChangeCallback={(data) => setSelectedSummaryTemplate(data)} />

          <CustomCheckbox
            label={'Include completed assessments only.'}
            fieldName={'generate_completed'}
            handleChangeCallback={(data) => {
              setGenerateCompletedAssessments(data);
            }}
          />

          <RegularButton
            label="Generate"
            onClick={exportTemplatedReport}
            loading={loading}
            disabled={componentRef.current?.assessments.length < 1}
          />

          {/* {selectedSummaryTemplate && <ReportTemplate summary_id={selectedSummaryTemplate.value} />} */}
        </Box>
      </CustomModal>

      <ImportModal
        open={importOpen}
        setOpen={setImportOpen}
        assessmentBatchId={selectedBatch}
        callbackAfterImport={() => componentRef.current.refreshData()}
      />

      <CustomModal setOpen={setOpenBatchTagTable} open={openBatchTagTable} header={'Batch Tags'} width={600}>
        <AppointmentLegendTable
          fromBatch
          visibleColumns={['tag_name']}
          callBackAfterSubmitFromAppointment={() => setRefresh((c) => c + 1)}
        />
      </CustomModal>
    </WaitForFacility>
  );
}

/* eslint-disable react/style-prop-object */
import { Avatar, Box, Typography } from '@mui/material';
import { CustomModal, CustomTabs, FileUpload, RegularButton } from 'core/components';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CameraOutlinedIcon from '@mui/icons-material/CameraOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { SIZES } from 'theme/constants';
import Webcam from 'react-webcam';
import { updatePatientProfilePhoto } from 'company/api/patient';
import { useSnackbar } from 'notistack';

interface ImageUploadProps {
  picture: any;
  setPicture: (value: any) => void;
  patient_id: number;
  facility_id: number;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ picture, setPicture, patient_id, facility_id }) => {
  const [isHovering, setIsHovering] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [openUpdateProfile, setOpenUpdateProfile] = useState(false);
  const [cameraError, setCameraError] = useState(false);
  const [loading, setLoading] = useState(false);
  const webcamRef: any = useRef(null);

  // const capture = useCallback(async () => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   const arr = imageSrc.split(',');
  //   const mime = arr[0].match(/:(.*?);/)[1]; // Extract MIME type
  //   const bstr = atob(arr[1]); // Decode base64 string to binary
  //   let n = bstr.length;
  //   const u8arr = new Uint8Array(n);

  //   // Convert binary data to Uint8Array
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }

  //   // Create a Blob from the binary data and specify the MIME type
  //   const blob = new Blob([u8arr], { type: mime });
  //   const file = new File([blob], 'profile.jpeg', { type: mime });

  //   const formData = new FormData();
  //   formData.append('file', file);
  //   formData.append('source', 'profile');
  //   formData.append('file_name', file.name);
  //   formData.append('file_type', file.type);
  //   formData.append('file_size', file.size + '');

  //   try {
  //     setLoading(true);
  //     await handleUpdateProfile(formData);
  //   } finally {
  //     setLoading(false);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [webcamRef]);

  // const handleUpdateProfile = async (formData: any) => {
  //   try {
  //     const { data } = await updatePatientProfilePhoto(facility_id, patient_id, formData);
  //     setPicture(data.file_url);
  //     enqueueSnackbar('Image successfully uploaded!', { variant: 'success' });
  //     setOpenUpdateProfile(false);
  //     setIsHovering(false);
  //   } catch (error) {
  //     enqueueSnackbar('Error occurred! Unable to upload image.', { variant: 'error' });
  //   }
  // };

  const handleUpdateProfile = useCallback(
    async (formData: any) => {
      try {
        const { data } = await updatePatientProfilePhoto(facility_id, patient_id, formData);
        setPicture(data.file_url);
        enqueueSnackbar('Image successfully uploaded!', { variant: 'success' });
        setOpenUpdateProfile(false);
        setIsHovering(false);
      } catch (error) {
        enqueueSnackbar('Error occurred! Unable to upload image.', { variant: 'error' });
      }
    },
    [facility_id, patient_id]
  );

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const arr = imageSrc.split(',');
    const mime = arr[0].match(/:(.*?);/)[1]; // Extract MIME type
    const bstr = atob(arr[1]); // Decode base64 string to binary
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const blob = new Blob([u8arr], { type: mime });
    const file = new File([blob], 'profile.jpeg', { type: mime });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('source', 'profile');
    formData.append('file_name', file.name);
    formData.append('file_type', file.type);
    formData.append('file_size', file.size + '');

    try {
      setLoading(true);
      await handleUpdateProfile(formData);
    } finally {
      setLoading(false);
    }
  }, [webcamRef, handleUpdateProfile]);

  useEffect(() => {
    setIsHovering(false);
    setOpenUpdateProfile(false);
    setCameraError(false);
    setLoading(false);
  }, [patient_id]);

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Box>
        <Box onClick={() => setOpenUpdateProfile(true)}>
          {picture ? (
            <Avatar
              alt="Profile"
              src={picture}
              sx={{ width: '170px', height: '170px', cursor: 'pointer', opacity: isHovering ? 0.7 : 1 }}
              onClick={() => fileInputRef.current?.click()}
            />
          ) : (
            <Avatar
              sx={{ width: '170px', height: '170px', cursor: 'pointer', opacity: isHovering ? 0.7 : 1 }}
              onClick={() => fileInputRef.current?.click()}
            >
              <PersonIcon sx={{ fontSize: '80px', color: 'gray' }} />
            </Avatar>
          )}
        </Box>

        {isHovering && (
          <div
            style={{
              position: 'absolute',
              top: '55%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              cursor: 'pointer',
            }}
            onClick={() => setOpenUpdateProfile(true)}
          >
            <CameraAltIcon />
          </div>
        )}
      </Box>

      <CustomModal
        open={openUpdateProfile}
        setOpen={setOpenUpdateProfile}
        header="Upload Profile Picture"
        onClose={() => setIsHovering(false)}
        width={700}
      >
        <CustomTabs
          tabs={[
            {
              label: 'Use Camera',
              icon: <CameraAltOutlinedIcon />,
              content: (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  gap={SIZES.padding}
                >
                  <Webcam
                    audio={false}
                    height={cameraError ? 0 : 500}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={500}
                    videoConstraints={{ width: 700, height: 700, facingMode: 'user' }}
                    onUserMediaError={(error) => {
                      if (error) setCameraError(true);
                    }}
                  />
                  {!cameraError && (
                    <RegularButton
                      onClick={capture}
                      label="Capture Photo"
                      startIcon={<CameraOutlinedIcon />}
                      loading={loading}
                    />
                  )}
                  {cameraError && (
                    <Box>
                      <div id="camera-error-message">
                        <strong>Camera Access Denied:</strong> Your browser has not enabled the camera.
                        <br />
                        <br />
                        <em>To resolve this issue:</em>
                        <ul>
                          <li>Check if your browser is blocking camera access.</li>
                          <li>Ensure that you've granted the website permission to use your camera.</li>
                          <li>
                            If prompted, select <strong>"Allow"</strong> when your browser asks for camera access.
                          </li>
                          <li>
                            If the camera is still not working, follow these steps for your browser:
                            <ul>
                              <li>
                                <strong>Google Chrome / Microsoft Edge:</strong>
                                <ol>
                                  <li>Click on the lock icon next to the address bar.</li>
                                  <li>
                                    In the dropdown, ensure <strong>Camera</strong> is set to <strong>Allow</strong>.
                                  </li>
                                  <li>Reload the page.</li>
                                </ol>
                              </li>
                              <li>
                                <strong>Firefox:</strong>
                                <ol>
                                  <li>Click on the camera icon in the address bar.</li>
                                  <li>
                                    Ensure the camera permission is set to <strong>Allow</strong>.
                                  </li>
                                  <li>Reload the page.</li>
                                </ol>
                              </li>
                              <li>
                                <strong>Safari (macOS):</strong>
                                <ol>
                                  <li>
                                    Go to <strong>Safari &gt; Preferences &gt; Websites</strong>.
                                  </li>
                                  <li>
                                    Select <strong>Camera</strong> in the sidebar and set the website permission to{' '}
                                    <strong>Allow</strong>.
                                  </li>
                                  <li>Reload the page.</li>
                                </ol>
                              </li>
                            </ul>
                          </li>
                        </ul>
                        If the issue persists, ensure that your device's camera is working correctly and is not being
                        used by another application.
                      </div>
                    </Box>
                  )}
                </Box>
              ),
            },
            {
              label: 'Upload',
              icon: <FileUploadOutlinedIcon />,
              content: (
                <FileUpload
                  source={'profile'}
                  uploadOnly
                  fileTypes={{ 'image/*': [] }}
                  maxFilesAllowed={1}
                  maxFilesonDrop={1}
                  handleUploadFile={handleUpdateProfile}
                />
              ),
            },
          ]}
          tabGroupId="pic"
        />
      </CustomModal>
    </div>
  );
};

export default ImageUpload;

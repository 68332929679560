import { Box, Typography, useMediaQuery } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';

interface ResultItem {
  icon: string;
  value: string;
  description: string;
}

const Scroll3 = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const isTablet = useMediaQuery('(max-width:900px)');
  const [isPaused, setIsPaused] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const results: ResultItem[] = [
    {
      icon: './assets/LandingMobile/1-icon_healthcare.png',
      value: '23%',
      description: 'Increased availed clinic services',
    },
    {
      icon: './assets/LandingMobile/3-icon_patients.png',
      value: '1,704',
      description: 'Average additional patients served by CareGo Health Suite',
    },
    {
      icon: './assets/LandingMobile/2-icon_patients.png',
      value: '100%',
      description: 'Increased patient retention',
    },
    {
      icon: './assets/Landing2/p3/graph.png',
      value: '100%',
      description: 'Month-on-month growth in partner clinics',
    },
    {
      icon: './assets/Landing2/p3/tower.png',
      value: 'Nationwide',
      description: 'CareGo works with 10 provinces and regions to improve health care in the Philippines',
    },
    {
      icon: './assets/Landing2/p3/clock.png',
      value: '75%',
      description: 'Faster turnaround for scheduling, recording transactions, and reminding patients',
    },
    {
      icon: './assets/Landing2/p3/medical-appointment.png',
      value: '55,000+',
      description: 'Medical appointments and sessions facilitated',
    },
    {
      icon: './assets/Landing2/p3/income-insurance.png',
      value: '₱50,000+',
      description: 'Recovered in monthly income losses',
    },
    {
      icon: './assets/Landing2/p3/philippine-peso.png',
      value: '₱1.7 M',
      description: 'Processed monthly in clinic payments and reimbursements',
    },
    {
      icon: './assets/Landing2/p3/group.png',
      value: '6,300+',
      description: 'Patients served per month on average on CareGo Health Suite',
    },
    {
      icon: './assets/Landing2/p3/medical-record.png',
      value: '100,000+',
      description: 'Digital patient records kept secure and safe',
    },
    {
      icon: './assets/Landing2/p3/monitoring.png',
      value: '30x',
      description: 'More efficient annual health facility assessments, cutting 2 months to 2 days',
    },
  ];

  let itemsPerView = isMobile ? 1 : isTablet ? 2 : 3;
  const totalSections = Math.ceil(results.length / itemsPerView);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (!isPaused) {
      interval = setInterval(() => {
        setActiveIndex((prev) => (prev + 1) % totalSections);
      }, 8000);
    }

    return () => clearInterval(interval);
  }, [isPaused, totalSections]);

  return (
    <Box
      sx={{
        width: '100%',
        py: { xs: 4, md: 8 },
        px: { xs: 2, md: 4 },
        bgcolor: '#fff',
        overflow: 'hidden',
        height: 'auto',
        minHeight: { md: '450px' },
      }}
    >
      <Box sx={{ textAlign: 'center', mb: { xs: 4, md: 6 } }}>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 'bold',
            mb: 0,
            background: 'linear-gradient(to right, #239bd7, #0463ae)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            display: 'inline-block',
          }}
        >
          CareGo's Impact <br />
          on Clinics and Patients
        </Typography>
      </Box>

      <Box
        sx={{
          position: 'relative',
          maxWidth: '1200px',
          mx: 'auto',
          overflow: 'hidden',
        }}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <Box
          sx={{
            display: 'flex',
            overflowX: 'hidden',
            gap: { xs: 2, sm: 3, md: 4 },
            justifyContent: 'center',
            py: 2,
            width: '100%',
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={activeIndex}
              initial={{ x: '100%' }}
              animate={{ x: '0%' }}
              exit={{ x: '-100%' }}
              transition={{ duration: 1 }}
              style={{
                display: 'flex',
                gap: '2rem',
                flexShrink: 0,
                justifyContent: 'center',
                width: '100%',
              }}
            >
              {results.slice(activeIndex * itemsPerView, (activeIndex + 1) * itemsPerView).map((result, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                  style={{
                    width: isMobile ? '100%' : isTablet ? '45%' : '30%',
                    flexShrink: 0,
                  }}
                >
                  <Box
                    sx={{
                      p: { xs: 2, sm: 3, md: 4 },
                      borderRadius: 3,
                      bgcolor: 'white',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      height: '100%',
                    }}
                  >
                    <Box
                      component="img"
                      src={result.icon}
                      alt={result.description}
                      sx={{
                        height: { xs: 40, sm: 50, md: 60 },
                        width: 'auto',
                        mb: 1,
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                        fontWeight: 'bold',
                        background: 'linear-gradient(to right, #239bd7, #0463ae)',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        mb: 2,
                      }}
                    >
                      {result.value}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#666',
                        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                      }}
                    >
                      {result.description}
                    </Typography>
                  </Box>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        </Box>
      </Box>
    </Box>
  );
};

export default Scroll3;

import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getInventories, getInventory, getVariantsOfProduct } from 'company/api/inventories';
import { useCallback, useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import WaitForFacility from '../WaitForFacility';
import { formatProductVariantName } from 'core/utils';

interface VariantSearcProps extends TemplateDropdownProps {
  product_id?: number;
}

const ProductVariantSearchDropdown: React.FC<VariantSearcProps> = (props: VariantSearcProps) => {
  const { facility } = useContext(FacilityContext);
  const [refresh, setRefresh] = useState(0);
  const [productId, setProductId] = useState(props.product_id);

  useEffect(() => {
    setProductId(props.product_id);
    setTimeout(() => {
      setRefresh((prev) => prev + 1);
    }, 1000);
  }, [props.product_id]);

  const fetchData = useCallback(
    (query: ApiQuery) => {
      return productId ? getVariantsOfProduct(productId, query) : getInventories(facility.id, query);
    },
    [productId, facility?.id]
  );

  return (
    <WaitForFacility facility={facility}>
      <TemplateDropdown
        {...props}
        refresh={refresh}
        entity={'Product Variant'}
        getData={(query: ApiQuery) => fetchData(query)}
        getById={getInventory}
        processOption={(variant) => ({
          key: formatProductVariantName(variant),
          value: variant.variant_id,
          data: variant,
        })}
        processNewRecord={(record) => record.variant}
      />
    </WaitForFacility>
  );
};

export default ProductVariantSearchDropdown;

/* eslint-disable react-hooks/exhaustive-deps */

import {
  PNT_ENTITY_NAME,
  patientNoteTemplateFields,
  patientNoteTemplateTableColumns,
} from './PatientNoteTemplateModel';
import { TableActionProps, TableComponentProps } from 'core/PageBuilder/CareGoTable';
import { deleteTemplate, getTemplates } from 'company/api/note-templates';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { CompanyServiceModel } from 'company/model/Entities';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import PatientNoteTemplateForm from './PatientNoteTemplateForm';
import TemplateTable from '../../../Template/TemplateTable';
import { FacilityContext } from 'core/context/facility.context';

type Props = {
  serviceId?: number;
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
  hideAddUpdateDeleteAction?: boolean;
  tableAction?: TableActionProps;
  tableComponent?: TableComponentProps;
  hideAddButton?: boolean;
  overrideGetData?: (query: ApiQuery) => Promise<any>;
};

const PatientNoteTemplatesTable = forwardRef((props: Props, ref) => {
  const {
    serviceId,
    visibleColumns,
    setDrawerSelectedRow,
    drawerSelectedRow,
    hideAddUpdateDeleteAction,
    tableAction,
    tableComponent,
    hideAddButton,
    tableRef,
    overrideGetData,
  } = props;

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const [selectedRow, setSelectedRow] = useState<CompanyServiceModel>();
  const { facility } = useContext(FacilityContext);
  const getData = (query: ApiQuery) => {
    if (overrideGetData) {
      return overrideGetData(query);
    }
    if (serviceId) {
      query = { ...query, service_id: serviceId };
    }
    return getTemplates(facility.id, query);
  };

  const handleDelete = (data: any) => {
    return deleteTemplate(data);
  };

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  useEffect(() => {
    if (serviceId) mainRef.current.refreshTable();
  }, [serviceId]);

  return (
    <TemplateTable
      ref={mainRef}
      entityName={PNT_ENTITY_NAME}
      fields={patientNoteTemplateFields}
      visibleColumns={visibleColumns ?? patientNoteTemplateTableColumns}
      getData={getData}
      handleDelete={handleDelete}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      drawerSelectedRow={drawerSelectedRow}
      setDrawerSelectedRow={setDrawerSelectedRow}
      hideAddUpdateDeleteAction={hideAddUpdateDeleteAction}
      hideAddButton={hideAddButton}
      tableAction={tableAction}
      tableComponent={tableComponent}
      templateForm={<PatientNoteTemplateForm template={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />}
      processFormInitialValues={(data) => {
        data.options =
          data.options &&
          data.options.map((collection: any) => {
            return { key: collection.name, value: collection.id };
          });
      }}
    />
  );
});

export default PatientNoteTemplatesTable;

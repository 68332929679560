/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, Typography } from '@mui/material';
import { ConfirmationDialog, PrimaryButton, RegularButton } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { addProducts, getProductsNotAdded } from 'company/api/appointment-products';
import { formatNumber, formatProductVariantName } from 'core/utils';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import { PageBuilderTableType } from 'core/PageBuilder';
import { addInvoiceItems } from 'company/api/invoice-items';
import { getInvoiceAvailableProducts } from 'company/api/products';
import { getInvoiceAvailableServices } from 'company/api/services';
import { useSnackbar } from 'notistack';

type AddTableProps = {
  entity: string;
  refreshMainTable: () => void;
  isProduct?: boolean;
  invoice_id?: number;
  appointment_id?: number;
  patient_id?: number;
  refreshBillingTable?: () => void;
};

const AddTable: React.FC<AddTableProps> = ({
  entity,
  refreshMainTable,
  isProduct,
  invoice_id,
  appointment_id,
  patient_id,
  refreshBillingTable,
}) => {
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const [forceRefreshTable, setForceRefreshTable] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [alertContent, setAlertContent] = useState<string>('');
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const service_columns: GridColDef[] = [
    { field: 'service_name', headerName: 'Name', flex: 2 },
    {
      field: 'clinic_price',
      headerName: 'Price',
      flex: 1,
      renderCell: (params) =>
        formatNumber(parseFloat(params.row.clinic_price)) ? formatNumber(parseFloat(params.row.clinic_price)) : '0',
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={(event) => {
              setOpenAddDialog(true);
              setAlertContent('Are you sure you want to add ' + params.row.service_name + '?');
              setSelectedRow(params.row);
              setSelectedRows([]);
              event.stopPropagation();
            }}
          >
            Add
          </Button>
        </Box>
      ),
    },
  ];

  const product_columns: GridColDef[] = [
    {
      field: 'product_name',
      headerName: 'Name',
      flex: 2,
      renderCell: ({ value, row }) => <Typography>{formatProductVariantName(row)}</Typography>,
    },
    {
      field: 'unit_price',
      headerName: 'Price',
      flex: 1,
      renderCell: (params) =>
        formatNumber(parseFloat(params.row.unit_price)) ? formatNumber(parseFloat(params.row.unit_price)) : '0',
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <RegularButton
          label="Add"
          variant="outlined"
          color="success"
          size="small"
          onClick={(event) => {
            setOpenAddDialog(true);
            setAlertContent('Are you sure you want to add ' + params.row.item_name + '?');
            setSelectedRow(params.row);
            setSelectedRows([]);
            event.stopPropagation();
          }}
        />
      ),
    },
  ];

  const [columns] = useState<GridColDef[]>(isProduct ? product_columns : service_columns);

  const getList = (query: ApiQuery) => {
    if (isProduct) {
      if (invoice_id) {
        return getInvoiceAvailableProducts(facility.id, invoice_id, query);
      } else {
        return appointment_id && getProductsNotAdded(facility.id, appointment_id, query);
      }
    } else {
      return invoice_id && getInvoiceAvailableServices(facility.id, invoice_id, query);
    }
  };

  const handleSelectRows = (selected: any) => {
    // setSelectedRow(0);
    // setSelectedRows(selected);
    setSelectedRows(Array.from(selected));
  };

  const refreshTable = () => {
    setForceRefreshTable((prev: number) => prev + 1);
  };

  const showAddDialog = (content: string) => {
    if ((selectedRows.length && !selectedRow) || selectedRow) {
      setOpenAddDialog(true);
      setAlertContent(content);
    }
  };

  const handleAdd = () => {
    const dataList = JSON.stringify(selectedRow ? [selectedRow.id] : selectedRows);
    let data = { data: dataList, item_type: isProduct ? 'product' : 'service' };

    if (invoice_id) {
      return addInvoiceItems(facility.id, invoice_id, data).then(() => {
        const itemLabel = isProduct ? 'Product' : 'Service';
        const pluralSuffix = selectedRows.length > 1 || (selectedRow && selectedRow.length > 1) ? 's' : '';

        enqueueSnackbar(`${itemLabel}${pluralSuffix} successfully added!`, { variant: 'success' });
        refreshMainTable();
        refreshTable();
        refreshBillingTable && refreshBillingTable();
      });
    } else if (appointment_id && patient_id) {
      let data2 = {
        ...data,
        patient_id: patient_id,
      };
      return addProducts(facility.id, appointment_id, data2).then(() => {
        const pluralSuffix = selectedRows.length > 1 || (selectedRow && selectedRow.length > 1) ? 's' : '';

        enqueueSnackbar(`Product${pluralSuffix} successfully added!`, { variant: 'success' });
        refreshMainTable();
        refreshTable();
      });
    }
  };

  const content = {
    type: BLOCK_TYPE.TABLE,
    entityName: entity,
    useColoredTable: true,
    tableComponent: {
      columns: columns,
      getData: getList,
      forceRefresh: forceRefreshTable,
      checkboxSelection: true,
      selectableRow: true,
      hideAddSelectedButton: true,
      rowHeight: 30,
      onSelectRow: handleSelectRows,
      rowId: 'id',
      headerComponent: (
        <Box display="flex" gap="10px">
          <PrimaryButton
            onClick={() => {
              if (selectedRows.length) {
                const itemType = isProduct ? 'product' : 'service';
                const itemCount = selectedRows.length;
                const pluralizedItem = itemCount > 1 ? `${itemType}s` : itemType;

                showAddDialog(`Are you sure you want to add ${itemCount} ${pluralizedItem}?`);
              } else {
                enqueueSnackbar('Invalid action. Select ' + entity + ' first!', { variant: 'error' });
              }
            }}
            label={'Add Selected ' + entity}
            disabled={!selectedRows.length}
          />
        </Box>
      ),
    },
  } as PageBuilderTableType;

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? <CustomLoadingIndicator /> : <PageBuilder content={content} />}

      <ConfirmationDialog
        open={openAddDialog}
        setOpen={setOpenAddDialog}
        content={alertContent}
        onConfirm={handleAdd}
        confirmButtonColor
      />
    </>
  );
};

export default AddTable;

/* eslint-disable react-hooks/exhaustive-deps */

import { ForbiddenScreen, LoadingScreen } from 'core/screens';
import { useContext, useEffect, useState } from 'react';

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import Campaigns from '../Campaigns';
import { CareGoPage } from 'core/PageBuilder';
import Clinics from './components/Clinic/Clinics';
import CompanyPrintSettings from '../DocumentEditor/CompanyPrintSettings';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Doctors from './components/Doctors/Doctors';
import ExportedReports from '../ExportedReports';
import HealthPackage from '../HealthPackage/HealthPackage';
import ICDCodeTable from 'company/entities/modules/ClinicManagement/ICDCode/ICDCodeTable';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import Medicines from './components/Medicines/Medicines';
import PinIcon from '@mui/icons-material/Pin';
import Services from './components/Services/Services';
import SmsTemplatesScreen from '../SMS/SmsTemplatesScreen';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { TabProps } from 'core/components/CustomTabs';
import Templates from '../Templates';
import { TourContext } from 'core/context/tour.context';
import UserAccess from './components/UserAccess/UserAccess';
import { UserContext } from 'core/context/user.context';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';
import CompanyPaymentModes from './components/PaymentModes/CompanyPaymentModes';

const ClinicManagement = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selected, setSelected] = useState<number>(0);
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user?.company?.is_active) {
      navigate('/company');
    }
  }, [user]);

  const requiredPermissions = [
    'MANAGE_CLINICS',
    'MANAGE_CLINIC_USER_ACCESS',
    'MANAGE_CLINIC_SERVICES',
    'MANAGE_CLINIC_SERVICE_PROVIDERS',
    'MANAGE_CLINIC_HEALTH_SESSIONS',
    'MANAGE_CLINIC_MEDICINES',
    'MANAGE_CLINIC_RECORD_TEMPLATES',
    'MANAGE_CLINIC_CAMPAIGNS',
    'MANAGE_CLINIC_SMS',
    'MANAGE_CLINIC_ICD_10_CODES',
    'MANAGE_CLINIC_PRINT_SETTINGS',
  ];

  const hasAnyPermission = user?.authorities?.some((auth) => requiredPermissions.includes(auth));

  const tabs: TabProps[] = [
    {
      label: 'Clinic',
      content: <Clinics />,
      icon: <LocalHospitalIcon />,
      hidden: !user?.authorities?.includes('MANAGE_CLINICS'),
    },
    {
      label: 'User Access',
      content: <UserAccess selectedTab={selected} forceRefresh={refresh} setForceRefresh={setRefresh} />,
      icon: <AccountBoxOutlinedIcon />,
      id: 'user-access-tab',
      hidden: !user?.authorities?.includes('MANAGE_CLINIC_USER_ACCESS'),
    },
    {
      label: 'Services',
      content: <Services />,
      icon: <MedicalInformationOutlinedIcon />,
      id: 'services-tab',
      hidden: !user?.authorities?.includes('MANAGE_CLINIC_SERVICES'),
    },
    {
      label: 'Service Providers',
      content: <Doctors selectedTab={selected} />,
      icon: <SupervisedUserCircleOutlinedIcon />,
      id: 'provider-tab',
      hidden: !user?.authorities?.includes('MANAGE_CLINIC_SERVICE_PROVIDERS'),
    },
    {
      label: 'Health Sessions',
      content: <HealthPackage />,
      icon: <MedicalServicesOutlinedIcon />,
      id: 'health-packages-tab',
      hidden: !user?.authorities?.includes('MANAGE_CLINIC_HEALTH_SESSIONS'),
    },
    {
      label: 'Medicines',
      content: <Medicines />,
      icon: <VaccinesOutlinedIcon />,
      id: 'medicines-tab',
      hidden: !user?.authorities?.includes('MANAGE_CLINIC_MEDICINES'),
    },
    {
      label: 'Record Templates',
      content: <Templates />,
      icon: <DescriptionOutlinedIcon />,
      id: 'templates-tab',
      hidden: !user?.authorities?.includes('MANAGE_CLINIC_RECORD_TEMPLATES'),
    },
    {
      label: 'Exported Reports',
      content: <ExportedReports />,
      icon: <IosShareOutlinedIcon />,
      id: 'exported-reports-tab',
      // hidden: !user?.authorities?.includes('MANAGE_CLINIC_RECORD_TEMPLATES'),
    },
    {
      label: 'Campaigns',
      content: <Campaigns />,
      icon: <CampaignOutlinedIcon />,
      id: 'campaigns-tab',
      hidden: !user?.company?.with_campaigns || !user?.authorities?.includes('MANAGE_CLINIC_CAMPAIGNS'),
    },
    {
      label: 'SMS',
      content: <SmsTemplatesScreen />,
      icon: <SpeakerNotesOutlinedIcon />,
      id: 'sms-tab',
      hidden: !user?.authorities?.includes('MANAGE_CLINIC_SMS'),
    },
    {
      label: 'ICD-10 Codes',
      content: (
        <CareGoPage>
          <ICDCodeTable />
        </CareGoPage>
      ),
      icon: <PinIcon />,
      id: 'icd-codes-tab',
      hidden: !user?.authorities?.includes('MANAGE_CLINIC_ICD_10_CODES') || !isDevMode(),
    },
    {
      label: 'Print Settings',
      content: <CompanyPrintSettings />,
      icon: <LocalPrintshopOutlinedIcon />,
      id: 'print-tab',
      hidden: !user?.authorities?.includes('MANAGE_CLINIC_PRINT_SETTINGS'),
    },

    {
      label: 'Payment Modes',
      content: <CompanyPaymentModes />,
      icon: <PaymentsOutlinedIcon />,
      id: 'mop-tab',
      // hidden: !user?.authorities?.includes('MANAGE_CLINIC_PRINT_SETTINGS'),
    },
    // {
    //   label: 'Products',
    //   content: <Products selectedTab={selected} viewInPatientProfile={true} />,
    //   icon: <CardGiftcardIcon />,
    // },
  ];

  const {
    tourState: { tourActive },
    setTourState,
  } = useContext(TourContext);

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setTourState((prev: any) => {
          return { ...prev, stepIndex: 2 };
        });
      }, 300);
    }
  });

  useEffect(() => {
    setBreadcrumb([{ label: 'Clinic Settings' }]);
  }, []);

  useEffect(() => {
    setRefresh((refresh) => refresh + 1);
  }, [selected]);

  useEffect(() => {
    if (user) {
      setIsLoading(false);
    }
  }, [user]);

  if (!hasAnyPermission && !isLoading) {
    return <ForbiddenScreen />;
  }

  return (
    <LoadingScreen loading={isLoading}>
      <CareGoPage
        header="Clinic Settings"
        tabs={tabs}
        selected={selected}
        tabGroupId="settings"
        setSelected={setSelected}
        tabMinWidth="160px"
      ></CareGoPage>
    </LoadingScreen>
  );
};

export default ClinicManagement;

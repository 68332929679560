import { ApiQuery } from 'core/model/interface';
import { Products } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'products';

export const getProduct = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getProducts = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, {
    params: query,
  });
};

export const getClinicProducts = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/clinic-products`, {
    params: query,
  });
};

export const createClinicProduct = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateClinicProduct = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteClinicProduct = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const updateClinicProductPrice = (clinic_id: number, id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${clinic_id}/${id}`, data);
};

export const removeProductsFromClinic = (clinic_id: number, products: Products) => {
  return axios.delete(`${baseURL}/${path}/clinic/${clinic_id}`, { data: products });
};

export const assignProductToClinic = (clinic_id: number, products: any) => {
  return axios.post(`${baseURL}/${path}/clinic/${clinic_id}`, products);
};

export const getProductsAssigned = (clinic_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/assigned/${clinic_id}`, { params: query });
};

export const getProductsNotAssigned = (clinic_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/not-assigned/${clinic_id}`, { params: query });
};

export const updateProductVisibility = (clinic_id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/clinic/${clinic_id}`, data);
};

export const getInvoiceAvailableProducts = (clinic_id: number, invoice_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/invoice/${clinic_id}/${invoice_id}`, {
    params: query,
  });
};

export const exportProductDirectory = (facility_id: number, clinic_name: string, query?: ApiQuery) => {
  return axios
    .post(
      `${baseURL}/${path}/export/${facility_id}`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', clinic_name + ' - Product Directory.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

// export const exportProductVariants = (
//   facility_id: number,
//   clinic_name: string,
//   product_id: number,
//   query?: ApiQuery
// ) => {
//   return axios
//     .post(
//       `${baseURL}/${path}/export/${facility_id}/${product_id}`,
//       {},
//       {
//         params: query,
//         headers: {
//           'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
//         },
//       }
//     )
//     .then((response) => {
//       const link = document.createElement('a');
//       link.href = response.data;
//       link.setAttribute('download', clinic_name + ' - Product Directory.xlsx');
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     });
// };

import { PatientNoteModel } from 'company/model/Entities';

export const medical_history_malvar = [
  'Tuberculosis',
  'Nose or Throat Disorder',
  'Liver Disease',
  'Asthma',
  'Skin Disease',
  'Fainting Spells, Fits or Seizures',
  'Other Lung Disease',
  'Cancer or Tumor',
  'Frequent Headaches, Migraine',
  'Hypertension',
  'Musculoskeletal Disorder',
  'Mental Disorders',
  'Heart Disease',
  'Stomach Pain or Ulcer',
  'Sexually Transmitted Disease',
  'Rheumatic Fever',
  'Typhoid or Paratyphoid Fever',
  'Genetic or Familial Disorders',
  'Diabetes Mellitus',
  'Malaria',
  'Others',
  'Endocrine Disorders',
  'Other Infectious Disease',
  'Eye or Ear Problems',
  'Kidney Disease',
];

export const medical_history_malvar_2 = [
  'Present Symptoms',
  'Other Past Medical History',
  'Family Medical History',
  'Operations and Accidents',
  'Allergies',
  'OB & Menstrual History',
  // 'Personal / Social History'
  'OB Score',
  'Smoking History',
  'If "Smoker" is selected, indicate duration',
  'Alcohol Consumption',
  // 'Other Present Symptoms',
];

export const physical_exam_malvar = [
  'Skin',
  'Head/Scalp',
  'Eyes',
  'Ears',
  'Nose/Sinuses',
  'Throat',
  'Neck',
  'Heart',
  'Lungs',
  'Chest/Breast',
  'Abdomen',
  'Anus/Rectum',
  'Genito Urinary',
  'Musculoskeletal',
  'Neurological Exam',
  'Hernia Check',
  'Mental & Emotion',
  // 'Muscoloskeletal',
  // 'Anus',
  // 'Genitals',
  // 'Extremities',
  // 'Skin',
];

export const medical_history_mapping = medical_history_malvar.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Medical History',
    source_type: 'table',
    table_name: '',
    table_column_name: 'Past Medical History',
    table_column_name_answer: 'Yes or No',
    more_info: '',
  };
  return acc;
}, {});

export const physical_exam_mapping_malvar = physical_exam_malvar.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Physical Exam',
    source_type: 'table',
    table_name: '',
    table_column_name: 'Physical Exam',
    table_column_name_answer: 'Findings',
  };
  return acc;
}, {});
export const medical_history_mapping_malvar_2 = medical_history_malvar_2.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Medical History',
  };
  return acc;
}, {});

export const vital_signs_malvar = [
  "Height (ft' in)",
  // 'Height (m)',
  'Weight (lbs)',
  'BMI',
  'BMI Class',
  'Blood Pressure (mmHg)',
  'Pulse Rate (per min)',
  'Respiratory Rate (per min)',
  'Temperature (°C)',
  'Blood Pressure Class',
  // 'Weight (kg)',
];

export const vital_signs_mapping_malvar = vital_signs_malvar.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Vital Signs',
  };
  return acc;
}, {});

export const eye_test_malvar = ['Eye Test', 'OD', 'OS', 'OU', 'Color Vision (Ishihara)'];

export const eye_test_mapping = eye_test_malvar.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Eye Test',
  };
  return acc;
}, {});
export const xray_malvar = ['RESULT', 'IMPRESSION', 'X-RAY VIEW', 'SIGNIFICANT FINDINGS', 'RECOMMENDATION'];
export const xray_mapping_malvar = xray_malvar.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Radiologic Findings',
  };
  return acc;
}, {});
export const medical_history_index_malvar = [
  1, 10, 19, 2, 11, 20, 3, 12, 21, 4, 13, 22, 5, 14, 23, 6, 15, 24, 7, 16, 25, 8, 17, 9, 18,
];

export const urinalysis = [
  'Color',
  'Transparency',
  'pH',
  'Specific Gravity',
  'Protein',
  'Sugar',
  'WBC',
  'RBC',
  'Epithelial Cells',
  'Mucus Threads',
  'Bacteria',
  'Crystals',
];

export const urinalysis_mapping = urinalysis.reduce(
  (acc: any, condition) => {
    acc['U_' + condition] = {
      template_name: 'Urinalysis',
    };
    return acc;
  },
  {
    'Remarks (Significant Findings based on Result)': {
      template_name: 'Urinalysis',
    },
    'With Menstruation': {
      template_name: 'Urinalysis',
    },
  }
);

export const fecalysis = ['Color', 'Consistency', 'WBC', 'RBC', 'Parasites', 'Parasite Seen'];
export const fecalysis_mapping = fecalysis.reduce(
  (acc: any, condition) => {
    acc['F_' + condition] = {
      template_name: 'Fecalysis',
    };
    return acc;
  },
  {
    Remarks: {
      template_name: 'Fecalysis',
    },
    'Summary Remarks': {
      template_name: 'Fecalysis',
    },
  }
);

export const summary_mapping = {
  ...medical_history_mapping,
  ...physical_exam_mapping_malvar,
  ...medical_history_mapping_malvar_2,
  ...vital_signs_mapping_malvar,
  ...eye_test_mapping,
  ...xray_mapping_malvar,
  ...urinalysis_mapping,
  ...fecalysis_mapping,

  'Hepatitis B Result': {
    template_name: 'Hepatitis B Screening (HBsAg)',
  },
  'Hepatitis A Result': {
    template_name: 'Hepatitis A Screening (HAV IgM)',
  },
  'Drug Test': {
    template_name: 'Other Tests Findings',
  },
  Hemoglobin: {
    template_name: 'Hematology',
    source_type: 'table',
    table_name: '',
    table_column_name: 'Test',
    table_column_name_answer: 'Level',
  },
  Hematocrit: {
    template_name: 'Hematology',
    source_type: 'table',
    table_name: '',
    table_column_name: 'Test',
    table_column_name_answer: 'Level',
  },
};

export const getAnswerMalvarForm = (
  field: string,
  notes: PatientNoteModel[],
  defaultValue?: string,
  occurence?: number,
  prefix?: string,
  columns?: string[]
): string => {
  const defValue = defaultValue ?? '-';
  const defOccurence = occurence ?? 1;
  const source_mapping = summary_mapping as any;
  const fieldKey = prefix ? prefix + field : field;
  const mapping = source_mapping[fieldKey];
  if (mapping) {
    const note = notes.find(
      (note) =>
        note.header.toLowerCase().includes(mapping.template_name.toLowerCase()) ||
        note.template_id === mapping.template_id
    );

    if (note) {
      const body = note.template_body[0];
      const responses = note.responses;
      if (body) {
        if (mapping.source_type === 'table') {
          const tableField = body.fields.find(
            (field: any) =>
              (mapping.table_name && field.display_name === mapping.table_name) ||
              (!mapping.table_name && field.type === 'table')
          );

          if (tableField) {
            const questionColumn = tableField.columns.find(
              (column: any) => column.displayName === mapping.table_column_name
            );

            const rowResponses = responses[tableField.field_name];
            if (rowResponses) {
              const response = rowResponses.find((response: any) => {
                return response[questionColumn.fieldName] === field;
              });

              if (response) {
                if (columns && columns.length > 0) {
                  // Format columns into a string for easy display
                  return columns
                    .map((columnName) => {
                      const column = tableField.columns.find((col: any) => col.displayName === columnName);
                      return response[column?.fieldName] ? `${columnName}: ${response[column?.fieldName]}` : defValue;
                    })
                    .join(', ');
                } else {
                  // Default behavior: return only the main answer column
                  const answerColumn = tableField.columns.find(
                    (column: any) => column.displayName === mapping.table_column_name_answer
                  );
                  return response[answerColumn?.fieldName] || defValue;
                }
              }
            }
          }
        } else {
          let count = 0;
          const formField = body.fields.find((item: any) => {
            if (item.display_name?.trim() === field?.trim() && !item.section_header) {
              count++;
              return defOccurence === count;
            }
            return false;
          });

          return responses[formField?.field_name] || defValue;
        }
      }
    }
  }

  return defValue;
};

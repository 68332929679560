import {
  PNST_ENTITY_NAME,
  PatientNoteSummaryTemplateModel,
  patientNoteSummaryTemplateFields,
  patientNoteSummaryTemplateTableColumns,
} from './PatientNoteSummaryTemplateModel';
import { deleteNoteSummaryTemplate, getPatientNotesSummaryTemplates } from 'company/api/patient-note-summary-template';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import PatientNoteSummaryTemplateForm from './PatientNoteSummaryTemplateForm';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import TemplateTable from 'company/entities/Template/TemplateTable';
import TuneIcon from '@mui/icons-material/Tune';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';

import { useNavigate } from 'react-router-dom';
import { FacilityContext } from 'core/context/facility.context';
import { CustomModal } from 'core/components';
import ClinicsTable from 'company/screens/ClinicManagement/components/ClinicsTable';

type Props = {
  visibleColumns?: string[];
  tableRef?: any;
  tableAction?: TableActionProps;
};

const PatientNoteSummaryTemplateTable = forwardRef((props: Props, ref) => {
  const { facility } = useContext(FacilityContext);

  const { visibleColumns, tableRef } = props;
  const [selectedRow, setSelectedRow] = useState<PatientNoteSummaryTemplateModel>();
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const [openClinicAccessTable, setOpenClinicAccessTable] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);

  const getData = (query: ApiQuery) => {
    return getPatientNotesSummaryTemplates(facility.id, query);
  };

  const refreshTable = () => {
    if (mainRef.current) {
      mainRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  useEffect(() => {
    refreshTable();
  }, [refresh]);

  function callBackAfterSubmit() {
    refreshTable();
    mainRef.current?.closeFormModal();
  }

  const navigate = useNavigate();
  const update = (data: any) => {
    navigate(`/company/notes-summary/${data.id}`);
  };
  const handleDeleteNoteTemplateSummary = (data: any) => {
    return deleteNoteSummaryTemplate(data);
  };

  const rowActions = [
    {
      label: 'Customize Template',
      action: update,
      icon: <TuneIcon />,
    },
    {
      label: 'Update Clinic Access',
      action: () => setOpenClinicAccessTable(true),
      icon: <MapsHomeWorkOutlinedIcon />,
    },
  ];

  return (
    <>
      <TemplateTable
        tableAction={{
          rowActions,
        }}
        // hideAddUpdateDeleteAction
        handleDelete={(data) => handleDeleteNoteTemplateSummary(data)}
        // hideUpdateAction
        // hideDeleteAction

        useColoredTable
        ref={mainRef}
        entityName={PNST_ENTITY_NAME}
        fields={patientNoteSummaryTemplateFields}
        getData={getData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        visibleColumns={visibleColumns ?? patientNoteSummaryTemplateTableColumns}
        addActionDisplayOverwrite={`Create ${PNST_ENTITY_NAME}`}
        updateActionDisplayOverwrite={!!selectedRow ? `Update ${PNST_ENTITY_NAME}` : `Create ${PNST_ENTITY_NAME}`}
        templateForm={
          <PatientNoteSummaryTemplateForm callbackAfterSubmit={callBackAfterSubmit} healthAssessment={selectedRow} />
        }
      />
      <CustomModal
        setOpen={setOpenClinicAccessTable}
        open={openClinicAccessTable}
        header={`Update "${selectedRow?.template_name}" Clinic Access`}
      >
        <ClinicsTable
          source="Note Summary Template"
          selectedNoteSummaryTemplate={selectedRow}
          setRefreshExternalDetails={setRefresh}
        />
      </CustomModal>
    </>
  );
});

export default PatientNoteSummaryTemplateTable;

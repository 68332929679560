import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { formatCurrency, formatDate } from 'core/utils';

import { ApiQuery } from 'core/model/interface';
import { ColoredTable } from 'core/components';
import { GridColDef } from '@mui/x-data-grid';
import { getLotsForVariant } from 'company/api/inventories';

interface LotsTableProps {
  variantId: number;
  facilityId: number;
  refresh: any;
}

const LotsTable: React.FC<LotsTableProps> = ({ variantId, facilityId, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [tableKey, setTableKey] = useState(0);

  const refreshTable = useCallback(() => {
    setTableKey((prevKey) => prevKey + 1);
  }, []);

  useEffect(() => {
    refreshTable();
  }, [variantId, facilityId, refreshTable, refresh]);

  const headerContent = (
    <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5" fontWeight={'bold'}>
        Lot Number (Batches)
      </Typography>
    </Box>
  );

  const columns: GridColDef[] = [
    {
      field: 'lot_number',
      headerName: 'Lot Number',
      flex: 1,
      sortable: false,
    },
    {
      field: 'quantity_in_stock',
      headerName: 'Quantity in Stock',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      sortable: false,
    },
    {
      field: 'product_cost',
      headerName: 'Cost per item',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      sortable: false,
      renderCell: (params) => <Typography> {formatCurrency(params.value)}</Typography>,
    },
    {
      field: 'manufacturing_date',
      headerName: 'Manufacturing Date',
      flex: 1,
      sortable: false,
      renderCell: (params) => <Typography> {formatDate(params.value)}</Typography>,
    },
    {
      field: 'expiry_date',
      headerName: 'Expiry Date',
      flex: 1,
      sortable: false,
      renderCell: (params) => <Typography> {formatDate(params.value)}</Typography>,
    },
  ];

  const getData = async (query: ApiQuery) => {
    setLoading(true);
    try {
      const response = await getLotsForVariant(variantId, facilityId, query);
      return response;
    } catch (error) {
      console.error('Error fetching lots:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ColoredTable
      key={tableKey}
      headerComponent={headerContent}
      columns={columns}
      getData={getData}
      orderBy="expiry_date"
      order="asc"
      rowHeight={60}
    />
  );
};

export default LotsTable;

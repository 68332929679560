import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
  CategoryModel,
  PRODUCT_ENTITY_NAME,
  ProductModel,
  addProductFormFields,
  addProductInitialValues,
  createProductModelSchema,
  productFields,
  updateProductFormFields,
  updateProductInitialValues,
} from './ProductModel';
import { createClinicProduct, updateClinicProduct } from 'company/api/products';
import { FacilityContext } from 'core/context/facility.context';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { getProductCategories } from 'company/api/product-categories';
import ProductVariantForm from './ProductVariant/ProductVariantForm';
import { CustomModal } from 'core/components';

type Props = {
  product?: ProductModel;
  medicine?: boolean;
  readonly?: boolean;
  submitOnTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
  source?: string;
  readOnly?: boolean;
};

const ProductForm: React.FC<Props> = (props: Props) => {
  const { facility } = useContext(FacilityContext);
  const { callbackAfterSubmit, product, source, readOnly, medicine } = props;
  const [options, setOptions] = useState({});
  const [isVariantModalOpen, setIsVariantModalOpen] = useState(false);
  const [createdProduct, setCreatedProduct] = useState<ProductModel | null>(null);

  useEffect(() => {
    getProductCategories().then((response) => {
      const categories: CategoryModel[] = response.data.data;
      setOptions({
        category_id: categories.map((category) => ({
          key: category.category,
          value: category.id,
        })),
      });
    });
  }, []);

  const handleFormSubmit = (data: any) => {
    return product && !medicine
      ? updateClinicProduct(product.id, data)
      : createClinicProduct({ ...data, source: source ?? '', facility_id: facility.id });
  };

  const handleTemplateFormSubmit = (responseData: any) => {
    if (!product && !medicine) {
      const productData = responseData?.data?.product || responseData?.product;
      if (productData?.id) {
        setCreatedProduct({
          id: productData.id,
          product_name: productData.product_name,
          product_id: productData.id,
          category_id: productData.category_id || 0,
          description: productData.description || '',
          category: '',
          category_description: '',
          is_active: '',
          price: '',
        });
        setIsVariantModalOpen(true);
        return;
      }
    }

    if (callbackAfterSubmit) {
      callbackAfterSubmit(responseData);
    }
  };

  const handleVariantSubmitSuccess = (variantData: any) => {
    setIsVariantModalOpen(false);
    setCreatedProduct(null);

    if (callbackAfterSubmit) {
      // Pass a flag to indicate this was from the auto-variant flow
      callbackAfterSubmit({ ...variantData, isAutoVariant: true });
    }
  };

  return (
    <Box>
      <TemplateForm
        readOnly={readOnly}
        submitButtonId={'create_product_submit_button'}
        entityName={PRODUCT_ENTITY_NAME}
        fields={productFields}
        entity={product}
        visibleFields={product && !medicine ? updateProductFormFields : addProductFormFields}
        initialValues={product && !medicine ? updateProductInitialValues : addProductInitialValues}
        dropdownOptions={options}
        schema={createProductModelSchema}
        formSubmitApiFunction={handleFormSubmit}
        callbackAfterSubmit={handleTemplateFormSubmit}
        fill={medicine}
      />

      <CustomModal
        open={isVariantModalOpen}
        setOpen={setIsVariantModalOpen}
        header={`Add Variant - ${createdProduct?.product_name || ''}`}
        onClose={() => setIsVariantModalOpen(false)}
        width={800}
      >
        {createdProduct && (
          <ProductVariantForm product={createdProduct} callbackAfterSubmit={handleVariantSubmitSuccess} />
        )}
      </CustomModal>
    </Box>
  );
};

export default ProductForm;

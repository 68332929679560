import { CompanyModel, CompanyTagsModel, ProgramCategoryModel } from 'company/model/Entities';

import { LguModel } from 'lgu/model/Entities';

export enum AccountType {
  LGU = 'lgu',
  COMPANY = 'company',
  CAREGO = 'carego',
  PATIENT = 'patient',
}

export interface UserModel {
  id: number;
  username: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  title: string;
  mobile_number: string;
  role: string;
  account_type: AccountType;
  main_admin: number;
  is_active: boolean;
  authorities?: string[];
  lgu?: LguModel;
  company?: CompanyModel;
  facilities?: FacilityModel[];
  languages?: LanguageModel[];
  programs?: ProgramCategoryModel[];
  user_group: UserGroupModel;
  user_group_id: number;
  read_terms?: number;
  provider_id?: number;
  remaining_storage: number;
  remaining_company_credits: number;
  is_company_account: boolean;
  is_patient_account: boolean;
  is_company_admin: boolean;
  facility: FacilityModel
}

export interface UserGroupModel {
  id: number;
  description: string;
  group_name: string;
}

export interface LanguageModel {
  id: number;
  language: string;
}

export interface FacilityServices {
  id: number;
  service_name: string;
  sms_name: string;
  price: string;
  description: string;
  banner_image: string;
}

export interface FacilityModel {
  id: number;
  company_id: number;
  facility_name: string;
  facility_short_name: string;
  facility_code: string;
  facility_type: string;
  sensitive_data: boolean;
  region: string;
  region_name: string;
  province: string;
  province_name: string;
  municipality: string;
  municipality_name: string;
  address: string;
  email: string;
  is_active: number;
  schedule: any;
  description: string;
  smart_mobile_number: string;
  globe_mobile_number: string;
  primary_language: number;
  tags: CompanyTagsModel[];
  banner_image: string;
  is_admin: boolean;
  company_logo: string;
}

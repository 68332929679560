import { useState } from 'react';
import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CalendarMonth, Phone, Mail, LocationOn, LinkedIn, Facebook } from '@mui/icons-material';
import { tokens } from 'theme/theme';
import { RegularButton } from 'core/components';

const About = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const socialLinks = [
    {
      icon: <Facebook sx={{ fontSize: '32px' }} />,
      url: 'https://www.facebook.com/CareGoHealthSuite',
      label: 'Facebook',
    },
    {
      icon: <LinkedIn sx={{ fontSize: '32px' }} />,
      url: 'https://www.linkedin.com/company/cp-health-innovations',
      label: 'LinkedIn',
    },
  ];

  return (
    <Box sx={{ minHeight: '100vh', background: 'linear-gradient(to bottom right, #ffffff, #f0f7ff)' }}>
      <Container sx={{ pt: 12, pb: 8 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 4,
            maxWidth: '64rem',
            mx: 'auto',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3,
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box
              component="img"
              src="/assets/landingassets/logoblacktext.png"
              alt="Logo"
              sx={{
                height: { xs: '50px', md: '60px' },
                width: 'auto',
              }}
            />
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '2.25rem', md: '3rem' },
                fontWeight: 'bold',
                background: 'linear-gradient(to right, #2196f3, #1976d2)',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
              }}
            >
              Transforming Healthcare
            </Typography>
          </Box>

          <Typography
            variant="h5"
            sx={{
              color: 'text.secondary',
              maxWidth: '36rem',
              mx: 'auto',
              fontSize: { xs: '1.125rem', md: '1.25rem' },
            }}
          >
            CareGo Health Suite is pioneering the future of healthcare technology.
          </Typography>

          <RegularButton
            onClick={() => window.open('https://calendar.app.google/2EFqg7x3Tz1CWtbdA', '_blank')}
            startIcon={<CalendarMonth />}
            styles={{
              bgcolor: '#0192d5',
              color: 'white',
              px: 4,
              py: 2,
              borderRadius: '12px',
              fontSize: '1.1rem',
              boxShadow: '0 8px 20px rgba(1, 146, 213, 0.3)',
              '&:hover': {
                bgcolor: '#0463ae',
                transform: 'translateY(-2px)',
                boxShadow: '0 12px 24px rgba(1, 146, 213, 0.4)',
              },
              transition: 'all 0.3s ease',
            }}
          >
            Schedule a Call
          </RegularButton>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              alignItems: 'center',
              width: '100%',
              maxWidth: '36rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 2,
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <Phone sx={{ color: 'primary.main' }} />
                <Typography>+63-999-422-8127</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <Mail sx={{ color: colors.primary }} />
                <Typography>customercare@cphealthinnovations.com</Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 1,
                color: 'text.secondary',
                textAlign: 'left',
              }}
            >
              <LocationOn sx={{ color: colors.primary, mt: 0.5 }} />
              <Typography>
                Commerce Avenue cor. East Asia Drive, Filinvest, Alabang, Muntinlupa, Metro Manila 1781, Philippines
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 2 }}>
              {socialLinks.map((social, index) => (
                <Box
                  key={index}
                  component="a"
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: colors.primary,
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.1)',
                    },
                  }}
                >
                  {social.icon}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default About;

import * as yup from 'yup';

import { EntityFields } from '../../../utils';
import { generatePasswordString } from 'core/components/CustomPasswordGeneratorField';
import { userGroups } from 'core/data/list';

export interface ServiceProviderModel {
  id: number;
  doctor_id: number;
  assignment_id: number;
  mobile_number_1: string;
  mobile_number_2: string | null;
  email: string;
  profile: string | null;
  first_name: string;
  middle_name: string;
  last_name: string;
  title: string;
  // designation: string | null;
  is_active: number;
  username: string;
  picture: any;
  ptr_number: string;
  license_number: string;
  full_name_with_title: string;
  full_name: string;
  signature: any;
  facilities: any[];
  assigned_facilities: string;
  assigned_facilities_ids: string;
  user_group_id: number;
  schedule: any;
  color: string;
  facility_id: number;
  facility_short_name: string;
  facility_name: string;
}

export const SERVICE_PROVIDER_ENTITY_NAME = 'Service Provider';

export const service_provider_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  last_name: yup.string().required('Last Name is required.'),
  username: yup.string().required('Username is required.'),
  facilities: yup.array().when('apply_to_all_clinics', {
    is: (value: boolean) => !value,
    then: yup.array().min(1, 'At least one clinic must be selected'),
    otherwise: yup.array().nullable(),
  }),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .max(64, 'Password must be at most 64 characters long')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must include at least one special character')
    .matches(/\d/, 'Password must include at least one number')
    .required('Password is required'),
});

export const service_provider_update_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  last_name: yup.string().required('Last Name is required.'),
  facilities: yup.array().when('apply_to_all_clinics', {
    is: (value: boolean) => !value,
    then: yup.array().min(1, 'At least one clinic must be selected'),
    otherwise: yup.array().nullable(),
  }),
});

export const serviceProviderFields: EntityFields[] = [
  { fieldName: 'full_name', displayName: 'Name' },
  {
    fieldName: 'first_name',
    displayName: 'First Name',
    onChange: (data, setFieldValue) => {
      data && setFieldValue && setFieldValue('username', `${data.toLowerCase().replace(/\s/g, '')}_${Date.now()}`);
    },
  },
  { fieldName: 'middle_name', displayName: 'Middle Name', optional: true },
  { fieldName: 'last_name', displayName: 'Last Name' },
  { fieldName: 'title', displayName: 'Designation', optional: true },
  { fieldName: 'license_number', displayName: 'License No.', optional: true },
  { fieldName: 'ptr_number', displayName: 'PTR No.', optional: true },
  { fieldName: 'username', displayName: 'Username' },
  // { fieldName: 'password', displayName: 'Password', type: 'password_generator' },
  { fieldName: 'password', displayName: 'Password' },
  { fieldName: 'email', displayName: 'Email', optional: true },
  { fieldName: 'mobile_number_1', displayName: 'Mobile No. 1', optional: true },
  { fieldName: 'mobile_number_2', displayName: 'Mobile No. 2', optional: true },
  { fieldName: 'divider', type: 'divider' },
  {
    fieldName: 'customization_subheader',
    type: 'subsection_header',
    subsection_header: 'Customization',
  },

  { fieldName: 'color', displayName: 'Color Hex Code', optional: true, type: 'color' },

  {
    fieldName: 'personal_info_subheader',
    type: 'subsection_header',
    subsection_header: 'Personal Information',
  },
  {
    fieldName: 'user_access_subheader',
    type: 'subsection_header',
    subsection_header: 'Clinic Access',
  },
  {
    fieldName: 'profile',
    displayName: 'Profile',
    type: 'rich_text',
    onChange: (value: string, setFieldValue: any) => {
      setFieldValue('profile', value);
    },
  },
  {
    fieldName: 'signature',
    type: 'signature',
    displayName: 'Signature',
    onChange: (value: string, setFieldValue: any) => {
      setFieldValue('signature', value);
    },
  },
  {
    fieldName: 'user_group_id',
    displayName: 'User Type',
    type: 'select',
    options: userGroups.map((u: any) => {
      return { key: u.group_name, value: u.id };
    }),
  },
  {
    fieldName: 'apply_to_all_clinics',
    displayName: 'Access to All Clinics',
    type: 'checkbox',
    span: 2,
    style: { marginBottom: '2000px' },
  },
  {
    fieldName: 'facilities',
    displayName: 'Clinic',
    type: 'multiselect',
    span: 4,
    options: [],
    hiddenBasedOnOtherField: (data: any) => {
      return data.apply_to_all_clinics;
    },
  },
];

export const serviceProviderTableColumns: string[] = ['full_name', 'mobile_number_1', 'username', 'email'];
export const serviceProviderInitialValues = {
  first_name: '',
  middle_name: '',
  last_name: '',
  username: '',
  password: generatePasswordString(),
  color: '#e6f7ff',
  mobile_number_1: '',
  mobile_number_2: '',
  email: '',
  title: '',
  user_group_id: 8,
  apply_to_all_clinics: true,
  facilities: [],
};

export const updateServiceProviderVisibleFields: string[] = [
  'personal_info_subheader',
  'first_name',
  'middle_name',
  'last_name',
  'title',
  'license_number',
  'ptr_number',
  'mobile_number_1',
  'mobile_number_2',
  'email',
  // 'divider',
  // 'user_access_subheader',
  // 'apply_to_all_clinics',
  // 'facilities',
  'divider',
  'profile',
  'signature',
  'divider',
  'customization_subheader',
  'color',
];

export const createServiceProviderVisibleFields: string[] = [
  'personal_info_subheader',
  'first_name',
  'middle_name',
  'last_name',
  'title',
  'license_number',
  'ptr_number',
  'mobile_number_1',
  'mobile_number_2',

  'divider',
  'user_access_subheader',
  // 'username',
  // 'password',
  'email',
  'apply_to_all_clinics',
  'facilities',
  'divider',
  'customization_subheader',
  'color',
  // 'user_group_id',
];

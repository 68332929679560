import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

import AppointmentSearchDropdown from 'company/components/dropdown/AppointmentSearchDropdown';
import PatientSearchDropdown from 'company/components/dropdown/PatientSearchDropdown';
import { tokens } from 'theme/theme';

interface PatientAppointmentFilterProps {
  facilityId: number;
  onFilterChange: (patientId: number, appointmentId: number) => void;
  resetFilter: boolean | undefined;
}

const PatientAppointmentFilter: React.FC<PatientAppointmentFilterProps> = ({ onFilterChange, resetFilter }) => {
  const [selectedPatient, setSelectedPatient] = useState<number>(0);
  const [selectedAppointment, setSelectedAppointment] = useState<number>(0);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (resetFilter) {
      setSelectedPatient(0);
      setSelectedAppointment(0);

      setRefreshKey((prev) => prev + 1);

      onFilterChange(0, 0);

      localStorage.removeItem('selectedPatient');
    }
  }, [resetFilter, onFilterChange]);

  return (
    <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
        <Typography color={colors.primary} fontWeight={'600'}>
          Step 1: Select a patient
        </Typography>
        <PatientSearchDropdown
          key={`patient-dropdown-${refreshKey}`}
          handleChangeCallback={(value) => {
            const patientId = value ? value.value : 0;
            setSelectedPatient(patientId);
            setSelectedAppointment(0);
            localStorage.setItem('selectedPatient', patientId.toString());
            onFilterChange(patientId, 0);
          }}
          initialValue={selectedPatient}
          hideAddOption
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
        <Typography color={colors.primary} fontWeight={'600'}>
          Step 2: Select an appointment
        </Typography>
        <AppointmentSearchDropdown
          key={`appointment-dropdown-${refreshKey}`}
          patientId={selectedPatient ? selectedPatient : 0}
          handleChangeCallback={(value) => {
            const appointmentId = value ? value.value : 0;
            setSelectedAppointment(appointmentId);
            onFilterChange(selectedPatient, appointmentId);
          }}
          initialValue={selectedAppointment}
        />
      </Box>
    </Box>
  );
};

export default PatientAppointmentFilter;

import * as yup from 'yup';

import { Box, Tooltip, Typography } from '@mui/material';
import { DROPDOWN_FIELD, FieldInput } from 'core/model/interface';

import { EntityFields } from '../../../utils';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { formatProductVariantName } from 'core/utils';

export const SERVICE_PRODUCTS_ENTITY_NAME = 'Service Product';

export const createServiceProductSchema = yup.object().shape({
  service_id: yup.number().required('Service is required.'),
  variant_id: yup.number().required('Product is required.'),
  quantity: yup.number().positive().integer().max(100, 'Maximum quantity is 100').required('Quantity is required'),
  price: yup
    .string()
    .matches(/^\d+(\.\d+)?$/, 'Price must be a valid number')
    .required('Price is required'),
  show_in_invoice: yup.boolean().required('Show in invoice is required'),
});

export const createServiceProductDetailsSchema = yup.object().shape({
  quantity: yup.number().positive().integer().max(100, 'Maximum quantity is 100').required('Quantity is required'),
  price: yup
    .string()
    .matches(/^\d+(\.\d+)?$/, 'Price must be a valid number')
    .required('Price is required'),
  show_in_invoice: yup.boolean().required('Show in invoice is required'),
  uom_id: yup.number().positive().integer().required('UOM is required'),
});

export interface ServiceProductModel {
  id: number;
  company_id: number;
  service_id: number;
  variant_id: number;
  variant_name: string;
  product_name: string;
  sku: string;
  quantity: number;
  price: string;
  show_in_invoice: boolean;
  variant_price?: string;
  uom_id?: number;
  uom?: string;
}

export interface ServiceProductInput {
  service_id: number;
  variant_id: number;
  quantity: number;
  price: string;
  show_in_invoice: boolean;
  uom_id?: number;
  uom?: string;
}

export interface ServiceProductFieldInput extends FieldInput {
  field_name?: keyof ServiceProductInput;
}

export const serviceProductInitialValues: ServiceProductInput = {
  service_id: 0,
  variant_id: 0,
  quantity: 1,
  price: '0',
  show_in_invoice: false,
};

export const serviceProductFields: EntityFields[] = [
  { fieldName: 'service_id', displayName: 'Service', type: 'select', options: [] },
  {
    fieldName: 'product_name',
    displayName: 'Product',
    type: 'select',
    options: [],
    flex: 2,
    renderCell: ({ row }) => <Typography>{formatProductVariantName(row)}</Typography>,
  },
  {
    fieldName: 'product_name_display',
    displayName: 'Product',
    flex: 2,
    renderCell: ({ value, row }) => {
      return (
        <Box display="flex" gap="8px" alignItems="center">
          {!!row.show_in_invoice && (
            <Tooltip title="Shown in invoice">
              <RemoveRedEyeOutlinedIcon sx={{ fontSize: '18px', cursor: 'pointer' }} />
            </Tooltip>
          )}
          {!row.show_in_invoice && (
            <Tooltip title="Not shown in invoice">
              <VisibilityOffOutlinedIcon sx={{ fontSize: '18px', cursor: 'pointer' }} />
            </Tooltip>
          )}
          <Typography>{formatProductVariantName(row)}</Typography>
        </Box>
      );
    },
  },
  { fieldName: 'sku', displayName: 'SKU', type: 'text', flex: 1 },
  {
    fieldName: 'quantity',
    displayName: 'Quantity',
    type: 'number',
    renderCell: ({ value, row }) => (
      <Typography>
        {value} {row.uom}
      </Typography>
    ),
  },
  { fieldName: 'price', displayName: 'Unit Price', type: 'text' },
  { fieldName: 'show_in_invoice', displayName: 'Show in Invoice', type: 'checkbox' },
  { fieldName: 'uom_id', displayName: 'UOM', type: DROPDOWN_FIELD.PRODUCT_UOM },
  {
    fieldName: 'uom',
    displayName: 'UOM',
    type: 'text',
    renderCell: ({ value, row }) => (
      <Typography>
        {value} ({row.uom_symbol})
      </Typography>
    ),
  },
];

export const serviceProductTableColumns: string[] = ['product_name_display', 'sku', 'quantity', 'price'];

export const serviceProductFormTableColumns: string[] = ['product_name', 'sku', 'uom'];

export const createServiceProductDetailsVisibleFields: string[] = ['price', 'quantity', 'uom_id', 'show_in_invoice'];

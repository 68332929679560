import { Box, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, Header, RegularButton } from 'core/components';
import { deleteVariant, getInventory, getVariantsOfProduct } from 'company/api/inventories';
import { formatCurrency, formatProductVariantName } from 'core/utils';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { CareGoPage } from 'core/PageBuilder';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { ProductModel } from 'company/entities/modules/InventorySystem/Product/ProductModel';
import ProductVariantForm from 'company/entities/modules/InventorySystem/Product/ProductVariant/ProductVariantForm';
import { ProductVariantModel } from 'company/entities/modules/InventorySystem/Product/ProductVariant/ProductVariantModel';
import ProductVariantsPage from './ProductVariantsPage';
import { SIZES } from 'theme/constants';
import TransactionHistory from './TransactionHistory';
import { WaitForFacility } from 'company/components';
import { getAllTransactions } from 'company/api/transactions';
import { getInvoicesByProduct } from 'company/api/invoice-items';
import { getProduct } from 'company/api/products';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

const NewProductVariantsPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const [product, setProduct] = useState<ProductModel>();
  const [selectedVariant, setSelectedVariant] = useState<number>();
  const [selectedVariantDetails, setSelectedVariantDetails] = useState<ProductVariantModel>();
  const [variantHistory, setVariantHistory] = useState<any[]>([]);
  const [openAddVariant, setOpenAddVariant] = useState(false);
  const [addedVariant, setAddedVariant] = useState<number>();
  const [deletedVariant, setDeletedVariant] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [updatedVariant, setUpdatedVariant] = useState<any>();

  const [deletedId, setDeletedId] = useState<number>();
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const tableRef: any = useRef();
  const [totalProductSales, setTotalProductSales] = useState<number>(0);
  const [totalVariantSales, setTotalVariantSales] = useState<number>(0);
  // might use later
  // const [snackbarKey, setSnackbarKey] = useState<SnackbarKey | null>(null);
  // const [historyKey, setHistoryKey] = useState(0);

  // const fetchVariantHistory = useCallback(async () => {
  //   if (selectedVariant && facility) {
  //     try {
  //       const res = await getAllTransactions(facility.id, { length: 30, variant_id: selectedVariant } as any);
  //       setVariantHistory(res.data.data);
  //       setHistoryKey((prevKey) => prevKey + 1);
  //     } catch (error) {
  //       console.error('Error fetching variant history:', error);
  //       enqueueSnackbar('Failed to fetch variant history', { variant: 'error' });
  //     }
  //   }
  // }, [selectedVariant, facility, enqueueSnackbar]);

  // useEffect(() => {
  //   if (snackbarKey) {
  //     fetchVariantHistory();
  //     closeSnackbar(snackbarKey);
  //     setSnackbarKey(null);
  //   }
  // }, [snackbarKey, fetchVariantHistory, closeSnackbar]);

  useEffect(() => {
    if (!product) {
      getInventory(parseInt(id as string))
        .then((res) => {
          getProduct(res.data.product_id).then((prodRes) => setProduct(prodRes.data));
        })
        .catch(() => {
          navigate('/company/inventory/products');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getVariantDetails = (data?: any) => {
    if (selectedVariant) {
      getInventory(selectedVariant).then((res) => {
        setSelectedVariantDetails(res.data);
      });

      getAllTransactions(facility.id, { length: 30, variant_id: selectedVariant } as any).then((res) => {
        setVariantHistory(res.data.data);
      });
    }

    if (data) {
      setUpdatedVariant(data.variant);
    }
  };

  useEffect(() => {
    if (selectedVariant && product) {
      getVariantDetails();

      getInvoicesByProduct(facility.id, product.id, 'product', {}).then((invoices) => {
        const totalProductPriceArray = invoices.data.data.map((invoice: any) => parseFloat(invoice.total_price));
        const totalProductSales = totalProductPriceArray.reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        );
        setTotalProductSales(totalProductSales);

        const totalVariantPriceArray = invoices.data.data
          .filter((inv: any) => inv.variant_id === selectedVariant)
          .map((invoice: any) => parseFloat(invoice.total_price));
        const totalVariantSales = totalVariantPriceArray.reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        );
        setTotalVariantSales(totalVariantSales);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant, product]);

  const handleDelete = (data: any) => {
    return deleteVariant(data.id).then(() => {
      setDeletedVariant(data.id);
      enqueueSnackbar('Variant successfully deleted!', { variant: 'success' });
      setDeletedId(data.id);
    });
  };

  useEffect(() => {
    getVariantDetails();
  }, [deleteVariant]);

  // const handleUpdateHmo = (data: any) => {
  //   return updateHMO(data.id, data).then(()=> {
  //     setUpdatedHMO(data)
  //   })
  // }

  const displayVariant = useCallback(
    (variant: any) => {
      return (
        <>
          <Box
            display="flex"
            flexDirection="column"
            gap="5px"
            width="100%"
            color={variant.id === selectedVariant ? 'white' : colors.text}
          >
            <Typography variant="h5" fontWeight="600">
              {formatProductVariantName(variant)}
            </Typography>
            <Typography fontSize="11px">{variant.sku}</Typography>
          </Box>
        </>
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedVariant]
  );

  const formattedBalance = `${formatCurrency(totalProductSales)} `;
  const formattedBalance2 = `${formatCurrency(totalVariantSales)} `;
  return (
    <CareGoPage>
      <WaitForFacility facility={product && facility}>
        {product && (
          <InfiniteScrollContainer
            title={product.product_name + ' Variants'}
            getData={(query) => getVariantsOfProduct(product?.id!, query)}
            getSingleData={(id) => getInventory(id)}
            selected={selectedVariant}
            setSelected={setSelectedVariant}
            renderItem={displayVariant}
            urlKeyId="id"
            addedNewItem={addedVariant}
            deleteId={deletedId}
            updatedItem={updatedVariant}
            sidebarWidth="300px"
            titleComponents={
              <RegularButton
                startIcon={<AddIcon />}
                label="Variant"
                onClick={() => {
                  setIsAdd(true);
                  setOpenAddVariant(true);
                }}
              />
            }
            displaySelectedTitle={() =>
              selectedVariantDetails && (
                <Typography variant="h3" fontWeight={700} color={colors.primary}>
                  {formatProductVariantName(selectedVariantDetails)}
                </Typography>
              )
            }
            hideActions={!selectedVariant}
            actions={[
              {
                icon: <DeleteOutlineIcon />,
                label: 'Delete',
                color: 'error',
                action: () => {
                  setOpenDeleteModal(true);
                  //use deleteVariant
                },
              },
            ]}
          >
            <Box display="grid" gridTemplateColumns="1fr 260px" gap="20px" mt={'20px'}>
              {selectedVariantDetails && (
                <ProductVariantsPage variant={selectedVariantDetails} refreshVariant={getVariantDetails} />
              )}

              <Box borderRadius="20px" sx={{ display: 'flex', flexDirection: 'column' }}>
                <Header title="Product Logs" variant="h4" />
                <Box display="flex" flexDirection="column" gap="10px" paddingBottom={SIZES.padding}>
                  <CustomDashboardWidget label="Total Product Sales" count={formattedBalance} icon={PaidOutlinedIcon} />
                  <CustomDashboardWidget
                    label="Total Variant Sales"
                    count={formattedBalance2}
                    icon={PaidOutlinedIcon}
                  />
                  {/* <SalesSummary>
              <SalesValue> {formatCurrency(totalVariantSales)} </SalesValue>
              <SalesDetail> Total Variant Sales </SalesDetail>
            </SalesSummary> */}
                </Box>
                <TransactionHistory variantHistory={variantHistory} />
              </Box>
            </Box>

            <CustomModal open={openAddVariant} setOpen={setOpenAddVariant} header="Add Variant">
              <ProductVariantForm
                product={product}
                callbackAfterSubmit={(data) => {
                  setAddedVariant(data.variant.id);
                  setOpenAddVariant(false);
                }}
              />
            </CustomModal>
          </InfiniteScrollContainer>
        )}
      </WaitForFacility>

      <ConfirmationDialog
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onConfirm={handleDelete}
        data={selectedVariantDetails}
        title={'Delete ' + selectedVariantDetails?.variant_name}
        content={'Are you sure you want to delete ' + selectedVariantDetails?.variant_name + '?'}
      />
    </CareGoPage>
  );
};

export default NewProductVariantsPage;

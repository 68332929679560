import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'notes-summary-templates';

export const getPatientNotesSummaryTemplates = (facility_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/facility/${facility_id}`, { params: query });
};

export const createNewNoteSummaryTemplate = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};

export const deleteNoteSummaryTemplate = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const updateNoteSummaryTemplate = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data)
}

export const getSinglePatientNoteSummary = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const getNoteSummaryTemplateFacilities = (id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/summary-template-facility-access/${id}`, {params: query});
}

export const getNoteSummaryTemplateFacilitiesNotAdded = (summary_template_id: number) => {
  return axios.get(`${baseURL}/${path}/summary-template-facilities-not-added/${summary_template_id}`);
}

export const removeSummaryTemplateClinicAccess = (facility_id: number, summary_template_id: number) => {
  return axios.delete(`${baseURL}/${path}/remove-clinic-access/${facility_id}/${summary_template_id}`);
}

export const assignSummaryTemplateToFacility = (facility_id: number, summary_template_id: number) => {
  return axios.post(`${baseURL}/${path}/assign-to-facility/${facility_id}/${summary_template_id}`)
}

export const updateTemplateFile = (id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${id}`, data);
};

export const updateTemplateFileStartRow = (id: number, startRow: number) => {
  return axios.patch(`${baseURL}/${path}/start-row/${id}`, { template_file_start_row: startRow });
};

export const exportReportByTemplate = (
  file_name: string,
  template_id: number,
  batch_id: number,
  generateCompletedAssessments: boolean
) => {
  return axios
    .post(`${baseURL}/${path}/export/${template_id}/${batch_id}`, { generateCompletedAssessments })
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', file_name + '.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

//

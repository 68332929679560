import { Box, Card, CardContent, LinearProgress, Typography, styled, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { AccountType } from 'core/model/Entities';
import { CareGoPage } from 'core/PageBuilder';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomAccordion from 'core/components/CustomAccordion';
import { FacilityContext } from 'core/context/facility.context';
import ModuleCard from './ModuleCard';
import TopUpForm from 'company/screens/CompanyDashboard/components/TopUpForm';
import { UserContext } from 'core/context/user.context';
import { formatNumber } from 'core/utils';
import { getDashboardStat } from 'company/api/inventory-dashboard';
import { getPatients } from 'company/api/patient';
import { getTotalStorage } from 'company/api/file-upload';
import { getUsersAssigned } from 'company/api/users';
import { tokens } from 'theme/theme';

interface StorageCount {
  value: number;
  unit: string;
}

interface Resource {
  title: string;
  type: string;
  current?: number | StorageCount;
  limit?: number | null;
  description: string;
  unit: string;
  price: string;
  progress: number;
  price_per_unit: number;
}

const StyledCard = styled(Card)({
  height: '70vh',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  transition: 'transform 0.2s, box-shadow 0.2s',
  // '&:hover': {
  //   transform: 'translateY(-2px)',
  //   boxShadow: '0 10px 20px rgba(0, 0, 0, 0.12)',
  // },
  borderRadius: '15px',
  backgroundColor: 'white',
});

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  marginTop: '20px',
  marginBottom: '20px',
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
  },
}));

const ErrorContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
  textAlign: 'center',
});

type Props = {
  hideButton?: boolean;
  message?: string;
};

const CustomForbiddenScreen: React.FC<Props> = ({ hideButton, message }) => {
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const [userCount, setUserCount] = useState<number>(0);
  const [userLimit, setUserLimit] = useState<number>(0);
  const [patientCount, setPatientCount] = useState<number>(0);
  const [patientLimit, setPatientLimit] = useState<number>(0);
  const [storageCount, setStorageCount] = useState<StorageCount>({ value: 0, unit: 'KB' });
  const [storageLimit, setStorageLimit] = useState<number>(0);
  const [smsCount, setSmsCount] = useState<number>(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (facility) {
      Promise.all([
        getUsersAssigned(facility.id),
        getPatients(facility.id),
        getTotalStorage(facility.id),
        getDashboardStat(facility.id),
      ])
        .then(([users, patients, storage, smsCredits]) => {
          setUserCount(users.data.data.length);
          setPatientCount(patients.data.meta.total);
          setSmsCount(smsCredits.data.sms_credits);
          const storageInGB = storage.data / (1024 * 1024 * 1024);
          setStorageCount({
            value: parseFloat(storageInGB.toFixed(2)),
            unit: 'GB',
          });
        })
        .catch(console.error);
    }
  }, [facility]);

  useEffect(() => {
    if (user) {
      setUserLimit(user.company?.user_limit ?? 0);
      setPatientLimit(user.company?.patient_limit ?? 0);
      setStorageLimit(user.company?.storage_limit ?? 0);
    }
  }, [user]);

  const calculateStorageProgress = (): number => {
    let storageInGB =
      storageCount.unit === 'GB'
        ? storageCount.value
        : storageCount.unit === 'MB'
        ? storageCount.value / 1024
        : storageCount.value / (1024 * 1024);
    // return (storageInGB / storageLimit) * 100;

    return user.remaining_storage;
  };

  const getSmsStatus = (count: number) => {
    if (count < 100) return { status: 'critical', progress: 33 };
    if (count < 200) return { status: 'moderate', progress: 66 };
    return { status: 'safe', progress: 100 };
  };

  const getHomeLink = () => {
    if (user.is_company_account || user.account_type === AccountType.LGU) {
      return '/company';
    }
    return '/admin';
  };

  const resources: Resource[] = [
    {
      title: 'Storage Space',
      type: 'storage',
      current: Number(formatNumber(storageLimit - user.remaining_storage)),
      limit: storageLimit,
      description: 'Additional storage space for your files and documents.',
      unit: 'GB',
      price: '₱100 per 5GB (Monthly)',
      price_per_unit: 100,
      progress: calculateStorageProgress(),
    },
    {
      title: 'User Accounts',
      type: 'account',
      current: userCount,
      limit: userLimit,
      description: 'Additional user accounts for your facility.',
      unit: 'accounts',
      price: '₱500 per 1 account (Monthly)',
      price_per_unit: 500,
      progress: (userCount / userLimit) * 100,
    },
    // {
    //   title: 'Module Access',
    //   type: 'modules',
    //   description: 'Request an upgrade for module access to [POS or Patient Transaction].',
    //   unit: 'modules',
    //   price: 'Price Varies',
    //   progress: (patientCount / patientLimit) * 100,
    // },
    {
      title: 'SMS Credits',
      type: 'credits',
      current: smsCount,
      limit: null,
      description: 'Additional SMS credits for your facility.',
      unit: 'credits',
      price: '₱2000 per 2500 SMS Credits',
      price_per_unit: 2000,
      progress: getSmsStatus(smsCount).progress,
    },
  ];

  return (
    <CareGoPage header="Top Up Resources">
      <Box sx={{ maxWidth: '100%' }}>
        <ErrorContainer>
          <CloudUploadIcon sx={{ fontSize: 64, color: colors.primary, mb: 2 }} />
          <Typography variant="h3" mb={1}>
            Access Restricted: Upgrade Required
          </Typography>

          <Typography variant="h5" color="textSecondary" sx={{ maxWidth: 600 }}>
            {message ||
              'Explore the available upgrades below to unlock more features. For help or questions, feel free to reach out to your administrator.'}
          </Typography>
        </ErrorContainer>

        {/* {user && !hideButton && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Button component={Link} to={getHomeLink()} variant="contained" color="primary">
            Return to Dashboard
          </Button>
        </Box>
      )} */}

        <Box sx={{ mt: 2 }}>
          <Typography variant="h5" mb={2} color={colors.primary} fontWeight={600} textAlign={'center'}>
            Only Company Admins have authorization to Top-Up
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr ' },
              gap: 2,
            }}
          >
            {resources.map((resource, index) => (
              <StyledCard key={index}>
                <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      textAlign: 'center',
                      height: '15%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="h5" gutterBottom>
                      {resource.title}
                    </Typography>
                    <Typography variant="h3" color="primary" fontWeight={700}>
                      {resource.price}
                    </Typography>
                  </Box>

                  <Box sx={{ height: '15%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      {resource.type !== 'module' ? (
                        <>
                          <Typography variant="body2">Usage</Typography>
                          <Typography variant="body2">
                            {resource.type === 'credits'
                              ? `${resource.current} ${resource.unit} remaining`
                              : typeof resource.current === 'object'
                              ? `${resource.current.value} ${resource.current.unit} / ${resource.limit} ${resource.unit}`
                              : `${resource.current} / ${resource.limit} ${resource.unit}`}
                          </Typography>
                        </>
                      ) : (
                        <Box width={'100%'} m={2} mb={4} mt={0}>
                          <Typography textAlign="center">Modules are new features for your clinic</Typography>
                        </Box>
                      )}
                    </Box>

                    {resource.type !== 'module' && (
                      <StyledLinearProgress variant="determinate" value={resource.progress} />
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '70%',
                    }}
                  >
                    <Box mb={2}>
                      <CustomAccordion title="Top Up">
                        <TopUpForm
                          requestType={resource.type}
                          unit={resource.unit}
                          pricePerUnit={resource.price_per_unit}
                        />
                      </CustomAccordion>
                    </Box>

                    <Typography variant="body2" align="center" color="textSecondary" mt={2}>
                      {resource.description}
                    </Typography>
                  </Box>
                </CardContent>
              </StyledCard>
            ))}
          </Box>

          <ModuleCard />
        </Box>
      </Box>
    </CareGoPage>
  );
};

export default CustomForbiddenScreen;

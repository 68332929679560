import './index.css';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactDOM from 'react-dom/client';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID as string;
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// inject security meta when running in production
// so that it does not cause errors in development
if (process.env.NODE_ENV == 'production') {
  const cspMetaTag = document.createElement('meta');
  cspMetaTag.httpEquiv = 'Content-Security-Policy';
  cspMetaTag.content =
    "default-src 'self' *.google.com *.googleapis.com *.gstatic.com *.google.com 'unsafe-inline' data: ws: blob: *.amazonaws.com ";

  // note: additional checking should be done here
  // to identify which CSP should be allowed instead of defining it all

  // backend for dev environment
  cspMetaTag.content += ' caregoemrsms.app';

  // backend for uat server
  cspMetaTag.content += ' caregohealthsuite-uat-server.link';

  // backend for production server
  cspMetaTag.content += ' caregosuitebackend.com';

  document.head.appendChild(cspMetaTag);
}

root.render(
  <GoogleOAuthProvider clientId={CLIENT_ID}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </GoogleOAuthProvider>
);

import 'core/styles/Components.css';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { BackButton, ConfirmationDialog, Dropdown, HideOrShowComponent } from 'core/components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountManagement from 'lgu/screens/Settings/AccountManagement';
import { AccountType } from 'core/model/Entities';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
import Cookies from 'js-cookie';
import { FacilityContext } from 'core/context/facility.context';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HomeIcon from '@mui/icons-material/Home';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LogoutIcon from '@mui/icons-material/Logout';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationComponent from './Notification';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import Referral from 'company/screens/CompanyReferrals/Referral';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchBar from 'company/screens/InventorySystem/POS/components/SearchBar';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ThemeToggle from 'company/screens/Settings/components/ThemeToggle';
import { UserContext } from 'core/context/user.context';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { logout } from 'core/api/user';
import { tokens } from 'theme/theme';

const Topbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const colors = tokens(theme.palette.mode);
  const { user, setUser } = useContext(UserContext);
  const { breadcrumb } = useContext(BreadcrumbContext);
  const { facility, setFacility } = useContext(FacilityContext);
  const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = useState<boolean>(false);
  const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);
  const [clinics, setClinics] = useState<any[]>([]);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const [clinicSearchQuery, setClinicSearchQuery] = useState<string>('');

  useEffect(() => {
    if (user && user.facilities) {
      setClinics(
        user.facilities
          .filter((facility) => facility.facility_name.toLowerCase().includes(clinicSearchQuery.toLowerCase()))
          .map((filteredFacility) => ({
            label: filteredFacility.facility_name,
            action: () => changeClinic(filteredFacility.id),
          }))
      );

      if (user.is_company_admin)
        setClinics((prev) => [{ label: 'Admin Mode', action: () => changeClinic(-1) }, ...prev]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, clinicSearchQuery]);

  const logoutUser = () => {
    logout().then(() => {
      setUser(undefined);
      Cookies.remove('access_token');
      Cookies.remove('refresh_token');

      const storedAccountType = localStorage.getItem('accountType');
      if (storedAccountType === 'patient') navigate('/patient-portal');
      else navigate('/portal');
    });
  };

  const updatePassword = () => {
    setOpenUpdatePasswordModal(true);
  };

  const displayAccount = () => {
    if (user) {
      if (user.account_type === AccountType.CAREGO) {
        return 'CareGo Admin';
      } else if (user.is_company_account) {
        return facility?.facility_name;
      } else if (user.account_type === AccountType.PATIENT) {
        return user.first_name + ' ' + user.last_name;
      }

      return user.lgu?.lgu_name + ' ' + user.lgu?.office;
    }
  };

  const getHomeDirectory = () => {
    if (user) {
      if (user.account_type === AccountType.CAREGO) {
        return '/admin';
      } else if (user.is_company_account) {
        if (location.pathname.startsWith('/company/inventory')) return '/company/inventory/products';
        if (location.pathname.startsWith('/company/admin')) return '/company/admin';
        return '/company';
      }
    }
    return '/';
  };

  const changeClinic = (facilityId: number) => {
    localStorage.setItem('selectedClinicId', facilityId.toString());

    const selectedFacility = user?.facilities?.find((facility) => facility.id === facilityId);

    if (selectedFacility || facilityId === -1) {
      if (facilityId === -1) {
        setFacility({ id: -1, facility_name: 'Admin Mode', facility_short_name: 'Admin Mode', is_admin: true } as any);
      } else {
        setFacility(selectedFacility);
      }
      window.location.reload();
    } else {
      console.error(`Facility with id ${facilityId} not found.`);
    }
  };

  const mainOptions = [
    {
      component: (
        <Box width="100%">
          <Accordion
            sx={{
              backgroundColor: colors.background,
              width: '100%',
              '& .MuiAccordionSummary-root': { minHeight: 'unset' },
              '& .MuiAccordionSummary-content': { marginBlock: '15px' },
            }}
            elevation={0}
          >
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
              <Box display="flex" alignItems="center" gap="20px">
                <img src={'/assets/user.svg'} alt="icon" height="40px" width="auto" />
                <Typography fontWeight={'bold'}>{user && user.first_name + ' ' + user.last_name}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Divider style={{ marginBottom: '10px' }} />

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <HideOrShowComponent hidden={!user?.is_company_account}>
                  <Button
                    sx={{
                      width: '100%',
                      borderRadius: '8px',
                      textTransform: 'none',
                      justifyContent: 'flex-start',
                      paddingInline: '15px',
                      backgroundColor: colors.light_blue_background,
                    }}
                    onClick={() =>
                      navigate(facility.id === -1 ? '/company/admin/activity-log' : '/company/activity-log')
                    }
                  >
                    <Box display="flex" gap="1rem" alignItems="center">
                      <Typography sx={{ lineHeight: 1, '& .MuiSvgIcon-root': { fontSize: '18px' } }}>
                        <img alt="img" src="/assets/Topbar/browse_activity.svg" />
                      </Typography>
                      <Typography>Activity Logs</Typography>
                    </Box>
                  </Button>
                </HideOrShowComponent>

                <Button
                  sx={{
                    // width: '100%',
                    borderRadius: '8px',
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    paddingInline: '15px',
                    backgroundColor: colors.light_blue_background,
                  }}
                  onClick={() => updatePassword()}
                >
                  <Box display="flex" gap="1rem" alignItems="center">
                    <Typography
                      sx={{
                        lineHeight: 1,
                        '& .MuiSvgIcon-root': {
                          fontSize: '18px',
                        },
                      }}
                    >
                      <VpnKeyOutlinedIcon />
                    </Typography>
                    <Typography>Update Password</Typography>
                  </Box>
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      ),
    },
    // {
    //   label: 'divider',
    //   style: { marginBlock: '10px' },
    //   hidden: !isDevMode(),
    // },
    // {
    //   label: 'My Account',
    //   action: () => isDevMode() && navigate('/company/settings'),
    //   hidden: !isDevMode(),
    //   icon: <HomeIcon />,
    // },
    {
      label: 'divider',
      style: { marginBlock: '10px' },
      hidden: !user?.is_company_account || !isDevMode(),
    },
    {
      label: 'Guide',
      parent: true,
      hidden: !user?.is_company_account || !isDevMode(),
    },
    {
      label: 'Help Desk',
      action: () => navigate('/company/helpdesk'),
      icon: <HelpOutlineOutlinedIcon />,
      hidden: !user?.is_company_account || !isDevMode(),
    },
    {
      label: 'divider',
      hidden: !user?.is_company_account,
      style: { marginBlock: '10px' },
    },
    {
      label: 'About CareGo Health Suite',
      parent: true,
      hidden: !user?.is_company_account,
    },
    {
      label: 'About Us',
      action: () => navigate('/company/about'),
      icon: <InfoOutlinedIcon />,
      hidden: !user?.is_company_account,
    },
    {
      label: 'Terms of Use and Privacy Policy',
      action: () => navigate('/company/terms'),
      icon: <PolicyOutlinedIcon />,
      hidden: !user?.is_company_account,
    },
    // {
    //   label: 'SMS Credits Top-up',
    //   action: viewSettings,
    //   icon: <SettingsOutlinedIcon />,
    //   hidden: user?.user_group.group_name === 'COMPANY_DOCTOR',
    // },
    { label: 'divider', style: { marginBlock: '10px' } },
    {
      label: 'Logout',
      action: () => setOpenLogoutConfirmation(true),
      icon: <LogoutIcon />,
    },
  ];

  function MobileTopBar() {
    const [burgerDrawerOpen, setBurgerDrawerOpen] = useState<boolean>(false);
    const [clinicsDrawerOpen, setClinicsDrawerOpen] = useState<boolean>(false);

    const handleOpenBurgerDrawer = () => {
      setBurgerDrawerOpen(true);
    };
    const handleOpenClinicsDrawer = () => {
      setClinicsDrawerOpen(true);
    };

    const DrawerContent = (
      <Box
        sx={{ width: 300, height: '100%', display: 'flex', flexDirection: 'column' }}
        role="presentation"
        onClick={() => setBurgerDrawerOpen(false)}
      >
        <Box sx={{ flex: '1 1 auto', padding: '5%' }}>
          <Box sx={{ width: '100%', paddingBottom: '5px' }}>
            <KeyboardBackspaceIcon
              sx={{ color: colors.accent, fontSize: '1.5rem' }}
              onClick={() => setBurgerDrawerOpen(false)}
            />
          </Box>

          <Box>
            <Box display="flex" alignItems="center" gap=".5rem">
              <img src={'/assets/user.svg'} alt="icon" height="35px" width="auto" />
              <Box>
                {user && (
                  <Typography
                    sx={{
                      fontSize: '0.825rem',
                      fontWeight: '600',
                      letterSpacing: '.6px',
                      color: colors.dark_grey_text,
                    }}
                  >
                    {user.first_name.toUpperCase() + ' ' + user.last_name.toUpperCase()}
                  </Typography>
                )}
                {facility && (
                  <Typography sx={{ fontSize: '0.625rem', color: colors.grey_text }}>{facility.email}</Typography>
                )}
              </Box>
            </Box>
          </Box>

          {user?.is_company_account && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  width: '100%',
                  padding: '1rem 0',
                  gap: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                    padding: '10px 0',
                    margin: '5px 0',
                    alignItems: 'center',
                    backgroundColor: colors.light_blue_background,
                    borderRadius: '8px',
                  }}
                >
                  <img alt="img" src="/assets/Topbar/home_health.svg" />
                  <Typography sx={{ fontSize: '0.825rem', color: colors.accent, fontWeight: '500' }}>
                    {facility?.facility_name}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0.3rem 0',
                    border: `1px solid ${colors.accent}`,
                    borderRadius: '8px',
                    gap: '0.5rem',
                  }}
                  onClick={handleOpenClinicsDrawer}
                >
                  <ReplayIcon sx={{ transform: 'rotateY(180deg)', fontSize: '1.15rem', color: colors.accent }} />
                  <Typography sx={{ color: colors.accent }}>Switch Clinic</Typography>
                </Box>
              </Box>

              <Divider />

              <List>
                <ListItem key={1} disablePadding>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ListItemText>
                      <Typography sx={{ color: colors.accent, fontWeight: '600' }}>Account Settings</Typography>
                    </ListItemText>
                    <ListItemButton
                      onClick={() => navigate('/company/clinic-management')}
                      sx={{ width: '100%', '& .MuiListItemIcon-root': { marginRight: '-20px' } }}
                    >
                      <ListItemIcon>
                        <SettingsOutlinedIcon sx={{ color: colors.dark_grey_text }} />
                      </ListItemIcon>

                      <ListItemText primary={'Clinic Settings'} />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => navigate('/company/settings')}
                      sx={{ width: '100%', '& .MuiListItemIcon-root': { marginRight: '-20px' } }}
                    >
                      <ListItemIcon>
                        <AccountCircleIcon sx={{ color: colors.dark_grey_text }} />
                      </ListItemIcon>

                      <ListItemText primary={'My Account'} />
                    </ListItemButton>
                  </Box>
                </ListItem>

                <ListItem key={2} disablePadding onClick={() => navigate('/company/helpdesk')}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ListItemText>
                      <Typography sx={{ color: colors.accent, fontWeight: '600' }}>Guide</Typography>
                    </ListItemText>
                    <ListItemButton sx={{ '& .MuiListItemIcon-root': { marginRight: '-20px' } }}>
                      <ListItemIcon>
                        <HelpOutlineOutlinedIcon sx={{ color: colors.dark_grey_text }} />
                      </ListItemIcon>
                      <ListItemText primary={'Help Desk'} />
                    </ListItemButton>
                  </Box>
                </ListItem>

                <ListItem key={3} disablePadding>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ListItemText>
                      <Typography sx={{ color: colors.accent, fontWeight: '600' }}>About</Typography>
                    </ListItemText>
                    <ListItemButton
                      sx={{ '& .MuiListItemIcon-root': { marginRight: '-20px' } }}
                      onClick={() => navigate('/company/about')}
                    >
                      <ListItemIcon>
                        <FeedbackOutlinedIcon sx={{ color: colors.dark_grey_text }} />
                      </ListItemIcon>
                      <ListItemText primary={'About Us'} />
                    </ListItemButton>

                    <ListItemButton
                      sx={{ '& .MuiListItemIcon-root': { marginRight: '-20px' } }}
                      onClick={() => navigate('/company/terms')}
                    >
                      <ListItemIcon>
                        <PrivacyTipOutlinedIcon sx={{ color: colors.dark_grey_text }} />
                      </ListItemIcon>
                      <ListItemText primary={'Terms of Use and Privacy Policy'} />
                    </ListItemButton>
                  </Box>
                </ListItem>
              </List>
            </>
          )}
          <Divider sx={{ margin: '1rem 0' }} />
          <ListItemButton
            sx={{ '& .MuiListItemIcon-root': { marginRight: '-20px' } }}
            onClick={() => setOpenLogoutConfirmation(true)}
          >
            <ListItemIcon>
              <LogoutOutlinedIcon sx={{ color: colors.redAccent }} />
            </ListItemIcon>
            <ListItemText primary={'Logout'} sx={{ color: colors.redAccent }} />
          </ListItemButton>
        </Box>

        <Box sx={{ position: 'sticky', bottom: 0, width: '100%', padding: '1rem 0' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '28px', gap: '15px' }}>
            <Brightness4OutlinedIcon sx={{ color: colors.accent }} />
            <Typography sx={{ color: colors.accent }}>Theme</Typography>
            <ThemeToggle />
          </Box>
        </Box>
      </Box>
    );

    const ClinicDrawerContent = (
      <Box sx={{ minHeight: '300px' }}>
        <Box
          sx={{
            backgroundColor: colors.light_blue_background,
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            padding: '1rem 0',
          }}
        >
          <Typography sx={{ fontSize: '1.25rem', color: colors.accent, fontWeight: '600' }}>Switch Clinic</Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '85%', padding: '1rem 0 0 0' }}>
            <SearchBar
              fullWidth
              handleSearch={(searchKey) => setClinicSearchQuery(searchKey)}
              styles={{ width: '100%', '@media screen and (max-width: 768px)': { width: '100%' } }}
            />
          </Box>
          <Box sx={{ width: '85%' }}>
            {clinics?.map((childList: any) => (
              <MenuItem
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  borderRadius: '8px',
                  gap: '10px',
                  marginTop: '10px',
                  backgroundColor:
                    facility.facility_name === childList.label ? colors.light_blue_background : colors.lightBackground,
                }}
                key={childList.label}
                onClick={() => childList.action && childList.action()}
              >
                <Box sx={{ display: 'flex', gap: '5px' }}>
                  <Typography>
                    <img alt="img" src="/assets/Topbar/home_health.svg" />
                  </Typography>
                  <Typography>{childList.label}</Typography>
                  <Typography fontWeight={'bold'}>
                    {facility.facility_name === childList.label ? '(In Use)' : ''}
                  </Typography>
                </Box>
                <Radio
                  checked={facility.facility_name === childList.label}
                  // onChange={handleChange}
                  value={facility.facility_name}
                  name={facility.facility_name}
                />
              </MenuItem>
            ))}
          </Box>
        </Box>
      </Box>
    );

    const imgSrc =
      theme.palette.mode === 'light'
        ? '/assets/carego_health_suite_logo.png'
        : '/assets/carego_health_suite_logo_white.png';

    return (
      <Box
        sx={{
          backgroundColor: colors.light_blue_background,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '5px 10px',
          marginBottom: '10px',
        }}
      >
        {/* BREADCRUMB */}
        {/* <Box display="flex" gap={1}>
      <img src="/assets/carego_health_suite_logo.png" style={{width: "50px", height: "50px"}}></img>
    </Box> */}

        {/* WELCOME LEFT SIDE */}

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.25rem' }}>
          <Box onClick={() => navigate('/company')}>
            <img src={imgSrc} alt="CPHI-LOGO" style={{ width: '3.5rem', height: '3.5rem' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{ fontSize: '0.925rem', fontWeight: '600', letterSpacing: '.8px', color: colors.dark_grey_text }}
            >
              {facility
                ? facility?.facility_short_name?.toUpperCase() + ' ' + (facility?.facility_type?.toUpperCase() ?? '')
                : 'CareGo Health Suite'}
            </Typography>

            {/* <Box sx={{ display: 'flex', gap: '5px' }}>
                <WavingHandIcon sx={{ color: '#F4B32D', transform: 'rotateY(180deg)', fontSize: '.725rem' }} />
                <Typography sx={{ fontSize: '0.725rem', color: colors.grey_text }}>
                  Welcome, {facility?.facility_short_name?.toUpperCase()}{' '}
                  {capitalizeWord(facility?.facility_type) ?? ''}!
                </Typography>
              </Box> */}
          </Box>

          <Drawer open={burgerDrawerOpen} onClose={() => setBurgerDrawerOpen(false)} anchor="right">
            {DrawerContent}
          </Drawer>

          <Drawer
            open={clinicsDrawerOpen}
            onClose={() => setClinicsDrawerOpen(false)}
            anchor="bottom"
            PaperProps={{
              sx: {
                borderRadius: '30px 30px 0 0',
              },
            }}
          >
            {ClinicDrawerContent}
          </Drawer>
        </Box>

        {/* RIGHT SIDE  -- ICONS*/}

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          {user?.is_company_account && <NotificationComponent />}
          <MenuIcon sx={{ color: colors.dark_grey_text }} onClick={handleOpenBurgerDrawer} />
        </Box>

        <ConfirmationDialog
          open={openLogoutConfirmation}
          setOpen={setOpenLogoutConfirmation}
          content="Are you sure you want to logout?"
          onConfirm={logoutUser}
        />
        {/* ICONS */}
      </Box>
    );
  }

  if (isMobilePhone) return <MobileTopBar />;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" m="10px 30px">
      {/* BREADCRUMB */}
      <Box display="flex" gap={1}>
        {breadcrumb?.length > 1 && (
          <>
            <BackButton />
            <Box sx={{ borderLeft: `1.5px dashed ${colors.text}`, marginRight: '8px' }} />
          </>
        )}
        <Box display="flex" alignItems="center" gap={1}>
          <HomeIcon sx={{ color: colors.text, marginRight: '10px' }} />
          <Link to={getHomeDirectory()} style={{ textDecoration: 'none' }}>
            <Typography
              variant="subtitle1"
              color={colors.text}
              fontSize="13px"
              sx={{
                cursor: 'pointer',
                borderBottom: `1px solid ${colors.text}`,
              }}
            >
              Home
            </Typography>
          </Link>

          {breadcrumb?.map((item, index) => {
            return (
              <Box display="flex" alignItems="center" gap="10px" key={index}>
                <KeyboardArrowRightIcon fontSize="medium" />
                {item.link ? (
                  <Link to={item.link} style={{ textDecoration: 'none' }}>
                    <Typography
                      variant="subtitle1"
                      color={colors.text}
                      fontSize="13px"
                      sx={
                        item.link
                          ? {
                              cursor: 'pointer',
                              borderBottom: `1px solid ${colors.text}`,
                            }
                          : {}
                      }
                    >
                      {item.label}
                    </Typography>
                  </Link>
                ) : (
                  <Typography variant="subtitle1" color={colors.text} fontSize="13px">
                    {item.label}
                  </Typography>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>

      {/* ICONS */}
      <Box display="flex" gap={0}>
        {user?.is_company_account && (
          <Box display="flex" alignItems="center" gap={3} mr={3}>
            <NotificationComponent />
            <Typography variant="h3" color={colors.grey_text}>
              |
            </Typography>
          </Box>
        )}

        {isDevMode() && user?.account_type === AccountType.COMPANY && !user.company?.free_trial_period && (
          <Box display="flex" alignItems="center" gap={3} mr={3}>
            <Referral />
            <Typography variant="h3" color={colors.grey_text}>
              |
            </Typography>
          </Box>
        )}

        {/* {!isMobilePhone && isDevMode() && (
          <Box display="flex" alignItems="center" gap={3} mr={3}>
            <ThemeToggle />
            <Typography variant="h3" color={colors.grey_text}>
              |
            </Typography>
          </Box>
        )} */}
        <Box display="flex" gap={1} className="star-burst">
          <Box sx={{ '& .Mui-expanded': { margin: '0px !important' } }}>
            <Dropdown
              labelIsComponent
              // buttonLabel={<AccountCircleOutlinedIcon fontSize="large" />}
              buttonLabel={
                // {/* <img src={'/assets/user.svg'} alt="icon" height="30px" width="auto" /> */}
                <Box display={'flex'} flexDirection={'column'} textAlign={'left'}>
                  <div>{displayAccount()}</div>
                  {facility ? facility.email : user?.email}
                </Box>
              }
              variant="text"
              startIcon={<img src={'/assets/user.svg'} alt="icon" height="30px" width="auto" />}
              icon={<ArrowDropDownIcon sx={{ color: colors.text }} />}
              optionIconStyle={{ fontSize: '18px', margintop: 0 }}
              // icon={<SettingsOutlinedIcon />}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              tooltip="Settings"
              popupContainerStyle={{ padding: '20px' }}
              optionList={mainOptions}
            />
          </Box>

          <AccountManagement open={openUpdatePasswordModal} setOpen={setOpenUpdatePasswordModal} />
        </Box>
        <ConfirmationDialog
          open={openLogoutConfirmation}
          setOpen={setOpenLogoutConfirmation}
          content="Are you sure you want to logout?"
          onConfirm={logoutUser}
        />
      </Box>
    </Box>
  );
};

export default Topbar;

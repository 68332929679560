import TemplateForm from 'company/entities/Template/TemplateForm';
import React from 'react';
import {
  MOP_ENTITY_NAME,
  mopFields,
  mopFormInitialValues,
  payment_mode_schema,
  PaymentModeInput,
  PaymentModeModel,
  paymentModesVisibleColumns,
} from './CompanyPaymentModeModel';
import { createNewPaymentMode, updatePaymentMode } from 'company/api/payment-modes';
import { useSnackbar } from 'notistack';

type Props = {
  callbackAfterSubmit?: (data: any) => void;
  payment_mode?: PaymentModeModel;
};
export default function CompanyPaymentModeForm({ callbackAfterSubmit, payment_mode }: Props) {
  function handleSubmit(data: PaymentModeInput) {
    if (!payment_mode)
      return createNewPaymentMode(data).then((res) =>
        enqueueSnackbar(`${data.payment_method} payment mode successfully created!`, { variant: 'success' })
      );
    return updatePaymentMode(payment_mode.id, data).then((res) =>
      enqueueSnackbar(`${data.payment_method} successfully updated!`, { variant: 'success' })
    );
  }
  const { enqueueSnackbar } = useSnackbar();

  return (
    <TemplateForm
      hideDefaultSnackbar
      //   entity={MOP_ENTITY_NAME}
      schema={payment_mode_schema}
      callbackAfterSubmit={callbackAfterSubmit}
      entityName={MOP_ENTITY_NAME}
      fields={mopFields}
      initialValues={payment_mode ? payment_mode : mopFormInitialValues}
      visibleFields={paymentModesVisibleColumns}
      formSubmitApiFunction={handleSubmit}
      //   processInitialValues={processInitialValues}
    />
  );
}

import { Box, Typography } from '@mui/material';
import { calculateAge, formatDateTime } from 'core/utils';
import { cbc_tests, chronic_illnesses_history, fecalysis, getAnswerCareGoPEME, physicalExamData, urinalysis, vital_signs } from './source_fields';

import React from 'react';

const underlineStyle = { borderBottom: '1px solid #878787', paddingInline: '5px', width: '100%' };

type CareGoFormProps = {
  assessment: any;
};
export default function CareGoForm({ assessment }: CareGoFormProps) {
  const getAPEAnswer = (
    field: string,
    defaultValue?: string,
    occurence?: number,
    prefix?: string,
    columns?: string[]
  ): string => {
    return getAnswerCareGoPEME(field, assessment.notes ?? [], defaultValue, occurence, prefix, columns);
  };

  return (
    <Box>
      {/* HEADER */}
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography sx={{ fontWeight: '600', fontSize: '0.825rem' }}>
          Annual Physical Examination Form
        </Typography>
      </Box>

      {/* PERSONAL INFORMATION */}
      <Box className="body">
        <Typography sx={{ fontWeight: '600', fontSize: '0.625rem' }}>Personal Information</Typography>
        <Box display="grid" gridTemplateColumns="1.8fr 1fr 1fr 1fr" columnGap="10px">
          <Box display="grid" gridTemplateColumns="0.3fr 1.5fr">
            <Typography>Name:</Typography>
            <Typography sx={underlineStyle}>{assessment.full_name}</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="0.5fr 1.5fr">
            <Typography>Age:</Typography>
            <Typography sx={underlineStyle}>{calculateAge(assessment.birthday)}</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="0.5fr 1.5fr">
            <Typography>Sex:</Typography>
            <Typography sx={underlineStyle}>{assessment.sex}</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="0.5fr 1.5fr">
            <Typography>Date:</Typography>
            <Typography sx={underlineStyle}>{formatDateTime(assessment.created_at)}</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="0.3fr 1.5fr">
            <Typography>Address:</Typography>
            <Typography sx={underlineStyle}>{assessment.address}</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="0.8fr 1.2fr">
            <Typography>Contact No.</Typography>
            <Typography sx={underlineStyle}>{assessment.mobile_number}</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="0.5fr 1.5fr">
            <Typography>Email:</Typography>
            <Typography sx={underlineStyle}>{assessment.email}</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="0.6fr 1.4fr">
            <Typography>Company:</Typography>
            <Typography sx={underlineStyle}>{assessment.company_name}</Typography>
          </Box>
        </Box>
      </Box>

      {/* PAST MEDICAL HISTORY */}
      <Box mt="10px">
        <Box sx={{ backgroundColor: '#406CAA !important' }}>
          <Typography fontWeight="500" color={'#FFF'} fontSize="0.75rem" pl="10px">
            I. Past Medical History
          </Typography>
        </Box>

        {/* <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          gridTemplateAreas="
    'chronic-illness past-surgeries allergies'
    'chronic-illness injuries family-history'
    'work-related pregnancy lifestyle'"
          gap={2}
          className="body"
        >
          <Box gridArea="chronic-illness">
            <Typography>A. Chronic illnesses:</Typography>
            {chronic_illnesses_history.map((test) => (
              <Box display="grid" gridTemplateColumns="0.2fr 1.2fr" columnGap="5px">
                <Box sx={{ border: '1px solid #878787', textAlign: 'center', width: '0.625.rem' }}>
                  <Typography>{getAPEAnswer(test)}</Typography>
                </Box>
                <Typography>{test}</Typography>
              </Box>
            ))}
          </Box>

          <Box gridArea="past-surgeries">
            <Typography>B. Past Surgeries or Hospitalizations:</Typography>
            <Typography>Any previous surgeries (e.g., appendectomy, cesarean section) and reasons</Typography>
            <Box sx={{ border: '1px solid #878787', minHeight: '40px' }}>
              <Typography p="4px">{getAPEAnswer('Past Surgeries or Hospitalizations')}</Typography>
            </Box>
          </Box>

          <Box gridArea="allergies">
            <Typography>D. Allergies</Typography>
            <Typography>Any allergies to food, drugs and the environment</Typography>
            <Box p="4px" sx={{ border: '1px solid #878787', minHeight: '40px' }}>
              <Typography>{getAPEAnswer('Allergies')}</Typography>
            </Box>
          </Box>

          <Box gridArea="injuries">
            <Typography>C. Injuries</Typography>
            <Typography>Any history of fractures, dislocations, or other significant injuries</Typography>
            <Box p="4px" sx={{ border: '1px solid #878787', minHeight: '40px' }}>
              <Typography>{getAPEAnswer('Injuries')}</Typography>
            </Box>
          </Box>

          <Box gridArea="family-history">
            <Typography>E. Family Medical History</Typography>
            <Typography>Family history of hereditary or chronic conditions</Typography>
            <Box p="4px" sx={{ border: '1px solid #878787', minHeight: '40px' }}>
              <Typography>{getAPEAnswer('Family Medical History')}</Typography>
            </Box>
          </Box>

          <Box gridArea="work-related">
            <Typography>F. Work-related exposure history:</Typography>
            <Box display="grid" gridTemplateColumns="0.2fr 1.2fr" columnGap="5px">
              <Box p="4px" sx={{ border: '1px solid #878787', textAlign: 'center', px: 1 }}>
                <Typography>{getAPEAnswer('Chemicals')}</Typography>
              </Box>
              <Typography>Chemicals</Typography>
            </Box>

            <Box display="grid" gridTemplateColumns="0.2fr 1.2fr" columnGap="5px">
              <Box sx={{ border: '1px solid #878787', textAlign: 'center', px: 1 }}>
                <Typography>{getAPEAnswer('Dust')}</Typography>
              </Box>
              <Typography>Dust</Typography>
            </Box>

            <Box display="grid" gridTemplateColumns="0.2fr 1.2fr" columnGap="5px">
              <Box sx={{ border: '1px solid #878787', textAlign: 'center', px: 1 }}>
                <Typography>{getAPEAnswer('Noise')}</Typography>
              </Box>
              <Typography>Noise</Typography>
            </Box>
          </Box>

          <Box gridArea="pregnancy">
            <Typography> G. Pregnancy and Obstetric History (date and history):</Typography>
            <Typography>Past pregnancies, complications, or gynecological issues</Typography>
            <Box p="4px" sx={{ border: '1px solid #878787', minHeight: '40px' }}>
              <Typography>{getAPEAnswer('Pregnancy and Obstetric History (date and history)')}</Typography>
            </Box>
          </Box>

          <Box gridArea="lifestyle">
            <Typography>H. Lifestyle Factors</Typography>

            <Box display="grid" gridTemplateColumns="1fr 1.2fr" columnGap="5px">
              <Box sx={{ border: '1px solid #878787', textAlign: 'left', px: 1 }}>
                <Typography>
                  <Typography>{getAPEAnswer('Smoking')}</Typography>
                </Typography>
              </Box>
              <Typography>Smoking</Typography>
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 1.2fr" columnGap="5px">
              <Box sx={{ border: '1px solid #878787', textAlign: 'left', px: 1 }}>
                <Typography>{getAPEAnswer('Alcohol consumption')}</Typography>
              </Box>
              <Typography>Alcohol consumption</Typography>
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 1.2fr" columnGap="5px">
              <Box sx={{ border: '1px solid #878787', textAlign: 'left', px: 1 }}>
                <Typography>{getAPEAnswer('Physical Activity')}</Typography>
              </Box>
              <Typography>Physical Activity</Typography>
            </Box>
          </Box>
        </Box> */}

        {/* gridTemplateAreas="
    'chronic-illness past-surgeries allergies'
    'chronic-illness injuries family-history'
    'work-related pregnancy lifestyle'" */}
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          gridTemplateAreas="
    'chronic-illness injuries work-related'
    'chronic-illness allergies pregnancy'
    'past-surgeries family-history lifestyle'"
          gap={2}
          className="body"
        >
          <Box gridArea="chronic-illness">
            <Typography>A. Chronic illnesses:</Typography>
            {chronic_illnesses_history.map((test: any) => {
              const moreInfo = getAPEAnswer(test, '', 1, '', ['More Info']);

              return (
                <Box display="grid" gridTemplateColumns="0.2fr 1.2fr" columnGap="5px">
                  <Box sx={{ border: '1px solid #878787', textAlign: 'center', width: '0.625.rem' }}>
                    <Typography>{getAPEAnswer(test)}</Typography>
                  </Box>
                  <Typography>{test}</Typography>
                  {moreInfo && <Box></Box>}
                  {moreInfo && <Typography sx={underlineStyle}>{moreInfo}</Typography>}
                </Box>
              );
            })}
          </Box>

          <Box gridArea="past-surgeries">
            <Typography>B. Past Surgeries or Hospitalizations:</Typography>
            <Typography>Any previous surgeries (e.g., appendectomy, cesarean section) and reasons</Typography>
            <Box sx={{ border: '1px solid #878787', minHeight: '40px' }}>
              <Typography p="4px">{getAPEAnswer('Past Surgeries or Hospitalizations')}</Typography>
            </Box>
          </Box>

          <Box gridArea="allergies">
            <Typography>D. Allergies</Typography>
            <Typography>Any allergies to food, drugs and the environment</Typography>
            <Box p="4px" sx={{ border: '1px solid #878787', minHeight: '40px' }}>
              <Typography>{getAPEAnswer('Allergies')}</Typography>
            </Box>
          </Box>

          <Box gridArea="injuries">
            <Typography>C. Injuries</Typography>
            <Typography>Any history of fractures, dislocations, or other significant injuries</Typography>
            <Box p="4px" sx={{ border: '1px solid #878787', minHeight: '40px' }}>
              <Typography>{getAPEAnswer('Injuries')}</Typography>
            </Box>
          </Box>

          <Box gridArea="family-history">
            <Typography>E. Family Medical History</Typography>
            <Typography>Family history of hereditary or chronic conditions</Typography>
            <Box p="4px" sx={{ border: '1px solid #878787', minHeight: '40px' }}>
              <Typography>{getAPEAnswer('Family Medical History')}</Typography>
            </Box>
          </Box>

          <Box gridArea="work-related">
            <Typography>F. Work-related exposure history:</Typography>
            <Box display="grid" gridTemplateColumns="0.2fr 1.2fr" columnGap="5px">
              <Box sx={{ border: '1px solid #878787', textAlign: 'center', px: 1 }}>
                <Typography>{getAPEAnswer('Chemicals')}</Typography>
              </Box>
              <Typography>Chemicals</Typography>
            </Box>

            <Box display="grid" gridTemplateColumns="0.2fr 1.2fr" columnGap="5px">
              <Box sx={{ border: '1px solid #878787', textAlign: 'center', px: 1 }}>
                <Typography>{getAPEAnswer('Dust')}</Typography>
              </Box>
              <Typography>Dust</Typography>
            </Box>

            <Box display="grid" gridTemplateColumns="0.2fr 1.2fr" columnGap="5px">
              <Box sx={{ border: '1px solid #878787', textAlign: 'center', px: 1 }}>
                <Typography>{getAPEAnswer('Noise')}</Typography>
              </Box>
              <Typography>Noise</Typography>
            </Box>
          </Box>

          <Box gridArea="pregnancy">
            <Typography> G. Pregnancy and Obstetric History (date and history):</Typography>
            <Typography>Past pregnancies, complications, or gynecological issues</Typography>
            <Box p="4px" sx={{ border: '1px solid #878787', minHeight: '40px' }}>
              <Typography>{getAPEAnswer('Pregnancy and Obstetric History (date and history)')}</Typography>
            </Box>
          </Box>

          <Box gridArea="lifestyle">
            <Typography>H. Lifestyle Factors</Typography>

            <Box display="grid" gridTemplateColumns="1fr 1.2fr" columnGap="5px">
              <Box sx={{ border: '1px solid #878787', textAlign: 'left', px: 1 }}>
                <Typography>
                  <Typography>{getAPEAnswer('Smoking')}</Typography>
                </Typography>
              </Box>
              <Typography>Smoking</Typography>
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 1.2fr" columnGap="5px">
              <Box sx={{ border: '1px solid #878787', textAlign: 'left', px: 1 }}>
                <Typography>{getAPEAnswer('Alcohol consumption')}</Typography>
              </Box>
              <Typography>Alcohol consumption</Typography>
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 1.2fr" columnGap="5px">
              <Box sx={{ border: '1px solid #878787', textAlign: 'left', px: 1 }}>
                <Typography>{getAPEAnswer('Physical Activity')}</Typography>
              </Box>
              <Typography>Physical Activity</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* VITAL SIGNS & PHYSICAL EXAM */}
      <Box display="grid" gridTemplateColumns="1fr 2fr" columnGap="1.2%">
        <Box>
          <Box sx={{ backgroundColor: '#406CAA !important' }}>
            <Typography fontWeight="500" color={'#FFF'} fontSize="0.75rem" pl="10px">
              II. Vital Signs
            </Typography>
          </Box>

          {vital_signs.map((sign: any) => (
            <Box display="grid" gridTemplateColumns="1fr 1fr 3fr" className="body" columnGap="5px">
              <Typography sx={underlineStyle}>{getAPEAnswer(sign.sign)}</Typography>
              <Typography>{sign.unit}</Typography>
              <Typography>{sign.sign}</Typography>
            </Box>
          ))}
        </Box>

        <Box>
          <Box sx={{ backgroundColor: '#406CAA !important' }}>
            <Typography fontWeight="500" color={'#FFF'} fontSize="0.75rem" pl="10px">
              III. Physical Examination
            </Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="1fr 1fr" className="body" columnGap="5px">
            <Box display="grid" gridTemplateColumns="0.8fr 1.2fr">
              <Box></Box>
              <Typography textAlign="center">Findings</Typography>
            </Box>
            <Box display="grid" gridTemplateColumns="0.8fr 1.2fr">
              <Box></Box>
              <Typography textAlign="center">Findings</Typography>
            </Box>
            {physicalExamData.map((exam: any) => {
              return (
                <Box display="grid" gridTemplateColumns="0.8fr 1.2fr" className="body">
                  <Typography textAlign="left">{exam.letter + '. ' + exam.exam}</Typography>
                  <Typography sx={underlineStyle}>{getAPEAnswer(exam.exam, 'Normal')}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>

      {/* LABORATORY */}
      <Box>
        <Box sx={{ backgroundColor: '#406CAA !important' }}>
          <Typography fontWeight="500" color={'#FFF'} fontSize="0.75rem" pl="10px">
            IV. Laboratory
          </Typography>
        </Box>

        <Box
          display="grid"
          gridTemplateColumns="1.2fr 1fr 1fr 1fr"
          className="body"
          gap={2}
          gridTemplateAreas="
         'complete-blood-count urinalysis fecalysis blood-chemistry'
         'complete-blood-count urinalysis fecalysis blood-chemistry'
         'complete-blood-count urinalysis drug-test pregnancy-test'
         'complete-blood-count urinalysis hepatitis-b hbsag-test'
         'complete-blood-count urinalysis blood-typing papsmear-result'
         "
        >
          <Box gridArea="complete-blood-count">
            <Typography>A. Complete Blood Count</Typography>

            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" mb="5px">
              <Typography>Test</Typography>
              <Typography>Result</Typography>
              <Typography>Normal Values</Typography>
            </Box>
            {cbc_tests.map((test: any, index: number) => (
              <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" columnGap={'10px'}>
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>{test.test}</Typography>
                {test.test !== 'Differential count' && (
                  <Typography
                    sx={{
                      width: '100%',
                      borderBottom: '1px solid #878787',
                      display: 'flex',
                      alignItems: 'end',
                      paddingInline: '5px',
                    }}
                  >
                    {index < 6 ? getAPEAnswer(test.test) : getAPEAnswer(test.test, '', 1, 'DC_')}
                  </Typography>
                )}
                <Typography>{test.normal_value}</Typography>
              </Box>
            ))}
          </Box>

          <Box gridArea="urinalysis">
            <Typography>B. Urinalysis</Typography>

            <Box display="grid" gridTemplateColumns="1fr 1fr">
              <Typography>Test</Typography>
              <Typography>Result</Typography>
            </Box>
            {urinalysis.map((test: any) => (
              <Box display="grid" gridTemplateColumns="1fr 1fr">
                <Typography>{test}</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer(test, '', 1, 'U_')}</Typography>
              </Box>
            ))}

            <Box display="grid" gridTemplateColumns="1fr 1fr">
              <Typography>Remarks:</Typography>
              <Typography sx={underlineStyle}>
                {getAPEAnswer('Remarks (Significant Findings based on Result)')}
              </Typography>
            </Box>
          </Box>

          <Box gridArea="fecalysis">
            <Typography>C. Fecalysis</Typography>

            <Box display="grid" gridTemplateColumns="1fr 1fr">
              <Typography>Test</Typography>
              <Typography>Result</Typography>
            </Box>

            {fecalysis.map((test: any) => (
              <Box display="grid" gridTemplateColumns="1fr 1fr">
                <Typography>{test}</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer(test, '', 1, 'F_')}</Typography>
              </Box>
            ))}
          </Box>

          <Box gridArea="drug-test">
            <Typography>D. Drug Test Result</Typography>

            <Typography sx={underlineStyle} mt="10px">
              {getAPEAnswer('Drug Test')}
            </Typography>
          </Box>

          <Box gridArea="hepatitis-b">
            <Typography>E. HBsAg Result</Typography>
            <Typography sx={underlineStyle} mt="10px">
              {getAPEAnswer('Hepatitis B Result')}
            </Typography>
          </Box>

          <Box gridArea="blood-typing">
            <Typography>F. Blood Typing Result</Typography>
            <Typography sx={underlineStyle} mt="10px">
              {`${getAPEAnswer('Blood Typing')} ${getAPEAnswer('RH Typing')}`}
            </Typography>
          </Box>

          <Box gridArea="blood-chemistry">
            <Typography>G. Blood Chemistry Result</Typography>

            <Typography sx={underlineStyle}>{getAPEAnswer('Blood Chemistry')}</Typography>

          </Box>

          <Box gridArea="pregnancy-test">
            <Typography>H. Pregnancy Test Result</Typography>
            <Typography sx={underlineStyle} mt="10px">
              {/* {getAPEAnswer('Pregnancy Test', '', 1, 'U_')} */}
              {getAPEAnswer('Pregnancy Test')}
            </Typography>
          </Box>

          <Box gridArea="hbsag-test">
            <Typography>I. HAV IgM</Typography>
            <Typography sx={underlineStyle} mt="10px">
              {getAPEAnswer('Hepatitis A Result')}
            </Typography>
          </Box>

          <Box gridArea="papsmear-result">
            <Typography>J. Papsmear Result</Typography>
            <Typography sx={underlineStyle} mt="10px">
              {getAPEAnswer('Papsmear Findings')}
            </Typography>
          </Box>
        </Box>

        {/* <Box className="body" width="70%">
          <Box>
            <Typography>A. Complete Blood Count</Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" mb="5px">
            <Typography>Test</Typography>
            <Typography>Result</Typography>
            <Typography>Normal Values</Typography>
          </Box>
          {laboratory_tests.map((test) => (
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" columnGap={'10px'}>
              <Typography>{test.test}</Typography>
              <Typography sx={underlineStyle}></Typography>
              <Typography>{test.normal_value}</Typography>
            </Box>
          ))}
        </Box> */}
      </Box>

      {/* RADIOLOGIC FINDINGS AND ECG */}

      <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="5%">
        <Box>
          <Box sx={{ backgroundColor: '#406CAA !important' }}>
            <Typography fontWeight="500" color={'#FFF'} fontSize="0.75rem" pl="10px">
              V. Radiological Findings
            </Typography>
          </Box>

          <Box className="body">
            <Box display="grid" gridTemplateColumns="0.45fr 1.55fr">
              <Typography sx={{ fontWeight: '500' }}>Impression:</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer('IMPRESSION')}</Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box sx={{ backgroundColor: '#406CAA !important' }}>
            <Typography fontWeight="500" color={'#FFF'} fontSize="0.75rem" pl="10px">
              VI. ECG
            </Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="1fr 1fr" className="body">
            <Typography sx={{ fontWeight: '500' }}>ECG Diagnosis:</Typography>
            <Typography sx={underlineStyle}>{getAPEAnswer('ECG Diagnosis')}{getAPEAnswer('Other ECG Diagnosis') !== '-' ? ', ' + getAPEAnswer('Other ECG Diagnosis') : ''}</Typography>
          </Box>
        </Box>
      </Box>

      {/* CLASSIFICATION */}
      <Box>
        <Box sx={{ backgroundColor: '#406CAA !important' }}>
          <Typography fontWeight="500" color={'#FFF'} fontSize="0.75rem" pl="10px">
            VII. Classification
          </Typography>
        </Box>

        <Box display="grid" gridTemplateColumns="4fr 1.5fr">
          <Box display="grid" gridTemplateColumns="0.5fr 7fr" className="body" columnGap="10px" rowGap="5px">
            <Box sx={{ border: '1px solid #878787', textAlign: 'center', px: 1, width: 30, height: 15 }}>
              <Typography> {assessment.class === 'A' ? 'x' : ''} </Typography>
            </Box>
            <Typography>
              <strong>Class A:</strong> Individuals who are deemed fit to work without medical intervention.{' '}
            </Typography>

            <Box sx={{ border: '1px solid #878787', textAlign: 'center', px: 1, width: 30, height: 15 }}>
              <Typography>{assessment.class === 'B' ? 'x' : ''}</Typography>
            </Box>
            <Typography>
              <strong>Class B:</strong> Individuals requiring short-term medical intervention. Physically
              under-developed or with correctible defects, (error of refraction, dental caries, defective hearing, and
              other similar defects) but otherwise fit to work.
            </Typography>

            <Box sx={{ border: '1px solid #878787', textAlign: 'center', px: 1, width: 30, height: 15 }}>
              <Typography>{assessment.class === 'C' ? 'x' : ''}</Typography>
            </Box>
            <Typography>
              <strong>Class C:</strong> Individuals with manageable chronic diseases or in need of long term medical
              intervention. Employable but owing to certain impairments, (heart disease, hypertension, anatomical
              defects), requires special placement or limited duty in a specified or selected assignment requiring
              follow up-treatment/periodic evaluation.
            </Typography>

            <Box sx={{ border: '1px solid #878787', textAlign: 'center', px: 1, width: 30, height: 15 }}>
              <Typography>{assessment.class === 'D' ? 'x' : ''}</Typography>
            </Box>
            <Typography>
              <strong>Class D:</strong> Unfit or unsafe for any type of employment (active PTB, advance heart disease
              with threatened failure, malignant hypertension, and other similar illnesses).
            </Typography>

             <Box sx={{ border: '1px solid #878787', textAlign: 'center', px: 1, width: 30, height: 15 }}>
                <Typography>{assessment.class === 'Pending' ? 'x' : ''}</Typography>
              </Box>
              <Typography>
                <strong>Pending</strong> 
              </Typography>
          </Box>

          <Box className="body">
            <Typography>Evaluated By:</Typography>
            
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

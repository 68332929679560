import { Box, Grid, TablePagination, Typography, useTheme } from '@mui/material';
import CustomFilter, { CheckboxOptionProps } from 'core/components/CustomFilter';
import { ReactNode, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { formatCurrency, formatDate, formatNumberMaxDecimal, formatProductVariantName } from 'core/utils';

import { AttributeValueModel } from 'company/entities/modules/InventorySystem/AttributeValue/AttributeValueModel';
import Chip from '@mui/material/Chip';
import { ContainerRow } from 'core/components/containers';
import { Dropdown } from 'core/components';
import { DropdownOptionProps } from 'core/components/Dropdown';
import { FacilityContext } from 'core/context/facility.context';
import { Link } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Product } from '../ClinicInventory';
import React from 'react';
import { SIZES } from 'theme/constants';
import SearchBar from '../../POS/components/SearchBar';
import StockConverterDisplay from './StockConvertedDisplay';
import { getInventories } from 'company/api/inventories';
import { getVariantAttributeValues } from 'company/api/variant-attributes';
import { tokens } from 'theme/theme';

interface Props {
  extraButtons?: ReactNode | ReactNode[];
  actions: DropdownOptionProps[];
  refreshInventory?: number;
  medicineAction?: boolean;
  productFilter?: number;
  productName?: string;
}

const ClinicInventoryScroller = forwardRef((props: Props, ref) => {
  const { extraButtons, actions, refreshInventory, medicineAction, productFilter, productName } = props;

  useImperativeHandle(ref, () => ({
    getAllProducts,
    getFilters: () => ({
      search: productSearchQuery,
      status: productFilters.length === 0 || productFilters.length === 3 ? undefined : productFilters,
      attribute_values: attributeFilters,
      product_id: productFilter,
    }),
  }));

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const parentRef = useRef();

  const [productFilters, setProductFilters] = useState<string[]>([]);
  const [attributeFilters, setAttributeFilters] = useState<string[]>([]);
  // const [hasMore, setHasMore] = useState<boolean>(true);
  // const [page, setPage] = useState<number>(1);
  const [products, setProducts] = useState<Product[]>([]);
  const [productSearchQuery, setProductSearchQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [attributes, setAttributes] = useState<AttributeValueModel[]>([]);

  // const filteredActions = (product: any) => {
  //   const filteredActions = actions.filter((action) =>
  //     ['Update Price', 'Update Details'].includes(action.label as string)
  //   );
  //   return filteredActions;
  // };

  // const handleScroll = () => {
  //   const parentElement = parentRef.current as any;
  //   if (parentElement && parentElement.scrollHeight - parentElement.scrollTop - parentElement.clientHeight < 1) {
  //     setTimeout(() => {
  //       if (hasMore) {
  //         setPage((prevPage) => prevPage + 1);
  //       }
  //     }, 1000);
  //   }
  // };

  const getAllProducts = async () => {
    if (facility) {
      setIsLoading(true);
      try {
        const response = await getInventories(facility.id, {
          page: currentPage + 1, // Convert from 0-based (MUI) to 1-based (API)
          length: rowsPerPage,
          search: productSearchQuery,
          order_by: 'updated_at',
          order: 'desc',
          status: productFilters.length === 0 || productFilters.length === 3 ? undefined : productFilters,
          attribute_values: attributeFilters,
          product_id: productFilter,
        });

        setProducts(response.data.data);

        setTotalCount(response.data.meta.total);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const refreshList = () => {
    setCurrentPage(0);
    getAllProducts();
  };

  const handleSearch = (searchKey: string) => {
    setProductSearchQuery(searchKey);
  };

  useEffect(() => {
    getVariantAttributeValues().then((res) => {
      setAttributes(res.data.data);
    });
  }, []);

  useEffect(() => {
    getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage, facility?.id, productFilters, attributeFilters, productSearchQuery, refreshInventory]);

  useEffect(() => {
    refreshList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFilters, attributeFilters, productSearchQuery, refreshInventory]);

  const optionList: CheckboxOptionProps[] = useMemo(() => {
    let currentAttributeId = 0;

    return attributes.flatMap((attr) => {
      const value: CheckboxOptionProps[] = [];
      if (currentAttributeId !== attr.attribute_id) {
        currentAttributeId = attr.attribute_id;
        value.push({ label: attr.attribute_name, id: attr.attribute_name, isHeader: true });
      }
      value.push({ label: attr.value, id: attr.id + '' });
      return value;
    });
  }, [attributes]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: { currentTarget: React.SetStateAction<HTMLElement | null> }) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  return (
    <Box paddingX={'16px'}>
      <ContainerRow sx={{ paddingBlock: SIZES.padding, alignItems: 'center' }}>
        {productName && (
          <Typography variant="h5" fontWeight={'bold'} width="200px">
            Variants for {productName}
          </Typography>
        )}
        <ContainerRow gap="5px" sx={{ justifyContent: 'flex-end' }}>
          <SearchBar handleSearch={(searchKey) => handleSearch(searchKey)} contrast />
          {/* <SearchBar handleSearch={(searchKey) => setProductSearchQuery(searchKey)} /> */}
          <CustomFilter
            optionList={[
              { label: 'In Stock', id: 'available' },
              { label: 'Low Stock', id: 'lowStock' },
              { label: 'Unavailable', id: 'unavailable' },
              { label: 'Made to Order', id: 'madeToOrder' },
              { label: 'Out of Stock', id: 'unavailable' },
            ]}
            buttonLabel="Availability"
            selected={productFilters}
            setSelected={setProductFilters}
            selectAllOption
            // variant="contained"
          />
          <CustomFilter
            buttonLabel={'Attributes'}
            optionList={optionList}
            selected={attributeFilters}
            setSelected={setAttributeFilters}
            span={2}
            noOptionContent={
              <Box p="0.825rem" display="flex" gap="5px">
                <Typography>No attribute filters available.</Typography>
                <Link to="/company/inventory/products?tab=2" style={{ textDecoration: 'none' }}>
                  <Typography sx={{ color: colors.primary, ':hover': { textDecoration: 'underline' } }}>
                    Add Attributes
                  </Typography>
                </Link>
              </Box>
            }
            // variant="contained"
          />

          {extraButtons}
        </ContainerRow>
      </ContainerRow>
      <Box
        ref={parentRef}
        height="100%"
        sx={{ overflowY: 'auto' }}
        display="flex"
        flexDirection="column"
        gap={'12px'}

        // onScroll={handleScroll}
      >
        {products.map((product) => (
          <Box
            key={product.variant_id}
            sx={{
              backgroundColor: 'white',
              borderRadius: '12px',
              padding: '16px 24px',
              width: '100%',
              '&:hover': {
                // backgroundColor: colors.hoverTableRow,
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.16)',
              },
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.04)',
              border: '1px solid rgba(0, 0, 0, 0.02)',
            }}
          >
            {/* Product Name Row - Full Width */}
            <Box style={{ width: '100%' }}>
              <Link
                to={`/company/inventory/products/variant?id=${product.id}`}
                style={{ textDecoration: 'none' }}
                state={{ from: '/company/inventory/products' }}
              >
                <Typography
                  variant="h5"
                  color={colors.accent}
                  fontWeight="bold"
                  sx={{ '&:hover': { textDecoration: 'underline' } }}
                >
                  {formatProductVariantName(product)}
                </Typography>
              </Link>
            </Box>

            {/* Details Grid - Width */}
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Grid item xs={12} sm={6} md={6} lg={2.75}>
                <Typography fontSize={'10px'} mb="3px" color={colors.grey_text}>
                  Category: {product.category}
                </Typography>
                <Typography>{product.sku}</Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={1.5}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              >
                <Typography fontSize={'10px'} color={colors.grey_text}>
                  Price
                </Typography>
                <Typography color={colors.accent} fontWeight="bold">
                  {formatCurrency(product.price)}
                </Typography>
              </Grid>

              {product.made_to_order ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={1.5}
                  sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography fontSize={'10px'} color={colors.grey_text}>
                    Availability
                  </Typography>
                  <Chip
                    label="Made to Order"
                    variant="outlined"
                    color="primary"
                    sx={{ fontSize: '10px' }}
                    size="small"
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={1.5}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  {parseInt(product.quantity_in_stock) <= 0 ? (
                    <Typography color={'#e74c3c'} fontSize={'10px'}>
                      Out of Stock
                    </Typography>
                  ) : parseInt(product.quantity_in_stock) > 0 &&
                    parseInt(product.quantity_in_stock) < product.low_stock_threshold ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography color={'#fe9801'} fontSize={'10px'}>
                        Low Stock
                      </Typography>
                      <Typography color={'#666'} fontSize={'8px'}>
                        Min: {formatNumberMaxDecimal(product.low_stock_threshold, 3)}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography color={'#4CAF50'} fontSize={'10px'}>
                      In Stock
                    </Typography>
                  )}
                  <StockConverterDisplay
                    quantity={Number(formatNumberMaxDecimal(product.quantity_in_stock, 6))}
                    baseUomSymbol={product.base_uom_symbol}
                    conversions={product.uom_conversions}
                  />
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={1.5}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              >
                <Typography fontSize={'10px'} color={colors.grey_text}>
                  Date Added
                </Typography>
                <Typography>{formatDate(product.created_at)}</Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                lg={2}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              >
                <Typography fontSize={'10px'} color={colors.grey_text}>
                  Supplier
                </Typography>
                <Typography>{product.exclusive_supplier_name ? product.exclusive_supplier_name : 'N/A'} </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                lg={1}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              >
                <Dropdown
                  optionList={
                    product.made_to_order && !medicineAction
                      ? actions.filter((action) =>
                          ['Update Price', 'Update Details', 'Delete Variant'].includes(action.label as string)
                        )
                      : actions
                  }
                  isIcon
                  icon={<MoreHorizIcon sx={{ fontSize: '24px', color: colors.accent }} />}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  data={product}
                />
              </Grid>
            </Grid>
          </Box>
        ))}

        {/* <Popover
              sx={{
                pointerEvents: 'none',
              }}
              // open={Boolean(anchorEl)} 
              open={Boolean(hoveredProduct)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Paper sx={{ p: 2, maxWidth: 300, boxShadow: 2 }}>
                <Typography variant="subtitle2" fontWeight="bold" mb={1}>
                  Available Quantities
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">
                      Base: {formatNumber(product.quantity_in_stock, 0)} {product.base_uom_symbol}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <UOMConversions 
                      uom_conversions={product.uom_conversions}
                      quantity_in_stock={Number(formatNumber(product.quantity_in_stock, 0))}
                      base_uom_name={product.base_uom_name}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Popover> */}

        {products.length > 0 && (
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
            rowsPerPageOptions={[10, 25, 50]}
            sx={{
              width: '100%',

              overflow: 'visible',
            }}
            showFirstButton
            showLastButton
          />
        )}

        {/* {isLoading && (
          <Box textAlign="center" padding="10px">
            <CircularProgress color="inherit" size="1.3rem" />
          </Box>
        )} */}
        {/* 
        {!hasMore && (
          <Typography textAlign="center" padding="10px">
            No more data
          </Typography>
        )} */}

        {products.length === 0 && (
          <Typography textAlign="center" padding="10px">
            No results found
          </Typography>
        )}
      </Box>
    </Box>
  );
});

export default ClinicInventoryScroller;

/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import CustomFilter from 'core/components/CustomFilter';
import { FacilityContext } from 'core/context/facility.context';
import ProductInTransaction from './ProductInTransaction';
import ProductOutTransaction from './ProductOutTransaction';
import { SIZES } from 'theme/constants';
import SearchBar from '../../POS/components/SearchBar';
import StockAdjustmentTransaction from './StockAdjustmentTransaction';
import { getAllTransactions } from 'company/api/transactions';

type TransactionsLogs = {
  refresh?: boolean;
  suppliers: any[];
};

const Transactions: React.FC<TransactionsLogs> = ({ refresh, suppliers }) => {
  const { facility } = useContext(FacilityContext);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [transactionSearchQuery, setTransactionSearchQuery] = useState<string>('');
  const [transactionFilters, setTransactionFilters] = useState<string[]>([]);

  const fetchAllTransactions = () => {
    if (facility) {
      getAllTransactions(facility.id, {
        length: 1000,
        search: transactionSearchQuery,
        supplier_id: transactionFilters.length ? transactionFilters : undefined,
      }).then((res) => {
        const transactions = res.data.data;
        setTransactions(transactions);
      });
    }
  };

  useEffect(() => {
    if (refresh) {
      fetchAllTransactions();
    }
  }, [refresh]);

  useEffect(fetchAllTransactions, [facility, transactionSearchQuery, transactionFilters]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h5" fontWeight="bold">
            Transactions
          </Typography>
        </Box>
        <Box display={'flex'} gap="5px" alignItems={'center'} justifyContent={'space-between'} flexWrap={'wrap'}>
          <Box width={'264px'}>
            <SearchBar handleSearch={(searchKey) => setTransactionSearchQuery(searchKey)} fullWidth />
          </Box>
          <CustomFilter
            optionList={suppliers.map((supplier) => ({ label: supplier.supplier_name, id: supplier.id.toString() }))}
            selected={transactionFilters}
            setSelected={setTransactionFilters}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={SIZES.paddingS}
          paddingRight={'5px'}
          height="calc(100vh + 72px)"
          sx={{ overflowY: 'auto' }}
        >
          {transactions.map((transaction, index) => {
            switch (transaction.type) {
              case 'in':
                return <ProductInTransaction key={index} transaction={transaction} />;
              case 'out':
                return <ProductOutTransaction key={index} transaction={transaction} />;
              case 'transfer':
                return;
              case 'adjustment':
                return <StockAdjustmentTransaction key={index} transaction={transaction} />;
              default:
                return null;
            }
          })}
        </Box>
      </Box>
    </>
  );
};

export default Transactions;

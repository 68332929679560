import React, { useContext, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import {
  TOPUP_ENTITY_NAME,
  TopUpInput,
  create_topup_schema,
  topupFields,
  topupFormFields,
  getInitialValues,
  getFieldsWithPriceCalculation,
} from './TopUpModel';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { createTopupRequest } from 'company/api/topup_requests';
import { FacilityContext } from 'core/context/facility.context';
import SuccessModal from 'core/components/modals/SuccessModal';
import CustomModal from 'core/components/modals/CustomModal';
import styled from 'styled-components';
import { UserContext } from 'core/context/user.context';
import { RegularButton } from 'core/components';

interface TopUpFormProps {
  requestType: string;
  unit: string;
  pricePerUnit: number;
  callbackAfterSubmit?: (data?: any) => void;
  visibleFields?: string[];
}

const TopUpForm = ({ requestType, unit, pricePerUnit, callbackAfterSubmit, visibleFields }: TopUpFormProps) => {
  const initialValues = getInitialValues(requestType, unit);
  const fields = getFieldsWithPriceCalculation(topupFields, requestType);
  const { facility } = useContext(FacilityContext);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [pendingData, setPendingData] = useState<TopUpInput | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { user } = useContext(UserContext);

  const handleBack = () => {
    setOpenSuccessModal(false);
    callbackAfterSubmit?.();
  };

  const handleConfirmationBack = () => {
    setOpenConfirmationModal(false);
    setPendingData(null);
  };

  const handleFormSubmit = async (data: TopUpInput): Promise<void> => {
    setPendingData(data);
    setOpenConfirmationModal(true);
  };

  const handleConfirmedSubmit = async () => {
    if (!pendingData) return;

    setIsSubmitting(true);
    const requestData = {
      request_type: requestType,
      add_on_value: pendingData.amount,
      unit,
      total_price: requestType === 'module' ? pricePerUnit : pendingData.total_price,
    };

    try {
      await createTopupRequest(facility.id, requestData);
      setOpenConfirmationModal(false);
      setOpenSuccessModal(true);
    } catch (error) {
      console.error('Error creating top up request:', error);
      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  const successMessageComponent = (
    <>
      <MainLine>Thank you for your top-up request!</MainLine>
      <SecondaryLine>
        Your request has been submitted and is pending approval from our admin team. Company Admins will receive a
        notification once your request has been processed.
      </SecondaryLine>
    </>
  );

  const confirmationContent = (
    <Box sx={{ textAlign: 'center', py: 2 }}>
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 500 }}>
        Please confirm your top-up request
      </Typography>
      <Typography sx={{ mb: 4, color: 'text.secondary' }}>
        You are about to request a top-up of {pendingData?.amount} {unit} of storage{' '}
        {pendingData?.total_price && `for ₱${pendingData.total_price.toLocaleString()}`}.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <RegularButton variant="outlined" onClick={handleConfirmationBack} disabled={isSubmitting}>
          Cancel
        </RegularButton>
        <RegularButton variant="contained" onClick={handleConfirmedSubmit} loading={isSubmitting}>
          Confirm Top-up
        </RegularButton>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <TemplateForm
        submitButtonId="top_up_submit"
        submitButtonText="Proceed with Top-up"
        entityName={TOPUP_ENTITY_NAME}
        fields={fields}
        visibleFields={visibleFields ? visibleFields : topupFormFields}
        initialValues={initialValues}
        schema={create_topup_schema}
        formSubmitApiFunction={handleFormSubmit}
        hideDefaultSnackbar
        disabled={user?.user_group.group_name !== 'COMPANY_ADMIN'}
        buttonPosition="center"
        buttonWidth="100%"
      />

      <CustomModal open={openConfirmationModal} setOpen={setOpenConfirmationModal} header="Confirm Top-up" width={500}>
        {confirmationContent}
      </CustomModal>

      <SuccessModal
        openModal={openSuccessModal}
        setOpenModal={setOpenSuccessModal}
        successMessageComponent={successMessageComponent}
        handleOkayCallback={handleBack}
      />
    </Box>
  );
};

const MainLine = styled(Typography)`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`;

const SecondaryLine = styled(Typography)`
  font-weight: regular;
  font-size: 14px;
  display: inline;
  text-align: center;
`;

export default TopUpForm;

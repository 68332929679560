import * as yup from 'yup';

import { EntityFields } from 'company/entities/utils';
import CustomLocationDropdown from 'company/components/dropdown/CustomLocationDropdown';
import CustomLocationSearchGroupDropdown from 'company/components/dropdown/CustomLocationSearchGroupDropdown';

export const SELF_REGISTRATION_ENTITY_NAME = 'Self Registration';

export const selfRegistrationSchema = yup.object().shape({
  first_name: yup.string().max(50, 'Must not exceed 50 characters').required('First Name is required.'),
  middle_name: yup.string().max(50, 'Must not exceed 50 characters'),
  last_name: yup.string().max(50, 'Must not exceed 50 characters').required('Last Name is required.'),
  email: yup.string().email('Invalid email').max(100, 'Email must not exceed 100 characters'),
  // .required('Email is required.'),
  mobile_number: yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile Number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile Number is required.'),
  // .max(12, 'Must not exceed 12 digits'),
  // .required('Mobile Number is required.'),
  mobile_number_2: yup.string().matches(/^(09|639)\d{9}$/, {
    message: 'Mobile Number is invalid.',
    excludeEmptyString: false,
  }),
  // .required('Alternative Number is required.'),
  landline_number: yup
    .string()
    .matches(/^[\d-]+$/, 'Only digits and dashes')
    .max(12, 'Must not exceed 12 characters'),

  birthday: yup.date().required('Birthday is required.'),
  sex: yup.string().oneOf(['Male', 'Female'], 'Invalid sex value').required('Sex is required.'),
  address: yup.string().max(500, 'Must not exceed 500 characters').required('Address is required.'),
  // region: yup.string().max(30, 'Must not exceed 30 characters'),
  // province: yup.string().max(30, 'Must not exceed 30 characters'),
  // municipality: yup.string().max(30, 'Must not exceed 30 characters'),
});

export interface SelfRegistrationModel {
  id?: number;
  first_name: string;
  middle_name?: string;
  last_name: string;
  email: string;
  mobile_number: string;
  mobile_number_2?: string;
  landline_number?: string;
  sex: string;
  birthday: Date;
  address: string;
  region?: string;
  province?: string;
  municipality?: string;
}

export const selfRegistrationInitialValues: SelfRegistrationModel = {
  first_name: '',
  last_name: '',
  email: '',
  mobile_number: '',
  mobile_number_2: '',
  sex: '',
  birthday: new Date(),
  address: '',
};

export const selfRegistrationFields: EntityFields[] = [
  {
    fieldName: 'personal_info_subheader',
    type: 'subsection_header',
    subsection_header: 'Basic Information',
  },
  {
    fieldName: 'first_name',
    displayName: 'First Name',
    type: 'text',
  },
  {
    fieldName: 'middle_name',
    displayName: 'Middle Name',
    type: 'text',
    optional: true,
  },
  {
    fieldName: 'last_name',
    displayName: 'Last Name',
    type: 'text',
  },
  {
    fieldName: 'sex',
    displayName: 'Sex Assigned At Birth',
    type: 'select',
    options: [
      { key: 'Male', value: 'Male' },
      { key: 'Female', value: 'Female' },
    ],
  },
  {
    fieldName: 'birthday',
    displayName: 'Birthday',
    type: 'date',
  },
  {
    fieldName: 'contact_info_subheader',
    type: 'subsection_header',
    subsection_header: 'Contact Information',
  },
  {
    fieldName: 'email',
    displayName: 'Email',
    type: 'email',
    optional: true,
  },
  {
    fieldName: 'mobile_number',
    displayName: 'Mobile Number',
    type: 'text',
  },
  {
    fieldName: 'mobile_number_2',
    displayName: 'Alternative Mobile Number',
    type: 'text',
    optional: true,
  },
  {
    fieldName: 'landline_number',
    displayName: 'Landline Number',
    type: 'text',
  },
  {
    fieldName: 'address',
    displayName: 'Address',
    type: 'text',
    span: 4,
    multiline: true,
    rows: 3,
  },
  {
    fieldName: 'region',
    displayName: 'Region',
    type: 'text',
  },
  {
    fieldName: 'province',
    displayName: 'Province',
    type: 'text',
  },
  {
    fieldName: 'municipality',
    displayName: 'Municipality',
    type: 'text',
  },
  {
    fieldName: 'custom_location',
    displayName: 'Location',
    type: 'component',
    displayComponentBasedOnFormFields: (data, setFieldValue) => {
      return <CustomLocationSearchGroupDropdown data={data} setFieldValue={setFieldValue} />;
    },
  },

  {
    fieldName: 'health_information_subheader',
    type: 'subsection_header',
    subsection_header: 'Health Information',
  },
];

export const selfRegistrationFormFields: string[] = [
  'personal_info_subheader',
  'first_name',
  'middle_name',
  'last_name',
  'sex',
  'birthday',
  'contact_info_subheader',
  'email',
  'mobile_number',
  'mobile_number_2',
  'landline_number',
  // 'region',
  // 'province',
  // 'municipality',
  'custom_location',
  'address',
  'health_information_subheader',
];

import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'personal-records';

export const getAppointmentInformation = () => {
  return axios.get(`${baseURL}/patient-account/${path}`);
};

export const getPatientNote = (id: number) => {
  return axios.get(`${baseURL}/patient-account/${path}/note/${id}`);
};


export const getPatientPrescriptions = (prescription_id: number) => {
  return axios.get(`${baseURL}/patient-account/${path}/prescription/${prescription_id}`)
}


export const getPatientLabRequests = () => {
  return axios.get(`${baseURL}/patient-account/${path}/lab-requests`)
}
import { Box, Typography, useMediaQuery, IconButton, useTheme } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { tokens } from 'theme/theme';

const Scroll4 = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const isTablet = useMediaQuery('(max-width:900px)');
  const [activeSection, setActiveSection] = useState(0);
  const [activeFeedback, setActiveFeedback] = useState(0);
  const [activeFeatured, setActiveFeatured] = useState(0);
  const [activePartnerClinics, setActivePartnerClinics] = useState(0);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const partnerClinics = [
    {
      name: 'CSCIRC',
      logo: './assets/Landing2/p4/cscirc.png',
    },
    {
      name: 'St. Elsewhere Medical and Diagnostic Clinic',
      logo: './assets/Landing2/p4/elsewhere.jpg',
    },
    {
      name: 'Holy Family Pharmacy and Health Center',
      logo: './assets/Landing2/p4/holy-family.jfif',
    },
    {
      name: 'HealthOne Diagnostic and Medical Clinic',
      logo: './assets/Landing2/p4/healthone.png',
    },
    {
      name: 'Elishama Medical Laboratory',
      logo: './assets/Landing2/p4/elishama.jpg',
    },
    {
      name: 'Messiah Clinica Medica Medical Clinic ',
      logo: './assets/Landing2/p4/mcmmc.png',
    },
    // Add more partner clinics as needed
  ];

  const partners = [
    './assets/LandingMobile/partner1.png',
    './assets/LandingMobile/partner2.png',
    './assets/LandingMobile/partner3.png',
    './assets/LandingMobile/partner4.png',
    './assets/LandingMobile/partner5.png',
    './assets/LandingMobile/partner6.png',
    './assets/LandingMobile/partner7.jpg',
    './assets/LandingMobile/partner8.png',
    './assets/LandingMobile/partner9.png',
    './assets/LandingMobile/partner10.jpg',
    './assets/LandingMobile/partner11.jpg',
    './assets/landing2/p4/cti.png',
    './assets/landing2/p4/danao.jpeg',
    './assets/landing2/p4/dasma.jpeg',
    './assets/landing2/p4/dghic.png',
    './assets/landing2/p4/gcarsih.png',
    './assets/landing2/p4/lcp.jpeg',
    './assets/landing2/p4/sanofi.png',
  ];

  const features = [
    {
      img: './assets/LandingMobile/featured1,2.png',
      url: 'https://www.who.int/westernpacific/initiatives/innovation-for-health-impact/innovation-challenge/cp-health-innovations',
    },
    {
      img: './assets/LandingMobile/featured3.png',
      url: 'https://www.sunstar.com.ph/manila/local-news/us-supported-digital-platforms-to-increase-access-to-family-planning-resources-in-ph',
    },
    {
      img: './assets/LandingMobile/featured4.png',
      url: 'https://www.tatlerasia.com/people/ernest-troyss',
    },
    {
      img: './assets/LandingMobile/featured5.png',
      url: 'https://www.youtube.com/live/t5bM6MINV4g?si=u4uULskP6ts3S36c',
    },
    {
      img: './assets/LandingMobile/featured6.png',
      url: 'https://fb.watch/seztwSSXaz/',
    },
    {
      img: './assets/LandingMobile/featured7.png',
      url: 'https://www.undp.org/philippines/blog/tawidcovid-innovation-better-normal',
    },
    {
      img: './assets/LandingMobile/featured8.png',
      url: 'https://asia.makesense.org/for-innovators/',
    },
    {
      img: './assets/LandingMobile/featured10,13.png',
      url: 'https://www.youtube.com/watch?v=c8ADVkV6ApM',
    },
    // {
    //   img: './assets/LandingMobile/featured16.png',
    //   url: 'https://www.who.int/westernpacific/initiatives/innovation-for-health-impact/innovation-challenge/cp-health-innovations',
    // },
  ];

  const feedbacks = [
    {
      avatar: './assets/Landing2/p4/ms_ethyl.png',
      name: 'Ms. Ethyl',
      role: 'Clinic staff of a Physical Therapy clinic in Cavite',
      quote:
        'It’s easier to track our transactions because everything is here. It’s also easier to schedule patients since the reminders are automated.',
    },
    {
      avatar: './assets/Landing2/p4/ms_deoc.png',
      name: 'Ms. Deocariza',
      role: 'Manager of a clinic and pharmacy in Zamboanga',
      quote:
        'It helps us connect our patient records to prescriptions that are needed in our pharmacy. Hindi na kami mag-manual para sa mga gamot ng patients.',
    },
    {
      avatar: './assets/Landing2/p4/mr_reyes.jpeg',
      name: 'Mr. Reyes',
      role: 'General Manager of a growing Physical Therapy clinic brand in Cavite',
      quote:
        'This will help me with the remote monitoring of my 2 clinic branches. I can’t keep track of the requirements for HMOs one by one anymore. This is a big help for managing my clinics.',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSection((prev) => (prev + 1) % Math.ceil(partners.length / (isMobile ? 3 : 5)));
      setActivePartnerClinics((prev) => (prev + 1) % Math.ceil(partnerClinics.length / (isMobile ? 3 : 5)));
      setActiveFeatured((prev) => (prev + 1) % Math.ceil(features.length / (isMobile ? 3 : 5)));
    }, 5000);
    return () => clearInterval(interval);
  }, [isMobile, partners.length, partnerClinics.length, features.length]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'white',
        py: { xs: 0, md: 10 },
        pt: { xs: 0, md: 5 },
        pb: { xs: 6, md: 10 },
        overflow: 'hidden',
      }}
    >
      {/* Partners Section */}
      <Box sx={{ mb: 10 }}>
        <Typography
          variant="h2"
          align="center"
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2rem', md: '2.3rem' },
            mb: 4,
            background: 'linear-gradient(to right, #239bd7, #0463ae)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}
        >
          Our Partners
        </Typography>

        <Box
          sx={{
            position: 'relative',
            maxWidth: '1200px',
            mx: 'auto',
            px: { xs: 2, md: 4 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              overflowX: 'hidden',
              gap: 4,
              justifyContent: 'center',
              py: 2,
              width: '100%',
            }}
          >
            <AnimatePresence mode="wait">
              <motion.div
                key={activeSection}
                initial={{ x: '100%' }}
                animate={{ x: '0%' }}
                exit={{ x: '-100%' }}
                transition={{ duration: 1 }}
                style={{
                  display: 'flex',
                  gap: '2rem',
                  flexShrink: 0,
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                {partners
                  .slice(
                    activeSection * (isMobile ? 3 : isTablet ? 3 : 5),
                    (activeSection + 1) * (isMobile ? 3 : isTablet ? 3 : 5)
                  )
                  .map((partner, index) => (
                    <motion.div key={index} whileHover={{ scale: 1.05 }} transition={{ duration: 0.2 }}>
                      <Box
                        component="img"
                        src={partner}
                        alt="Partner logo"
                        sx={{
                          height: { xs: 80, md: 100 },
                          width: isMobile ? 'auto' : '200px',
                          objectFit: 'contain',
                          transition: '0.3s',
                          // '&:hover': {
                          //   width: '101%',
                          // },
                        }}
                      />
                    </motion.div>
                  ))}
              </motion.div>
            </AnimatePresence>
          </Box>
        </Box>
      </Box>

      {/* Staff Image Section */}
      {!isMobile && (
        <motion.div
          variants={itemVariants}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <Box
            component="img"
            src="./assets/Landing2/p3/staff1.gif"
            alt="Staff"
            sx={{
              height: { xs: '200px', md: '400px' },
              borderRadius: 3,
              boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
              mb: 10,
            }}
          />
        </motion.div>
      )}

      {/* Empowered Partner Clinics Section */}
      <Box sx={{ mb: 10 }}>
        <Typography
          variant="h2"
          align="center"
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2rem', md: '2.3rem' },
            mb: 4,
            background: 'linear-gradient(to right, #239bd7, #0463ae)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}
        >
          Featured Partner Clinics
        </Typography>

        <Box
          sx={{
            maxWidth: '1200px',
            mx: 'auto',
            px: { xs: 2, md: 4 },
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              },
              gap: 4,
              justifyContent: 'center',
              alignItems: 'stretch',
            }}
          >
            {partnerClinics.map((clinic, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    p: 4,
                    borderRadius: 2,
                    bgcolor: 'white',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 6px 25px rgba(0,0,0,0.15)',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mb: 3,
                      flex: 1,
                    }}
                  >
                    <Box
                      component="img"
                      src={clinic.logo}
                      alt={clinic.name}
                      sx={{
                        height: { xs: 80, md: 100 },
                        width: '100%',
                        maxWidth: '200px',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: { xs: '0.9rem', md: '1rem' },
                      fontWeight: 500,
                      color: colors.primary,
                      mt: 0,
                    }}
                  >
                    {clinic.name}
                  </Typography>
                </Box>
              </motion.div>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Feedback Section */}
      <Box
        sx={{
          maxWidth: '100vw',
          mx: 'auto',
          px: { xs: 2, md: 4 },
          py: { xs: 3, md: 6 },
          background: 'linear-gradient(135deg, #0463ae 0%, #239bd7 100%)',
          mb: 10,
        }}
      >
        <Typography
          variant="h3"
          align="center"
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2rem', md: '2.5rem' },
            mb: 2,
            color: 'white',
          }}
        >
          Feedback from our partner clinics
        </Typography>

        <Typography
          align="center"
          sx={{
            color: 'white',
            mb: 6,
            maxWidth: '800px',
            mx: 'auto',
          }}
        >
          Read what our partner clinics have to say about their experience with our services
        </Typography>

        <Box sx={{ position: 'relative' }}>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeFeedback}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.3 }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' },
                  gap: 4,
                  px: { xs: 2, md: 8 },
                }}
              >
                {feedbacks.map((feedback, index) => (
                  <motion.div key={index} variants={itemVariants} whileHover={{ y: -5 }}>
                    <Box
                      sx={{
                        p: 4,
                        borderRadius: 3,
                        bgcolor: 'white',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                      }}
                    >
                      <Box
                        component="img"
                        src={feedback.avatar}
                        alt={feedback.name}
                        sx={{
                          width: 80,
                          height: 80,
                          borderRadius: '50%',
                          mb: 2,
                        }}
                      />
                      <Typography variant="h3" sx={{ mb: 1, fontWeight: 'bold' }}>
                        {feedback.name}
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 3, color: colors.primary }}>
                        {feedback.role}
                      </Typography>
                      <Typography
                        sx={{
                          fontStyle: 'italic',
                          color: 'text.secondary',
                          lineHeight: 1.6,
                        }}
                      >
                        "{feedback.quote}"
                      </Typography>
                    </Box>
                  </motion.div>
                ))}
              </Box>
            </motion.div>
          </AnimatePresence>
        </Box>
      </Box>

      {/* Featured In Section */}
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h2"
          align="center"
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2rem', md: '2.5rem' },
            mb: 4,
            background: 'linear-gradient(to right, #239bd7, #0463ae)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}
        >
          Featured In
        </Typography>

        <Box
          sx={{
            position: 'relative',
            maxWidth: '1200px',
            mx: 'auto',
            px: { xs: 2, md: 4 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              overflowX: 'hidden',
              gap: 4,
              justifyContent: 'center',
              py: 2,
              width: '100%',
            }}
          >
            <AnimatePresence mode="wait">
              <motion.div
                key={activeFeatured}
                initial={{ x: '100%' }}
                animate={{ x: '0%' }}
                exit={{ x: '-100%' }}
                transition={{ duration: 0.5 }}
                style={{
                  display: 'flex',
                  gap: '2rem',
                  flexShrink: 0,
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                {features
                  .slice(
                    activeFeatured * (isMobile ? 3 : isTablet ? 3 : 5),
                    (activeFeatured + 1) * (isMobile ? 3 : isTablet ? 3 : 5)
                  )
                  .map((feature, index) => (
                    <motion.a
                      key={index}
                      href={feature.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Box
                        component="img"
                        src={feature.img}
                        alt="Featured logo"
                        sx={{
                          height: { xs: 80, md: 100 },
                          width: isMobile ? 'auto' : '200px',
                          objectFit: 'contain',
                          transition: '0.3s',
                          // '&:hover': {
                          //   width: '101%',
                          // },
                        }}
                      />
                    </motion.a>
                  ))}
              </motion.div>
            </AnimatePresence>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Scroll4;

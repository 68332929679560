import * as yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from 'company/entities/utils';

export const PRODUCT_VARIANT_ENTITY_NAME = 'Product Variant';
export interface ProductVariantModel {
  id: number;
  variant_name: any;
  variant_description: string;
  price: string;
  sku: string;
  product_name: string;
  quantity_in_stock: string;
  product_id?: number;
  exclusive_supplier?: number;
  exclusive_supplier_name?: string;
  made_to_order?: number;
  product_cost?: number;

  tax: 'non-vat' | 'vat';
  initial_quantity?: number;
  attributes?: ProductVariantAttributesModel[];
  attribute_ids?: string;

  lot_number?: string;
  manufacturing_date?: Date;
  expiry_date?: Date;

  brand_name?: string;
  generic_name?: string;
  dosage?: string;
  low_stock_threshold: number;
  uom: string;
  uom_symbol: string;
  uom_id: number;
}

export interface ProductVariantAttributesModel {
  attribute_id: number;
  attribute_name: string;
  value: string;
  variant_id: number;
}

export const createProductVariantModelSchema = yup.object().shape({
  // product_id: yup.number().required('Product is required'),
  product_cost: yup
    .number()
    .min(0, 'Amount should be positive')
    .max(999999, 'Maximum product cost is 999,999')
    .typeError('Must be a number')
    .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
    .test('decimal-places', 'Maximum of 2 decimal places allowed', (value) => {
      if (!value) return true;
      return /^\d+(\.\d{0,2})?$/.test(value.toString());
    }),
  variant_price: yup
    .number()
    .min(0, 'Amount should be positive')
    .required('Price is required')
    .typeError('Must be a number'),
  low_stock_threshold: yup
    .number()
    .min(0, 'Value should be positive')
    .typeError('Must be a number')
    .max(99999, 'At most 5 digits long')
    .test('decimal', 'Up to 3 decimal places only', (value) => {
      if (value === undefined || value === null) return false;
      return /^\d+\.?\d{0,3}$/.test(value.toString());
    }),
  lot_number: yup.string().when(['made_to_order'], {
    is: (made_to_order: boolean) => !made_to_order,
    then: (schema) => schema.required('Lot Number is required'),
    otherwise: (schema) => schema.optional(),
  }),
  manufacturing_date: yup.date().typeError('Invalid Date'),
  expiry_date: yup
    .date()
    .min(yup.ref('manufacturing_date'), 'Expiry cannot be before manufacturing')
    .typeError('Invalid Date'),
});

export const updateProductVariantModelSchema = yup.object().shape({
  variant_price: yup
    .number()
    .min(0, 'Amount should be positive')
    .required('Price is required')
    .typeError('Must be a number'),
  low_stock_threshold: yup
    .number()
    .min(0, 'Value should be positive')
    .typeError('Must be a number')
    .max(99999, 'At most 5 digits long')
    .test('decimal', 'Up to 3 decimal places only', (value) => {
      if (value === undefined || value === null) return false;
      return /^\d+\.?\d{0,3}$/.test(value.toString());
    }),
});

export const variantPrice = yup.object().shape({
  facility_inventory_price: yup.number().required('Price is required').typeError('Must be a number'),
});

export const addQuantitySchema = yup.object().shape({
  transaction_date: yup.date().required('Transaction Date is required').typeError('Invalid Date'),
  supplier: yup.string().required('Supplier is required'),
  quantity_in_stock: yup
    .number()
    .required('Quantity is required.')
    .typeError('Must be a number')
    .min(1, 'Quantity must be greater than 0')
    .test('decimal', 'Must be a number with up to 6 decimal places', (value) => {
      if (value === undefined || value === null) return false;
      return /^\d+\.?\d{0,3}$/.test(value.toString());
    }),
  product_cost: yup.number().typeError('Must be a number'),

  lot_number: yup.string().when(['made_to_order', 'initial_quantity'], {
    is: (made_to_order: boolean, initial_quantity: number) => !made_to_order && initial_quantity > 0,
    then: (schema) => schema.required('Lot Number is required'),
    otherwise: (schema) => schema.optional(),
  }),
  manufacturing_date: yup.date().typeError('Invalid Date'),
  expiry_date: yup
    .date()
    .min(yup.ref('manufacturing_date'), 'Expiry cannot be before manufacturing')
    .typeError('Invalid Date'),
});

export const editQuantitySchema = yup.object().shape({
  quantity_in_stock: yup
    .number()
    .required('Quantity is required')
    .typeError('Must be a number')
    .min(1, 'Quantity must be greater than 0')
    .test('decimal', 'Must be a number with up to 6 decimal places', (value) => {
      if (value === undefined || value === null) return false;
      return /^\d+\.?\d{0,3}$/.test(value.toString());
    }),
  reason: yup.string().required('Purpose is required'),
});

export const transferSchema = yup.object().shape({
  transfer_quantity: yup
    .number()
    .required('Quantity is required')
    .typeError('Must be a number')
    .min(1, 'Quantity must be greater than 0')
    .test('decimal', 'Must be a number with up to 6 decimal places', (value) => {
      if (value === undefined || value === null) return false;
      return /^\d+\.?\d{0,3}$/.test(value.toString());
    }),
  dest_facility_id: yup.number().required('Clinic is required'),
});

export interface ProductVariantInput {
  sku: string;
  variant_description: string;
  variant_name?: string;
  variant_price: string;
  made_to_order: boolean;
  tax: string;
  uom_id: number;
  lot_number?: string;
  manufacturing_date?: Date;
  expiry_date?: Date;
}
export const productVariantFields: EntityFields[] = [
  {
    fieldName: 'variant_name',
    displayName: 'Variant Name',
    span: 2,
    generateLink: (data) => `'/company/inventory/products/variant?id=${data.id}'`,
    linkPreviousLocation: '/company/inventory/products',
    // optional: true
  },
  { fieldName: 'variant_price', displayName: 'Price', type: 'currency' },
  { fieldName: 'variant_description', displayName: 'Description', multiline: true, rows: 3, span: 4, optional: true },
  { fieldName: 'sku', displayName: 'SKU', optional: true },
  { fieldName: 'product_id', displayName: 'Product', type: DROPDOWN_FIELD.PRODUCT },
  { fieldName: 'exclusive_supplier', displayName: 'Exclusive Supplier', type: DROPDOWN_FIELD.SUPPLIER, optional: true },
  { fieldName: 'exclusive_supplier_name', displayName: 'Exclusive Supplier' },
  { fieldName: 'made_to_order', displayName: 'Made to Order', type: 'checkbox' },
  {
    fieldName: 'tax',
    displayName: 'Tax',
    type: 'radiogroup',
    options: [
      { key: 'VAT', value: 'vat' },
      { key: 'Non-VAT', value: 'non-vat' },
    ],
  },
  { fieldName: 'product_cost', displayName: 'Cost per item', type: 'currency', optional: true },
  {
    fieldName: 'initial_quantity',
    displayName: 'Initial Quantity',
    type: 'number',
    hiddenBasedOnOtherField: (data: any) => data.made_to_order,
  },
  {
    fieldName: 'lot_number',
    displayName: 'Lot Number',
    type: 'string',
    hiddenBasedOnOtherField: (data: any) => data.made_to_order,
  },
  {
    fieldName: 'manufacturing_date',
    displayName: 'Manufacturing Date',
    type: 'date',
    hiddenBasedOnOtherField: (data: any) => data.made_to_order,
  },
  {
    fieldName: 'expiry_date',
    displayName: 'Expiration Date',
    type: 'date',
    hiddenBasedOnOtherField: (data: any) => data.made_to_order,
    disablePast: true,
  },
  {
    fieldName: 'low_stock_threshold',
    displayName: 'Low Stock Threshold',
    type: 'number',
    hiddenBasedOnOtherField: (data: any) => data.made_to_order,
  },
  {
    fieldName: 'uom_id',
    displayName: 'Unit of Measurement',
    type: DROPDOWN_FIELD.PRODUCT_UOM,
    optional: true,
  },
  {
    fieldName: 'price_section',
    type: 'subsection_header',
    subsection_header: 'Price',
  },
  {
    fieldName: 'quantity_section',
    type: 'subsection_header',
    subsection_header: 'Quantity',
  },
  {
    fieldName: 'product_sourcing_section',
    type: 'subsection_header',
    subsection_header: 'Product Sourcing',
  },
  { fieldName: 'divider', type: 'divider' },
  {
    fieldName: 'description_section',
    type: 'subsection_header',
    subsection_header: 'Description',
  },
];

export const productVariantVisibleColumns = ['variant_name', 'sku', 'exclusive_supplier_name'];
export const productVariantFormFields = [
  'attributes',
  'divider',
  'description_section',
  'sku',
  'variant_description',

  'price_section',
  'variant_price',
  'tax',

  'quantity_section',
  'made_to_order',
  'uom_id',
  'lot_number',
  'initial_quantity',
  'low_stock_threshold',
  'manufacturing_date',
  'expiry_date',

  'product_sourcing_section',
  'exclusive_supplier',
  'product_cost',
];
export const productVariantFormFieldsExclusive = [
  'product_id',
  'variant_price',
  'sku',
  // 'exclusive_supplier',
  'variant_description',
  'initial_quantity',
  'uom_id',
  'low_stock_threshold',
  'tax',
  'made_to_order',
  'attributes',
];
export const productVariantUpdateFormFields = [
  'attributes',
  'divider',
  'description_section',
  'sku',
  'variant_description',

  'price_section',
  'variant_price',
  'tax',

  'quantity_section',
  'made_to_order',
  'uom_id',
  'low_stock_threshold',

  'product_sourcing_section',
  'exclusive_supplier',
  // 'product_cost',
];
export const productVariantInitialValues = {
  variant_name: '',
  variant_price: '',
  variant_description: '',
  sku: '',
  made_to_order: false,
  tax: 'non-vat',
  initial_quantity: 0,
  low_stock_threshold: 10, //default value
  uom_id: '',
  lot_number: '',
  manufacturing_date: '',
  expiry_date: '',
};

export const productVariantFormFieldsExclusiveWithProduct = [
  'product_id',
  'variant_price',
  'sku',
  'exclusive_supplier',
  'variant_description',
  'initial_quantity',
  'low_stock_threshold',
  'product_cost',
  'made_to_order',
  'tax',
  'attributes',
];

import { Box, SvgIconTypeMap, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Ref, forwardRef, useContext } from 'react';
import { calculateAge, formatArray, formatDate, formatDateTime } from 'core/utils';

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { ContainerColumn } from 'core/components/containers';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FacilityContext } from 'core/context/facility.context';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MedicationLiquidOutlinedIcon from '@mui/icons-material/MedicationLiquidOutlined';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientLabRequestProps } from 'core/model/interface';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import { SIZES } from 'theme/constants';
import { ServiceModel } from 'company/entities/modules/ClinicManagement/Service/ServiceModel';
import { UserContext } from 'core/context/user.context';
import { tokens } from 'theme/theme';

type Props = {
  patientLabRequest: PatientLabRequestProps;
  patient: PatientModel;
  selectedServices: ServiceModel[];
  fontSizes?: { header: string; body: string };
};

type FacilityDetailsProps = {
  label: string | undefined;
  Icon: OverridableComponent<SvgIconTypeMap>;
};

const PrintableLabRequest = forwardRef(
  ({ patientLabRequest, patient, selectedServices, fontSizes }: Props, ref: Ref<HTMLDivElement>) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useContext(UserContext);
    const { facility } = useContext(FacilityContext);
    const isMobilePhone = useMediaQuery('(max-width:768px)');

    const styles = `
      table {
        border-collapse: collapse;
        padding: 10px;
        width: 100%;
        table-layout: fixed;
      }

      thead.lab-request tr td {
        padding-top: 150px;
      }

      thead.lab-request td, tbody td {
        padding: 8px;
        border-bottom: 1px solid #E0E0E0;
      }

      tbody p {
        word-wrap: break-word;
      }

      tbody td p,
      thead.lab-request td p {
        font-size: ${fontSizes ? fontSizes.body : '11px'} !important;
      }
      `;

    const FacilityDetails: React.FC<FacilityDetailsProps> = ({ label, Icon }) => {
      if (label) {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
            <Icon color="primary" sx={{ fontSize: fontSizes?.header }} />
            <Typography textAlign="center" sx={{ fontSize: fontSizes?.body }}>
              {label}
            </Typography>
          </Box>
        );
      }
      return <></>;
    };

    return (
      <Box>
        <style>{styles}</style>
        <Box ref={ref}>
          <Box>
            {!!user && (
              <ContainerColumn sx={{ position: 'fixed' }}>
                <Box>
                  <Box alignSelf="flex-start" position="absolute" top="0">
                    <img
                      alt="rx-logo"
                      width="90px"
                      height="90px"
                      id="logo"
                      src={process.env.PUBLIC_URL + `/assets/rx-img.png`}
                    />
                  </Box>

                  <Box
                    width={isMobilePhone ? '100%' : '60%'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap="2px"
                    margin="0 auto"
                  >
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{ color: colors.accent, mb: '5px', fontSize: fontSizes ? fontSizes.header : '13px' }}
                    >
                      {user.is_patient_account ? user.facility?.facility_name : facility.facility_name}
                    </Typography>
                    <FacilityDetails
                      Icon={CallOutlinedIcon}
                      label={formatArray(
                        user.is_patient_account
                          ? [facility?.smart_mobile_number, facility?.globe_mobile_number]
                          : [user.facility?.smart_mobile_number, user.facility?.globe_mobile_number],
                        ' | '
                      )}
                    />
                    <FacilityDetails
                      Icon={EmailOutlinedIcon}
                      label={user.is_patient_account ? user.facility?.email : facility.email}
                    />
                    <FacilityDetails
                      Icon={LocationOnOutlinedIcon}
                      label={formatArray(
                        !user.is_patient_account
                          ? [facility.address, facility.municipality_name, facility.province_name]
                          : [user.facility?.address, user.facility?.municipality_name, user.facility?.province_name]
                      )}
                    />
                  </Box>

                  <Box alignSelf="flex-start" position="absolute" top="0" right="0">
                    <img
                      alt="cphi-logo"
                      width="60px"
                      height="auto"
                      id="logo"
                      src={process.env.PUBLIC_URL + `${user.company?.company_logo}`}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mt: '10px',
                    }}
                  >
                    <Typography variant="h5" fontWeight={600} sx={{ fontSize: fontSizes ? fontSizes.header : '13px' }}>
                      Lab Request
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: fontSizes?.body }}>
                      Request ID: {patientLabRequest.id}
                    </Typography>
                    <Typography variant="body2" fontSize={fontSizes ? fontSizes.body : '11px'}>
                      {formatDate(patientLabRequest.created_at)}
                    </Typography>
                  </Box>
                </Box>
              </ContainerColumn>
            )}
          </Box>
          <Box sx={{ paddingTop: '160px', marginBottom: '-160px' }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <PersonPinOutlinedIcon color="primary" sx={{ fontSize: fontSizes ? fontSizes.header : '13px' }} />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: '600',
                    color: colors.dark_grey_text,
                    fontSize: fontSizes ? fontSizes.header : '13px',
                  }}
                >
                  Patient Information
                </Typography>
              </Box>
              <Box display="grid" gridTemplateColumns={'repeat(4, 1fr)'} gap="10px" mt="10px">
                <PatientField
                  label="Full Name"
                  value={patient.full_name}
                  fontSizes={fontSizes}
                  fieldFontSize={fontSizes ? fontSizes.body : '11px'}
                />
                <PatientField
                  label="Sex"
                  value={patient.sex}
                  fontSizes={fontSizes}
                  fieldFontSize={fontSizes ? fontSizes.body : '11px'}
                />
                <PatientField
                  label="Birthday"
                  value={formatDate(patient.birthday)}
                  fontSizes={fontSizes}
                  fieldFontSize={fontSizes ? fontSizes.body : '11px'}
                />
                <PatientField
                  label="Age"
                  value={calculateAge(patient.birthday)}
                  fontSizes={fontSizes}
                  fieldFontSize={fontSizes ? fontSizes.body : '11px'}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                mb: SIZES.padding,
                mt: SIZES.padding,
              }}
            >
              <MedicationLiquidOutlinedIcon color="primary" sx={{ fontSize: fontSizes ? fontSizes.header : '13px' }} />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: '600',
                  color: colors.dark_grey_text,
                  fontSize: fontSizes ? fontSizes.header : '13px',
                }}
              >
                Medication
              </Typography>
            </Box>
          </Box>
          <Box>
            <ContainerColumn gap={SIZES.padding}>
              <Box>
                <table>
                  <thead className="lab-request">
                    <tr>
                      <td width="10%">
                        <Typography fontWeight="600" sx={{ fontSize: fontSizes?.body }}>
                          #
                        </Typography>
                      </td>
                      <td width="90%">
                        <Typography fontWeight="600" sx={{ fontSize: fontSizes?.body }}>
                          Service
                        </Typography>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedServices.map((service, index) => (
                      <tr key={index}>
                        <td>
                          <Typography sx={{ fontSize: fontSizes?.body }}>{index + 1}</Typography>
                        </td>
                        <td>
                          <Typography sx={{ fontSize: fontSizes?.body }}>{service.service_name}</Typography>
                        </td>
                      </tr>
                    ))}
                    {patientLabRequest.other_tests && (
                      <tr>
                        <td>
                          <Typography sx={{ fontSize: fontSizes?.body }}>{selectedServices.length + 1}</Typography>
                        </td>
                        <td>
                          <Typography sx={{ fontSize: fontSizes?.body, whiteSpace: 'pre-wrap' }}>
                            {patientLabRequest.other_tests}
                          </Typography>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2}>
                        <Box width="100%">
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'flex-end',
                              padding: '40px 10px 50px',
                            }}
                          >
                            <Box minWidth="30%" sx={{ '& p': { fontSize: fontSizes ? fontSizes.body : '11px' } }}>
                              <Typography sx={{ color: colors.accent, fontWeight: '600' }}>
                                {patientLabRequest.doctor_name}
                              </Typography>
                              <Typography>License No. {patientLabRequest.license_number}</Typography>
                              <Typography>PTR No. {patientLabRequest.ptr_number}</Typography>
                            </Box>
                          </Box>

                          <Box position="fixed" bottom="0" width="100%">
                            <Box width="100%" textAlign="center">
                              <Typography fontSize="9px" sx={{ fontWeight: '400' }}>
                                Any unauthorized review, use, tampering, disclosure, or distribution of this
                                prescription is strictly prohibited.
                              </Typography>
                            </Box>

                            <hr
                              style={{
                                border: 'none',
                                borderTop: '0.5px solid #0192D5',
                                width: '100%',
                                transform: 'scaleY(0.5)',
                              }}
                            />

                            <Box width="100%">
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                <img
                                  alt="cphi-logo"
                                  width="30px"
                                  height="auto"
                                  id="logo"
                                  src={process.env.PUBLIC_URL + `/assets/carego_health_suite_logo.png`}
                                />

                                <Box sx={{ textAlign: 'left', gap: '4px' }}>
                                  <Typography sx={{ fontSize: '9px', color: colors.accent }}>
                                    Document generated using CareGo Health Suite on {formatDateTime(new Date())}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </Box>
            </ContainerColumn>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default PrintableLabRequest;

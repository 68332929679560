import * as XLSX from 'xlsx';

import { Box, Button, Divider, Drawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  ColoredTable,
  ConfirmationDialog,
  CustomAnimatedDrawer,
  CustomGridCell,
  CustomIconButton,
  CustomModal,
  RegularButton,
} from 'core/components';
import CustomDateRangeFilter, { DateRangeValue } from 'core/components/CustomDateRangeFilter';
import { DISPLAY_ONLY_FIELDS, FORM_MODE, FieldInput } from 'core/model/interface';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getNote, getNotesByTemplate, getPatientNotesByTemplate } from 'company/api/patient-notes';
import {
  getTemplate,
  getTemplates,
  getTemplatesWithNotes,
  updateTemplateTableFormat,
} from 'company/api/note-templates';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CareGoPage } from 'core/PageBuilder';
import ChartEditor from './components/ChartEditor';
import { ContainerRow } from 'core/components/containers';
import CreatePatientNoteModal from '../RegistrationBoard/components/CreatePatientNoteModal';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import DocumentEditor from '../DocumentEditor';
import DraggableList from 'core/components/DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { FacilityContext } from 'core/context/facility.context';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterEditor from './components/FilterEditor';
import { GridColDef } from '@mui/x-data-grid';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { PatientNoteTemplateModel } from 'company/entities/modules/ClinicManagement/PatientNoteTemplate/PatientNoteTemplateModel';
import PatientNotesContainer from '../PatientManagement/components/BasicHealthInfo/Sections/PatientNotesContainer';
import PrescriptionForm from 'company/entities/modules/ClinicManagement/Prescription/PrescriptionForm';
import { PrescriptionTable } from 'company/entities/tables';
import { Description as RecordsIcon } from '@mui/icons-material';
import RichTextEditor from 'core/components/RichTextEditor';
import { SIZES } from 'theme/constants';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { UserContext } from 'core/context/user.context';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { WaitForFacility } from 'company/components';
import { createRunningInvoice } from 'company/api/running-invoices';
import { formatDate } from 'core/utils';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type Props = {
  patient?: PatientModel;
};

const PatientRecords: React.FC<Props> = ({ patient }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { facility } = useContext(FacilityContext);

  const { user } = useContext(UserContext);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const [selectedTemplate, setSelectedTemplate] = useState<number>(isMobilePhone || !!patient ? 0 : -1);
  const [selectedTemplateDetails, setSelectedTemplateDetails] = useState<PatientNoteTemplateModel>();
  const [patientRecords, setPatientRecords] = useState<any[]>([]);
  const [selectedPatientNote, setSelectedPatientNote] = useState<any>();
  const [openPatientNoteEditor, setOpenPatientNoteEditor] = useState(false);
  const [openChartEditor, setOpenChartEditor] = useState(false);
  const [openFilterEditor, setOpenFilterEditor] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState<boolean>(false);
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState<boolean>(false);
  const [confirmCreateRunningInvoiceOpen, setConfirmCreateRunningInvoiceOpen] = useState<boolean>(false);
  const [selectedPrescription, setSelectedPrescription] = useState<any>();
  const [, setRefreshCount] = useState<number>(0);
  const [openAppointmentsDrawer, setOpenAppointmentsDrawer] = useState(false);
  const [openRecordActions, setOpenRecordActions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [otherColumns, setOtherColumns] = useState<GridColDef[]>([]);
  const [filterDateRange, setFilterDateRange] = useState<DateRangeValue>([undefined, undefined]);
  const [openCustomizeTable, setOpenCustomizeTable] = useState(false);
  const [reorderedFields, setReorderedFields] = useState<any[]>([]);
  const [hiddenFields, setHiddenFields] = useState<any[]>([]);
  const [allFields, setAllFields] = useState<FieldInput[]>([]);
  const [selectedPatientRunningInvoice] = useState<any>();

  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const location = useLocation(); //use to refresh the table when changing view in switch
  const noteRef: any = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const { setBreadcrumb } = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb([{ label: 'Patient Records' }]);
  }, [setBreadcrumb]);

  const getPatientNoteTemplates = () => {
    if (facility && selectedTemplate > 0) {
      getTemplate(facility.id, selectedTemplate)
        .then((res) => {
          setSelectedTemplateDetails(res.data);
        })
        .catch();
    }
  };

  useEffect(() => {
    setIsLoading(true);

    getPatientNoteTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  const fetchLatestData = useCallback(() => {
    setIsLoading(true);

    if (facility && selectedTemplate > 0) {
      const start_date = filterDateRange[0];
      const end_date = filterDateRange[1];
      const fetchPromise = patient
        ? getPatientNotesByTemplate(facility.id, patient.id, selectedTemplate, { start_date, end_date })
        : getNotesByTemplate(facility.id, selectedTemplate, { start_date, end_date });

      fetchPromise
        .then((res) => setPatientRecords(res.data))
        .catch((error) => console.error('Error fetching data:', error))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [facility, patient, selectedTemplate, filterDateRange]);

  useEffect(() => {
    fetchLatestData();
  }, [fetchLatestData, refreshTrigger, patient]);

  // Function to trigger a refresh
  const refreshData = useCallback(() => {
    setRefreshTrigger((prev) => prev + 1);
  }, []);

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, patient]);

  const getData = useCallback(
    (query: any) => {
      if (patient && facility) {
        return getTemplatesWithNotes(facility.id, patient.id, query);
      } else if (facility) {
        return getTemplates(facility.id, query);
      } else {
        return Promise.resolve({ data: [] });
      }
    },
    [patient, facility]
  );

  useEffect(() => {
    if (selectedTemplateDetails) {
      const body: any = selectedTemplateDetails.body[0];
      const fields: FieldInput[] = body.fields ?? [];

      const responses: any = body.responses;
      const finalFields = fields.flatMap((field: any) => {
        if (field.type === 'table') {
          const rows: any[] = responses[field.field_name];
          const mainColumn = field.columns?.find((col: any) => col.main_column);
          if (mainColumn && rows) {
            return rows.map((row) => ({
              field_name: field.field_name + '|' + row.id,
              display_name: row[mainColumn.fieldName],
              type: 'table-column',
              columns: field.columns,
            }));
          }
        }
        return field;
      });
      setTableColumns(finalFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateDetails]);

  const setTableColumns = (fields: FieldInput[]) => {
    if (!selectedTemplateDetails) return;
    let input_fields = fields.filter((field) => !DISPLAY_ONLY_FIELDS.includes(field.type as string));
    setAllFields(input_fields);

    const copy = input_fields;
    if (selectedTemplateDetails.table_format) {
      input_fields = selectedTemplateDetails.table_format.map(
        (field_name) => input_fields.find((field) => field.field_name === field_name) as FieldInput
      );
      input_fields = input_fields.filter((field) => !!field);
    } else {
      input_fields = input_fields.slice(0, 6);
    }

    const visible = input_fields.map((field) => field.field_name);
    setReorderedFields(input_fields);
    setHiddenFields(copy.filter((field) => !visible.includes(field.field_name)));
    setOtherColumns(
      input_fields.map((field) => ({
        field: field.field_name,
        headerName: field.display_name,
        flex: field.multiline ? 1.5 : 1,
        sortable: false,
        renderCell: (params) => {
          const responses = params.row.responses ?? {};

          if (field.field_name.includes('|')) {
            const [field_id, row_id] = field.field_name.split('|');
            if (responses[field_id]) {
              const row = responses[field_id].find((row_input: any) => row_input.id + '' === row_id);
              if (row) {
                return field.columns
                  ?.filter((column) => column.type !== 'fixed_text')
                  .map((column) => <Typography>{row[column.fieldName] || ''}</Typography>);
              }
            }
            return <></>;
          }

          const value = responses[field.field_name];
          if (field.type === 'date') return <Typography>{formatDate(value)}</Typography>;
          if (field.type === 'rich_text') return <RichTextEditor content={value} readOnly />;
          return <Typography>{value}</Typography>;
        },
      }))
    );
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const newFields = [...reorderedFields];
    const [movedField] = newFields.splice(result.source.index, 1);
    newFields.splice(result.destination.index, 0, movedField);

    if (result.source.index !== result.destination.index) {
      newFields.forEach((field, index) => {
        // updateBasicHealthInformationField(parseInt(field.field_name), { position: index + 1 });
      });
    }
    setReorderedFields(newFields);
    updateTableColumns(newFields);
  };

  const updateTableColumns = async (fields: any[]) => {
    const field_ids = fields.map((field) => field.field_name);
    await updateTemplateTableFormat(field_ids, selectedTemplate);
    getPatientNoteTemplates();
  };

  const displayItem = useCallback(
    (patient: PatientNoteTemplateModel) => {
      const isSelectedPatient = selectedTemplate === patient.id;
      return (
        <>
          {/* <Checkbox sx={{ padding: '3px', color: selected === patient.patient_id ? 'white' : colors.light }} /> */}
          <Box display="flex" flexDirection="column" gap="5px" width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              color={isSelectedPatient && !isMobilePhone ? 'white' : colors.text}
              gap={SIZES.padding}
            >
              <Typography variant="h5" fontWeight="600">
                {patient.header}
              </Typography>
            </Box>
          </Box>
        </>
      );
    },
    [colors.text, isMobilePhone, selectedTemplate]
  );

  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Patient',
      flex: 1.25,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: any) => (
        <CustomGridCell>
          <Box display="flex" flexDirection="column">
            <Link to={`/company/patient-management?patient_id=${params.row.patient_id}`} className="emphasis">
              <Typography>{params.value}</Typography>
            </Link>
            <Typography sx={{ fontSize: '12px' }}>ID: {params.row.patient_id}</Typography>
          </Box>
        </CustomGridCell>
      ),
    },

    {
      field: 'header',
      headerName: 'Title',
      flex: 2,
      renderCell: (params: any) =>
        params.stringOnly ? (
          params.value
        ) : (
          <Box>
            <RegularButton
              label={params.value}
              size="small"
              variant="text"
              onClick={() => {
                setSelectedPatientNote(params.row);
                setOpenPatientNoteEditor(true);
              }}
              styles={{ fontSize: '13px', textAlign: 'left' }}
            />
            <Typography sx={{ fontSize: '12px', color: 'gray', marginLeft: '20px' }}>
              Date Recorded: {formatDate(params.row.date_recorded)}
            </Typography>
          </Box>
        ),
    },
  ];

  const patientColumns: GridColDef[] = [
    {
      field: 'header',
      headerName: 'Title',
      flex: 2,
      renderCell: (params: any) =>
        params.stringOnly ? (
          params.value
        ) : (
          <Box>
            <RegularButton
              label={params.value}
              size="small"
              variant="text"
              onClick={() => {
                setSelectedPatientNote(params.row);
                setOpenPatientNoteEditor(true);
              }}
              styles={{ fontSize: '13px', textAlign: 'left' }}
            />
            <Typography sx={{ fontSize: '12px', color: 'gray', marginLeft: '20px' }}>
              Date Recorded: {formatDate(params.row.date_recorded)}
            </Typography>
          </Box>
        ),
    },
  ];

  const handleHideShowOption = (index: any, item: any, hidden?: boolean) => {
    if (hidden) {
      const newHiddenFields = hiddenFields.filter((_, i) => i !== index);
      setHiddenFields(newHiddenFields);
      const newFields = [...reorderedFields, { ...item, hidden: false }];
      setReorderedFields(newFields);
      updateTableColumns(newFields);
    } else {
      const newFields = reorderedFields.filter((_, i) => i !== index);
      setReorderedFields(newFields);
      const newHiddenFields = [...hiddenFields, { ...item, hidden: true }];
      setHiddenFields(newHiddenFields);
      updateTableColumns(newFields);
    }
  };

  const exportPatientRecords = () => {
    // Prepare processed data with formatted dates
    const processed = patientRecords.map((record) => {
      return allFields.reduce(
        (result: any, column, index) => {
          const responses = record.responses ?? {};
          let value = responses[column.field_name] ?? '';

          // Format date values if needed
          if (column.type === 'date') {
            value = formatDate(new Date(value));
          }

          result[index + 1 + '. ' + column.display_name] = value;
          return result;
        },
        {
          'Patient ID': record.patient_id,
          Name: record.full_name,
          Date: formatDate(new Date(record.date_recorded)),
        }
      );
    });

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(processed);

    // Define styles for the header row and cells
    const headerCellStyle = { font: { bold: true }, alignment: { wrapText: true } };
    const cellStyle = { alignment: { wrapText: true } };

    // Apply styles to the header row (first row)
    const range = XLSX.utils.decode_range(worksheet['!ref'] as string);
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
      if (worksheet[cellAddress]) {
        worksheet[cellAddress].s = headerCellStyle; // Apply the bold and wrap text style
      }
    }

    // Apply wrap text style to all cells
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].s = cellStyle; // Apply wrap text style
        }
      }
    }

    // Set column widths (in terms of number of characters)
    const columnWidths = allFields.map(() => ({ wch: 50 })); // Adjust 'wch' (character width) as needed
    worksheet['!cols'] = [
      { wch: 15 }, // 'Patient ID' column width
      { wch: 30 }, // 'Name' column width
      { wch: 20 }, // 'Date' column width
      ...columnWidths, // Other columns
    ];

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Patient Records');

    // Export to Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${selectedTemplateDetails?.header} - ${formatDate(new Date())}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const exportTemplatedReport = async () => {
  //   await exportReportByTemplate('TEST', 1, 1);
  // };

  const onClosePatientNoteDrawer = () => {
    if (selectedPatientNote) {
      getNote(facility.id, selectedPatientNote.id).then((res) => {
        const newNote = res.data;
        selectedPatientNote.header = newNote.header;
        selectedPatientNote.date_recorded = newNote.date_recorded;
        selectedPatientNote.responses = newNote.responses;
        // selectedPatientNote.body = res.data.template_body[0];
        setSelectedPatientNote(undefined);
      });
    }
  };

  const handleCreateNote = (data: any) => {
    setSelectedPrescription(undefined);
    setSelectedPatientNote(data); // Update the state with the newly created note
    setOpenNoteModal(false);
    noteRef.current.refreshTimeline();
  };

  const handleCreatePrescription = (data: any) => {
    setSelectedPatientNote(undefined);
    setSelectedPrescription(data.data[0]); // Update the state with the newly created prescription
    setOpenPrescriptionModal(false);
    noteRef.current.refreshTimeline();
  };

  const handleConfirmAddRunningInvoice = () => {
    if (facility && patient?.patient_id) {
      createRunningInvoice(facility.id, { patient_id: patient?.patient_id })
        .then((res) => {
          enqueueSnackbar(`Running invoice successfully created`, { variant: 'success' });
          setRefreshCount((count: number) => count + 1);
        })
        .catch((err) => {
          if (err.response.status === 400)
            enqueueSnackbar(`${patient?.full_name} has an active running invoice`, { variant: 'error' });
        });
    }
  };

  return (
    <CareGoPage loading={!user} breadcrumb={[{ label: 'Patient Management' }]}>
      <WaitForFacility facility={facility}>
        <InfiniteScrollContainer
          key={`${patient?.id}`}
          title="Patients Notes Templates"
          getData={getData}
          getSingleData={(id) => getTemplate(facility.id, id)}
          renderItem={displayItem}
          selected={selectedTemplate}
          setSelected={setSelectedTemplate}
          urlKeyId="template_id"
          displaySelectedTitle={() => (
            <Typography variant="h3" fontWeight={700} color={colors.primary}>
              {selectedTemplate > 0 ? selectedTemplateDetails?.header : 'Prescriptions'}
            </Typography>
          )}
          hideActions={selectedTemplate === -1}
          additionalItems={() =>
            patient ? (
              <></>
            ) : (
              <Button
                variant="text"
                sx={{
                  textAlign: 'left',
                  backgroundColor: selectedTemplate === -1 && !isMobilePhone ? colors.primary : colors.background,
                  borderRadius: isMobilePhone ? '5px' : '10px',
                  borderBottom: isMobilePhone ? `2px solid ${colors.input_background}` : undefined,
                  padding: '15px',
                  display: 'flex',
                  gap: '6px',
                  alignItems: 'center',
                  textTransform: 'none',
                  color: '#FFFFFF',
                  '&.Mui-active': {
                    color: 'white !important',
                  },
                  '&:active': {
                    color: 'white !important',
                  },
                  '&:hover': {
                    backgroundColor:
                      selectedTemplate === -1 && !isMobilePhone ? colors.primary_hovered : 'rgba(0, 146, 213, 0.1)',
                  },
                }}
                onClick={() => setSelectedTemplate(-1)}
              >
                {displayItem({ header: 'Prescription', id: -1 } as any)}
              </Button>
            )
          }
          actions={[
            {
              icon: <EditNoteOutlinedIcon />,
              label: 'Edit Template',
              action: () => navigate('/company/notes/Modify/' + selectedTemplateDetails?.id),
            },
            { icon: <TableChartOutlinedIcon />, label: 'Customize Table', action: () => setOpenCustomizeTable(true) },
            {
              icon: <AnalyticsOutlinedIcon />,
              label: 'Create Charts',
              action: () => setOpenChartEditor(true),
              hidden: !isDevMode() || user.remaining_storage <= 0,
            },
            {
              icon: <FilterAltOutlinedIcon />,
              label: 'Manage Filters',
              action: () => setOpenFilterEditor(true),
              hidden: !isDevMode(),
            },
          ]}
        >
          {isMobilePhone ? (
            <>
              <ContainerRow>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography fontSize={'1.425rem'} color={colors.primary} fontWeight={'600'}>
                      {patient?.full_name}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '2px' }}>
                      <HowToRegOutlinedIcon sx={{ fontSize: '1rem', color: colors.primary }} />
                      <Typography sx={{ fontSize: '0.7rem', color: colors.grey_text }}>
                        Registered on {formatDate(patient?.created_at)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ContainerRow>
              <ContainerRow
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '10px',
                  marginTop: '20px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <RecordsIcon sx={{ color: colors.primary }} />
                  <Typography fontWeight={600}>Patient Records</Typography>
                </Box>
                {/* <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <RegularButton onClick={() => setOpenRecordActions(true)} label="Create" startIcon={<AddIcon />} />
                  <IconButton
                    onClick={() => {
                      setOpenAppointmentsDrawer(true);
                    }}
                  >
                    <BookmarkAddedIcon sx={{ color: colors.primary }} />
                  </IconButton>
                </Box> */}
              </ContainerRow>
            </>
          ) : (
            <ContainerRow>
              <CustomDateRangeFilter setFilterDateRange={setFilterDateRange} />
              {selectedTemplate > 0 && (
                <RegularButton
                  variant="outlined"
                  label="Export"
                  onClick={exportPatientRecords}
                  startIcon={<FileDownloadOutlinedIcon />}
                />
              )}
            </ContainerRow>
          )}
          {/* <CustomDateRangeFilter setFilterDateRange={setFilterDateRange} />
          <RegularButton
            variant="outlined"
            label="Export"
            onClick={exportPatientRecords}
            startIcon={<FileDownloadOutlinedIcon />}
          /> */}
          {selectedTemplate > 0 ? (
            <ColoredTable
              dataRows={patientRecords}
              columns={patient ? [...patientColumns, ...otherColumns] : [...columns, ...otherColumns]}
              stickyTop={80}
              mobileViewConfig={(data: any) => {
                return {
                  title_fields: ['header'],
                  title_link: `/company/patient-management?patient_id=${data.patient_id}`,
                  subtitle_fields: [''],
                };
              }}
            />
          ) : (
            <PrescriptionTable filterDateRange={filterDateRange} />
          )}
        </InfiniteScrollContainer>

        <CustomAnimatedDrawer open={openCustomizeTable} setOpen={setOpenCustomizeTable} header="Customize Table">
          <Box>
            {reorderedFields && (
              <>
                <DraggableList
                  title={'Fields'}
                  droppableId={'template-fields'}
                  fromModal
                  handleDragEnd={handleDragEnd}
                  list={reorderedFields}
                  style={{ padding: '5px 10px' }}
                  displayItem={(item, index) => (
                    <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                      <Box>
                        <Typography fontWeight="bold">
                          {item.display_name} {item.field_unit ? ` (${item.field_unit})` : ''}
                        </Typography>
                      </Box>
                      <CustomIconButton
                        disabled={reorderedFields.length <= 3}
                        icon={<VisibilityOffIcon />}
                        onClick={() => handleHideShowOption(index, item)}
                      />
                    </Box>
                  )}
                />

                {!!hiddenFields.length && (
                  <>
                    <Divider />

                    <Typography fontWeight="bold" my="10px">
                      Hidden Fields
                    </Typography>

                    <Box display="flex" flexDirection="column" gap={SIZES.paddingS} sx={{ paddingInline: '10px' }}>
                      {hiddenFields.map((item, index) => (
                        <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Typography fontWeight="bold">
                              {item.display_name} {item.field_unit ? ` (${item.field_unit})` : ''}
                            </Typography>
                          </Box>
                          <CustomIconButton
                            disabled={reorderedFields.length >= 8}
                            icon={<VisibilityIcon />}
                            onClick={() => handleHideShowOption(index, item, true)}
                          />
                        </Box>
                      ))}
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </CustomAnimatedDrawer>

        {isMobilePhone ? (
          <CustomModal
            open={openPatientNoteEditor}
            setOpen={setOpenPatientNoteEditor}
            header={selectedPatientNote?.header}
            onClose={onClosePatientNoteDrawer}
            width={800}
          >
            {selectedPatientNote && (
              <DocumentEditor
                template_id={selectedPatientNote.id}
                source={'patient'}
                patient_id={selectedPatientNote.patient_id}
                fromPatientRecords
                stickyTop={'0px'}
              />
            )}
          </CustomModal>
        ) : (
          <CustomAnimatedDrawer
            open={openPatientNoteEditor}
            setOpen={setOpenPatientNoteEditor}
            header={selectedPatientNote?.header}
            onClose={onClosePatientNoteDrawer}
            width={800}
          >
            {selectedPatientNote && (
              <DocumentEditor
                template_id={selectedPatientNote.id}
                source={'patient'}
                patient_id={selectedPatientNote.patient_id}
                fromPatientRecords
                stickyTop={'0px'}
                formMode={FORM_MODE.RESPOND}
              />
            )}
          </CustomAnimatedDrawer>
        )}

        <CustomAnimatedDrawer open={openChartEditor} setOpen={setOpenChartEditor} header={'Create Charts'} width={1000}>
          <ChartEditor template_id={selectedTemplate} fields={allFields} patientRecords={patientRecords} />
        </CustomAnimatedDrawer>

        <CustomAnimatedDrawer
          open={openFilterEditor}
          setOpen={setOpenFilterEditor}
          header={'Manage Filters'}
          width={1000}
        >
          <FilterEditor template_id={selectedTemplate} fields={allFields} patientRecords={patientRecords} />
        </CustomAnimatedDrawer>

        {/* Modal for creating notes */}
        <CustomModal open={openNoteModal} setOpen={setOpenNoteModal} header={'Create Note'} width={1000}>
          <CreatePatientNoteModal
            patient_id={patient?.patient_id}
            patient={patient}
            onCreate={(data) => {
              handleCreateNote(data);
            }}
          />
        </CustomModal>

        {/* Modal for creating prescription */}
        {patient && (
          <CustomModal header="Create Prescription" open={openPrescriptionModal} setOpen={setOpenPrescriptionModal}>
            <PrescriptionForm
              patientID={patient?.patient_id}
              callbackAfterSubmit={(data: any) => {
                handleCreatePrescription(data);
              }}
            />
          </CustomModal>
        )}
        {/* Confirmation Dialog For Adding Running Invoice */}
        <ConfirmationDialog
          open={confirmCreateRunningInvoiceOpen}
          setOpen={setConfirmCreateRunningInvoiceOpen}
          onConfirm={handleConfirmAddRunningInvoice}
          title="Create Running Invoice"
          content={`Create Running Invoice for patient ${patient?.full_name}`}
        ></ConfirmationDialog>

        <Drawer
          anchor="right"
          open={openAppointmentsDrawer}
          onClose={() => setOpenAppointmentsDrawer(false)}
          sx={{ zIndex: 9999 }}
        >
          <Box sx={{ width: 375, height: '100%' }}>
            {patient && (
              <PatientNotesContainer
                ref={noteRef}
                patientNote={selectedPatientNote}
                patientPrescription={selectedPrescription}
                runningInvoice={selectedPatientRunningInvoice}
                patient={patient}
                mode={FORM_MODE.RESPOND}
              />
            )}
          </Box>
        </Drawer>

        <CustomBottomPopover title="Create Patient Record" open={openRecordActions} setOpen={setOpenRecordActions}>
          <Box
            display="flex"
            gap={SIZES.paddingS}
            alignItems="center"
            sx={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1rem 0.5rem',
              marginTop: '20px',
            }}
          >
            <RegularButton
              size="small"
              variant="outlined"
              label="Create Note"
              onClick={() => setOpenNoteModal(true)}
              startIcon={<AddIcon />}
              fullWidth
              styles={{ border: 'none', backgroundColor: colors.light_blue_background, fontWeight: '400' }}
            />
            <RegularButton
              size="small"
              variant="outlined"
              label="Create Prescription"
              onClick={() => setOpenPrescriptionModal(true)}
              startIcon={<AddIcon />}
              fullWidth
              styles={{ border: 'none', backgroundColor: colors.light_blue_background, fontWeight: '400' }}
            />
          </Box>
        </CustomBottomPopover>
      </WaitForFacility>
    </CareGoPage>
  );
};

export default PatientRecords;

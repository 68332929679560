import { UserGroups } from "company/model/Entities";
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'admin-user-groups';

export const caregoGetUserGroups = () => {
  return axios.get(`${baseURL}/${path}`);
};

export const caregoGetUserGroupById = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const caregoGetUserGroupsByCompany = (company_id: number) => {
  return axios.get(`${baseURL}/${path}/company/${company_id}`);
};

export const caregoCreateUserGroup = (data: UserGroups) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const caregoUpdateUserGroup = (id: number, data: UserGroups) => {
  data.id = id;
  return axios.put(`${baseURL}/${path}`, data);
};

export const caregoDeleteUserGroup = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
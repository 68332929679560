/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, IconButton, Snackbar, Typography, useTheme } from '@mui/material';
import {
  ColoredTable,
  CompanyProtectedComponent,
  ConfirmationDialog,
  CustomModal,
  ExportButton,
  RegularButton,
} from 'core/components';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { deleteClinicProduct, exportProductDirectory, getProducts } from 'company/api/products';
import { deleteVariant, getInventorySummary } from 'company/api/inventories';
import { formatCurrency, formatProductVariantName } from 'core/utils';

import AddIcon from '@mui/icons-material/Add';
import AddStock from 'company/entities/modules/InventorySystem/components/AddStock';
import AdjustStock from 'company/entities/modules/InventorySystem/components/AdjustStock';
import { CareGoPage } from 'core/PageBuilder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClinicInventoryScroller from './components/ClinicInventoryScroller';
import CloseIcon from '@mui/icons-material/Close';
import CustomFilter from 'core/components/CustomFilter';
import DeleteButton from 'core/components/buttons/DeleteButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { DropdownOptionProps } from 'core/components/Dropdown';
import { FacilityContext } from 'core/context/facility.context';
import GroupedFilters from 'core/components/GroupedFilters';
import ImportButton from 'core/components/buttons/ImportButton';
import ImportProductVariantModal from './components/ImportProductVariantModal';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import InventoryWidget from './InventoryWidget';
import MoveUpOutlinedIcon from '@mui/icons-material/MoveUpOutlined';
import ProductForm from 'company/entities/modules/InventorySystem/Product/ProductForm';
import ProductInTransaction from './components/ProductInTransaction';
import ProductLogsScroller from './components/ProductLogsScroller';
import ProductOutTransaction from './components/ProductOutTransaction';
import ProductTransferTransaction from './components/ProductTransferTransaction';
import ProductVariantForm from 'company/entities/modules/InventorySystem/Product/ProductVariant/ProductVariantForm';
import ReversalTransaction from './components/ReversalTransaction';
import { SIZES } from 'theme/constants';
import SearchBar from '../POS/components/SearchBar';
import StockAdjustmentTransaction from './components/StockAdjustmentTransaction';
import TransferStock from 'company/entities/modules/InventorySystem/components/TransferStock';
import UpdateButton from 'core/components/buttons/UpdateButton';
import UpdateDetails from 'company/entities/modules/InventorySystem/components/UpdateDetails';
import UpdatePrice from 'company/entities/modules/InventorySystem/components/UpdatePrice';
import { UserContext } from 'core/context/user.context';
import { getAllTransactions } from 'company/api/transactions';
import { getProductCategories } from 'company/api/product-categories';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface Product {
  attributes: string;
  id: number;
  product_id: number;
  variant_id: number;
  variant_name: string;

  tax: string;
  variant_description: string;
  price: string;
  variant_price: string;
  sku: string;
  product_name: string;
  quantity_in_stock: string;
  exclusive_supplier?: number;
  exclusive_supplier_name: string;
  supplier_name: string;
  category: string;
  category_id: number;
  description: string;
  category_description: string;
  is_active: string;

  created_at: string;
  uom_id: number;
  base_uom_name: string;
  base_uom_symbol: string;
  uom_conversions: any[];
  low_stock_threshold: number;
  total_quantity: number;
}

const ProductPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const productLogsScrollerRef: any = useRef();

  const [productSearchQuery, setProductSearchQuery] = useState<string>('');

  const [selectedProductVariant, setSelectedProductVariant] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [openUpdateDetails, setOpenUpdateDetails] = useState(false);
  const [productFilters, setProductFilters] = useState<string[]>([]);
  const [openExclusiveProductModal, setOpenExclusiveProductModal] = useState<boolean>(false);
  const [openVariantModal, setOpenVariantModal] = useState<boolean>(false);

  const tableRef: any = useRef();
  const [openDeleteProductVariant, setOpenDeleteProductVariant] = useState<boolean>(false);
  const [openDeleteProduct, setOpenDeleteProduct] = useState<boolean>(false);
  const [refreshTransactions, setRefreshTransactions] = useState<number>(0);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [loadingImport, setLoadingImport] = useState<boolean>(false);

  const [openViewAll, setOpenViewAll] = useState(false);

  const [transactionFilters, setTransactionFilters] = useState<string[]>([]);
  const [transactionSearchQuery, setTransactionSearchQuery] = useState<string>('');
  const itemsPerPage = 10;
  const [transactions, setTransactions] = useState<any[]>([]);
  const [summary, setSummary] = useState<any>();

  const [openAddStock, setOpenAddStock] = useState(false);
  const [openAdjustStock, setOpenAdjustStock] = useState(false);
  const [openTransferStock, setOpenTransferStock] = useState(false);
  const [openUpdatePrice, setOpenUpdatePrice] = useState(false);

  const [categories, setCategories] = useState<Array<{ id: number; category: string }>>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const [openSnackBar, setOpenSnackbar] = useState<boolean>(false);
  const [newlyAddedVariant, setNewlyAddedVariant] = useState<number>();

  const [openImportModal, setOpenImportModal] = useState(false);

  const navigate = useNavigate();

  const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleNavigateToNewVariant = () => {
    if (newlyAddedVariant) {
      navigate(`/company/inventory/products/variant?id=${newlyAddedVariant}`);
    }
  };

  const snackBarAction = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleNavigateToNewVariant}>
        <Typography sx={{ textTransform: 'none', color: '#6884E0' }}>View variant</Typography>
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleVariantCreated = (data: any) => {
    setNewlyAddedVariant(data.variant.id);
    setOpenSnackbar(true);
    setOpenVariantModal(false);
    setRefreshTransactions((prev) => prev + 1);
    refreshTable();
  };

  const fetchCategories = async () => {
    try {
      const response = await getProductCategories();
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      enqueueSnackbar('Failed to load categories', { variant: 'error' });
    }
  };

  const getInventoryTransactions = () => {
    if (facility) {
      return getAllTransactions(facility.id, {
        search: transactionSearchQuery,
        type: transactionFilters.length ? transactionFilters : undefined,
        length: itemsPerPage,
      }).then((res) => {
        setTransactions(res.data.data);
      });
    }
  };

  const refreshTable = () => {
    if (tableRef.current) {
      tableRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  // const getAllProducts = () => {
  //   if (facility) {
  //     return getInventories(facility.id, {
  //       length: 1000,
  //       order_by: 'updated_at',
  //       order: 'desc',
  //       search: productSearchQuery,
  //     }).then((res) => {
  //       const newProducts = res.data.data;

  //       if (productFilters.length > 0) {
  //         const filteredProducts = newProducts.filter((product: Product) => {
  //           const totalQuantity = product.total_quantity;

  //           if (productFilters.includes('available')) {
  //             return totalQuantity > 10;
  //           }
  //           if (productFilters.includes('lowStock')) {
  //             return totalQuantity <= 10;
  //           }
  //           if (productFilters.includes('unavailable')) {
  //             return totalQuantity === 0;
  //           }
  //           return true;
  //         });

  //         setProducts(filteredProducts);
  //       }
  //     });
  //   }
  // };

  const actions: DropdownOptionProps[] = [
    {
      label: 'Restock',
      action: (data: any) => {
        setSelectedProductVariant(data);
        setOpenAddStock(true);
      },
      icon: <AddIcon />,
    },
    {
      label: 'Adjust Stock',
      action: (data: any) => {
        setSelectedProductVariant(data);

        setOpenAdjustStock(true);
      },
      icon: <Inventory2OutlinedIcon />,
    },
    {
      label: 'Transfer',
      action: (data: any) => {
        setSelectedProductVariant(data);
        setOpenTransferStock(true);
      },
      icon: <MoveUpOutlinedIcon />,
    },
    {
      label: 'Update Price',
      action: (data: any) => {
        setSelectedProductVariant(data);
        setOpenUpdatePrice(true);
      },
      icon: <DriveFileRenameOutlineOutlinedIcon />,
    },
    {
      label: 'Update Details',
      action: (data: any) => {
        setSelectedProductVariant(data);
        setSelectedProduct(data.product || { id: data.product_id, category_id: data.category_id });
        setOpenUpdateDetails(true);
      },
      icon: <DriveFileRenameOutlineOutlinedIcon />,
    },
    {
      label: 'Delete Variant',
      action: (data: any) => {
        setSelectedProductVariant(data);
        setOpenDeleteProductVariant(true);
      },
      color: 'error',
      icon: <DeleteOutlinedIcon color="error" />,
      labelStyle: { color: colors.redAccent },
    },
  ];

  const filterGroups: any = [
    {
      id: 'categories',
      label: 'Categories',
      options: categories.map((cat) => ({
        id: cat.id.toString(),
        label: cat.category,
      })),
    },
    {
      id: 'stockAmount',
      label: 'Stock Amount',
      options: [
        { id: 'available', label: 'Available' },
        { id: 'lowStock', label: 'Low Stock' },
        { id: 'unavailable', label: 'Unavailable' },
      ],
    },
  ];

  const handleDeleteProductVariant = () => {
    if (selectedProductVariant) {
      deleteVariant(selectedProductVariant.id)
        .then((res) => {
          enqueueSnackbar('Product Variant successfully deleted!', { variant: 'success' });
          refreshTable();
          setRefreshTransactions((prev) => prev + 1);
        })
        .catch((error) => {
          enqueueSnackbar('Error occured! Unable to delete product variant.', { variant: 'error' });
        });
    }
  };

  const handleDeleteProduct = () => {
    if (selectedProduct) {
      deleteClinicProduct(selectedProduct.id)
        .then((res) => {
          enqueueSnackbar('Product successfully deleted!', { variant: 'success' });
          refreshTable();
          // getAllProducts();
          setRefreshTransactions((prev) => prev + 1);

          const currentOrders = JSON.parse(localStorage.getItem('orders') || '[]');
          const updatedOrders = currentOrders.filter((order: any) => order.product_id !== selectedProduct.id);
          localStorage.setItem('orders', JSON.stringify(updatedOrders));
        })
        .catch((error) => {
          enqueueSnackbar('Error occurred! Unable to delete product.', { variant: 'error' });
        });
    }
  };

  const handleExport = () => {
    setLoadingExport(true);
    try {
      exportProductDirectory(facility.id, facility.facility_name, {
        search: productSearchQuery,
        category_ids: selectedCategories.length > 0 ? selectedCategories.map(Number) : undefined,
        stock_status: productFilters,
      });
    } finally {
      setLoadingExport(false);
    }
  };

  const handleImport = () => {
    setOpenImportModal(true);
  };

  // const handleVariantExport = () => {
  //   setLoadingExport(true);
  //   try {
  //     exportProductVariants(facility.id, facility.facility_name, selectedProduct.id, {
  //       search: productSearchQuery,
  //     });
  //   } finally {
  //     setLoadingExport(false);
  //   }

  const getFilteredProducts = async (params: any) => {
    if (!facility) return;

    const apiParams = {
      ...params,
      category_ids: selectedCategories.length > 0 ? selectedCategories : undefined,
      stock_status: productFilters.length > 0 ? productFilters : undefined,
      order_by: 'product_name',
      order: 'asc',
    };

    return getProducts(apiParams);
  };

  const handleProductFormSubmit = (data: any) => {
    if (data.isAutoVariant) {
      setNewlyAddedVariant(data.variant.id);
      setOpenSnackbar(true);
    }

    refreshTable();

    setRefreshTransactions((prev) => prev + 1);

    setOpenExclusiveProductModal(false);
  };

  useEffect(() => {
    // getAllProducts();
    refreshTable();
    setRefreshTransactions((prev: number) => prev + 1);
  }, [facility, productFilters, productSearchQuery, selectedCategories]);

  useEffect(() => {
    getInventoryTransactions();
    getInventorySummary(facility.id).then((res) => {
      setSummary(res.data);
    });
  }, [transactionSearchQuery, transactionFilters, facility]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => localStorage.setItem('previousPath', window.location.pathname + window.location.search), []);

  return (
    <>
      <CareGoPage loading={!user} breadcrumb={[{ label: 'Product Directory' }]}>
        <Box display="flex" gap="28px" width="100%">
          <Box width="120%" flex="1">
            <Box display="flex" flexDirection="column" gap="20px" sx={{ backgroundColor: 'none' }}>
              <ColoredTable
                ref={tableRef}
                getData={getFilteredProducts}
                onSearchChange={setProductSearchQuery}
                searchOnLeft
                noSort
                arrowLabel="Variants"
                joinedChildren
                headerComponent={
                  <ContainerColumn gap="10px">
                    <ContainerRow
                      gap="5px"
                      sx={{
                        justifyContent: 'flex-end',
                        alignContent: 'flex-start',
                        pt: 2,
                        flexWrap: 'wrap',
                        width: '100%',
                      }}
                    >
                      {/* <CustomFilter
                        optionList={categories.map((cat) => ({
                          label: cat.category,
                          id: cat.id.toString(),
                        }))}
                        buttonLabel="Categories"
                        selected={selectedCategories}
                        setSelected={setSelectedCategories}
                      />
                      <CustomFilter
                        optionList={[
                          { label: 'Available', id: 'available' },
                          { label: 'Low Stock', id: 'lowStock' },
                          { label: 'Unavailable', id: 'unavailable' },
                        ]}
                        buttonLabel="Stock Amount"
                        selected={productFilters}
                        setSelected={setProductFilters}
                      /> */}
                      <GroupedFilters
                        filterGroups={filterGroups}
                        selectedFilters={{
                          categories: selectedCategories,
                          stockAmount: productFilters,
                        }}
                        onFilterChange={(newFilters) => {
                          setSelectedCategories(newFilters.categories || []);
                          setProductFilters(newFilters.stockAmount || []);
                        }}
                        buttonSize="medium"
                        variant="outlined"
                      />
                      {/* <RegularButton
                        startIcon={sortByCreation ? <AccessTimeIcon /> : <SortByAlphaIcon />}
                        label={sortByCreation ? 'Sort by Date' : 'Sort by Name'}
                        onClick={() => {
                          setSortByCreation(!sortByCreation);
                          refreshTable();
                        }}
                        variant="outlined"
                        size="medium"
                      /> */}
                      <CompanyProtectedComponent requiredAuth={['EXPORT_CLINIC_INVENTORY']}>
                        <ExportButton onClick={handleExport} loading={loadingExport} />
                      </CompanyProtectedComponent>
                      <ImportButton onClick={handleImport} loading={loadingImport} />
                      <RegularButton
                        startIcon={<AddIcon />}
                        label="Product"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setSelectedProduct(undefined);
                          setOpenExclusiveProductModal(true);
                        }}
                        size="medium"
                      />
                    </ContainerRow>
                  </ContainerColumn>
                }
                columns={[
                  {
                    field: 'product_name',
                    headerName: 'Product Name',
                    flex: 1,
                  },
                  {
                    field: 'category',
                    headerName: 'Category',
                    flex: 0.7,
                  },
                  {
                    field: 'total_quantity',
                    headerName: 'Total Stock',
                    flex: 0.3,
                  },
                  {
                    field: 'action',
                    sortable: false,
                    headerName: 'Actions',
                    headerAlign: 'center',
                    align: 'center',
                    flex: 2,
                    renderCell: (params) => (
                      <Box display="flex" gap="4px" alignItems="center">
                        <RegularButton
                          label="Variant"
                          startIcon={<AddIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProduct(params.row);
                            setOpenVariantModal(true);
                          }}
                          size="small"
                        />
                        <UpdateButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProduct(params.row);
                            setOpenExclusiveProductModal(true);
                          }}
                        />
                        <DeleteButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProduct(params.row);
                            setOpenDeleteProduct(true);
                          }}
                        />
                      </Box>
                    ),
                  },
                ]}
                displayChildComponent={(row) => (
                  <Box bgcolor={'#F3F9FF'} px={'8px'} borderRadius={'0px 0px 12px 12px'}>
                    <ClinicInventoryScroller
                      // extraButtons={
                      //   <Box display="flex" gap="4px">
                      //     <CompanyProtectedComponent requiredAuth={['EXPORT_CLINIC_INVENTORY']}>
                      //       <ExportButton onClick={handleVariantExport} loading={loadingExport} />
                      //     </CompanyProtectedComponent>
                      //   </Box>
                      // }
                      actions={actions}
                      refreshInventory={refreshTransactions}
                      productFilter={row.id}
                      productName={row.product_name}
                    />
                  </Box>
                )}
              />
            </Box>
          </Box>

          {/* <GeneralProductLogs refresh={refreshTransactions} /> */}
          <Box
            sx={{
              display: 'flex',
              width: '300px',
              flexDirection: 'column',
              gap: SIZES.paddingS,
              position: 'relative',
              flexShrink: 0,
            }}
          >
            <Box display={'flex'} flexDirection="column" gap={SIZES.paddingS} justifyContent={'space-between'} pt={2}>
              <InventoryWidget
                count={summary?.totalCost ? `${formatCurrency(summary.totalCost)}` : ''}
                // icon={Inventory2OutlinedIcon}
                label="Total Cost of SKUs"
              />
              <InventoryWidget
                sideCount={summary?.totalItems}
                sideLabel="Total Stock Keeping Units"
                count={summary?.availableItems}
                // icon={Inventory2OutlinedIcon}
                label="Available Stock Keeping Units"
              />
              <InventoryWidget
                // sideCount={summary?.variantsAdded}
                sideCount={summary?.noStockItems}
                sideLabel="Empty Stock Keeping Units"
                count={summary?.lowStockItems}
                // icon={Inventory2OutlinedIcon}
                label="Low Stock Keeping Units"
              />
            </Box>
            <Box
              sx={{
                position: 'sticky',
                top: '-1px',
                zIndex: 10,
                backgroundColor: colors.background,
                paddingBlock: SIZES.paddingS,
              }}
            >
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pb={SIZES.paddingS}>
                <Typography variant="h5" fontWeight="bold">
                  Product Logs
                </Typography>
                <RegularButton
                  label="View All"
                  onClick={() => {
                    setTransactionSearchQuery('');
                    setOpenViewAll(true);
                  }}
                  variant="text"
                  size="small"
                />
                <CustomFilter
                  optionList={[
                    { label: 'Product In', id: 'in' },
                    { label: 'Product Out', id: 'out' },
                    { label: 'Product Transfer', id: 'transfer' },
                    { label: 'Stock Adjustment', id: 'adjustment' },
                    { label: 'Reversal', id: 'reversal' },
                  ]}
                  selected={transactionFilters}
                  setSelected={setTransactionFilters}
                  buttonSize="small"
                />
              </Box>
              <Box display={'flex'} gap="5px" justifyContent={'space-between'} flexWrap={'wrap'}>
                <SearchBar handleSearch={(searchKey) => setTransactionSearchQuery(searchKey)} />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={SIZES.paddingS}
              paddingRight={'5px'}
              width="300px"
              // height="calc(100vh + 72px)"
              // sx={{ overflowY: 'auto' }}
            >
              {transactions.length > 0 ? (
                transactions.map((transaction, index) => {
                  switch (transaction.type) {
                    case 'in':
                      return <ProductInTransaction key={index} transaction={transaction} />;
                    case 'out':
                      return <ProductOutTransaction key={index} transaction={transaction} />;
                    case 'transfer':
                      return <ProductTransferTransaction key={index} transaction={transaction} />;
                    case 'adjustment':
                      return <StockAdjustmentTransaction key={index} transaction={transaction} />;
                    case 'reversal':
                      return <ReversalTransaction key={index} transaction={transaction} />;
                    default:
                      return null;
                  }
                })
              ) : (
                <Typography variant="body1">No results found</Typography>
              )}
            </Box>
          </Box>
        </Box>

        {selectedProductVariant && selectedProduct && (
          <>
            <UpdateDetails
              variant={selectedProductVariant}
              product={selectedProduct}
              open={openUpdateDetails}
              setOpen={setOpenUpdateDetails}
              callbackAfterSubmit={() => {
                setOpenUpdateDetails(false);
                refreshTable();
                setRefreshTransactions((prev) => prev + 1);
              }}
            />

            <ConfirmationDialog
              content={`Are you sure you want to delete ${formatProductVariantName(selectedProductVariant)} ?`}
              open={openDeleteProductVariant}
              setOpen={setOpenDeleteProductVariant}
              onConfirm={handleDeleteProductVariant}
            />
          </>
        )}
      </CareGoPage>

      <CustomModal
        header={selectedProduct ? 'Update Product' : 'Add Product'}
        open={openExclusiveProductModal}
        setOpen={setOpenExclusiveProductModal}
        width={800}
      >
        <ProductForm product={selectedProduct} callbackAfterSubmit={handleProductFormSubmit} />
      </CustomModal>
      {selectedProduct && (
        <CustomModal
          header={'Add Variant for ' + selectedProduct.product_name}
          open={openVariantModal}
          setOpen={setOpenVariantModal}
          width={800}
        >
          <ProductVariantForm product={selectedProduct} callbackAfterSubmit={handleVariantCreated} />
        </CustomModal>
      )}
      {selectedProduct && (
        <ConfirmationDialog
          content={'Are you sure you want to delete ' + selectedProduct.product_name + '?'}
          open={openDeleteProduct}
          setOpen={setOpenDeleteProduct}
          onConfirm={handleDeleteProduct}
        />
      )}

      <CustomModal open={openViewAll} setOpen={setOpenViewAll} width={1000} header={'Product Logs (All)'}>
        <ProductLogsScroller ref={productLogsScrollerRef} />
      </CustomModal>

      <ImportProductVariantModal
        open={openImportModal}
        setOpen={setOpenImportModal}
        callbackAfterImport={() => {
          refreshTable();
          setRefreshTransactions((prev: number) => prev + 1);
        }}
      />

      {selectedProductVariant && (
        <>
          <AddStock
            variant={selectedProductVariant}
            open={openAddStock}
            setOpen={setOpenAddStock}
            callbackAfterSubmit={() => {
              setRefreshTransactions((prev: number) => prev + 1);
              getInventoryTransactions();
              refreshTable();
            }}
            exclusiveSupplier={selectedProductVariant.exclusive_supplier}
          />

          <AdjustStock
            variant={selectedProductVariant}
            open={openAdjustStock}
            setOpen={setOpenAdjustStock}
            callbackAfterSubmit={() => {
              setRefreshTransactions((prev: number) => prev + 1);
              getInventoryTransactions();
              refreshTable();
            }}
            initialStock={parseInt(selectedProductVariant.quantity_in_stock)}
          />

          <TransferStock
            variant={selectedProductVariant}
            open={openTransferStock}
            setOpen={setOpenTransferStock}
            callbackAfterSubmit={() => {
              setRefreshTransactions((prev: number) => prev + 1);
              getInventoryTransactions();
              refreshTable();
            }}
            currentStock={parseInt(selectedProductVariant.quantity_in_stock)}
          />

          <UpdatePrice
            variant={selectedProductVariant}
            open={openUpdatePrice}
            setOpen={setOpenUpdatePrice}
            callbackAfterSubmit={() => {
              setRefreshTransactions((prev: number) => prev + 1);
              getInventoryTransactions();
            }}
          />

          <UpdateDetails
            variant={selectedProductVariant}
            product={selectedProduct}
            open={openUpdateDetails}
            setOpen={setOpenUpdateDetails}
            callbackAfterSubmit={() => {
              setOpenUpdateDetails(false);
              setRefreshTransactions((prev: number) => prev + 1);
              getInventoryTransactions();
            }}
          />
        </>
      )}

      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleCloseSnackBar}
        message={
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <CheckCircleIcon fontSize="small" sx={{ color: '#69BA80' }} />
            <Typography sx={{ fontSize: '14px' }}>Variant successfully created!</Typography>
          </Box>
        }
        action={snackBarAction}
      />
    </>
  );
};

export default ProductPage;

import { Box, TextField, useTheme } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { deleteFile, downloadFile, renameFile } from 'company/api/file-upload';

import ConfirmationDialog from '../modals/ConfirmationDialog';
import ContainerColumn from './ContainerColumn';
import ContainerRow from './ContainerRow';
import CustomIconButton from '../buttons/IconButton';
import CustomModal from '../modals/CustomModal';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import RegularButton from '../buttons/RegularButton';
import { SIZES } from 'theme/constants';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'; // Added save icon
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

export type PatientFile = {
  id: number;
  file_name: string;
  file_type: string;
  file_size: string;
  file_path: string;
  base_url: string;
};

interface ImageCarouselProps {
  files: PatientFile[];
  onFilesRefresh?: () => void;
  setValues?: (value: any) => void;
  hideViewInModal?: boolean;
}

const Carousel: React.FC<ImageCarouselProps> = ({ files, onFilesRefresh, setValues, hideViewInModal }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const [currentFiles, setCurrentFiles] = useState<PatientFile[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [isEditingFileName, setIsEditingFileName] = useState(false);
  const [editedFileName, setEditedFileName] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleThumbnailClick = (index: number) => {
    setSelectedImageIndex(index);
  };

  const handlePrevClick = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? currentFiles.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === currentFiles.length - 1 ? 0 : prevIndex + 1));
  };

  const handleDownload = async () => {
    const selectedFile = currentFiles[selectedImageIndex];

    try {
      const response = await downloadFile(facility.id, selectedFile.id);

      const url = response.data.url;
      const contentType = response.data.content_type;
      const fileName = response.data.file_name;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      link.setAttribute('type', contentType);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleDelete = async () => {
    const selectedFile = currentFiles[selectedImageIndex];
    try {
      await deleteFile(facility.id, selectedFile.id);
      enqueueSnackbar('File successfully deleted!', { variant: 'success' });

      const updatedFiles = currentFiles.filter((file) => file.id !== selectedFile.id);
      setCurrentFiles(updatedFiles);
      setValues && setValues(updatedFiles);

      onFilesRefresh && onFilesRefresh();

      if (selectedImageIndex === currentFiles.length - 1 && currentFiles.length > 1) {
        setSelectedImageIndex(0);
      }
    } catch (error) {
      enqueueSnackbar('Error occured! Unable to delete file.', { variant: 'error' });
    }
  };

  const handleRename = () => {
    setIsEditingFileName(true);
    setEditedFileName(currentFiles[selectedImageIndex].file_name);
  };

  const handleSaveFileName = async () => {
    const editedFileName = inputRef.current ? inputRef.current.value : '';
    const selectedFile = currentFiles[selectedImageIndex];
    try {
      await renameFile(facility.id, selectedFile.id, { file_name: editedFileName });
      setEditedFileName(inputRef.current ? inputRef.current.value : '');
      enqueueSnackbar('File Name successfully updated!', { variant: 'success' });

      const updatedFiles = [...currentFiles];
      updatedFiles[selectedImageIndex] = { ...selectedFile, file_name: editedFileName };
      setCurrentFiles(updatedFiles);
      setValues && setValues(updatedFiles);

      onFilesRefresh && onFilesRefresh();

      setIsEditingFileName(false);
    } catch (error) {
      enqueueSnackbar('Error occured! Unable to delete file.', { variant: 'error' });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSaveFileName();
    }
  };

  useEffect(() => {
    setCurrentFiles(files ?? []);
    setSelectedImageIndex(0);
  }, [files]);

  if (!currentFiles.length) return <></>;

  const CarouselContent = () => {
    return (
      <ContainerColumn gap={SIZES.paddingS}>
        <Box
          className="carousel"
          sx={{
            height: 'calc(100vh - 260px)',
            width: '100%',
            backgroundColor: colors.light_blue_background,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: SIZES.borderRadius,
            padding: SIZES.padding,
          }}
        >
          {currentFiles[selectedImageIndex].file_type.startsWith('image') ? (
            <img
              src={currentFiles[selectedImageIndex].base_url + currentFiles[selectedImageIndex].file_path}
              alt="file"
              style={{
                height: 'auto',
                width: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'cover',
                borderRadius: SIZES.borderRadiusS,
              }}
            />
          ) : currentFiles[selectedImageIndex].file_type === 'application/pdf' ? (
            <iframe
              src={currentFiles[selectedImageIndex].base_url + currentFiles[selectedImageIndex].file_path}
              title="PDF Preview"
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
              }}
            />
          ) : (
            <div>
              <PictureAsPdfOutlinedIcon sx={{ fontSize: '50px' }} />
            </div>
          )}
        </Box>
        <ContainerRow sx={{ alignItems: 'center' }}>
          <CustomIconButton
            color="primary"
            onClick={handlePrevClick}
            icon={<NavigateBeforeIcon style={{ fontSize: '36px' }} />}
          />
          <ContainerRow gap="5px" sx={{ justifyContent: 'center', overflowX: 'auto' }}>
            {currentFiles.map((file, index) => (
              <Box
                key={index}
                sx={{
                  borderRadius: '5px',
                  padding: '5px',
                  backgroundColor: index === selectedImageIndex ? colors.light_blue_background_2 : undefined,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {file.file_type.startsWith('image') ? (
                  <img
                    key={index}
                    src={file.base_url + file.file_path}
                    height="40px"
                    width="auto"
                    alt={`Thumbnail ${index + 1}`}
                    onClick={() => handleThumbnailClick(index)}
                  />
                ) : (
                  <PictureAsPdfOutlinedIcon
                    style={{ width: 'auto', height: '30px' }}
                    onClick={() => handleThumbnailClick(index)}
                  />
                )}
              </Box>
            ))}
          </ContainerRow>
          <CustomIconButton
            color="primary"
            onClick={handleNextClick}
            icon={<NavigateNextIcon style={{ fontSize: '36px' }} />}
          />
        </ContainerRow>
        <ContainerRow sx={{ alignItems: 'center', justifyContent: 'center' }}>
          {isEditingFileName ? (
            <TextField
              id="rename-input"
              label="Rename"
              variant="outlined"
              defaultValue={editedFileName}
              onKeyDown={handleKeyDown}
              autoFocus
              inputRef={inputRef}
            />
          ) : (
            <Box sx={{ margin: '0 10px', fontSize: '18px', fontWeight: 'bold' }}>
              {currentFiles[selectedImageIndex].file_name}
            </Box>
          )}
          {isEditingFileName ? (
            <CustomIconButton
              color="primary"
              onClick={handleSaveFileName}
              tooltip="Save"
              placement="bottom"
              icon={<SaveOutlinedIcon style={{ fontSize: '18px' }} />}
            />
          ) : (
            <CustomIconButton
              color="primary"
              onClick={handleRename}
              tooltip="Rename"
              placement="bottom"
              icon={<EditOutlinedIcon style={{ fontSize: '18px' }} />}
            />
          )}
          <CustomIconButton
            color="primary"
            onClick={handleDownload}
            tooltip="Download"
            placement="bottom"
            icon={<DownloadOutlinedIcon style={{ fontSize: '25px' }} />}
          />
          <CustomIconButton
            color="error"
            onClick={() => {
              setOpenConfirmationDialog(true);
              setAlertContent('Are you sure you want to delete ' + currentFiles[selectedImageIndex].file_name + '?');
            }}
            tooltip="Delete"
            placement="bottom"
            icon={<DeleteOutlinedIcon style={{ fontSize: '18px' }} color="error" />}
          />
        </ContainerRow>

        <ConfirmationDialog
          open={openConfirmationDialog}
          setOpen={setOpenConfirmationDialog}
          content={alertContent}
          onConfirm={handleDelete}
        />
      </ContainerColumn>
    );
  };

  return (
    <>
      {!hideViewInModal && (
        <ContainerRow sx={{ justifyContent: 'flex-end', marginBlock: SIZES.paddingS }}>
          <RegularButton size="small" variant="outlined" label="View in modal" onClick={() => setOpenModal(true)} />
        </ContainerRow>
      )}
      <CarouselContent />
      <CustomModal open={openModal} setOpen={setOpenModal} width={1000} header="Uploaded Files">
        <CarouselContent />
      </CustomModal>
    </>
  );
};

export default Carousel;

import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getPatientNotePrintTemplates, getSinglePatientNotePrint } from 'company/api/patient-note-print-template';

import { ApiQuery } from 'core/model/interface';

interface PatientNotePrintTemplateSearchDropdownProps extends TemplateDropdownProps {
  onChange?: (value: any) => void;
  initialValue?: number;
}

const PatientNotePrintTemplateSearchDropdown: React.FC<PatientNotePrintTemplateSearchDropdownProps> = (
  props: PatientNotePrintTemplateSearchDropdownProps
) => {
  return (
    <TemplateDropdown
      {...props}
      initialValue={props.initialValue}
      hideAddOption
      entity={'Note Print Template'}
      getData={(query: ApiQuery) => getPatientNotePrintTemplates(query)}
      getById={(id: number) => getSinglePatientNotePrint(id)}
      processOption={(record) => ({ key: record.template_name, value: record.id, template_code: record.template_code })}
      //   processNewRecord={(record) => record.hmo}
      handleChangeCallback={props.onChange}
    />
  );
};

export default PatientNotePrintTemplateSearchDropdown;

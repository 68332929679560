import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { googleLoginCallback, login, patientLogin, verifyOtp } from 'core/api/user';
import {
  loginFields,
  loginInitialValues,
  loginSchema,
  patientLoginFields,
  patientLoginInitialValues,
  patientLoginSchema,
  patientOtpFields,
  patientOtpSchema,
} from './Login/components/LoginModel';
import { signupFields, signupInitialValues, signupSchema } from './Signup/components/SignUpModel';
import { useLocation, useNavigate } from 'react-router-dom';

import { AccountType } from 'core/model/Entities';
import Cookies from 'js-cookie';
import { CustomForm } from 'core/components';
import { FacilityContext } from 'core/context/facility.context';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { motion } from 'framer-motion';
import { tokens } from 'theme/theme';
import { useGoogleLogin } from '@react-oauth/google';
import useZoomLevel from './ZoomLevel';

interface AuthFlipCardProps {
  handleSubmit: (values: any) => void;
  isSuccess: boolean;
  isLoading: boolean;
  isMobile?: boolean;
  isAdmin?: boolean;
  isPatient?: boolean;
}

const AuthCard: React.FC<AuthFlipCardProps> = ({
  handleSubmit,
  isSuccess,
  isLoading,
  isMobile,
  isAdmin = false,
  isPatient,
}) => {
  const zoomLevel = useZoomLevel();

  const [isFlipped, setIsFlipped] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmail] = useState('');

  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { setFacility } = useContext(FacilityContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobilePhone = useMediaQuery('(max-width:840px)');

  const MAX_LOGIN_ATTEMPTS = 5;
  const LOCKOUT_DURATION = 60 * 1000;

  const getLoginAttempts = (username: string) => {
    const attempts = localStorage.getItem(`loginAttempts_${username}`);
    return attempts ? JSON.parse(attempts) : { count: 0, timestamp: 0 };
  };

  const updateLoginAttempts = (username: string, isSuccessful: boolean) => {
    const currentTime = Date.now();
    const attempts = getLoginAttempts(username);

    if (isSuccessful) {
      // Reset attempts on successful login
      localStorage.removeItem(`loginAttempts_${username}`);
      return true;
    }

    // Reset attempts if lockout period has passed
    if (currentTime - attempts.timestamp > LOCKOUT_DURATION) {
      attempts.count = 0;
    }

    attempts.count += 1;
    attempts.timestamp = currentTime;
    localStorage.setItem(`loginAttempts_${username}`, JSON.stringify(attempts));

    return attempts.count < MAX_LOGIN_ATTEMPTS;
  };

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
    setError('');
  };

  // const validateForm = (values: any) => {
  //   if (!values.accountType && !isAdmin) {
  //     setError('Select account type');
  //     return false;
  //   }

  //   if (!values.username) {
  //     setError('Username is required.');
  //     return false;
  //   }

  //   if (!values.password) {
  //     setError('Password is required.');
  //     return false;
  //   }

  //   return true;
  // };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const response = await googleLoginCallback(codeResponse.access_token);
        if (response.data.token) {
          Cookies.set('access_token', response.data.token);

          if (locationState) {
            const { redirectTo } = locationState as any;
            navigate(`${redirectTo.pathname}${redirectTo.search}`);
          } else {
            navigate('/company');
            localStorage.removeItem('orders');
            localStorage.removeItem('selectedPatient');
          }
        } else {
          setError('Login Failed');
        }
      } catch (error) {
        console.error('Login Failed:', error);
        setError('Google login failed');
      }
    },
    onError: (error) => {
      console.error('Login Failed:', error);
      setError('Google login failed');
    },
  });

  const handleLoginSubmit = async (values: any) => {
    setLoading(true);
    setError('');

    const username = values.username;
    const currentAttempts = getLoginAttempts(username);
    const currentTime = Date.now();

    // Check if user is in lockout period
    if (currentAttempts.count >= MAX_LOGIN_ATTEMPTS && currentTime - currentAttempts.timestamp < LOCKOUT_DURATION) {
      const remainingTime = Math.ceil((LOCKOUT_DURATION - (currentTime - currentAttempts.timestamp)) / 1000);
      setError(`Too many failed attempts. Please try again in ${remainingTime} seconds.`);
      setLoading(false);
      return;
    }

    try {
      let account = isAdmin ? AccountType.CAREGO : values.accountType === 'lgu' ? AccountType.LGU : AccountType.COMPANY;
      localStorage.setItem('accountType', values.accountType);

      const response = await login({ username: values.username, password: values.password }, account);

      if (response.data.token) {
        updateLoginAttempts(username, true);
        Cookies.set('access_token', response.data.token);

        if (locationState) {
          const { redirectTo } = locationState as any;
          navigate(`${redirectTo.pathname}${redirectTo.search}`);
        } else {
          if (isAdmin) {
            navigate('/admin');
          } else {
            values.accountType === 'lgu' ? navigate('/lgu') : navigate('/company');
          }
          localStorage.removeItem('orders');
          localStorage.removeItem('selectedPatient');
        }
      } else {
        const canTryAgain = updateLoginAttempts(username, false);
        if (!canTryAgain) {
          setError('Too many failed attempts. Please try again in 1 minute.');
        } else {
          setError('Login Failed');
        }
      }
    } catch (error: any) {
      const canTryAgain = updateLoginAttempts(username, false);
      if (!canTryAgain) {
        setError('Too many failed attempts. Please try again in 1 minute.');
      } else if (error.response?.status === 401) {
        setError('Invalid credentials provided. Please check your username and password and try again');
      } else {
        setError(error.response?.data?.message || 'Login failed');
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePatientLoginSubmit = async (values: any) => {
    setLoading(true);
    setError('');

    try {
      await patientLogin(values.email, values.code);
      setOtpSent(true);
      setEmail(values.email);
    } catch (error: any) {
      if (error.response?.status === 401) {
        setError('Invalid credentials provided. Please check your email and code and try again');
      } else {
        setError(error.response?.data?.message || 'Login failed');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmOTPSubmit = async (values: any) => {
    setLoading(true);
    setError('');

    try {
      const response = await verifyOtp(email, values.code);
      if (response.data.token) {
        Cookies.set('access_token', response.data.token);
        localStorage.setItem('accountType', 'patient');
        navigate('/patient');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        setError('Invalid OTP. Please check the code and try again');
      } else {
        setError(error.response?.data?.message || 'Login failed');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFacility(undefined);
    const storedAccountType = localStorage.getItem('accountType');

    if (Cookies.get('access_token') && (!isPatient || storedAccountType === AccountType.PATIENT)) {
      if (storedAccountType === AccountType.LGU) navigate('/lgu');
      if (storedAccountType === AccountType.COMPANY) navigate('/company');
      if (storedAccountType === AccountType.PATIENT) navigate('/patient');
      localStorage.removeItem('orders');
      localStorage.removeItem('selectedPatient');
    }

    if (locationState) {
      const { redirectTo } = locationState as any;
      if (redirectTo) {
        setLoading(false);
        setError('User is not signed in.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerStyle = {
    width: '100%',
    maxWidth: {
      sm: '90vw',
      md: '50vw',
      lg: '40vw',
      xl: '30vw',
    },
    height: 'auto',
    minHeight: {
      sm: '80vh',
      md: '85vh',
      lg: '90vh',
    },
    maxHeight: {
      sm: '90vh',
      md: '90vh',
      lg: '90vh',
    },
    p: {
      sm: 2,
      md: 3,
      lg: 4,
      xl: 5,
    },
    pt: {
      sm: 2,
      md: 3,
      lg: 4,
      xl: 5,
    },
    gap: 2,
    borderRadius: '20px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    zIndex: 2,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  };

  const wrapperStyle = {
    position: 'relative',
    width: {
      sm: '90vw',
      md: '50vw',
      lg: '40vw',
      xl: '30vw',
    },
    height: 'auto',
    minHeight: {
      sm: '80vh',
      md: '85vh',
      lg: '90vh',
    },
    maxHeight: {
      sm: '90vh',
      md: '90vh',
      lg: '90vh',
    },
    perspective: '1000px',
    zIndex: 2,
    mx: 'auto',
  };

  if (isMobilePhone) {
    return (
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          zIndex: 2,
        }}
      >
        {/* Mobile Login/Signup Form */}
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'white',
            borderRadius: '20px',
            p: 2,
            pb: 10,
          }}
        >
          {!isFlipped ? (
            // Login Form
            <Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="start" mb={3}>
                <Typography
                  variant="h1"
                  fontWeight={400}
                  color={colors.primary}
                  sx={{
                    fontSize: '1.8rem',
                  }}
                >
                  Login
                </Typography>

                {!isPatient && (
                  <Box>
                    <Typography color="text.secondary">Don't have an account?</Typography>
                    <Typography
                      onClick={handleFlip}
                      sx={{
                        cursor: 'pointer',
                        color: colors.primary,
                        '&:hover': {
                          color: colors.primary,
                        },
                      }}
                    >
                      Sign up
                    </Typography>
                  </Box>
                )}
              </Box>

              {error && (
                <Box className="error-msg" mb={2}>
                  <Typography color="error">{error}</Typography>
                </Box>
              )}

              {isPatient ? (
                <CustomForm
                  initialValues={otpSent ? { code: '' } : patientLoginInitialValues}
                  fields={otpSent ? patientOtpFields : patientLoginFields}
                  schema={otpSent ? patientOtpSchema : patientLoginSchema}
                  onSubmit={otpSent ? handleConfirmOTPSubmit : handlePatientLoginSubmit}
                  submitButtonText="Log in"
                  buttonWidth={isMobile ? '100%' : '200px'}
                  submitButtonMarginTop={4}
                  loading={loading}
                />
              ) : (
                <CustomForm
                  initialValues={loginInitialValues}
                  fields={loginFields}
                  schema={loginSchema}
                  onSubmit={handleLoginSubmit}
                  submitButtonText="Log in"
                  buttonWidth="100%"
                  submitButtonMarginTop={4}
                  loading={loading}
                />
              )}

              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} mt="2rem">
                <Box width={'100%'} flex={1}>
                  <Typography
                    variant="h6"
                    fontWeight={400}
                    color={colors.primary}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <TipsAndUpdatesIcon sx={{ fontSize: '2.2rem' }} />
                  </Typography>
                </Box>
                <Box width={'100%'} flex={6}>
                  <Typography
                    variant="h6"
                    fontWeight={400}
                    color={colors.primary}
                    sx={{
                      fontSize: zoomLevel <= 100 ? '1rem' : '0.875rem',
                      textAlign: 'center',
                    }}
                  >
                    You can get free rewards when a clinic signs up using your referral code!
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            // Signup Form
            <Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="start" mb={3}>
                <Typography
                  variant="h1"
                  fontWeight={400}
                  color={colors.primary}
                  sx={{
                    fontSize: '1.8rem',
                  }}
                >
                  Sign Up
                </Typography>

                <Box>
                  <Typography color="text.secondary" variant="subtitle1">
                    Already have an account?
                  </Typography>
                  <Typography
                    onClick={handleFlip}
                    sx={{
                      cursor: 'pointer',
                      color: colors.primary,
                      '&:hover': {
                        color: colors.primary,
                      },
                    }}
                  >
                    Log in
                  </Typography>
                </Box>
              </Box>

              <CustomForm
                initialValues={signupInitialValues}
                fields={signupFields}
                schema={signupSchema}
                onSubmit={handleSubmit}
                submitButtonText="Create Account"
                buttonWidth="100%"
                isSuccess={isSuccess}
                submitButtonMarginTop={4}
                loading={isLoading}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  // if (isPatient) {
  //   return (
  //     <Box sx={{ ...wrapperStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  //       <Box
  //         sx={{
  //           ...containerStyle,
  //           position: 'absolute',
  //           backfaceVisibility: 'hidden',
  //           width: '100%',
  //           minHeight: 'unset',
  //         }}
  //       >
  //         <Typography
  //           variant="h1"
  //           fontWeight={400}
  //           color={colors.primary}
  //           sx={{
  //             fontSize: '1.8rem',
  //             mb: 3,
  //           }}
  //         >
  //           Login
  //         </Typography>
  //         <Box width={'100%'} mb={zoomLevel <= 100 ? 10 : 5}>
  //           {error && (
  //             <Box className="error-msg" mb={2}>
  //               <Typography color="error">{error}</Typography>
  //             </Box>
  //           )}

  //         </Box>
  //       </Box>
  //     </Box>
  //   );
  // }

  return (
    <Box sx={wrapperStyle}>
      <motion.div
        animate={{ rotateY: isFlipped ? 180 : 0 }}
        transition={{ duration: 0.6 }}
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          transformStyle: 'preserve-3d',
        }}
      >
        {/* Login Form - Front */}
        <Box
          sx={{
            ...containerStyle,
            position: 'absolute',
            backfaceVisibility: 'hidden',

            width: '100%',
          }}
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="start" mb={3}>
            <Typography
              variant="h1"
              fontWeight={400}
              color={colors.primary}
              sx={{
                fontSize: {
                  xs: zoomLevel <= 100 ? '2.5rem' : '2rem',
                  md: zoomLevel <= 100 ? '4rem' : '2.2rem',
                },
              }}
            >
              Login
            </Typography>

            {!isPatient && (
              <Box>
                <Typography
                  color="text.secondary"
                  variant="h6"
                  sx={{
                    fontSize: {
                      xs: zoomLevel <= 100 ? '0.9rem' : '0.8rem',
                      md: zoomLevel <= 100 ? '1.3rem' : '1rem',
                    },
                  }}
                >
                  Don't have an account?
                </Typography>
                <Typography
                  onClick={handleFlip}
                  sx={{
                    cursor: 'pointer',
                    color: colors.primary,
                    '&:hover': {
                      color: colors.primary,
                    },
                    fontSize: {
                      xs: zoomLevel <= 100 ? '1rem' : '0.875rem',
                      md: zoomLevel <= 100 ? '1.3rem' : '1rem',
                    },
                  }}
                >
                  Sign up
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            className="login-title"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent={'center'}
            gap="7px"
            mb={3}
          >
            <Typography
              variant="h6"
              fontWeight={400}
              sx={{
                fontSize: zoomLevel <= 100 ? '1.3rem' : '1rem',
              }}
            >
              Log in to access your CareGo Health Suite account.
            </Typography>
          </Box>

          {error && (
            <Box className="error-msg" mb={2}>
              <Typography color="error">{error}</Typography>
            </Box>
          )}

          <Box width={'100%'} mb={zoomLevel <= 100 ? 10 : 5}>
            {isPatient ? (
              <CustomForm
                initialValues={otpSent ? { code: '' } : patientLoginInitialValues}
                fields={otpSent ? patientOtpFields : patientLoginFields}
                schema={otpSent ? patientOtpSchema : patientLoginSchema}
                onSubmit={otpSent ? handleConfirmOTPSubmit : handlePatientLoginSubmit}
                submitButtonText="Log in"
                buttonWidth={isMobile ? '100%' : '200px'}
                submitButtonMarginTop={4}
                loading={loading}
              />
            ) : (
              <CustomForm
                initialValues={loginInitialValues}
                fields={loginFields}
                schema={loginSchema}
                onSubmit={handleLoginSubmit}
                submitButtonText="Log in"
                buttonWidth={isMobile ? '100%' : '200px'}
                submitButtonMarginTop={4}
                loading={loading}
              />
            )}
          </Box>

          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            <Box width={'100%'} flex={1}>
              <Typography
                variant="h6"
                fontWeight={400}
                color={colors.primary}
                sx={{
                  textAlign: 'center',
                }}
              >
                <TipsAndUpdatesIcon sx={{ fontSize: '3rem' }} />
              </Typography>
            </Box>
            <Box width={'100%'} flex={6}>
              <Typography
                variant="h6"
                fontWeight={400}
                color={colors.primary}
                sx={{
                  fontSize: zoomLevel <= 100 ? '1.3rem' : '1rem',
                  textAlign: 'center',
                }}
              >
                You can get free rewards when a clinic signs up using your referral code!
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Sign Up Form - Back */}
        <Box
          sx={{
            ...containerStyle,
            position: 'absolute',
            backfaceVisibility: 'hidden',
            width: '100%',
            transform: 'rotateY(180deg)',
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="start"
            mb={zoomLevel <= 100 ? 6 : 3}
          >
            <Typography
              variant="h1"
              fontWeight={400}
              color={colors.primary}
              sx={{
                fontSize: zoomLevel <= 100 ? '3.5rem' : '2.2rem',
              }}
            >
              Sign Up
            </Typography>

            <Box>
              <Typography
                color="text.secondary"
                variant="h6"
                sx={{
                  fontSize: {
                    xs: zoomLevel <= 100 ? '0.7rem' : '0.6rem',
                    md: zoomLevel <= 100 ? '1.1rem' : '0.8rem',
                  },
                }}
              >
                Already have an account?
              </Typography>
              <Typography
                onClick={handleFlip}
                sx={{
                  cursor: 'pointer',
                  color: colors.primary,
                  '&:hover': {
                    color: colors.primary,
                  },
                  fontSize: zoomLevel <= 100 ? '1.2rem' : '1rem',
                }}
              >
                Log in
              </Typography>
            </Box>
          </Box>

          <CustomForm
            initialValues={signupInitialValues}
            fields={signupFields}
            schema={signupSchema}
            onSubmit={handleSubmit}
            submitButtonText="Create Account"
            buttonWidth={isMobile ? '100%' : '200px'}
            isSuccess={isSuccess}
            submitButtonMarginTop={4}
            loading={isLoading}
          />
        </Box>
      </motion.div>
    </Box>
  );
};

export default AuthCard;

import * as yup from 'yup';
import { EntityFields } from 'company/entities/utils';
export const MOP_ENTITY_NAME = 'Payment Mode';
export const mopFields: EntityFields[] = [{ fieldName: 'payment_method', displayName: 'Payment Mode' }];

export interface PaymentModeModel {
  id: number;
  payment_method: string;
  payment_method_code: string;
}

export const paymentModesVisibleColumns: string[] = ['payment_method'];
export interface PaymentModeInput {
  payment_method: string;
}
export const mopFormInitialValues: PaymentModeInput = {
  payment_method: '',
};

export const payment_mode_schema = yup.object().shape({
  payment_method: yup
    .string()
    .required('Payment mode required.')
    .max(50, 'Should not exceed 50 characters.')
    .min(3, 'Should be 3 or more characters'),
});

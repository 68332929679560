import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'self-reg-fields';

export const getSelfRegHealthInfoFields = (company_id: number) => {
    return axios.get(`${baseURL}/${path}/${company_id}`);
  };


import {
  APPOINTMENT_ENTITY_NAME,
  AppointmentInitialValues,
  WarningNoNumberComponent,
  appointmentFields,
  appointmentFormFields,
  appointment_schema,
} from './AppointmentModel';
import { Box, Typography, useTheme } from '@mui/material';
import { getHealthPackage, getStages } from 'company/api/health-packages';
import { scheduleAppointment, scheduleRepeatingAppointments } from 'company/api/appointments';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { FacilityModel } from 'core/model/Entities';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { SkeletonDetails } from 'company/screens/HealthAssessments';
import SmsPreview from 'company/screens/Appointments/components/modal/SmsPreview';
import TemplateForm from '../../../Template/TemplateForm';
import { UserContext } from 'core/context/user.context';
import { enrollHealthPackagePatients } from 'company/api/health-packages-patients';
import { getDashboardStat } from 'company/api/inventory-dashboard';
import { getSMSCredit } from 'core/api/dashboard';
import moment from 'moment';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type Props = {
  readonly?: boolean;
  // appointment?: CompanyAppointmentModel;
  showSubmitAtTop?: boolean;
  facility: FacilityModel;
  callbackAfterSubmit?: (data: any) => void;
  patientId?: number;
  patientMobileNumber?: string;
  providerId?: number;
  schedule?: Date;
};

const AppointmentForm: React.FC<Props> = (props) => {
  const {
    facility,
    readonly,
    showSubmitAtTop,
    callbackAfterSubmit,
    patientId,
    patientMobileNumber,
    providerId,
    schedule,
  } = props;

  const [stages, setStages] = useState<any>();
  const [selectedStage, setSelectedStage] = useState<number | null>(null);
  const [formData, setFormData] = useState<any>();
  const [smsCredits, setSMSCredits] = useState<number | undefined>(1);
  const [loadingSMSCredits, setLoadingSMSCredits] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const SMS_CREDIT_THRESHOLD = 100;
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const smsPreviewRef: any = useRef();
  const handleSubmitApi = (data: any) => {
    const final = { ...data };
    final.patients = data.patient_id;
    final.services = JSON.stringify(data.service_id.map((service: any) => service.value));
    if (Number(final.type) === 2) {
      final.date_started = data.schedule;
      return enrollHealthPackagePatients(facility.id, final);
    } else if (Number(final.type) === 3) {
      return scheduleRepeatingAppointments(facility.id, final);
    }
    return scheduleAppointment(facility.id, final);
  };

  const fields = useMemo(() => {
    return appointmentFields.map((field) => {
      if (providerId && field.fieldName === 'service_id') {
        field.onChange = undefined;
      }

      if (field.fieldName === 'send_sms' && smsPreviewRef?.current?.smsCount >= user.remaining_company_credits) {
        field.disabled = true;
      }
      return field;
    });
  }, [providerId, smsPreviewRef?.current?.smsCount]);

  const visibleFields = useMemo(() => {
    let fields = appointmentFormFields;
    // if (schedule) fields = fields.filter((field) => field !== 'walk_in');
    if (patientId)
      return fields.filter(
        (field) => field !== 'patient_id' && !(field === 'no_number_message' && patientMobileNumber)
      );
    if (!loadingSMSCredits) {
      if (user.remaining_company_credits !== undefined && user.remaining_company_credits < 1) {
        return [...fields.filter((field) => field !== 'send_sms'), 'no_credit_message'];
      }
    }
    return fields;
  }, [patientId, schedule, smsCredits]);

  useEffect(() => {
    const checkSMSCredits = async (formData: any) => {
      if (formData?.send_sms) {
        try {
          const credits = user.remaining_company_credits;
          if (credits < SMS_CREDIT_THRESHOLD) {
            enqueueSnackbar(
              `Important Notice: Your SMS credit balance has fallen below ${SMS_CREDIT_THRESHOLD} credits. Current balance: ${credits} credits.`,
              {
                variant: 'warning',
                persist: true,
              }
            );
          }
        } catch (error) {
          console.error('Error fetching SMS credits:', error);
        }
      }
    };

    if (formData) {
      checkSMSCredits(formData);
    }
  }, [formData?.send_sms]);

  if (!loadingSMSCredits)
    return (
      <TemplateForm
        entityName={APPOINTMENT_ENTITY_NAME}
        fields={fields}
        disabled={disableSubmit}
        // entity={appointment}
        schema={appointment_schema}
        formSubmitApiFunction={handleSubmitApi}
        visibleFields={visibleFields}
        initialValues={{
          ...AppointmentInitialValues,
          schedule: schedule,
          patient_id: patientId,
          provider_id: providerId,
          facility_id: facility?.id,
        }}
        callbackAfterSubmit={callbackAfterSubmit}
        showSubmitAtTop={showSubmitAtTop}
        readOnly={readonly}
        customFields={[
          {
            fieldName: 'package_id',
            displayName: 'Session Plan Name',
            type: DROPDOWN_FIELD.HEALTH_PACKAGE,
            hiddenBasedOnOtherField: (data: any) => data.type !== '2',
            onChange: async (value: any, setFieldValue: any) => {
              try {
                const response = await getHealthPackage(value.value);
                const selectedPackage = response.data;

                const res = await getStages(selectedPackage.id);
                const stage = res.data.data;
                setStages(stage);
                setFieldValue('stage_id', stage.length > 0 ? stage[0].id : '');
                setSelectedStage(stage.length > 0 ? stage[0].id : null);
              } catch (error) {
                console.error('Error fetching stages for the selected package:', error);
                setStages([]);
                setFieldValue('stage_id', '');
              }
            },
          },
          {
            fieldName: 'stage_id',
            displayName: 'Starting Session',
            type: 'select',
            hiddenBasedOnOtherField: (data: any) => data.type !== '2',
            options: stages?.map((u: any) => ({
              key: u.stage_name,
              value: u.id,
            })),
            disabled: !stages || stages.length === 0,
            span: 2,
            onChange: (data: number) => setSelectedStage(data),
          },
          {
            fieldName: 'stages_date',
            type: 'component',
            hiddenBasedOnOtherField: (data: any) => {
              setFormData(data);
              return data.type !== '2' || (!data.stage_id && !data.package_id);
            },
            component: (
              <StagesDate stages={stages} selectedStage={selectedStage} formData={formData} facility={facility} />
            ),
            span: 4,
          },
          {
            fieldName: 'sms_preview',
            type: 'component',
            // hiddenBasedOnOtherField: (data: any) => !data.send_sms,
            displayComponentBasedOnFormFields: (data, setFieldValue) => (
              <SmsPreview
                facility={facility}
                templateCode={'appointment_reminder'}
                ref={smsPreviewRef}
                visible={data.send_sms}
              />
            ),
          },

          {
            fieldName: 'blocked_provider_schedule_message',
            type: 'component',
            span: 4,
            component: (
              <WarningNoNumberComponent message="Provider's schedule conflict, please choose another date/time." />
            ),
            // hidden: true,
            hiddenBasedOnOtherField: (data: any) => {
              setDisableSubmit(false);
              if (!data.schedule) return true;
              if (!data.blocked_provider_schedule_message || data.blocked_provider_schedule_message.length < 1)
                return true;
              const dayOfWeek = moment(data.schedule).format('ddd').toUpperCase();
              const currentDay = data.blocked_provider_schedule_message?.find((day: any) => day.day === dayOfWeek);
              if (!(!!currentDay && currentDay.hours.length >= 1)) {
                // setDisableSubmit(true);
                return false;
              }

              const scheduleMoment = moment(data.schedule);
              const scheduleDate = scheduleMoment.format('YYYY-MM-DD');

              const startHour = moment(`${scheduleDate} ${currentDay.hours[0].start}`, 'YYYY-MM-DD hh:mm A');
              const endHour = moment(`${scheduleDate} ${currentDay.hours[0].end}`, 'YYYY-MM-DD hh:mm A');

              const isWithinSchedule = scheduleMoment.isBetween(startHour, endHour, 'minute', '[]');
              if (!isWithinSchedule) {
                // setDisableSubmit(true);
                return false;
              }
              setDisableSubmit(false);
              return true;
            },
          },
        ]}
      />
    );
  return <SkeletonDetails length={5} styles={{ width: '100%', height: '60px', borderRadius: '10px', padding: 0 }} />;
};

export function StagesDate({ stages, selectedStage, formData, facility }: any) {
  const moment = require('moment');
  const { schedule } = formData;
  let currentStageDate = moment(schedule);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!selectedStage) return <></>;

  return (
    <Box
      sx={{
        padding: '10px 20px',
        border: `1px solid ${colors.accent}`,
        borderRadius: '15px',
        backgroundColor: colors.light_blue_background,
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      {!schedule && <Typography>Please select a schedule</Typography>}
      <Box sx={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr 1fr' }}>
        {!!stages &&
          schedule &&
          stages
            .filter((stage: any) => stage.id >= selectedStage)
            .map((stage: any, index: number) => {
              if (stage.id === selectedStage) {
                currentStageDate = moment(schedule);
              } else if (stage.days_after_prev_stage !== null) {
                currentStageDate = currentStageDate.add(stage.days_after_prev_stage, 'days');
              }

              return (
                <PatientField
                  key={index}
                  label={stage.stage_name}
                  value={currentStageDate.format('MMMM D, YYYY hh:mm A')}
                />
              );
            })}
      </Box>
    </Box>
  );
}

export default AppointmentForm;

import {
  ATTRIBUTE_ENTITY_NAME,
  VariantAttributeModel,
  addAttributeFormFields,
  attributeFields,
  attributeInitialValues,
  attributeModelSchema,
} from './VariantAttributeModel';
import { createVariantAttribute, updateVariantAttribute } from 'company/api/variant-attributes';

import React, { useEffect, useState } from 'react';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';
import { createAttributeValue } from 'company/api/attribute-values';
import { getAttributeCategories } from 'company/api/attribute-categories';

type Props = {
  attribute?: VariantAttributeModel;
  callbackAfterSubmit?: (data?: any) => void;
  add?: boolean;
};

const VariantAttributeForm: React.FC<Props> = ({ attribute, callbackAfterSubmit, add = true }) => {
  const [attributeCategories, setAttributeCategories] = useState<any>([]);
  useEffect(() => {
    if (attribute) {
      getAttributeCategories(attribute?.id).then((res) => {
        const converted = res.data.map((cat: any) => ({ key: cat.category, value: cat.category_id }));
        setAttributeCategories(converted);
      });
    }
  }, [attribute]);
  const handleFormSubmit = async (data: VariantAttributeModel) => {
    try {
      const {
        attribute_value_1,
        attribute_value_2,
        attribute_value_3,
        attribute_value_4,
        attribute_value_5,
        ...attributeData
      } = data;

      const response =
        attribute && attribute.id
          ? await updateVariantAttribute(attribute.id, attributeData)
          : await createVariantAttribute(attributeData);

      let attribute_id: number;
      if (response.data && response.data.attribute.id) {
        attribute_id = response.data.attribute.id;
      } else {
        throw new Error('Failed to get attribute ID ');
      }

      const attributeValues = [
        attribute_value_1,
        attribute_value_2,
        attribute_value_3,
        attribute_value_4,
        attribute_value_5,
      ];

      const valueCreationPromises = attributeValues
        .filter((value): value is string => value != null && value !== '')
        .map((value) => createAttributeValue(attribute_id, { value }));

      await Promise.all(valueCreationPromises);

      if (callbackAfterSubmit) {
        callbackAfterSubmit(response.data);
      }
      return response;
    } catch (error) {
      console.error('Error in handleFormSubmit:', error);
      throw error;
    }
  };
  const initialVals =
    attribute && attributeCategories
      ? { ...changeNullToBlank(attribute), category_id: attributeCategories }
      : attributeInitialValues;
  return (
    <TemplateForm
      submitButtonId={'create_variant_attribute_submit_button'}
      entity={attribute}
      entityName={ATTRIBUTE_ENTITY_NAME}
      fields={add ? attributeFields : attributeFields.slice(0, 2)}
      visibleFields={add ? addAttributeFormFields : addAttributeFormFields.slice(0, 2)}
      schema={attributeModelSchema}
      initialValues={initialVals}
      formSubmitApiFunction={handleFormSubmit}
      useInitialValue={true}
    />
  );
};

export default VariantAttributeForm;

import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'note-templates';

export const getTemplates = (facility_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/facility/${facility_id}`, { params: query });
};

export const getTemplatesNotAdded = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/not-added`, { params: query });
};

export const getTemplate = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/facility/${facility_id}/note/${id}`);
};

export const getTemplatesWithNotes = (facility_id: number, patient_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/patient/${patient_id}`, { params: query });
};

export const getCareGoTemplate = (id: number) => {
  return axios.get(`${baseURL}/${path}/carego/${id}`);
};

export const getTemplateFacilityNoteAdded = (note_id: number, query?: ApiQuery)=>{
  return axios.get(`${baseURL}/${path}/facility-not-added/note/${note_id}`, { params: query });
}

export const getTemplateFacilities = (note_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/facilities/${note_id}`, {params: query})
}

export const getCareGoTemplates = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/carego`, { params: query });
};

export const createTemplate = (template: any) => {
  return axios.post(`${baseURL}/${path}`, template);
};

export const updateTemplate = (id: number, template: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, template);
};

export const updateTemplateTableFormat = (table_format: any, id: number) => {
  return axios.patch(`${baseURL}/${path}/table-format/${id}`, { table_format });
};

export const deleteTemplate = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const removeTemplateClinicAccess = (facility_id: number, note_id: number) => {
  return axios.delete(`${baseURL}/${path}/remove-access/${facility_id}/${note_id}`)
}

export const assignTemplateToClinic = (facility_id: number, note_id: number) => {
  return axios.post(`${baseURL}/${path}/assign-to-facility/${facility_id}/${note_id}`)
}

import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';

import { ApiQuery } from 'core/model/interface';
import { ConfirmationDialog } from 'core/components';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { formatDate, formatDateTime, formatNumber } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { deleteTopUpRequest, getTopUpRequests, updateTopUpRequest } from 'carego-admin/api/top-up-requests';
import { getCompanyStorageHistory } from 'carego-admin/api/company-storage-history';

const CompanyStorageHistory = () => {
  const [refreshTable, setRefreshTable] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const columns: GridColDef[] = [
    { field: 'company_id', headerName: 'Company ID', flex: 1 },
    { field: 'company_name', headerName: 'Company Name', flex: 1 },
    {
      field: 'database_size_kb',
      headerName: 'Database Size (KB)',
      flex: 1,
      renderCell: (params) => <Typography>{formatNumber(params.row.database_size_kb)} KB</Typography>,
    },
    {
      field: 's3_size_kb',
      headerName: 'S3 Size (KB)',
      flex: 1,
      renderCell: (params) => <Typography>{formatNumber(params.row.s3_size_kb)} KB</Typography>,
    },
    {
      field: 'total_size_kb',
      headerName: 'Total Storage Used (KB)',
      flex: 1,
      renderCell: (params) => <Typography>{formatNumber(params.row.total_size_kb)} KB</Typography>,
    },
    {
      field: 'checked_at',
      headerName: 'Last Checked',
      flex: 1,
      renderCell: (params) => <Typography>{formatDateTime(params.row.checked_at)}</Typography>,
    },
  ];

  const getData = async (query: ApiQuery) => {
    return getCompanyStorageHistory(query);
  };

  //   const handleDelete = (data: any) => {
  //     return deleteTopUpRequest(data);
  //   };

  //   const handleConfirm = () => {
  //     selectedRequestId &&
  //       updateTopUpRequest(selectedRequestId, 'confirmed')
  //         .then((response) => {
  //           enqueueSnackbar(response.data.message || 'Top-up request successfully confirmed!', { variant: 'success' });
  //           setRefreshTable((prev: number) => prev + 1);
  //           setOpenConfirmRequestModal(false);
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //           enqueueSnackbar(err.response?.data?.message || 'Failed to confirm top-up request', { variant: 'error' });
  //         });
  //   };

  const content = {
    type: BLOCK_TYPE.PAGE,
    header: 'Company Storage History',
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Company Storage',
        doNotWaitForFacility: true,
        tableComponent: {
          columns: columns,
          getData: getData,
          forceRefresh: refreshTable,
        },

        tableAction: {
          //   deleteApiFunction: handleDelete,
          //   handleRowActionsClick: (selectedRow, action) => {
          //     if (action === 'update') {
          //       setSelectedRequestId(selectedRow.id);
          //       setOpenConfirmRequestModal(true);
          //     }
          //   },
        },
      } as PageBuilderTableType,
    } as PageBuilderContainerType,
  } as PageBuilderPageType;

  return (
    <>
      {/* <ConfirmationDialog
        content="Are you sure you want to confirm this top-up request?"
        open={openConfirmRequestModal}
        setOpen={setOpenConfirmRequestModal}
        onConfirm={handleConfirm}
      /> */}
      <PageBuilder content={content} />
    </>
  );
};

export default CompanyStorageHistory;

import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import {
  CustomCheckbox,
  CustomModal,
  CustomTextField,
  Dropdown,
  HideOrShowComponent,
  RegularButton,
} from 'core/components';
import { FORM_MODE, FieldInput, PatientLabRequestProps } from 'core/model/interface';
import { calculateAge, formatDate, formatDateTime } from 'core/utils';
import {
  createPatientLabRequestForm,
  getAllPatientLabRequestTest,
  togglePatientLabRequestTest,
  updatePatientLabRequest,
} from 'company/api/lab-request';
import { useContext, useEffect, useRef, useState } from 'react';

import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import DraggableList from 'core/components/DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FacilityContext } from 'core/context/facility.context';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PrintableLabRequest from 'company/screens/RegistrationBoard/components/PrintableLabRequest';
import { SIZES } from 'theme/constants';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { ServiceModel } from 'company/entities/modules/ClinicManagement/Service/ServiceModel';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getCompanyLabServices } from 'company/api/services';
import { getPrintSettings } from 'company/api/print-settings';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';
import { UserContext } from 'core/context/user.context';
import { getPatientLabRequests } from 'patient/api/records';

type LabRequestFormProps = {
  patientLabRequest?: PatientLabRequestProps;
  fields: FieldInput[];
  initialMode?: FORM_MODE;
  responses?: any;
  patient?: PatientModel;
};

const LabRequestForm: React.FC<LabRequestFormProps> = ({
  patientLabRequest,
  fields,
  initialMode,
  responses,
  patient,
}) => {
  const isMobilePhone = useMediaQuery('(max-width:600px)');
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reorderedFields, setReorderedFields] = useState(fields || []);
  const [formFields, setFormFields] = useState<FieldInput[]>([]);
  const [mode, setMode] = useState<FORM_MODE>(initialMode ? initialMode : FORM_MODE.RESPOND);

  const [labServices, setLabServices] = useState<ServiceModel[]>([]);
  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [labRequestTests, setLabRequestTests] = useState<number[]>([]);
  const [otherTests, setOtherTests] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const componentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    async function getLabServices() {
      setIsLoadingServices(true);
      const res = !user.is_patient_account
        ? await getCompanyLabServices(user.is_patient_account ? user.facility.id : facility?.id, {
            length: 1000,
          })
        : await getPatientLabRequests();
      const data = res.data.data;
      setLabServices(data);
      setIsLoadingServices(false);
    }
    getLabServices();
    if (patientLabRequest && facility) {
      getAllPatientLabRequestTest(facility.id, patientLabRequest.id).then((res) =>
        setLabRequestTests(res.data.map((test: any) => test.service_id))
      );
    }
  }, [patientLabRequest, patient, facility]);

  const handleCloseModal = () => {
    setReorderedFields([]);
    setIsModalOpen(false);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newReorderedFields = [...reorderedFields];
    const [movedField] = newReorderedFields.splice(result.source.index, 1);
    newReorderedFields.splice(result.destination.index, 0, movedField);
    setReorderedFields(newReorderedFields);
  };

  const handleChangeVal = (event: React.ChangeEvent<HTMLInputElement>, data: any) => {
    if (mode === FORM_MODE.RESPOND && patientLabRequest) {
      togglePatientLabRequestTest(facility.id, {
        service_id: data.id,
        patient_request_id: patientLabRequest.id,
        is_checked: event.target.checked,
      }).then(() => {
        if (event.target.checked) {
          setLabRequestTests((prev) => [...prev, data.id]);
        } else {
          setLabRequestTests((prev) => prev.filter((service_id) => data.id !== service_id));
        }
      });
    }
  };

  const handleSavebuttonClicked = (autoSave?: boolean) => {
    const timeoutId = setTimeout(() => {
      setIsSaving(true);
      try {
        setIsSaving(true);
        if (patientLabRequest) {
          updatePatientLabRequest(facility.id, patientLabRequest.id, { other_tests: otherTests }).then(
            () => (patientLabRequest.other_tests = otherTests)
          );
        }
        if (!autoSave) {
          enqueueSnackbar('Lab Request successfully updated!', { variant: 'success' });
        }
      } catch (error) {
        if (!autoSave) {
          enqueueSnackbar('Error occured! Unable to update note.', { variant: 'error' });
        }
      } finally {
        setTimeout(() => setIsSaving(false), 500);
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  };

  useEffect(() => {
    setReorderedFields(fields);
    setFormFields(fields);
  }, [fields]);

  const saveLabRequestForm = () => {
    if (mode === FORM_MODE.EDIT) createPatientLabRequestForm({ body: reorderedFields });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (reorderedFields.length) saveLabRequestForm();
    }, 1000);
    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reorderedFields]);

  useEffect(() => {
    if (patientLabRequest) {
      setOtherTests(patientLabRequest.other_tests);
    }
  }, [patientLabRequest]);

  useEffect(() => {
    // const timeoutId = setTimeout(() => {
    //   if (patientLabRequest)
    //     updatePatientLabRequest(facility.id, patientLabRequest.id, { other_tests: otherTests }).then(
    //       () => (patientLabRequest.other_tests = otherTests)
    //     );
    // }, 1000);
    // return () => clearTimeout(timeoutId);
    handleSavebuttonClicked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherTests]);

  const [labRequestPrintSettings, setLabRequestPrintSettings] = useState({
    font: '',
    margin: '10mm 20mm',
    page: 'A4',
    orientation: 'portrait',
  });

  useEffect(() => {
    async function getLabRequestPrintSettings() {
      try {
        const res = await getPrintSettings();
        const settings = res.data.data;

        const {
          lab_request_font_size: font,
          lab_request_margin: margin,
          lab_request_page_size: page,
          lab_request_orientation: orientation,
        } = settings;
        if (!!font && !!margin && page) {
          const settingsCombined = { font, margin, page, orientation };
          setLabRequestPrintSettings(settingsCombined);
        } else throw new Error('Unable to fetch lab request print settings. Please modify on clinic settings.');
      } catch (err) {
        if (err instanceof Error) {
          enqueueSnackbar(`${err.message}`, { variant: 'error', autoHideDuration: 10000 });
        } else {
          console.error('Unknown error', err);
        }
      }
    }
    getLabRequestPrintSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility?.id]);

  const getFontSizes = (size: string) => {
    switch (size) {
      case 'xl':
        return { header: '15px', body: '14px' };
      case 'l':
        return { header: '14px', body: '13px' };
      case 's':
        return { header: '12px', body: '11px' };
      case 'm':
      default:
        return { header: '13px', body: '12px' };
    }
  };

  const fontSizes = getFontSizes(labRequestPrintSettings.font);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page {
        size: ${labRequestPrintSettings.page} ${labRequestPrintSettings.orientation};
        margin: ${labRequestPrintSettings.margin};
      }
      body {
        margin: 0;
      }
    `,
  });

  return isLoadingServices ? (
    <CustomLoadingIndicator />
  ) : (
    <Box
      sx={{
        width: '100%',
        maxWidth: '850px',
        margin: '0 auto',
      }}
    >
      {!isLoadingServices && !labServices && !patientLabRequest && (
        <Typography sx={{ marginBottom: '20px', fontSize: '1rem' }}>No Laboratory Tests/Services</Typography>
      )}

      <ContainerRow
        gap={SIZES.paddingS}
        sx={{ justifyContent: 'space-between', mt: '15px', mb: '20px', alignItems: 'flex-start' }}
      >
        <Box display={'flex'} gap="20px">
          {!user.is_patient_account && !!patientLabRequest && patientLabRequest.facility_id == facility.id && (
            <Dropdown
              buttonLabel={(mode === FORM_MODE.VIEW ? 'View' : 'Edit') + ' Mode'}
              startIcon={mode === FORM_MODE.VIEW ? <VisibilityOutlinedIcon /> : <EditOutlinedIcon />}
              optionList={[
                {
                  label: 'Edit',
                  action: () => setMode(FORM_MODE.EDIT),
                  hidden: !!responses,
                  icon: <EditOutlinedIcon />,
                },
                { label: 'View', action: () => setMode(FORM_MODE.VIEW), icon: <VisibilityOutlinedIcon /> },
                {
                  label: 'Edit',
                  action: () => setMode(FORM_MODE.RESPOND),
                  hidden: !responses,
                  icon: <EditOutlinedIcon />,
                },
              ]}
              notFullWidth
              icon={<ExpandMoreOutlinedIcon />}
              buttonColor={FORM_MODE.VIEW === mode ? 'warning' : 'success'}
            />
          )}
          {!!patientLabRequest && (
            <RegularButton
              startIcon={<LocalPrintshopOutlinedIcon />}
              label="Print"
              onClick={() => handlePrint()}
              variant="outlined"
              color="secondary"
              styles={{ width: '150px' }}
            />
          )}
        </Box>
        {mode !== FORM_MODE.VIEW && (
          <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="flex-end" gap="10px">
            <RegularButton startIcon={<SaveOutlinedIcon />} label={'Save'} onClick={() => handleSavebuttonClicked()} />
            <Typography fontStyle="italic" sx={{ visibility: isSaving ? 'visible' : 'hidden' }}>
              Saving updates ...
            </Typography>
          </Box>
        )}
      </ContainerRow>
      <Box>
        {patientLabRequest && patient && (
          <Box display="none">
            <PrintableLabRequest
              ref={componentRef}
              patient={patient}
              patientLabRequest={patientLabRequest}
              selectedServices={labServices.filter((service) => labRequestTests.includes(service.id))}
              fontSizes={fontSizes}
            />
          </Box>
        )}
        <HideOrShowComponent hidden={!isDevMode()}>
          {patientLabRequest && (
            <Box
              paddingTop={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box display="grid" gridTemplateColumns="1fr 1fr " gap="10px">
                {patient && (
                  <>
                    <PatientField label="First Name" value={patient.first_name} />
                    <PatientField label="Middle Name" value={patient.middle_name} />
                    <PatientField label="Last Name" value={patient.last_name} />
                    <PatientField label="Birthday" value={formatDate(patient.birthday)} />
                    <PatientField label="Age" value={calculateAge(patient.birthday)} />
                    <PatientField label="Sex" value={patient.sex} />
                  </>
                )}
                <Divider sx={{ gridColumn: 'span 2' }} />
              </Box>
            </Box>
          )}

          <Box
            mt={SIZES.padding}
            sx={{
              borderRadius: '10px',
              padding:
                mode === FORM_MODE.EDIT
                  ? isMobilePhone
                    ? undefined
                    : '24px 12px'
                  : isMobilePhone
                  ? undefined
                  : '12px 12px',
            }}
          >
            {/* <Collapse in={open || mode === FORM_MODE.RESPOND} timeout="auto">
              {mode === FORM_MODE.RESPOND ? (
                <WaitForFacility facility={!!patient}>
                  <PatientRecordForm fields={formFields} responses={responses} patient={patient!} />
                </WaitForFacility>
              ) : (
                <CustomForm
                  onSubmit={(data) => undefined}
                  fields={reorderedFields!}
                  initialValues={{}}
                  loading={false}
                  hideSubmitButton
                  span={12}
                  readOnly
                  formMode={mode}
                  columnGap={mode === FORM_MODE.EDIT ? '10px' : undefined}
                  setReorderedFields={setReorderedFields}
                  autoSave={() => setRefresh((prev) => prev + 1)}
                />
              )}
            </Collapse> */}

            <CustomModal header="Edit Form" open={isModalOpen} setOpen={setIsModalOpen} onClose={handleCloseModal}>
              <Box mt="20px">
                <DraggableList
                  title="Reorder Form Fields"
                  fromModal
                  droppableId="form-fields"
                  handleDragEnd={handleDragEnd}
                  list={reorderedFields}
                  displayItem={(field, index) => (
                    <div>
                      {field.display_name ?? field.section_header ?? field.subsection_header} ({field.type})
                    </div>
                  )}
                />
              </Box>
            </CustomModal>
          </Box>
        </HideOrShowComponent>
        {/* <Divider sx={{ marginBlock: SIZES.padding }} /> */}
        <ContainerColumn>
          {labServices && (
            <Box sx={{ width: '100%' }}>
              <Box>
                <Typography sx={{ marginBottom: '10px', fontSize: '1rem' }}>Laboratory Tests/Services</Typography>
                {patientLabRequest && (
                  <Typography sx={{ marginBottom: '20px', fontSize: '13px' }}>
                    Request ID: {patientLabRequest.id}{' '}
                  </Typography>
                )}
              </Box>
              <Box sx={{ display: 'grid', gridTemplateColumns: isMobilePhone ? '1fr' : '1fr 1fr 1fr', gap: '20px' }}>
                {labServices.map((data: any, index: number) => {
                  const exists = labRequestTests.filter((service_id) => service_id === data.id);
                  return (
                    <CustomCheckbox
                      key={index}
                      value={exists?.length > 0}
                      fieldName="service_id"
                      label={data.service_name}
                      readOnly={mode === FORM_MODE.VIEW || !patientLabRequest}
                      handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeVal(event, data)}
                    />
                  );
                })}
              </Box>
            </Box>
          )}

          <CustomTextField
            value={otherTests}
            rows={5}
            multiline
            label={'Others'}
            fieldName={'others'}
            readOnly={mode === FORM_MODE.VIEW || !patientLabRequest}
            handleChangeCallback={(value) => setOtherTests(value)}
          />
        </ContainerColumn>
        {patientLabRequest && (
          <Box display="grid" gridTemplateColumns="1fr 1fr " gap="10px" mt="20px">
            <Divider sx={{ gridColumn: 'span 2' }} />
            <PatientField fieldFontSize="11px" label="Created By:" value={patientLabRequest?.creator_name} />
            <PatientField fieldFontSize="11px" label="Last Updated By:" value={patientLabRequest?.editor} />
            <PatientField
              fieldFontSize="11px"
              label="Date Created:"
              value={formatDateTime(patientLabRequest?.created_at)}
            />
            <PatientField
              fieldFontSize="11px"
              label="Last Updated:"
              value={formatDateTime(patientLabRequest?.updated_at)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LabRequestForm;

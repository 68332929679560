import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
  REFERRAL_ENTITY_NAME,
  ReferralInput,
  create_referral_schema,
  referralFields,
  referralFormFields,
  referralInitialValues,
} from './ReferralModel';
import { CustomModal, RegularButton } from 'core/components';
import AnimatedRegularButton from 'core/components/buttons/AnimatedRegularButton';
import TemplateForm from 'company/entities/Template/TemplateForm';
import SuccessModal from 'core/components/modals/SuccessModal';
import { tokens } from 'theme/theme';
import { getReferralCode, updateRewardType } from 'company/api/referrals';
import { UserContext } from 'core/context/user.context';

const Referral = () => {
  const [openModal, setOpenModal] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const { user } = useContext(UserContext);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleSubmit = async (data: ReferralInput) => {
    try {
      await updateRewardType(data);
      setOpenSuccessModal(true);
      setOpenModal(false);
    } catch (error) {
      console.error('Error submitting referral:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchReferralCode = async () => {
      const response = await getReferralCode();
      setReferralCode(response.data.referral_code);
    };
    fetchReferralCode();
  }, []);

  const messageComponent = (
    <>
      <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 1, textAlign: 'center', pb: 2 }}>
        Thank you for selecting your reward!
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        Your selection has been saved. <br />
        You will receive your reward once your referral code is used and verified by the CareGo team.
      </Typography>
    </>
  );

  return (
    <>
      <AnimatedRegularButton onClick={() => setOpenModal(true)} label="Free Rewards" startIcon={<CardGiftcardIcon />} />

      <CustomModal open={openModal} setOpen={setOpenModal} header="Invite Clinics & Earn Rewards" width={900}>
        <Typography variant="h4" sx={{ fontWeight: 500, textAlign: 'center', color: colors.primary, p: 2 }}>
          Rewards will only be credited once valid companies use your code to sign up for a free-trial.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                md: user?.user_group?.group_name === 'COMPANY_ADMIN' ? 'repeat(2, 1fr)' : '1fr',
              },
              gap: 4,
              width: '100%',
            }}
          >
            {user?.user_group?.group_name === 'COMPANY_ADMIN' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  gap: 3,
                  p: 4,
                  width: '100%',
                  borderRadius: 2,
                  height: '100%',
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 600, textAlign: 'center' }}>
                  Step 1: Select your preferred reward (Admin)
                </Typography>

                <TemplateForm
                  submitButtonId="referral_submit"
                  submitButtonText="Confirm Selection"
                  entityName={REFERRAL_ENTITY_NAME}
                  fields={referralFields}
                  visibleFields={referralFormFields}
                  initialValues={referralInitialValues}
                  schema={create_referral_schema}
                  formSubmitApiFunction={handleSubmit}
                  hideDefaultSnackbar
                  buttonPosition="center"
                  buttonWidth="100%"
                  disabled={user?.user_group?.group_name !== 'COMPANY_ADMIN'}
                />
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 3,
                p: user?.user_group?.group_name === 'COMPANY_ADMIN' ? 4 : '32px 0',
                borderRadius: 2,
                height: '100%',
                width: user?.user_group?.group_name === 'COMPANY_ADMIN' ? '100%' : '400px',
                margin: user?.user_group?.group_name === 'COMPANY_ADMIN' ? '0' : '0 auto',
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                {user?.user_group?.group_name === 'COMPANY_ADMIN'
                  ? 'Step 2: Share your referral code'
                  : 'Share your referral code with other companies'}
              </Typography>
              <Box
                sx={{
                  border: '2px dashed #E0E0E0',
                  bgcolor: '#F5F5F5',
                  p: 3,
                  borderRadius: 1,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <Typography variant="h1" sx={{ fontWeight: 400, color: copied ? colors.primary : colors.black_text }}>
                  {referralCode}
                </Typography>
              </Box>
              <RegularButton
                onClick={handleCopy}
                variant="outlined"
                size="small"
                startIcon={<ContentCopyIcon />}
                fullWidth
              >
                {copied ? 'Copied!' : 'Copy Code'}
              </RegularButton>
            </Box>
          </Box>
        </Box>
      </CustomModal>

      <SuccessModal
        openModal={openSuccessModal}
        setOpenModal={setOpenSuccessModal}
        successMessageComponent={messageComponent}
        handleOkayCallback={() => setOpenSuccessModal(false)}
      />
    </>
  );
};

export default Referral;

/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, IconButton, Snackbar, SnackbarCloseReason, Typography, useTheme } from '@mui/material';
import { CompanyProtectedComponent, CustomModal, ExportButton, RegularButton } from 'core/components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { exportInventories, getInventorySummary } from 'company/api/inventories';

import AddIcon from '@mui/icons-material/Add';
import AddStock from 'company/entities/modules/InventorySystem/components/AddStock';
import AdjustStock from 'company/entities/modules/InventorySystem/components/AdjustStock';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ClinicInventoryScroller from './components/ClinicInventoryScroller';
import CloseIcon from '@mui/icons-material/Close';
import { ContainerRow } from 'core/components/containers';
import CustomFilter from 'core/components/CustomFilter';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { FacilityContext } from 'core/context/facility.context';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import InventoryWidget from './InventoryWidget';
import MoveUpOutlinedIcon from '@mui/icons-material/MoveUpOutlined';
import PageHeader from 'core/components/PageHeader';
import { ProductForm } from 'company/entities/forms';
import ProductInTransaction from './components/ProductInTransaction';
import ProductLogsScroller from './components/ProductLogsScroller';
import ProductOutTransaction from './components/ProductOutTransaction';
import ProductTransferTransaction from './components/ProductTransferTransaction';
import ProductVariantForm from 'company/entities/modules/InventorySystem/Product/ProductVariant/ProductVariantForm';
import ReversalTransaction from './components/ReversalTransaction';
import { SIZES } from 'theme/constants';
import SearchBar from '../POS/components/SearchBar';
import StockAdjustmentTransaction from './components/StockAdjustmentTransaction';
import TransferStock from 'company/entities/modules/InventorySystem/components/TransferStock';
import UpdateDetails from 'company/entities/modules/InventorySystem/components/UpdateDetails';
import UpdatePrice from 'company/entities/modules/InventorySystem/components/UpdatePrice';
import { UserContext } from 'core/context/user.context';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { formatCurrency } from 'core/utils';
import { getAllTransactions } from 'company/api/transactions';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';

export interface Product {
  id: number;
  product_id: number;
  variant_id: number;
  variant_name: string;
  variant_description: string;
  price: string;
  variant_price: string;
  sku: string;
  product_name: string;
  quantity_in_stock: any;
  exclusive_supplier?: number;
  exclusive_supplier_name: string;
  supplier_name: string;
  category: string;
  category_id: number;
  made_to_order: boolean;
  created_at: string;
  attributes: string;
  uom_id: number;
  base_uom_name: string;
  base_uom_symbol: string;
  uom_conversions: any[];
  low_stock_threshold: number;
}

const ClinicInventory = () => {
  const { user } = useContext(UserContext);

  const { facility } = useContext(FacilityContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [openAddStock, setOpenAddStock] = useState(false);
  const [openAdjustStock, setOpenAdjustStock] = useState(false);
  const [openTransferStock, setOpenTransferStock] = useState(false);
  const [openUpdatePrice, setOpenUpdatePrice] = useState(false);
  const [openUpdateDetails, setOpenUpdateDetails] = useState(false);
  const [openViewAll, setOpenViewAll] = useState(false);

  const [transactions, setTransactions] = useState<any[]>([]);
  const [transactionSearchQuery, setTransactionSearchQuery] = useState<string>('');
  const [transactionFilters, setTransactionFilters] = useState<string[]>([]);

  const [openExclusiveProductModal, setOpenExclusiveProductModal] = useState<boolean>(false);

  const [summary, setSummary] = useState<any>();

  const [refreshInventory, setRefreshInventory] = useState<number>(0);

  const inventoryScrollerRef: any = useRef();
  const productLogsScrollerRef: any = useRef();
  const itemsPerPage = 10;

  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackbar] = useState<boolean>(false);
  const [newlyAddedVariant, setNewlyAddedVariant] = useState<number>();
  const [openProductForm, setOpenProductForm] = useState<boolean>(false);
  const [forVariant, setForVariant] = useState<boolean>(true);

  const getInventoryTransactions = () => {
    if (facility) {
      return getAllTransactions(facility.id, {
        search: transactionSearchQuery,
        type: transactionFilters.length ? transactionFilters : undefined,
        length: itemsPerPage,
      }).then((res) => {
        setTransactions(res.data.data);
      });
    }
  };

  function handleOpenSnackBar(data: any) {
    if (!forVariant) {
      setOpenProductForm(false);
      setOpenSnackbar(true);
      return;
    }
    setOpenExclusiveProductModal(false);
    setNewlyAddedVariant(data.variant.id);
    setOpenSnackbar(true);
  }

  function handleCloseSnackBar(event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  }
  const handleNavigateToNewVariant = () => {
    navigate(
      forVariant ? `/company/inventory/products/variant?id=${newlyAddedVariant}` : `/company/inventory/products`
    );
  };

  const snackBarAction = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleNavigateToNewVariant}>
        <Typography sx={{ textTransform: 'none', color: '#6884E0' }}>
          {forVariant ? `View variant` : `View product`}
        </Typography>
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  useEffect(() => {
    getInventoryTransactions();
  }, [transactionSearchQuery, transactionFilters]);

  const actions = [
    {
      label: 'Restock',
      action: (data: any) => {
        setSelectedProduct(data);
        setOpenAddStock(true);
      },
      icon: <AddIcon />,
    },
    {
      label: 'Adjust Stock',
      action: (data: any) => {
        setSelectedProduct(data);
        setOpenAdjustStock(true);
      },
      icon: <Inventory2OutlinedIcon />,
    },
    {
      label: 'Transfer',
      action: (data: any) => {
        setSelectedProduct(data);
        setOpenTransferStock(true);
      },
      icon: <MoveUpOutlinedIcon />,
    },
    {
      label: 'Update Price',
      action: (data: any) => {
        setSelectedProduct(data);
        setOpenUpdatePrice(true);
      },
      icon: <DriveFileRenameOutlineOutlinedIcon />,
    },
    {
      label: 'Update Details',
      action: (data: any) => {
        setSelectedProduct(data);
        setOpenUpdateDetails(true);
      },
      icon: <DriveFileRenameOutlineOutlinedIcon />,
    },
  ];

  const handleExport = () => {
    setLoadingExport(true);
    try {
      const currentFilters = inventoryScrollerRef.current?.getFilters() || {};
      exportInventories(facility.id, facility.facility_name, currentFilters);
    } finally {
      setLoadingExport(false);
    }
  };

  useEffect(() => {
    getInventoryTransactions();
    getInventorySummary(facility.id).then((res) => {
      setSummary(res.data);
    });
  }, [facility]);

  useEffect(() => {
    setBreadcrumb([{ label: 'Product Directory' }]);
  }, []);

  return (
    <>
      <Box display="flex" gap="30px" width="100%">
        <Box width="100%" flex={2.5} minWidth="800px">
          <Box width="100%" sx={{ position: 'sticky', top: 0 }}>
            <ContainerRow>
              <PageHeader title="Clinic Inventory" mb="0" />
              <ContainerRow sx={{ justifyContent: 'flex-end' }} gap="10px">
                <RegularButton
                  startIcon={<VisibilityOutlinedIcon />}
                  label="View Product Directory"
                  color="primary"
                  variant="contained"
                  size="small"
                  // styles={{ width: '250px' }}
                  onClick={() => navigate('/company/inventory/products')}
                />
                <RegularButton
                  startIcon={<AddIcon />}
                  label="Add New Variant"
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setOpenExclusiveProductModal(true);
                    setForVariant(true);
                  }}
                  disabled={user.remaining_storage <= 0}
                />
                <RegularButton
                  startIcon={<AddIcon />}
                  size="small"
                  label="Add New Product"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpenProductForm(true);
                    setForVariant(false);
                  }}
                  disabled={user.remaining_storage <= 0}
                />
              </ContainerRow>
            </ContainerRow>

            <Box display="flex" flexDirection="column" gap="20px" sx={{ backgroundColor: 'none' }}>
              <ClinicInventoryScroller
                ref={inventoryScrollerRef}
                actions={actions}
                refreshInventory={refreshInventory}
                extraButtons={
                  <Box display="flex" gap="4px">
                    <CompanyProtectedComponent requiredAuth={['EXPORT_CLINIC_INVENTORY']}>
                      <ExportButton onClick={handleExport} loading={loadingExport} />
                    </CompanyProtectedComponent>
                  </Box>
                }
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            minWidth: '400px',
            flexDirection: 'column',
            gap: SIZES.paddingS,
            position: 'relative',
            flexShrink: 0,
          }}
        >
          <Box display={'flex'} flexDirection="column" gap={SIZES.paddingS} justifyContent={'space-between'}>
            <InventoryWidget
              count={summary?.totalCost ? `${formatCurrency(summary.totalCost)}` : ''}
              icon={Inventory2OutlinedIcon}
              label="Total Cost of SKUs"
            />
            <InventoryWidget
              sideCount={summary?.totalItems}
              sideLabel="Total Stock Keeping Units"
              count={summary?.availableItems}
              icon={Inventory2OutlinedIcon}
              label="Available Stock Keeping Units"
            />
            <InventoryWidget
              // sideCount={summary?.variantsAdded}
              sideCount={summary?.noStockItems}
              sideLabel="Empty Stock Keeping Units"
              count={summary?.lowStockItems}
              // count={summary?.productsAdded}
              icon={Inventory2OutlinedIcon}
              label="Low Stock Keeping Units"
            />
          </Box>
          <Box
            sx={{
              position: 'sticky',
              top: '-1px',
              zIndex: 10,
              backgroundColor: colors.background,
              paddingBlock: SIZES.paddingS,
            }}
          >
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pb={SIZES.paddingS}>
              <Typography variant="h5" fontWeight="bold">
                Product Logs
              </Typography>
              <RegularButton
                label="View All"
                onClick={() => {
                  setTransactionSearchQuery('');
                  setOpenViewAll(true);
                }}
                variant="text"
                size="small"
              />
            </Box>
            <Box display={'flex'} gap="5px" justifyContent={'space-between'}>
              <SearchBar handleSearch={(searchKey) => setTransactionSearchQuery(searchKey)} />
              <CustomFilter
                optionList={[
                  { label: 'Product In', id: 'in' },
                  { label: 'Product Out', id: 'out' },
                  { label: 'Product Transfer', id: 'transfer' },
                  { label: 'Stock Adjustment', id: 'adjustment' },
                  { label: 'Reversal', id: 'reversal' },
                ]}
                selected={transactionFilters}
                setSelected={setTransactionFilters}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={SIZES.paddingS}
            paddingRight={'5px'}
            // height="calc(100vh + 72px)"
            // sx={{ overflowY: 'auto' }}
          >
            {transactions.length > 0 ? (
              transactions.map((transaction, index) => {
                switch (transaction.type) {
                  case 'in':
                    return <ProductInTransaction key={index} transaction={transaction} />;
                  case 'out':
                    return <ProductOutTransaction key={index} transaction={transaction} />;
                  case 'transfer':
                    return <ProductTransferTransaction key={index} transaction={transaction} />;
                  case 'adjustment':
                    return <StockAdjustmentTransaction key={index} transaction={transaction} />;
                  case 'reversal':
                    return <ReversalTransaction key={index} transaction={transaction} />;
                  default:
                    return null;
                }
              })
            ) : (
              <Typography variant="body1">No results found</Typography>
            )}
          </Box>
        </Box>
      </Box>

      {selectedProduct && (
        <>
          <AddStock
            variant={selectedProduct}
            open={openAddStock}
            setOpen={setOpenAddStock}
            callbackAfterSubmit={() => {
              setRefreshInventory((prev: number) => prev + 1);
              getInventoryTransactions();
            }}
            exclusiveSupplier={selectedProduct.exclusive_supplier}
          />

          <AdjustStock
            variant={selectedProduct}
            open={openAdjustStock}
            setOpen={setOpenAdjustStock}
            callbackAfterSubmit={() => {
              setRefreshInventory((prev: number) => prev + 1);
              getInventoryTransactions();
            }}
            initialStock={parseFloat(selectedProduct.quantity_in_stock)}
          />

          <TransferStock
            variant={selectedProduct}
            open={openTransferStock}
            setOpen={setOpenTransferStock}
            callbackAfterSubmit={() => {
              setRefreshInventory((prev: number) => prev + 1);
              getInventoryTransactions();
            }}
            currentStock={parseFloat(selectedProduct.quantity_in_stock)}
          />

          <UpdatePrice
            variant={selectedProduct}
            open={openUpdatePrice}
            setOpen={setOpenUpdatePrice}
            callbackAfterSubmit={() => {
              setRefreshInventory((prev: number) => prev + 1);
              getInventoryTransactions();
            }}
          />

          <UpdateDetails
            variant={selectedProduct}
            open={openUpdateDetails}
            setOpen={setOpenUpdateDetails}
            callbackAfterSubmit={() => {
              setOpenUpdateDetails(false);
              setRefreshInventory((prev: number) => prev + 1);
              getInventoryTransactions();
            }}
          />
        </>
      )}

      <CustomModal open={openViewAll} setOpen={setOpenViewAll} width={1000} header={'Product Logs (All)'}>
        <ProductLogsScroller ref={productLogsScrollerRef} />
      </CustomModal>

      <CustomModal header={'Add new product'} open={openProductForm} setOpen={setOpenProductForm}>
        <ProductForm callbackAfterSubmit={handleOpenSnackBar} />
      </CustomModal>

      <CustomModal
        header={'Add new variant'}
        open={openExclusiveProductModal}
        setOpen={setOpenExclusiveProductModal}
        width={800}
      >
        <ProductVariantForm callbackAfterSubmit={handleOpenSnackBar} />
      </CustomModal>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleCloseSnackBar}
        message={
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <CheckCircleIcon fontSize="small" sx={{ color: '#69BA80' }} />
            <Typography sx={{ fontSize: '14px' }}>
              {forVariant ? `Variant successfully created!` : `Product successfully created!`}
            </Typography>
          </Box>
        }
        action={snackBarAction}
      />
    </>
  );
};

export default ClinicInventory;

import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'variant-attributes';

export const getVariantAttributeById = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getVariantAttributes = (query?: ApiQuery, category_id?: number) => {
  return axios.get(`${baseURL}/${path}`, {
    params: {
      ...query,
      category_id,
    },
  });
};

export const getVariantAttributeValues = () => {
  return axios.get(`${baseURL}/${path}/list/values`);
};

export const createVariantAttribute = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateVariantAttribute = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteVariantAttribute = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getAllGroups, getGroup } from 'company/api/service-groups';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { useContext } from 'react';

const ServiceGroupSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  const { facility } = useContext(FacilityContext);
  function getData(query: ApiQuery) {
    return getAllGroups(facility.id, query);
  }
  function getSingleData(id: number) {
    return getGroup(id);
  }
  return (
    <TemplateDropdown
      {...props}
      entity={'Service Package'}
      getData={(query: ApiQuery) => getData({ ...query, order_by: undefined })}
      getById={(id: number) => getSingleData(id)}
      processOption={(record) => ({ key: record.group_name, value: record.id, data: { ...record } })}
      processNewRecord={(record) => record.data}
    />
  );
};

export default ServiceGroupSearchDropdown;

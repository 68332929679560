import * as yup from 'yup';

import { CustomForm, CustomModal } from 'core/components';
import { getLotsForVariant, updateInventoryQuantity } from 'company/api/inventories';
import { useContext, useEffect, useState } from 'react';

import { ClinicModel } from '../../ClinicManagement/Clinic/ClinicModel';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { ProductVariantModel } from '../Product/ProductVariant/ProductVariantModel';
import { formatProductVariantName } from 'core/utils';
import { useSnackbar } from 'notistack';

const addQuantitySchema = yup.object().shape({
  lot_id: yup.string().required('Lot ID is required'),
  quantity: yup
    .number()
    .required('Quantity is required.')
    .typeError('Must be a number.')
    .min(1, 'Quantity must be greater than 0.')
    .test('decimal', 'Up to 6 decimal places only.', (value) => {
      if (value === undefined || value === null) return false;
      return /^\d+(\.\d{1,6})?$/.test(value.toString());
    }),
  reason: yup.string().required('Purpose is required.'),
});

const reasonList = [
  {
    key: 'Correction',
    value: 'correction',
  },
  {
    key: 'Damaged',
    value: 'damaged',
  },
  {
    key: 'Loss',
    value: 'loss',
  },
  {
    key: 'Expired',
    value: 'expired',
  },
  {
    key: 'Other',
    value: 'other',
  },
];

type Props = {
  variant: ProductVariantModel;
  open: boolean;
  setOpen: (open: boolean) => void;
  facilityDetails?: ClinicModel;
  callbackAfterSubmit?: (data?: any) => void;
  initialStock: number;
};

const AdjustStock: React.FC<Props> = ({
  variant,
  initialStock,
  open,
  setOpen,
  facilityDetails,
  callbackAfterSubmit,
}) => {
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [lotOptions, setLotOptions] = useState<Array<{ key: string; value: number }>>([]);

  const addQuantityFields: FieldInput[] = [
    {
      field_name: 'quantity',
      display_name: 'Set Quantity',
      type: 'text',
      span: 2,
    },
    { field_name: 'reason', display_name: 'Purpose', type: 'select', span: 2, options: reasonList },
    {
      field_name: 'lot_id',
      display_name: 'Lot Number',
      type: 'select',
      span: 2,
      options: lotOptions,
    },
  ];

  useEffect(() => {
    if (variant.id && facility.id) {
      getLotsForVariant(variant.id, facility.id)
        .then((response) => {
          const lotsData = response.data.data;

          const transformedLotOptions = lotsData.map((lot: any) => ({
            key: `${lot.lot_number} (Exp: ${lot.expiry_date}) - ${lot.quantity_in_stock} units`,
            value: lot.id,
          }));

          setLotOptions(transformedLotOptions);
        })
        .catch((error) => {
          console.error('Error fetching lots:', error);
        });
    }
  }, [variant.id, facility.id, open]);

  const handleSubmit = async (data: any) => {
    setLoading(true);
    const clinicId = facilityDetails ? facilityDetails.id : facility.id;
    updateInventoryQuantity(variant.id, { ...data, facility_id: clinicId })
      .then(() => {
        enqueueSnackbar('Stock successfully adjusted!', { variant: 'success' });
        setOpen(false);
        callbackAfterSubmit && callbackAfterSubmit();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      header={`Adjust Stock - ${formatProductVariantName(variant)}`}
      subHeader={facilityDetails ? facilityDetails.facility_name : facility?.facility_name}
    >
      <CustomForm
        fields={addQuantityFields}
        schema={addQuantitySchema}
        initialValues={{
          quantity: Number(initialStock.toFixed(6)),
          reason: 'correction',
          product_id: variant.product_id,
          lot_id: '',
        }}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </CustomModal>
  );
};

export default AdjustStock;

import React, { useState } from 'react';
import { Badge, Box, Popover, Typography, useMediaQuery, useTheme } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { tokens } from 'theme/theme';
import RegularButton from './buttons/RegularButton';
import CustomCheckbox from './CustomCheckbox';

interface FilterOption {
  id: string;
  label: string;
}

interface FilterGroup {
  id: string;
  label: string;
  options: FilterOption[];
}

interface SelectedFilters {
  [groupId: string]: string[];
}

interface GroupedFiltersProps {
  filterGroups: FilterGroup[];
  selectedFilters: SelectedFilters;
  onFilterChange: (filters: SelectedFilters) => void;
  buttonLabel?: string;
  buttonSize?: 'small' | 'medium' | 'large';
  customIcon?: React.ReactNode;
  variant?: 'text' | 'outlined' | 'contained';
  noOptionContent?: React.ReactNode;
}

const GroupedFilters: React.FC<GroupedFiltersProps> = ({
  filterGroups,
  selectedFilters,
  onFilterChange,
  buttonLabel = 'Filters',
  buttonSize,
  customIcon,
  variant = 'outlined',
  noOptionContent,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openFilterPopover = Boolean(anchorEl);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (groupId: string, optionId: string) => {
    const updatedFilters = { ...selectedFilters };
    const groupFilters = [...(updatedFilters[groupId] || [])];

    const filterIndex = groupFilters.indexOf(optionId);
    if (filterIndex === -1) {
      updatedFilters[groupId] = [...groupFilters, optionId];
    } else {
      updatedFilters[groupId] = groupFilters.filter((id) => id !== optionId);
      // Remove the group if it becomes empty
      if (updatedFilters[groupId].length === 0) {
        delete updatedFilters[groupId];
      }
    }

    onFilterChange(updatedFilters);
  };

  const handleClearAll = () => {
    onFilterChange({});
  };

  const handleSelectAll = (groupId: string) => {
    const group = filterGroups.find((g) => g.id === groupId);
    if (!group) return;

    const updatedFilters = { ...selectedFilters };
    updatedFilters[groupId] = group.options.map((opt) => opt.id);
    onFilterChange(updatedFilters);
  };

  const getTotalSelected = () => {
    return Object.values(selectedFilters).reduce((total, group) => total + group.length, 0);
  };

  const hasAnyOptions = filterGroups.some((group) => group.options.length > 0);

  return (
    <>
      <Badge
        badgeContent={getTotalSelected()}
        color="primary"
        sx={{
          '& .MuiBadge-badge': {
            top: 3,
            right: 5,
            bgcolor: getTotalSelected() > 0 ? undefined : 'transparent',
            color: getTotalSelected() > 0 ? undefined : 'transparent',
          },
        }}
      >
        <RegularButton
          aria-describedby="filter-popover"
          startIcon={customIcon ?? <FilterListIcon />}
          variant={variant}
          color="primary"
          onClick={handleClick}
          label={buttonLabel}
          styles={{
            '@media screen and (max-width: 768px)': { width: '100%', height: '40px', padding: '20px' },
          }}
          size={buttonSize}
        />
      </Badge>

      <Popover
        id="filter-popover"
        open={openFilterPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiSvgIcon-root': { color: colors.primary },
          '& .MuiPaper-root': { borderRadius: '15px', width: isMobile ? '90%' : 'auto', maxWidth: '90vw' },
        }}
      >
        <Box padding="15px 10px" display="flex" flexDirection="column" gap="5px">
          {!hasAnyOptions ? (
            noOptionContent || (
              <Box padding="10px">
                <Typography>No options available</Typography>
              </Box>
            )
          ) : (
            <>
              {filterGroups.map(
                (group) =>
                  group.options.length > 0 && (
                    <Box key={group.id}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ paddingInline: '5px' }}
                      >
                        <Typography flex={1} fontWeight="600">
                          {group.label}
                        </Typography>
                        <RegularButton
                          styles={{ flex: 1 }}
                          variant="text"
                          label="Select All"
                          onClick={() => handleSelectAll(group.id)}
                          size="small"
                        />
                      </Box>
                      <Box
                        display="grid"
                        gridTemplateColumns={isMobile ? '1fr' : 'repeat(2, 1fr)'}
                        columnGap="7px"
                        rowGap="7px"
                        sx={{
                          paddingInline: '5px',
                        }}
                      >
                        {group.options.map((option) => (
                          <Box
                            key={option.id}
                            display="flex"
                            alignItems="left"
                            flexDirection="column"
                            sx={{ maxWidth: '100%', textWrap: 'wrap', wordBreak: 'break-word' }}
                          >
                            <CustomCheckbox
                              style={{
                                color: colors.accent,
                                padding: '5px 12px',
                                backgroundColor: 'rgba(21, 129, 196, 0.04)',
                                borderRadius: '10px',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '100%',
                              }}
                              label={option.label}
                              handleChange={(e) => handleFilterChange(group.id, option.id)}
                              value={selectedFilters[group.id]?.includes(option.id)}
                              fieldName={option.id}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )
              )}

              <Box display="flex" gap="10px" mt="20px">
                {getTotalSelected() > 0 && (
                  <RegularButton
                    variant="contained"
                    color="primary"
                    onClick={handleClearAll}
                    label="Clear"
                    size="small"
                    fullWidth
                  />
                )}
                <RegularButton
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  fullWidth
                  label="Done"
                />
              </Box>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default GroupedFilters;

import { Box, Typography } from '@mui/material';
import { CustomModal, RegularButton } from 'core/components';
import {
  PRODUCT_VARIANT_ENTITY_NAME,
  ProductVariantModel,
  createProductVariantModelSchema,
  productVariantFields,
  productVariantFormFields,
  productVariantFormFieldsExclusive,
  productVariantFormFieldsExclusiveWithProduct,
  productVariantInitialValues,
  productVariantUpdateFormFields,
  updateProductVariantModelSchema,
} from './ProductVariantModel';
import { createVariant, updateVariant } from 'company/api/inventories';
import { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ContainerColumn } from 'core/components/containers';
import { FacilityContext } from 'core/context/facility.context';
import { ProductModel } from '../ProductModel';
import SearchBar from 'company/screens/InventorySystem/POS/components/SearchBar';
import TemplateForm from 'company/entities/Template/TemplateForm';
import VariantAttributeForm from '../../VariantAttribute/VariantAttributeForm';
import { VariantAttributeModel } from '../../VariantAttribute/VariantAttributeModel';
import VariantAttributeValueSearchDropdown from 'company/components/dropdown/VariantAttributeValueSearchDropdown';
import { getVariantAttributes } from 'company/api/variant-attributes';

type Props = {
  product?: ProductModel;
  productVariant?: ProductVariantModel;
  supplierId?: number;
  callbackAfterSubmit?: (data?: any) => void;
  variantAttributes?: any[];
};

const ProductVariantForm: React.FC<Props> = (props) => {
  const { product, productVariant, supplierId, callbackAfterSubmit, variantAttributes } = props;
  const { facility } = useContext(FacilityContext);
  const [attributes, setAttributes] = useState<VariantAttributeModel[]>([]);
  const [filteredAttributes, setFilteredAttributes] = useState<VariantAttributeModel[]>([]);
  const [attributeValues, setAttributeValues] = useState<any>({});
  const [isAttributeModalOpen, setIsAttributeModalOpen] = useState(false);

  const handleSearch = (searchKey: string) => {
    if (!searchKey) {
      setFilteredAttributes(attributes);
      return;
    }

    const filtered = attributes.filter((attribute) =>
      attribute.attribute_name?.toLowerCase().includes(searchKey.toLowerCase())
    );
    setFilteredAttributes(filtered);
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleFormSubmit = (data: ProductVariantModel) => {
    const output: any = {};

    for (const key in attributeValues) {
      if (attributeValues.hasOwnProperty(key)) {
        output[key] = attributeValues[key] ? [attributeValues[key].value] : [];
      }
    }

    data.attribute_ids = JSON.stringify(output);
    data.variant_name = product?.product_name ? product?.product_name : 'Standard';
    return productVariant
      ? updateVariant(productVariant.id, data)
      : createVariant(facility.id, (product?.id ?? data.product_id ?? null) as number, {
          ...data,
          exclusive_supplier: supplierId ?? data.exclusive_supplier,
        });
  };

  const handleAttributeSubmit = (data: any) => {
    getVariantAttributes({ length: 100 }, product?.category_id as number).then((res) => {
      setAttributes(res.data.data);
    });
    setIsAttributeModalOpen(false);
  };

  useEffect(() => {
    getVariantAttributes({ length: 100 }, product?.category_id as number).then((res) => {
      setAttributes(res.data.data);
    });
  }, [product?.category_id]);

  return (
    <>
      <TemplateForm
        hideDefaultSnackbar
        submitButtonId={'create_product_variant_submit_button'}
        entity={productVariant}
        entityName={PRODUCT_VARIANT_ENTITY_NAME}
        fields={productVariantFields}
        visibleFields={
          productVariant
            ? productVariantUpdateFormFields
            : !!supplierId
            ? productVariantFormFieldsExclusive
            : product
            ? productVariantFormFields
            : productVariantFormFieldsExclusiveWithProduct
        }
        schema={productVariant ? updateProductVariantModelSchema : createProductVariantModelSchema}
        initialValues={productVariantInitialValues}
        formSubmitApiFunction={handleFormSubmit}
        callbackAfterSubmit={callbackAfterSubmit}
        customFields={[
          {
            type: 'component',
            fieldName: 'attributes',
            component: (
              <Box>
                {!!attributes.length && (
                  <ContainerColumn sx={{ rowGap: '24px' }}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <Typography variant="h5" fontWeight="bold">
                        Attributes
                      </Typography>
                      <Box display={'flex'} alignItems={'center'} columnGap={'12px'} onKeyDown={handleSearchKeyDown}>
                        {attributes.length > 4 && (
                          <SearchBar handleSearch={handleSearch} styles={{ width: '160px !important' }} />
                        )}
                        <RegularButton
                          startIcon={<AddIcon />}
                          label="Attribute"
                          onClick={() => setIsAttributeModalOpen(true)}
                        />
                      </Box>
                    </Box>

                    <Box
                      display="grid"
                      gridTemplateColumns="1fr 1fr"
                      gap="25px"
                      sx={{ maxHeight: '160px', overflowY: attributes.length > 4 ? 'scroll' : 'hidden', pr: '12px' }}
                    >
                      {(filteredAttributes.length ? filteredAttributes : attributes).map((attribute) => (
                        <VariantAttributeValueSearchDropdown
                          key={attribute.id}
                          attribute_id={attribute.id}
                          initialValue={
                            variantAttributes && variantAttributes[attribute.id]
                              ? variantAttributes[attribute.id][0].value_id
                              : ''
                            // variantAttributes
                            //   ? variantAttributes[attribute.id]?.map((value: any) => ({
                            //       key: value.value,
                            //       value: value.value_id,
                            //     }))
                            //   : []
                          }
                          handleChangeCallback={(value) =>
                            setAttributeValues((prev: any) => {
                              prev[attribute.id] = value;
                              return { ...prev };
                            })
                          }
                        />
                      ))}
                    </Box>
                  </ContainerColumn>
                )}

                {/* <Divider /> */}
              </Box>
            ),
          },
        ]}
      />

      <CustomModal
        open={isAttributeModalOpen}
        setOpen={setIsAttributeModalOpen}
        header="Add New Attribute"
        onClose={() => setIsAttributeModalOpen(false)}
        width={600}
      >
        <VariantAttributeForm
          callbackAfterSubmit={handleAttributeSubmit}
          // If product has category, pre-populate it
          attribute={
            product?.category_id
              ? ({
                  category_id: [
                    {
                      value: product.category_id,
                    },
                  ],
                } as VariantAttributeModel)
              : undefined
          }
        />
      </CustomModal>
    </>
  );
};

export default ProductVariantForm;

import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getCompanyPaymentModes, getSinglePaymentModeByName } from 'company/api/payment-modes';

import { ApiQuery } from 'core/model/interface';
import { PaymentModeModel } from 'company/screens/ClinicManagement/components/PaymentModes/CompanyPaymentModeModel';

interface PaymentModeDropdownProps extends TemplateDropdownProps {}

const PaymentModesSearchDropdown: React.FC<PaymentModeDropdownProps> = (props: PaymentModeDropdownProps) => {
  function getData(query: ApiQuery) {
    return getCompanyPaymentModes(query);
  }

  function getSingleData(id: number) {
    return getSinglePaymentModeByName(id + '');
  }

  return (
    <TemplateDropdown
      {...props}
      entity={'Payment Mode'}
      getData={(query: ApiQuery) => getData(query)}
      getById={(id: number) => getSingleData(id)}
      // processOption={(record) => ({ key: record.service_category_name, value: record.id, data: { ...record } })}
      processOption={(record: PaymentModeModel) => ({ key: record.payment_method, value: record.payment_method_code })}
      processNewRecord={(record) => record.data}
    />
  );
};

export default PaymentModesSearchDropdown;

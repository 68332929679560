import { ApiQuery, FORM_MODE } from 'core/model/interface';
import { CustomModal, FileUpload } from 'core/components';
import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PAYMENT_ENTITY_NAME, paymentFields, paymentTableColumns } from './PaymentModel';
import { formatCurrency, formatDate, formatPaymentMethodLabel } from 'core/utils';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { getPayments, getPaymentsByInvoiceId, uploadProofOfPayment } from 'company/api/billing-payment';

import { Box } from '@mui/system';
import { Carousel } from 'core/components/containers';
import { FacilityContext } from 'core/context/facility.context';
import { PatientBillingModel } from '../PatientBilling/PatientBillingModel';
import PaymentForm from './PaymentForm';
import PostAddIcon from '@mui/icons-material/PostAdd';
import TemplateTable from '../../../Template/TemplateTable';
import VrpanoOutlinedIcon from '@mui/icons-material/VrpanoOutlined';
import { WaitForFacility } from 'company/components';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type Props = {
  invoice?: PatientBillingModel;
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
};

const PaymentTable = forwardRef((props: Props, ref) => {
  const { invoice, visibleColumns, setDrawerSelectedRow, drawerSelectedRow, tableRef } = props;
  const { facility } = useContext(FacilityContext);

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const [selectedRow, setSelectedRow] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();

  const [paymentsByInvoiceId, setPaymentsByInvoiceId] = useState<any>(null);
  const [openProofOfPaymentModal, setOpenProofOfPaymentModal] = useState<boolean>(false);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery('(max-width:768px)');
  const getData = (query: ApiQuery) => {
    if (invoice) {
      return getPaymentsByInvoiceId(facility.id, invoice.id, { ...query, length: 1000 });
    }
    return getPayments(facility.id, query);
  };

  useEffect(() => {
    async function getData(query: ApiQuery) {
      if (invoice) {
        const res = await getPaymentsByInvoiceId(facility.id, invoice.id, { ...query, length: 1000 });
        setPaymentsByInvoiceId(res.data.data);
      }
    }
    getData({});
  }, [facility?.id, invoice]);

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  async function handleUploadProofOfPayment(data: any) {
    if (!selectedRow || !invoice?.patient_id) return;
    await uploadProofOfPayment(facility.id, selectedRow.id, invoice?.patient_id, data);
    setOpenProofOfPaymentModal(false);
    mainRef.current.refreshTable();
    enqueueSnackbar('Proof of payment successfully uploaded!', { variant: 'success' });
  }

  // useEffect(() => {
  //   if (facility && invoice) mainRef.current.refreshTable();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [invoice]);
  if (isMobile && paymentsByInvoiceId)
    return (
      <Box sx={{ display: 'flex', gap: '10px', padding: '10px', flexDirection: 'column', mt: '10px' }}>
        {paymentsByInvoiceId.map((payment: any) => {
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>
                    {formatDate(payment.payment_date)}
                  </Typography>
                  <Typography>{formatPaymentMethodLabel(payment.payment_method)}</Typography>
                </Box>
                <Typography sx={{ fontSize: '1rem', fontWeight: '600', color: colors.primary }}>
                  {formatCurrency(payment.amount)}
                </Typography>
              </Box>
              <Divider />
            </>
          );
        })}
      </Box>
    );

  return (
    <WaitForFacility facility={!!facility && !!invoice}>
      <TemplateTable
        tableAction={{
          rowActions: [
            {
              style: { flex: 2 },
              label: 'View Proof of Payment',
              action: (data) => {
                setOpenPreview(true);
              },
              icon: <VrpanoOutlinedIcon />,
            },
            {
              style: { flex: 2 },
              label: 'Update Proof Of Payment',
              action: (data) => {
                setOpenProofOfPaymentModal(true);
              },
              icon: <PostAddIcon />,
            },
          ],
        }}
        ref={mainRef}
        entityName={PAYMENT_ENTITY_NAME}
        fields={paymentFields}
        visibleColumns={visibleColumns ?? paymentTableColumns}
        templateForm={
          <PaymentForm payment={selectedRow} invoice={invoice!} callbackAfterSubmit={callbackAfterSubmit} />
        }
        hideAddUpdateDeleteAction
        hideSearch
        hidePagination
        getData={getData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        tableComponent={{
          initialOrder: 'desc',
          initialOrderBy: 'payment_date',
        }}
      />
      <CustomModal open={openProofOfPaymentModal} setOpen={setOpenProofOfPaymentModal} header="Upload proof of payment">
        <FileUpload
          maxFilesAllowed={1}
          maxFilesonDrop={1}
          source="proof-of-payment"
          formMode={FORM_MODE.RESPOND}
          uploadOnly
          fileTypes={{ 'image/*': [] }}
          handleUploadFile={(formData) => handleUploadProofOfPayment(formData)}
        />
      </CustomModal>

      <CustomModal open={openPreview} setOpen={setOpenPreview} header="Proof of Payment">
        {selectedRow?.file_id && (
          <Carousel
            hideViewInModal
            files={[
              {
                base_url: selectedRow.base_url,
                file_name: selectedRow.file_name,
                file_path: selectedRow.file_path,
                file_size: selectedRow.file_size,
                file_type: selectedRow.file_type,
                id: selectedRow.file_id,
              },
            ]}
          ></Carousel>
        )}

        {!selectedRow?.file_id && <Typography>No uploaded proof of payment yet.</Typography>}
      </CustomModal>
    </WaitForFacility>
  );
});

export default PaymentTable;

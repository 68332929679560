import { Box, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode, useMemo } from 'react';

import { tokens } from 'theme/theme';

type Props = {
  label: string;
  value?: string | number | ReactNode | any[];
  sx?: SxProps<Theme>;
  valueIsComponent?: boolean;
  fieldFontSize?: string;
  fontSizes?: {
    header: string;
    body: string;
  };
  direction?: 'column' | 'row';
  fieldStyles?: SxProps<Theme>;
};

const PatientField: React.FC<Props> = ({
  label,
  value,
  sx,
  valueIsComponent,
  fieldFontSize,
  fontSizes,
  direction = 'column',
  fieldStyles,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const displayedValue = useMemo(() => {
    if (!valueIsComponent) {
      return Array.isArray(value) ? value.join(', ') : value;
    }
    return '';
  }, [value, valueIsComponent]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1px',
        flexDirection: direction,
        ...sx,
      }}
    >
      <Typography
        color={colors.primary}
        fontSize={isMobilePhone ? '12px' : fieldFontSize ?? '12px'}
        sx={{ ...fieldStyles }}
      >
        {label.includes(':') ? label : `${label}: `}
      </Typography>
      {valueIsComponent ? (
        value
      ) : (
        <Typography
          sx={{ textWrap: 'wrap', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
          fontSize={fontSizes ? fontSizes.body : isMobilePhone ? '12px' : fieldFontSize ?? '13px'}
        >
          {displayedValue || '-'}
        </Typography>
      )}
    </Box>
  );
};

export default PatientField;
